import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { Select, SelectListItem, SelectProps } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { StreamListStream } from 'main/services/queries/types'

type StreamSelectProps = Omit<
  SelectProps<StreamListStream, number>,
  'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'
>

export type StreamFieldProps<TFieldValues extends FieldValues> = FieldProps<StreamSelectProps, TFieldValues> & {
  streams: StreamListStream[]
}

export const StreamSelectField = <TFieldValues extends FieldValues>({
  streams,
  ...props
}: StreamFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        // @ts-ignore
        <Select<StreamListStream>
          {...props}
          {...inputProps}
          labelKey="name"
          valueKey="id"
          filterKeys={['name']}
          renderOption={(streamOption, renderProps) => (
            <SelectListItem
              {...renderProps}
              disabled={streamOption.disabled}
              label={streamOption.name}
              level={streamOption.parent_id ? 2 : 1}
            />
          )}
          inputRef={ref}
          onBlur={onBlur}
          onChange={onChange}
          options={streams}
          value={value}
          includeParentsOnSearch
        />
      )}
    />
  )
}
