import { useCallback, useContext } from 'react'

import { WebsocketContext } from 'main/context/websockets-provider'

type SubscribeOptions = {
  received?: (data: any) => void
  disconnected?: () => void
  connected?: () => void
}

export const useWebsockets = () => {
  const cable = useContext(WebsocketContext)

  const getChannelData = useCallback((channel: string, id: string | number, key: string) => {
    return {
      channel,
      id,
      key: key ? key : `${channel}-${id}`,
      data: { id }
    }
  }, [])

  const unsubscribe = useCallback((channel: string, id: string | number = '', key: string) => {
    const subscriptions = cable.subscriptions as any

    const existingSubscription: any = subscriptions.subscriptions.find(
      (sub: any) => JSON.stringify(getChannelData(channel, id, key)) === sub.identifier
    )

    existingSubscription?.unsubscribe()
  }, [])

  const subscribe = useCallback((channel: string, id: string | number, key: string, options: SubscribeOptions) => {
    return cable.subscriptions.create(getChannelData(channel, id, key), {
      ...options,
      received: data => {
        if (!data) return

        try {
          if (typeof data === 'string') {
            data = JSON.parse(data)
          }
        } catch (e) {
          console.error(`Couldn't parse payload from WebSocket message (${channel}):`, e)
        }
        options?.received?.(data)
      }
    })
  }, [])

  const findExistingSubscription = useCallback((channel: string, id: string | number = '', key: string) => {
    return (cable.subscriptions as any).subscriptions.find(
      (sub: any) => JSON.stringify(getChannelData(channel, id, key)) === sub.identifier
    )
  }, [])

  return {
    unsubscribe,
    subscribe,
    findExistingSubscription
  }
}
