import {
  ChangeEvent,
  ChangeEventHandler,
  CompositionEvent,
  FocusEventHandler,
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  memo,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { KeyboardType } from 'grommet/utils'
import styled from 'styled-components'
import {
  autoUpdate,
  offset,
  ReferenceType,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole
} from '@floating-ui/react'
import { mergeRefs } from 'use-callback-ref'

import {
  Avatar,
  AvatarGroup,
  AvatarSubject,
  Box,
  durationParser,
  duration as formatDuration,
  TaskItemDateData,
  TaskItemIcon,
  TaskItemLayout,
  Text,
  TextInput,
  TextInputProps,
  themeColor,
  Tooltip,
  useOnScreen,
  useUpdateEffect
} from '@cutover/react-ui'
import { TaskTypeIconName } from '@cutover/icons'
import { useNewTaskStreamId } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import {
  RunbookTimingMode,
  RunbookVersion,
  StreamListStream,
  TaskListTask,
  TaskType,
  RunbookTemplateRunbook as Template
} from 'main/services/queries/types'
import { createTask, TaskCreatePayload } from 'main/services/queries/use-task'
import { TaskSuccessorsModal } from 'main/components/runbook/modals/task-modals/task-successors-modal'
import { RunbookTaskCreateResponse } from 'main/services/api/data-providers/runbook-types'
import { TaskItemCreateMenu } from './task-item-create-menu'
import {
  TaskShortcutMenuItem,
  useMatchStreamsMenu,
  useMatchTaskTypesMenu,
  useMatchUsersAndTeamsMenu
} from 'main/recoil/data-access/menu-builder-access-hooks__TEMPORARY'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ConfigModel,
  CurrentRunbookVersionModel,
  RunbookViewModel,
  StreamModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { createDateDisplayForScheduled, createDateDisplayForUnscheduled } from './task-item-duration-helpers'

const DURATION_MATCH = new RegExp(/^\d+(\.\d+)?[dhms](\d+(\.\d+)?[dhms])*$/)

/**
 * TaskItemCreateToggle toggle for showing/hiding TaskItemCreate
 */
export const TaskItemCreateToggle = memo(
  ({ predecessorId, nextTaskId }: { predecessorId: number; nextTaskId?: number }) => {
    const toggleCreateInput = RunbookViewModel.useAction('taskCreate:toggle')
    const { predecessor: currentPredecessor } = RunbookViewModel.useGet('taskCreate')

    const handleToggle = useCallback(() => {
      toggleCreateInput({ predecessor: predecessorId })
    }, [predecessorId])

    return predecessorId === currentPredecessor ? (
      <TaskItemCreate onCancel={handleToggle} nextTaskId={nextTaskId} />
    ) : null
  }
)

export const useRootTaskCreateForm = ({ taskCount }: { taskCount: number }) => {
  const newTaskStreamId = useNewTaskStreamId()
  const { can: canCreateRootTask } = RunbookViewModel.usePermission('create:tasks')
  const openTaskCreateForm = RunbookViewModel.useAction('taskCreate:open')
  const closeTaskCreateForm = RunbookViewModel.useAction('taskCreate:close')

  const { predecessor: currentPredecessor, name } = RunbookViewModel.useGet('taskCreate')
  const showRootTaskForm = canCreateRootTask && currentPredecessor === 0

  // Note: currentPredecessor stores the state of the current new task form. '0' is for the root task input
  // If there are no results and the user has permission, open it by default (set to 0)
  useEffect(() => {
    if (currentPredecessor && currentPredecessor > 0) return

    const rootTaskFormAutoOpen = taskCount === 0 && canCreateRootTask
    if (rootTaskFormAutoOpen) return openTaskCreateForm({ predecessor: 0 })

    // Close create form if there is no editing value
    if (name === undefined) return closeTaskCreateForm()
  }, [taskCount, canCreateRootTask, newTaskStreamId])

  return showRootTaskForm ? <TaskItemCreateToggle predecessorId={0} /> : null
}

export type NewTaskInputProps = TextInputProps & {
  onCancel?: any
  onCreate?: () => void
  taskTypes?: TaskType[]
  streams?: StreamListStream[]
  streamLookup?: Record<number, StreamListStream>
  runbookTimingMode?: RunbookVersion['timing_mode']
  runbookStartPlanned?: RunbookVersion['start_planned']
  canAdd?: boolean
  nextTaskId?: number
}

type MenuType = 'taskTypes' | 'usersAndTeams' | 'streams'

type NewTaskState = Omit<TaskCreatePayload['task'], 'stream_id' | 'task_type_id'>
type LinkedResourceState = TaskCreatePayload['linked_resource']

const getInitialDate = ({
  previousTaskStartDisplay,
  previousTaskEndDisplay,
  runbookStartPlanned,
  timingMode,
  activeTimezone,
  dayLabel
}: {
  previousTaskStartDisplay?: number
  previousTaskEndDisplay?: number
  runbookStartPlanned?: number | null
  timingMode?: RunbookTimingMode
  activeTimezone?: string | null
  dayLabel: string
}) => {
  if (timingMode === 'scheduled') {
    return createDateDisplayForScheduled({
      startDisplay: previousTaskEndDisplay ?? runbookStartPlanned ?? null,
      activeTimezone
    })
  } else {
    return createDateDisplayForUnscheduled({
      taskStartDate: previousTaskEndDisplay ?? runbookStartPlanned ?? 0,
      runbookStartPlanned: runbookStartPlanned ?? 0,
      previousStartDisplay: previousTaskStartDisplay,
      dayLabel
    })
  }
}

const DEFAULT_NEW_TASK_LEVEL = 'level_3'

/**
 * TaskItemCreate w/ data from recoil state
 */
export const TaskItemCreate = memo(({ onCancel, nextTaskId }: NewTaskInputProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [menuItems, setMenuItems] = useState<TaskShortcutMenuItem[] | undefined>(undefined)
  const [filteredMenuItems, setFilteredMenuItems] = useState<TaskShortcutMenuItem[] | undefined>(undefined)
  const [activeIndex, setActiveIndex] = useState<number | null>(0)
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')
  const { refs, floatingStyles, context } = useFloating({
    open: isMenuOpen,
    onOpenChange: setMenuOpen,
    whileElementsMounted: autoUpdate,
    placement: 'top-start',
    middleware: [offset(6)]
  })
  const listRef = useRef<Array<HTMLElement | null>>([])
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true
  })
  const role = useRole(context, { role: 'listbox' })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNavigation])
  const [menuType, setMenuType] = useState<MenuType | undefined>(undefined)
  const [afterSelectionSubstring, setAfterSelectionSubstring] = useState<string | undefined>(undefined)
  const inputRef = useRef<HTMLInputElement>(null)
  const isInputVisible = useOnScreen(inputRef)
  const [isSuccessorsModalOpen, setSuccessorsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLinkedTemplateOpen, setIsLinkedTemplateOpen] = useState(false)
  const toggleCreateTaskInput = RunbookViewModel.useAction('taskCreate:toggle')
  const { predecessor: createTaskPredecessor } = RunbookViewModel.useGet('taskCreate')
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const { slug: accountSlug } = ActiveAccountModel.useGet()
  const { timing_mode: runbookTimingMode, start_planned: runbookStartPlanned } = CurrentRunbookVersionModel.useGet()
  const previousTask = TaskModel.useGet(createTaskPredecessor || 0)
  const processTaskCreateResponse = TaskModel.useOnAction('create')
  const previousTaskType = TaskTypeModel.useGet(previousTask?.task_type_id)
  const newTaskStreamId = useNewTaskStreamId({ prevTaskStreamId: previousTask?.stream_id })
  const newTaskStream = StreamModel.useGet(newTaskStreamId || 0)
  const parentStream = StreamModel.useGet(newTaskStream?.parent_id || 0)
  const initialTaskType = TaskTypeModel.useGet(
    (newTaskStream?.default_task_type_id || parentStream?.default_task_type_id) ?? undefined
  )
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const { taskLevels } = ConfigModel.useGet()
  const openModal = RunbookViewModel.useAction('modal:open')

  const initialLevel = useMemo(() => {
    // eslint-disable-next-line
    return taskLevels.find(level => level.id === (previousTask?.level || DEFAULT_NEW_TASK_LEVEL))!
  }, [taskLevels, previousTask?.level])

  const initialDuration = useMemo(
    () => (initialTaskType?.auto_finish ? 0 : initialTaskType?.default_duration),
    [initialTaskType?.auto_finish, initialTaskType?.default_duration]
  )

  const dateData = useMemo(
    () =>
      getInitialDate({
        previousTaskEndDisplay: previousTask?.end_display,
        previousTaskStartDisplay: previousTask?.start_display,
        runbookStartPlanned,
        timingMode: runbookTimingMode,
        activeTimezone,
        dayLabel: t('unscheduledDayLabel')
      }),
    [previousTask?.end_display, runbookStartPlanned, runbookTimingMode, activeTimezone]
  )

  const [dummy, setDummy] = useState<
    (NewTaskState & { dateData: TaskItemDateData; previousTask?: TaskListTask }) | undefined
  >(undefined)
  const [linkedResource, setLinkedResource] = useState<LinkedResourceState | undefined>(undefined)

  const { name: tempTaskName } = RunbookViewModel.useGet('taskCreate')
  const setTempTaskName = RunbookViewModel.useAction('taskCreate:setName')

  const [newTaskData, setNewTaskData] = useState<NewTaskState>({
    disable_notify: false,
    end_fixed: null,
    stream: newTaskStream,
    level: initialLevel.id,
    level_name: initialLevel.name,
    task_type: initialTaskType,
    name: tempTaskName ?? '',
    stage: 'new',
    duration: initialDuration
  })
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [selectedTeams, setSelectedTeams] = useState<number[]>([])
  const [avatarSubjects, setAvatarSubjects] = useState<AvatarSubject[]>([])

  const [isComposing, setIsComposing] = useState(false)

  useUpdateEffect(() => {
    // Update the stream when the url changes
    updateNewTaskData({ stream: newTaskStream })
  }, [newTaskStream])

  const updateNewTaskData = (data: Partial<NewTaskState>) => {
    setNewTaskData(prev => ({ ...prev, ...data }))
  }

  const onClose = () => {
    onCancel?.()
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (isLoading || dummy || newTaskData.name.length > 0 || createTaskPredecessor === 0 || isLinkedTemplateOpen) return
    resetMenu()
    onClose()
  }

  /**
   * Handles the filtering logic based on user input for task names.
   * Matches the last occurrence of special characters ('^', '@', or '$') triggering menu options,
   * filters menu items accordingly, and sets the menu state based on the input.
   *
   * @param {string} tempTaskName - The temporary task name input by the user.
   */
  useUpdateEffect(() => {
    if (tempTaskName && tempTaskName.length > 0) {
      const regex = /[\^@$][^[\^@$]*$/
      const match = tempTaskName.match(regex)

      if (match) {
        const matchedSubstring = match[0].substring(1).toLowerCase()
        const filteredItems = (menuItems ?? []).filter(item => item.label.toLowerCase().includes(matchedSubstring))
        if (filteredItems.length > 0) {
          const remainingSubstring = tempTaskName.replace(match[0], '')
          setAfterSelectionSubstring(remainingSubstring.trim())
          setFilteredMenuItems(filteredItems)
          setMenuOpen(true)
        } else {
          setAfterSelectionSubstring(undefined)
          // Note: Not resetting menu here, since then you couldn't hit backspace. Since menu is setup via keydown triggers
          setMenuOpen(false)
        }
      } else {
        // Note: this is here purely so the duration shortcut gets replaced. Not working in matchduration due the order of things
        stripShortcutMatchFromTaskName()
      }
    } else {
      resetMenu()
    }
  }, [tempTaskName])

  const resetNewTask = useCallback(() => {
    setNewTaskData({
      disable_notify: false,
      end_fixed: null,
      stage: 'new',
      stream: newTaskStream,
      level: initialLevel.id,
      level_name: initialLevel.name,
      task_type: initialTaskType,
      name: '',
      duration: initialDuration
    })
  }, [newTaskStream, initialLevel, initialTaskType])

  const handleKeyDown: KeyboardType = event => {
    event.stopPropagation()
    const input = event.target as HTMLInputElement
    const inputValue = input?.value

    switch (event.key) {
      case 'Enter':
        // In composition mode, users are still inputting intermediate text buffer,
        // hold the enter until composition is done.
        // More about composition events: https://developer.mozilla.org/en-US/docs/Web/API/CompositionEvent
        if (isComposing) {
          return
        }

        if (isMenuOpen) {
          return handleMenuSelection({ activeIndex })
        } else {
          return handleTaskCreate()
        }
      case 'Escape':
        return toggleCreateTaskInput({ predecessor: createTaskPredecessor })
      case '$':
        return matchStreams()
      case '@':
        return matchUsersAndTeams()
      case '^':
        return matchTaskTypes()
      case ' ':
        matchDuration(inputValue)
    }
  }

  const setupAndOpenMenu = useCallback((items: TaskShortcutMenuItem[], type: MenuType) => {
    setActiveIndex(0)
    setMenuType(type)
    setMenuItems(items)
    setFilteredMenuItems(items)
    setMenuOpen(true)
  }, [])

  const matchTaskTypes = useMatchTaskTypesMenu(setupAndOpenMenu)

  const matchUsersAndTeams = useMatchUsersAndTeamsMenu(setupAndOpenMenu, selectedTeams, selectedUsers)

  const matchStreams = useMatchStreamsMenu(setupAndOpenMenu)

  const matchDuration = (inputValue: string) => {
    const words = inputValue.split(/ +/)
    const lastWord = words[words.length - 1]
    if (DURATION_MATCH.test(lastWord)) {
      // word is a duration string, eg 45h30m, convert it to an actual duration
      const durationSeconds = durationParser(lastWord)
      const newName = newTaskData.name.slice(0, -lastWord.length)

      updateNewTaskData({ duration: durationSeconds })
      setAfterSelectionSubstring(newName)
    }
  }

  const handleMenuSelection = ({ activeIndex, item }: { activeIndex?: number | null; item?: TaskShortcutMenuItem }) => {
    if (!filteredMenuItems) return

    let selectedItem
    if (activeIndex !== null && activeIndex !== undefined) {
      selectedItem = filteredMenuItems[activeIndex]
    } else if (item) {
      selectedItem = item
    }

    if (!selectedItem) return

    let taskData: NewTaskState = { ...newTaskData }
    switch (menuType) {
      case 'taskTypes':
        const taskType = taskTypeLookup[selectedItem.id]
        taskData.task_type = taskType
        taskData.duration = taskType.auto_finish ? 0 : taskType.default_duration
        if (taskType.linkable && taskType.key !== 'snippet') {
          setIsLinkedTemplateOpen(true)
          openModal({
            type: 'linked-template-selector',
            data: {
              accountId: accountSlug,
              action: 'create',
              multiselect: false,
              runbookId: runbookId.toString(),
              runbookVersionId: runbookVersionId.toString(),
              onSubmit: (template: Template) => {
                setLinkedResource({
                  id: template.id,
                  type: 'Runbook',
                  meta: {
                    id: template.id,
                    meta: {},
                    name: template.name,
                    runbook_type_id: template.runbook_type_id,
                    tasks_count: template.tasks_count,
                    template_status: template.template_status
                  }
                })
                updateNewTaskData({ name: template.name })
                setTempTaskName(template.name)
                setIsLinkedTemplateOpen(false)
              }
            }
          })
        }
        break
      case 'usersAndTeams':
        if (selectedItem.props?.team?.team_id) {
          setSelectedTeams([...selectedTeams, selectedItem.id])
        } else {
          setSelectedUsers([...selectedUsers, selectedItem.id])
        }
        setAvatarSubjects([...avatarSubjects, selectedItem.props.avatarSubject])
        break
      case 'streams':
        taskData.stream = selectedItem.props?.stream
        break
      default:
        break
    }
    setNewTaskData(taskData)
    resetMenu()
    stripShortcutMatchFromTaskName()
  }

  const resetMenu = () => {
    setMenuOpen(false)
    setMenuItems(undefined)
    setFilteredMenuItems(undefined)
    setMenuType(undefined)
  }

  const stripShortcutMatchFromTaskName = () => {
    if (afterSelectionSubstring !== undefined) {
      setTempTaskName(afterSelectionSubstring)
      updateNewTaskData({ name: afterSelectionSubstring })
      setAfterSelectionSubstring(undefined)
    }
  }

  useUpdateEffect(() => {
    if (!isInputVisible) {
      resetMenu()
    }
  }, [isInputVisible])

  const handleChangeContent: ChangeEventHandler<HTMLInputElement> = event => {
    setTempTaskName(event.target.value)
    updateNewTaskData({ name: event.target.value })
  }

  const handleSuccessorSelection = (successors: number[]) => {
    setSuccessorsModalOpen(false)
    handleMutation(successors)
  }

  const handleTaskCreate = () => {
    if (isLoading || dummy || !newTaskData.name) return

    const successorIds = previousTask?.successor_ids
    const hasMultipleSuccessors = successorIds?.length > 1
    const isLastVisibleTask = successorIds?.length === 1 && !nextTaskId

    const isNextVisibleTaskNotSuccessor =
      successorIds?.length === 1 && nextTaskId && successorIds.indexOf(nextTaskId) === -1
    if (hasMultipleSuccessors || isLastVisibleTask || isNextVisibleTaskNotSuccessor) {
      setSuccessorsModalOpen(true)
    } else {
      handleMutation(previousTask ? [...previousTask.successor_ids] : [])
    }
  }

  const handleMutation = async (successors: number[]) => {
    setIsLoading(true)
    const payload = {
      task: { ...newTaskData, stream_id: newTaskData.stream?.id, task_type_id: newTaskData.task_type.id },
      linked_resource: linkedResource,
      base_task_id: createTaskPredecessor || null,
      predecessors: previousTask ? [previousTask.id] : [],
      successors,
      runbook_teams: selectedTeams,
      users: selectedUsers,
      integration_events: []
    }

    try {
      setDummy({ ...newTaskData, dateData, previousTask })
      resetNewTask()
      setTempTaskName(undefined)

      const response = await createTask({ runbookId, runbookVersionId, payload })
      processTaskCreateResponse(response as RunbookTaskCreateResponse)
      toggleCreateTaskInput({ predecessor: response.task.id })
      setIsLoading(false)
    } catch {
      setDummy(undefined)
      resetNewTask()
      setTempTaskName(undefined)
      setIsLoading(false)
    }
  }

  useUpdateEffect(() => {
    if (dummy) {
      inputRef.current?.focus()
    }
  }, [dummy])

  return (
    <>
      {dummy && (
        <TaskItemCreateInput
          dummy
          disabled
          dateData={dummy.dateData}
          activeTimezone={activeTimezone}
          previousTask={dummy.previousTask}
          previousTaskTypeIcon={previousTaskType?.icon as TaskTypeIconName}
          streamColor={dummy.stream?.color}
          taskName={dummy.name}
          taskTypeIcon={(dummy.task_type.icon + '-dashed') as TaskTypeIconName}
          duration={dummy.duration}
        />
      )}
      <TaskItemCreateInput
        ref={inputRef}
        setReference={refs.setReference}
        getReferenceProps={getReferenceProps}
        previousTask={previousTask}
        previousTaskTypeIcon={previousTaskType?.icon as TaskTypeIconName}
        dateData={dateData}
        activeTimezone={activeTimezone}
        streamColor={newTaskData.stream?.color}
        streamName={newTaskData.stream?.name}
        taskName={newTaskData.name}
        taskTypeIcon={(newTaskData.task_type.icon + '-dashed') as TaskTypeIconName}
        levelName={newTaskData.level_name}
        duration={newTaskData.duration}
        handleChangeContent={handleChangeContent}
        handleKeyDown={handleKeyDown}
        handleCompositionEnd={() => setIsComposing(false)}
        handleCompositionStart={() => setIsComposing(true)}
        onCancel={onClose}
        onBlur={handleBlur}
        avatarSubjects={avatarSubjects}
      />
      {!dummy && isMenuOpen && (
        <TaskItemCreateMenu
          isMenuOpen={isMenuOpen}
          menuItems={filteredMenuItems}
          getFloatingProps={getFloatingProps}
          getItemProps={getItemProps}
          context={context}
          setFloating={refs.setFloating}
          floatingStyles={floatingStyles}
          listRef={listRef}
          activeIndex={activeIndex}
          onSelectMenu={handleMenuSelection}
          constraints={{ minWidth: '300px', maxWidth: '300px', maxHeight: '200px' }}
        />
      )}
      {previousTask && (
        <TaskSuccessorsModal
          open={isSuccessorsModalOpen}
          onClose={() => setSuccessorsModalOpen(false)}
          previousTask={previousTask}
          onSubmit={(successors: number[]) => handleSuccessorSelection(successors)}
        />
      )}
    </>
  )
})

/**
 * TaskItemCreateInput presentational only
 */
const TaskItemCreateInput = forwardRef(
  (
    {
      dummy = false,
      previousTask,
      dateData,
      activeTimezone,
      disabled,
      streamColor,
      taskTypeIcon,
      previousTaskTypeIcon,
      onBlur,
      onCancel,
      handleChangeContent,
      handleKeyDown,
      handleCompositionEnd,
      handleCompositionStart,
      taskName,
      streamName,
      levelName,
      duration,
      setReference,
      getReferenceProps,
      avatarSubjects
    }: {
      dummy?: boolean
      previousTask?: TaskListTask
      dateData: TaskItemDateData
      activeTimezone?: string | null
      disabled?: boolean
      streamColor: string
      taskTypeIcon: TaskTypeIconName
      previousTaskTypeIcon?: TaskTypeIconName
      onCancel?: () => void
      onBlur?: FocusEventHandler<HTMLInputElement>
      handleChangeContent?: (e: ChangeEvent<HTMLInputElement>) => void
      handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
      handleCompositionEnd?: (e: CompositionEvent<HTMLInputElement>) => void
      handleCompositionStart?: (e: CompositionEvent<HTMLInputElement>) => void
      taskName: string
      streamName?: string
      levelName?: string
      duration?: number
      setReference?: ((node: ReferenceType | null) => void) & ((node: ReferenceType | null) => void)
      getReferenceProps?: (userProps?: HTMLProps<Element> | undefined) => Record<string, unknown>
      avatarSubjects?: AvatarSubject[]
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })
    const { name: tempTaskName } = RunbookViewModel.useGet('taskCreate')
    const [value, setValue] = useState(dummy ? taskName : tempTaskName ?? '')

    useEffect(() => {
      if (dummy) {
        setValue(taskName)
      } else {
        setValue(tempTaskName ?? '')
      }
    }, [dummy, taskName, tempTaskName])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!dummy) {
        handleChangeContent?.(e)
        setValue(e.target.value)
      }
    }

    const inputProps = dummy
      ? { value: taskName }
      : {
          ...getReferenceProps?.({
            ref: mergeRefs([ref, setReference ?? null]),
            onChange: handleChange,
            value,
            placeholder: t('enterTitle'),
            'aria-autocomplete': 'list',
            onKeyDown: handleKeyDown
          })
        }

    return (
      <TaskItemLayout
        disabled={disabled}
        data-testid={`task-list-item-create-from-${previousTask?.id ? previousTask?.id : 'root'}`}
        prefixContent={<Box width="30px" flex={false} />}
        dateData={dateData}
        activeTimezone={activeTimezone}
        icon={
          <TaskItemIcon
            color={streamColor}
            icon={taskTypeIcon}
            disabled={disabled}
            onClick={onCancel}
            hoverIcon="close"
            withConnectors={previousTask?.id ? { previousIcon: previousTaskTypeIcon } : undefined}
          />
        }
        mainContent={
          <Box width="100%">
            <TextInput
              {...inputProps}
              onBlur={onBlur}
              onCompositionEnd={handleCompositionEnd}
              onCompositionStart={handleCompositionStart}
              autoFocus
              plain
              css={`
                padding: 0;
                &:focus {
                  outline: none;
                }
              `}
            />

            {streamName && levelName && (
              <CreateTaskHelpTextBox>
                <Box css="position: absolute; top: 4px;" direction="row">
                  <Text data-testid="task-item-create-input-subtext" size="xsmall" color="text-light" truncate>
                    {t('stream', {
                      streamName,
                      level: levelName
                    })}
                  </Text>
                  <Tooltip content={<ShortcutsContent />} placement="top">
                    <Text
                      size="xsmall"
                      color="text-light"
                      css={`
                        position: relative;
                        top: -3px;
                        left: 4px;
                        color: ${themeColor('primary')};
                        white-space: nowrap;
                      `}
                    >
                      {t('shortcuts')}
                    </Text>
                  </Tooltip>
                </Box>
              </CreateTaskHelpTextBox>
            )}
          </Box>
        }
        endContent={duration ? <Text color="text-light">{formatDuration(duration, 2)}</Text> : null}
        suffixContent={
          avatarSubjects?.length === 0 ? (
            <Avatar />
          ) : avatarSubjects?.length === 1 ? (
            <Avatar subject={avatarSubjects?.[0]} tooltipPlacement="top" />
          ) : (
            <AvatarGroup
              avatars={(avatarSubjects ?? []).map(subject => ({ tooltip: '', subject }))}
              rightPosition={28}
            />
          )
        }
      />
    )
  }
)

const ShortcutsContent = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })

  return (
    <Box gap="small" css="text-align: left; padding: 4px;">
      <Text>{t('shortcutsTitle')}</Text>
      <Text>{t('shortcutsStream')}</Text>
      <Text>{t('shortcutsUserTeam')}</Text>
      <Text>{t('shortcutsTaskType')}</Text>
      <Text>{t('shortcutsDuration')}</Text>
    </Box>
  )
}

const CreateTaskHelpTextBox = styled(Box)`
  position: relative;
  bottom: 7px;
`
