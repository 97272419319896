import { atom } from 'recoil'

export type SharedViewStateType = {
  customFieldRefresh: {
    [customFieldId: number]: {
      refresh: boolean
      errors?: { [fieldValueId: number]: string[] | undefined }
    }
  }
}

export const sharedViewState_INTERNAL = atom<SharedViewStateType>({
  key: 'shared-view-state',
  default: {
    customFieldRefresh: {}
  }
})
