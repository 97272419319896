import { useMemo, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { TFunction } from 'react-i18next'

import { Box, Button, Message, SanitizedString, Text } from '@cutover/react-ui'
import { FieldOption, FieldValue, TaskAction, TaskListTask } from 'main/services/queries/types'
import { IntegrationDebugData } from 'main/components/integrations/types'
import { ExtendedCustomField } from 'main/recoil/runbook/models/account/custom-fields'
import { useLanguage } from 'main/services/hooks'
import { IntegrationDebugModal } from 'main/components/integrations/integration-debug-modal'
import { TaskModel } from 'main/data-access/models'
import { CustomFieldModel, FieldOptionModel } from 'main/data-access'

type DisplayAction = {
  author?: string
  bold?: boolean
  content: string
  index: number
  date: number
  overrides?: string
  valueData?: ValueData[]
  debugData?: IntegrationDebugData
  eventId?: string
}

type ValueData = { fieldName: string; fieldValue: string }

export const TaskActionContent = ({ task }: { task: TaskListTask }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.actionAccordion' })
  const cfLookup = CustomFieldModel.useGetLookup()
  const optionsLookup = FieldOptionModel.useGetLookup()
  const taskLookup = TaskModel.useGetLookup()
  const [selectedTaskActionEventId, setSelectedTaskActionEventId] = useState<string | undefined>(undefined)

  const displayActions = useMemo(
    () => buildActions({ task, cfLookup, optionsLookup, taskLookup, t }),
    [task, cfLookup, optionsLookup, taskLookup, t]
  )

  const needsReload = isOutOfSync(task, displayActions)

  return (
    <>
      {displayActions.length ? (
        <>
          {displayActions.map(displayAction => (
            <TaskActionContentInner
              key={`action-${displayAction.index}`}
              displayAction={displayAction}
              onDebugSelection={() => setSelectedTaskActionEventId(displayAction?.eventId)}
            />
          ))}
          {selectedTaskActionEventId && (
            <IntegrationDebugModal
              eventId={selectedTaskActionEventId}
              onDebugClose={() => setSelectedTaskActionEventId(undefined)}
            />
          )}
        </>
      ) : (
        <Text>{t('noActions')}</Text>
      )}
      {needsReload && (
        <Box css="margin: 16px 0 0 0">
          <Message message={t('outOfSync')} type="warning" />
        </Box>
      )}
    </>
  )
}

type TaskActionContentInnerProps = {
  displayAction: DisplayAction
  onDebugSelection: (eventId: string) => void
}

const TaskActionContentInner = ({ displayAction, onDebugSelection }: TaskActionContentInnerProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.actionAccordion' })
  const { index, bold, date, content, author, overrides, valueData, eventId } = displayAction

  return (
    <Box direction="row" gap="xxsmall" pad={{ bottom: 'xxsmall' }}>
      <Box data-testid={`date-${index}`} width="xsmall" flex={false}>
        <Text color="text-light">{format(date, 'd MMM HH:mm')}</Text>
      </Box>
      <Box data-testid={`content-${index}`}>
        <Text weight={bold ? 'bold' : 'normal'} css="white-space: normal">
          {content} {author && t('author', { author })} {overrides && t('overrides', { overrides })}
          {eventId && (
            <Button
              plain
              color="primary"
              label={t('debug')}
              css="text-decoration: underline;"
              onClick={() => onDebugSelection(eventId)}
            />
          )}
        </Text>
        {valueData?.map((vd, index) => (
          <Text color="text-light" size="small" key={`${vd.fieldName}-${index}`} css="white-space: normal">
            {vd.fieldName}:{' '}
            <SanitizedString data-testid={`task-action-${vd.fieldName}-value`} size="small" input={vd.fieldValue} />
          </Text>
        ))}
      </Box>
    </Box>
  )
}

// The task_actions are not available with the changed_tasks response, so the panel can be out of sync
const isOutOfSync = (task: TaskListTask, actions: DisplayAction[]) => {
  const hasSkippedAction = actions.some(action => action.content === 'Skipped')
  if (task.completion_type === 'complete_skipped' && !hasSkippedAction) return true
  return false
}

// Note: This is far too complex and unreliable, we need to rethink how task actions work,
// including UI and backend updates
const buildActions = ({
  task,
  cfLookup,
  optionsLookup,
  taskLookup,
  t
}: {
  task: TaskListTask
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
  taskLookup: Record<number, TaskListTask>
  t: TFunction<'tasks', 'editPanel.actionAccordion'>
}) => {
  // actions processed in task.task_actions
  let displayActions = [] as DisplayAction[]
  const taskAbandoned = task.completion_type === 'complete_abandoned'
  const taskSkipped = task.completion_type === 'complete_skipped'
  const wasSkippedInProgress = taskSkipped && task.start_actual !== task.end_actual

  if (taskAbandoned) {
    // Abandonded tasks cannot have any other actions
    displayActions.push(buildAbandonAction(task, taskLookup, t))
  } else {
    displayActions = processTaskActions({
      actions: task.task_actions ?? [],
      fieldValues: task.field_values ?? [],
      cfLookup,
      optionsLookup,
      t
    })

    // Values in task date fields do not resolve to milliseconds as do
    // task action dates, so we adjust these according to the use case.
    if (task.start_ready || task.stage === 'startable') {
      const startReady = {
        bold: true,
        content: t('actions.becomeStartable'),
        // keep base second as date should be before any other action
        date: task.start_ready ? task.start_ready * 1000 : task.start_display * 1000
      } as DisplayAction
      displayActions.push(startReady)
    }

    // Still show the started action if the task was skipped after it started
    if (task.start_actual && (!taskSkipped || wasSkippedInProgress)) {
      const startActual = {
        bold: true,
        content: t('actions.started'),
        // bump to next second as date should be after 'approved to start' actions
        date: (task.start_actual + 1) * 1000
      } as DisplayAction
      displayActions.push(startActual)
    }

    // Skipped tasks do not need a finish action
    if (task.end_actual && !taskSkipped) {
      const endActual = {
        bold: true,
        content: t('actions.finished'),
        // bump to next second as date should be after 'approved to finish' actions
        date: (task.end_actual + 1) * 1000
      } as DisplayAction
      displayActions.push(endActual)
    }
  }

  const orderedDisplayActions = displayActions.sort((a, b) => {
    return a.date - b.date
  })

  return orderedDisplayActions.map((action, index) => ({ ...action, index }))
}

const buildAbandonAction = (
  task: TaskListTask,
  taskLookup: Record<number, TaskListTask>,
  t: TFunction<'tasks', 'editPanel.actionAccordion'>
) => {
  let taskAbandonedMeta = ''
  const taskCompletionMeta = task.completion_meta ? JSON.parse(task.completion_meta) : undefined

  if (taskCompletionMeta) {
    const triggerTaskName = findAbandonTriggerTaskName(taskLookup, task.id, taskCompletionMeta.abandon_trigger) ?? ''
    taskAbandonedMeta = `${taskCompletionMeta.abandon_trigger} ${triggerTaskName}`
  }

  return {
    bold: true,
    content: t('actions.abandoned', { meta: taskAbandonedMeta }),
    date: task.end_display
  } as DisplayAction
}

// depth-first search of taskLookup to find task name with matching internal_id
const findAbandonTriggerTaskName = (
  taskLookup: Record<number, TaskListTask>,
  startTaskId: number,
  internalIdToFind: number
): string | undefined => {
  const visited: Set<number> = new Set()
  const stack: number[] = [startTaskId]

  while (stack.length > 0) {
    const currentTaskId = stack.pop()

    if (currentTaskId === undefined) {
      break
    }

    if (visited.has(currentTaskId)) {
      continue
    }

    visited.add(currentTaskId)

    const currentTask = taskLookup[currentTaskId]

    if (currentTask.internal_id === internalIdToFind) {
      return currentTask.name
    }

    for (const predecessorId of currentTask.predecessor_ids) {
      stack.push(predecessorId)
    }
  }

  return undefined
}

const processTaskActions = ({
  actions,
  fieldValues,
  cfLookup,
  optionsLookup,
  t
}: {
  actions: TaskAction[]
  fieldValues: FieldValue[]
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
  t: TFunction<'tasks', 'editPanel.actionAccordion'>
}): DisplayAction[] =>
  actions.reduce((acc: DisplayAction[], current: TaskAction) => {
    const actionIndex = current.action

    const action = {} as DisplayAction
    action.content = t(`actions.${actionContentKeys[actionIndex]}`)
    action.date = parseISO(`${current.created_at}Z`).getTime()

    switch (actionIndex) {
      case 0:
      case 1:
        action.author = current.author_name
        action.valueData = buildActionValues({ actionId: current.id, fieldValues, cfLookup, optionsLookup })
        action.overrides = current.author_id !== current.user_id ? current.user_name : undefined
        break
      case 2:
        action.bold = true
        action.content = t('actions.skipped')
        action.author = current.author_name
        break
      case 3:
        if (current.context) {
          action.content = `${current.context.html ? 'HTML' : 'Non-HTML'} emails sent`
          action.valueData = [
            {
              fieldName: '# recipients',
              fieldValue: current.context.recipients ?? 'unknown'
            }
          ]
        }
        break
      case 4:
      case 5:
      case 6:
        // noop
        break
      case 7:
      case 8:
      case 10:
        action.eventId = current.event_store_event_id
        break
      case 9:
        // noop
        break
      default:
        break
    }

    acc.push(action)
    return acc
  }, [])

const actionContentKeys: { [key: number]: string } = {
  0: 'approvedToStart',
  1: 'approvedToFinish',
  2: 'skipped',
  3: 'emailsSent',
  4: 'emailSuccess',
  5: 'emailFailure',
  6: 'endpointFired',
  7: 'endpointSuccessful',
  8: 'endpointFailed',
  9: 'endpointAborted',
  10: 'endpointRunning'
}

const buildActionValues = ({
  actionId,
  fieldValues,
  cfLookup,
  optionsLookup
}: {
  actionId: number
  fieldValues: FieldValue[]
  cfLookup: { [x: number]: ExtendedCustomField }
  optionsLookup: { [x: number]: FieldOption }
}) => {
  const data = [] as ValueData[]
  for (const fv of Object.values(fieldValues)) {
    const customField = cfLookup[fv.custom_field_id]

    if (fv.task_action_id === actionId && !customField.archived) {
      const fieldType = customField.field_type.slug
      let value = ''

      switch (fieldType) {
        case 'select_menu':
        case 'radiobox':
          if (fv.field_option_id) {
            value = optionsLookup[fv.field_option_id].name
          }
          break
        case 'searchable':
        case 'multi_searchable':
        case 'text':
        case 'textarea':
          value = fv.value ?? ''
          break
        case 'checkboxes':
          const selectedOptions = JSON.parse(fv.value ?? '')
          const selectedOptionNames = selectedOptions.map((opt: number) => optionsLookup[opt].name)
          if (selectedOptionNames?.length) {
            value = selectedOptionNames.join(', ')
          }
          break
      }

      if (value.length > 0) {
        data.push({ fieldName: customField.name, fieldValue: value })
      }
    }
  }
  return data
}
