import { useEffect } from 'react'
import { datadogRum, RumXhrResourceEventDomainContext } from '@datadog/browser-rum'

import { useInstanceName, useUserAnonymization } from '../../services/hooks'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export const useDatadog = () => {
  const currentUser = CurrentUserModel.useGet()
  const { datadogRumAppId, datadogRumClientId, datadogRumDatacenter, version } = ConfigModel.useGet()
  const { anonUserId } = useUserAnonymization()
  const instanceName = useInstanceName()

  useEffect(() => {
    if (!datadogRumAppId || !datadogRumClientId) return

    datadogRum.init({
      applicationId: datadogRumAppId,
      clientToken: datadogRumClientId,
      site: datadogRumDatacenter === 'US' ? 'datadoghq.com' : 'datadoghq.eu',
      silentMultipleInit: true,
      trackInteractions: true,
      // With `trackViewsManually` we can set this up with react router to provide helpful data, attaching the view name to user activity.
      // The suggested way (with react and react router) tracks location at the top level and sets the view based on parsing of the url with
      // the app routes. Need to consider the performance impact of this and we do not use a RouteObject to create our routes, so additional
      // work needs to be done to have a solid way to match to named routes.
      // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#react-router-instrumentation
      // trackViewsManually: true,
      version: version.label,
      service: 'react-app',
      // Note: we require intakeApiVersion 1 as cloud does not currently support v2.
      // We also require v3 of datadog/browser-rum package, as v4 removes support for intakeApiVersion 1.
      intakeApiVersion: 1,
      beforeSend: (event, context) => {
        // this will give Datadog the url as a non-hash based string. Datadog isn't able to parse any information on our paths
        // if it thinks the entire path part of the url is a hash.
        event.view.url = event.view.url.replace(/\/#\//, '/')

        try {
          if (event.type === 'resource' && event.resource.type === 'xhr') {
            // @ts-ignore
            const resourceContext: RumXhrResourceEventDomainContext = context
            if (resourceContext.xhr.status >= 400) {
              event.context = event.context || {}
              event.context.error = JSON.parse(resourceContext.xhr.responseText)
            }
          }
        } catch (_e) {}
      }
    })

    datadogRum.addRumGlobalContext('instance', {
      name: instanceName
    })

    // we also set the `user` context in our current user setter.
    datadogRum.addRumGlobalContext('usr', {
      id: anonUserId,
      isCutoverUser: (currentUser?.email ?? '').includes('@cutover.com')
    })

    return () => {
      datadogRum.removeRumGlobalContext('usr')
      datadogRum.removeRumGlobalContext('instance')
    }
  }, [])
}
