import { useMemo } from 'react'

import { colors } from '@cutover/react-ui'
import { PlannedVsActualDurationComponent } from 'main/components/dashboards/widgets/types'

export type DataPoint = { x: Date; y: number }

export const usePlannedVsActualDurationChartData = (values: PlannedVsActualDurationComponent['values']) => {
  return useMemo(() => getPlannedVsActualDurationChartData(values), [values])
}

const getPlannedVsActualDurationChartData = (values: PlannedVsActualDurationComponent['values']) => {
  const plannedDataPoints: DataPoint[] = []
  const actualDataPoints: DataPoint[] = []
  let plannedDone: Date | null = null
  let actualDone: Date | null = null
  let maxDuration: number | null = 0
  const activePointsToDisplayFilledCircle: DataPoint[] = []

  for (const value of values) {
    const xDate = new Date(value.interval)

    if (!plannedDone) {
      const plannedPoint = { x: xDate, y: value.planned_duration }
      plannedDataPoints.push(plannedPoint)
      if (value.planned_duration <= 0) {
        plannedDone = xDate
        activePointsToDisplayFilledCircle.push(plannedPoint)
      }
      if (!maxDuration || value.planned_duration > maxDuration) {
        maxDuration = value.planned_duration
      }
    }

    if (!actualDone) {
      const actualPoint = { x: xDate, y: value.actual_duration }
      actualDataPoints.push(actualPoint)
      if (value.actual_duration <= 0) {
        actualDone = xDate
        activePointsToDisplayFilledCircle.push(actualPoint)
      }
      if (!maxDuration || value.actual_duration > maxDuration) {
        maxDuration = value.actual_duration
      }
    }

    if (plannedDone && actualDone) {
      break
    }
  }

  return {
    lineChartData: [
      { id: 'planned', color: colors.bgDark, data: plannedDataPoints },
      { id: 'actual', color: colors.primary, data: actualDataPoints }
    ],
    plannedDone,
    actualDone,
    activePointsToDisplayFilledCircle,
    maxDuration,
    xMinValue: new Date(values[0].interval),
    xMaxValue: new Date(values[values.length - 1].interval)
  }
}
