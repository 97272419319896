import { useContext } from 'react'

import { Avatar, BarTable, Box, colors } from '@cutover/react-ui'
import { useAngularRightSidePanel } from '../../../../../services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { DashboardMediaType } from '../../types'
import { generateBarValues } from '../bar-table-widget-utils'
import { MrdDashboardWidget } from '../mrd-dashboard-widget'
import { DashboardContext } from 'main/components/dashboards/runbooks-dashboard-page'
import { getFilterParams } from '../mrd-filter-util'
import { ConfigModel } from 'main/data-access'

export type AttributeCompletionWidgetProps = {
  media: DashboardMediaType
  data: AttributeCompletionWidgetData
}

export type AttributeCompletionWidgetData = {
  id: number
  name: string
  errors?: string[]
  filters?: {
    resource_type: string
    resource_id: string | null
    value: string
  } | null
  notes?: any
  type: string
  complete: number
  startable: number
  in_progress: number
  not_live: number
  default: number
  values: AttributeCompletionValues[]
  runbook_total: number
  total: number
}

type AttributeCompletionValues = {
  id: number
  name: string
  color: string
  complete: number
  in_progress: number
  startable: number
  not_live: number
  default: number
  runbook_total: number
  total: number
}

export const AttributeCompletionWidget = ({ media, data }: AttributeCompletionWidgetProps) => {
  const { name, errors, notes, values, type } = data
  const attributeType = type === 'stream_completion' ? 'runbook' : 'task'
  const groupBy = name.includes('Team') ? 'Team' : 'Other' //not optimal way to differentiate..
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)

  const reactWorkspace = ConfigModel.useIsFeatureEnabled('react_workspace')
  const { openAngularSidePanel } = useAngularRightSidePanel()
  const { openRightPanel } = useSetActiveRightPanelState()

  const handleRowClick = (id: number) => {
    if (attributeType === 'task') return

    const contentId = buildContentIdObject(id)
    openRightSidePanel(contentId)
  }

  const openRightSidePanel = (contentId: { [key: string]: any }) => {
    if (reactWorkspace) {
      openRightPanel({ type: 'dashboard-runbook-list', params: contentId })
    } else {
      openAngularSidePanel({ content: 'filtered-changes', contentId })
    }
  }

  const buildContentIdObject = (id: number, displayStage?: string) => {
    return {
      accountId,
      dashboardId,
      template_type: 'off',
      ...getFilterParams(filterParams),
      ...(attributeType === 'runbook' && {
        stream_name: values.find(value => value.id === id)?.name,
        stream_stage: displayStage ? displayStage : undefined
      })
    }
  }

  const handleRowSegmentClick = (id: number, displayStageId: string | undefined) => {
    if (attributeType === 'task') return

    if (displayStageId) {
      const displayStage = displayStageId
      const contentId = buildContentIdObject(id, displayStage)
      openRightSidePanel(contentId)
    }
  }

  return (
    <MrdDashboardWidget title={name} errors={errors} notes={notes} fullWidth>
      <BarTable
        mainLabelAsComponent
        data={{ rowData: convertValuesToRowDataFormat(values, attributeType, groupBy) }}
        onRowClick={handleRowClick}
        onSegmentClick={handleRowSegmentClick}
        media={media}
      />
    </MrdDashboardWidget>
  )
}

// convert values into row data
const convertValuesToRowDataFormat = (values: AttributeCompletionValues[], attributeType: string, groupBy: string) => {
  const rowData = values.map(value => {
    const { name, complete, in_progress, startable, not_live, runbook_total, total, color, id } = value
    const runbookCountLabel = attributeType === 'task' ? ' in ' + runbook_total + ' runbooks' : ''
    const defaultBarColors = [colors.primary, colors.success, colors.successAlt, colors.textDisabled, colors.bg4]
    const attributeLabel = attributeType === 'runbook' ? 'stream(s) ' : ''

    return {
      id,
      mainLabel: (
        <Box direction="row" align="center">
          {groupBy === 'Team' && (
            <Box margin={{ right: '4px' }}>
              <Avatar subject={{ name, color }} size="small" />
            </Box>
          )}
          {` ${name}`}
        </Box>
      ),
      rowInfo: `${total} ${attributeType}${total > 1 ? 's' : ''} ${runbookCountLabel}`, // TODO: implement language hook
      values: generateBarValues({
        bars: [
          { count: complete, tooltip: `${attributeLabel}complete`, displayStage: 'complete' },
          { count: in_progress, tooltip: `${attributeLabel}in progress`, displayStage: 'in_progress' },
          { count: startable, tooltip: `${attributeLabel}startable`, displayStage: 'startable' },
          { count: value.default, tooltip: `${attributeLabel}not yet startable`, displayStage: 'default' },
          { count: not_live, tooltip: `${attributeLabel}in planning or rehearsal`, displayStage: 'not_live' }
        ],
        total,
        context: attributeType,
        barColors: defaultBarColors,
        displayPercentBarIndex: 0
      })
    }
  })
  return rowData
}
