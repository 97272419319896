import { useState } from 'react'
import { Heading } from 'grommet'
import styled from 'styled-components'

import { Box, Button } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { WorkspaceCreateModal } from './modals/workspace-create-modal'
import { WorkspaceTypeType } from 'main/services/queries/types'

export const WorkspacesHeader = ({
  canCreate,
  workspaceTypes
}: {
  canCreate?: boolean
  workspaceTypes?: WorkspaceTypeType[]
}) => {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)
  const { t } = useLanguage('accounts')

  const handleClickCreate = () => {
    setCreateModalOpen(true)
  }

  return (
    <Box direction="row" width="100%">
      <Box flex="grow" justify="center">
        <WorkspacesTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
          {t('header.title')}
        </WorkspacesTitle>
      </Box>
      <Box>
        {canCreate && (
          <Button
            data-testid="add-workspace-button"
            icon="add"
            primary
            label={t('header.addButton')}
            onClick={handleClickCreate}
          />
        )}
      </Box>
      {isCreateModalOpen && (
        <WorkspaceCreateModal workspaceTypes={workspaceTypes} onClose={() => setCreateModalOpen(false)} />
      )}
    </Box>
  )
}

const WorkspacesTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
`
