import { atom, selector } from 'recoil'

import {
  fetchAccountQuery,
  GetAccountResponseType
} from 'main/services/api/data-providers/account/use-get-account-data'
import { CustomField } from 'main/services/queries/types'

// WARNING: there is a circular dependency somewhere that seems to depend entirely on this fetchAccountQuery being
// imported here
fetchAccountQuery

export type GetAccountResponseAndComputedPropertiesState = GetAccountResponseType & {
  computed: {
    customFields?: {
      parentChildLookup: { [key: string]: CustomField }
      childParentLookup: { [key: string]: CustomField }
    }
  }
}

export const accountResponseState_INTERNAL = atom<GetAccountResponseAndComputedPropertiesState>({
  key: 'account:response'
})

export const accountState = selector({
  key: 'account',
  get: ({ get }) => {
    const { account } = get(accountResponseState_INTERNAL)
    return account
  }
})

export const accountMetaState = selector({
  key: 'account:meta',
  get: ({ get }) => {
    const { meta } = get(accountResponseState_INTERNAL)
    return meta
  }
})

export const accountComputedState = selector({
  key: 'account:computed',
  get: ({ get }) => {
    const { computed } = get(accountResponseState_INTERNAL)
    return computed
  }
})

export const accountComputedCustomFields = atom<
  GetAccountResponseAndComputedPropertiesState['computed']['customFields'] | undefined
>({
  key: 'account:computed-custom-fields',
  default: undefined
})
