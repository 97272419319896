import { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Button, Message, RadioboxGroup, Select, TextInput } from '@cutover/react-ui'
import { IntegrationConnectionFormType } from './integrations/integration-setting-types'
import { IntegrationFormSettingFields } from './shared-integration-components/integration-form-setting-fields'
import { IntegrationConnectionConfigCamel, useLanguage } from 'main/services/hooks'

type IntegrationConnectionFormProps = {
  customIntegration: IntegrationConnectionConfigCamel | undefined
  integrations: Array<IntegrationConnectionConfigCamel>
  onIntegrationChange: (integration: IntegrationConnectionConfigCamel) => void
}

type IntegrationsLookup = Record<IntegrationConnectionConfigCamel['klass'], IntegrationConnectionConfigCamel>

export const IntegrationConnectionForm = ({
  customIntegration,
  onIntegrationChange,
  integrations
}: IntegrationConnectionFormProps) => {
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'newIntegrationConnectionModal' })

  const integrationTypeOptions = integrations
    .filter(integration => !integration.hideInList)
    .map(integration => {
      return { label: integration.name, value: integration.klass }
    })

  const { control, getValues, watch, setValue, register, formState } = useFormContext<IntegrationConnectionFormType>()

  const { errors } = formState

  const setInitialIntegrationName = () => {
    const integrationName = integrationsLookup[getValues('integration') ?? '']?.name

    setValue('name', integrationName, {
      shouldValidate: !onlyIntegrationTypeIsDirty()
    })
  }

  const setInitialIntegrationImageUrl = () => {
    const integrationImageUrl = integrationsLookup[getValues('integration') ?? '']?.imageUrl

    setValue('imageUrl', integrationImageUrl, {
      shouldValidate: !onlyIntegrationTypeIsDirty()
    })
  }

  const onlyIntegrationTypeIsDirty = () => {
    return formState.dirtyFields.integrationType && Object.keys(formState.dirtyFields).length === 1
  }

  const integrationTypeIsCustom = () => {
    return getValues('integrationType') === 'custom'
  }

  const nameFieldMarginRight = integrationTypeIsCustom() ? 'large' : undefined

  const integrationsLookup = useMemo(
    () =>
      integrations.reduce<IntegrationsLookup>((acc, integration) => {
        acc[integration.klass] = integration
        return acc
      }, {}),
    [integrations]
  )

  useEffect(() => {
    setInitialIntegrationName()
    setInitialIntegrationImageUrl()
    const integration = integrationsLookup[getValues('integration') ?? '']
    if (integration) {
      onIntegrationChange(integration)
    }
  }, [watch('integration')])

  useEffect(() => {
    if (integrationTypeIsCustom()) {
      setValue('integration', customIntegration?.klass)
    } else {
      setValue('integration', undefined)
    }
  }, [watch('integrationType')])

  const LearnMoreButton = ({ docsLink }: { docsLink: string }) => {
    return (
      <Button
        icon="info"
        secondary
        label={t('learnMoreButton')}
        onClick={() => window.open(docsLink, '_newtab')}
        aria-label={t('learnMoreButton')}
        margin={{ left: 'large' }}
      />
    )
  }

  return (
    <Box>
      <Controller
        name={'integrationType'}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <RadioboxGroup
            required
            name={name}
            value={value}
            onChange={onChange}
            direction={'row'}
            label={t('radioBoxGroup.label')}
            options={[
              { label: t('radioBoxGroup.customLabel'), value: 'custom' },
              { label: t('radioBoxGroup.predefinedLabel'), value: 'predefined' }
            ]}
          />
        )}
      />
      <Box margin={{ bottom: '12px' }}>
        <Message>{t(`${getValues('integrationType')}IntegrationMessage`)}</Message>
      </Box>
      {getValues('integrationType') === 'predefined' && (
        <Box direction="row">
          <Box css={{ flex: 1 }} margin={{ right: 'large' }}>
            <Controller
              name={'integration'}
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Select
                  label={t('form.integration')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={integrationTypeOptions}
                  required
                />
              )}
            />
          </Box>
          <LearnMoreButton
            docsLink={
              integrationsLookup[getValues('integration') ?? '']?.name === 'Conditional Logic'
                ? t('documentationLinks.conditionalLogic')
                : t('documentationLinks.predefined')
            }
          />
        </Box>
      )}
      <Box direction="row">
        <Box css={{ flex: 1 }} margin={{ right: nameFieldMarginRight }}>
          <TextInput
            {...register('name')}
            label={t('form.name')}
            required
            hasError={Boolean(errors.name)}
            inlineError={errors.name?.message}
          />
        </Box>
        {integrationTypeIsCustom() && <LearnMoreButton docsLink={t('documentationLinks.customIntegration')} />}
      </Box>
      <TextInput
        {...register('imageUrl')}
        label={t('form.imageUrl')}
        required
        hasError={Boolean(errors.imageUrl)}
        inlineError={errors.imageUrl?.message}
        width="100%"
      />
      <IntegrationFormSettingFields settings={integrationsLookup[getValues('integration') ?? '']?.settings} />
    </Box>
  )
}
