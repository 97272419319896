import { Box, Text } from '@cutover/react-ui'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DashboardComponent, DashboardMediaType } from 'main/components/dashboards/widgets/types'
import { SelectField } from 'main/components/shared/form'

type ValueRealisationWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export const ValueRealisationWidget = ({ media, data }: ValueRealisationWidgetProps) => {
  const { name } = data

  return (
    <MrdDashboardWidget title={name || 'Value Realisation'}>
      {media === 'screen' ? <BrowserWidget data={data} /> : <EmailWidget data={data} />}
    </MrdDashboardWidget>
  )
}

const EmailWidget = ({ data }: { data: DashboardComponent }) => {
  const customField = data.custom_fields.find(field => field.apply_to.slug === 'pir_value_select')
  const fieldValue = data.runbook.field_values.find(field => field.custom_field_id === customField?.id)
  const selectedOption = customField?.field_options.find(option => option.id === fieldValue?.field_option_id)

  return (
    <Box margin={{ top: '10px' }}>
      <Text>{selectedOption?.name}</Text>
    </Box>
  )
}

const BrowserWidget = ({ data }: { data: DashboardComponent }) => {
  const customField = data.custom_fields.find(field => field.apply_to.slug === 'pir_value_select')
  const options = customField?.field_options.map(({ id, name }) => ({ id, name }))

  return customField?.id ? (
    <SelectField
      options={options}
      valueKey="id"
      labelKey="name"
      name={`${customField.id}`}
      placeholder="Select an option"
    />
  ) : null
}
