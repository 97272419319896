import { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import { ConfigModel } from 'main/data-access'

export const useSentryPerformanceProfiling = () => {
  const fePerformanceProfiling = ConfigModel.useIsFeatureEnabled('fe_performance_profiling')

  const getDurationTShirtSize = useCallback((duration: number) => {
    if (duration < 500) return 'S'
    if (duration < 1000) return 'M'
    if (duration < 2000) return 'L'
    if (duration < 4000) return 'XL'
    return 'XXL'
  }, [])

  const getRunbookTShirtSize = useCallback((taskCount: number) => {
    if (taskCount < 25) return 'XXS'
    if (taskCount < 50) return 'XS'
    if (taskCount < 100) return 'S'
    if (taskCount < 200) return 'M'
    if (taskCount < 500) return 'L'
    if (taskCount < 1000) return 'XL'
    if (taskCount < 2000) return 'XXL'
    return 'XXXL'
  }, [])

  const observeAndReportLongTasks = useCallback(() => {
    if (!fePerformanceProfiling) return

    if ('PerformanceObserver' in window) {
      const observer = new PerformanceObserver(list => {
        list.getEntries().forEach(entry => {
          if (entry.duration > 100) {
            // 0.1s is considered 'instantaneous' https://www.nngroup.com/articles/website-response-times/ https://web.dev/articles/rail
            Sentry.captureMessage('Long task detected', {
              level: 'warning',
              tags: { 'js.long_task_duration': getDurationTShirtSize(entry.duration) },
              extra: {
                taskDuration: entry.duration.toFixed(2) + 'ms',
                startTime: entry.startTime.toFixed(2) + 'ms'
              }
            })
          }
        })
      })
      observer.observe({ type: 'longtask', buffered: true })
    }
  }, [])

  const profileWebsocketProcessing = (
    cb: Function,
    { requestClass, requestMethod }: { requestClass: string; requestMethod: string }
  ) => {
    if (!fePerformanceProfiling) return cb()

    Sentry.startSpan(
      {
        name: 'rb-ws-processing',
        forceTransaction: true,
        op: 'js',
        attributes: {
          'request-class': requestClass,
          'request-method': requestMethod
        }
      },
      async () => {
        Sentry.getCurrentScope().setTag('rb-ws-processing.request-type', `${requestClass}-${requestMethod}`)
        return await cb()
      }
    )
  }

  const profileRunbookSize = useCallback((tasksCount: number) => {
    if (!fePerformanceProfiling) return

    Sentry.setTag('runbook-size', getRunbookTShirtSize(tasksCount))
  }, [])

  return { observeAndReportLongTasks, profileWebsocketProcessing, profileRunbookSize }
}
