import { useRecoilCallback, useRecoilValue } from 'recoil'

import { FieldValueModelType } from 'main/data-access/models/field-value-model'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { taskIdToExtendedFieldValuesRecord, taskListTaskState } from 'main/recoil/runbook'

/* -------------------------------------------------------------------------- */
/*                                 Get All By                                 */
/* -------------------------------------------------------------------------- */
export const useGetAllFieldValuesBy: FieldValueModelType['useGetAllBy'] = (getAllBy, opts = {}) => {
  useEnsureStableArgs({ ...getAllBy, ...opts })

  /* eslint-disable react-hooks/rules-of-hooks */
  if (opts.scope === 'extended') return useRecoilValue(taskIdToExtendedFieldValuesRecord)[getAllBy.taskId]

  return useRecoilValue(taskListTaskState(getAllBy.taskId)).field_values
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useGetAllFieldValuesByCallback: FieldValueModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (getAllBy, opts = {}) => {
        if (opts.scope === 'extended')
          return (await snapshot.getPromise(taskIdToExtendedFieldValuesRecord))[getAllBy.taskId]
        return (await snapshot.getPromise(taskListTaskState(getAllBy.taskId))).field_values
      },
    []
  )
