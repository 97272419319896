import { memo, useEffect } from 'react'

import { TaskEditForm } from './task-edit-form'
import { CloseRightPanelAction, useRightPanelTypeState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel, ActiveRunbookVersionModel, CurrentUserModel, TaskModel } from 'main/data-access'
import { useGetTaskShowPermissions, useTaskEditUpdatedState } from 'main/recoil/data-access'
import { getTask } from 'main/services/queries/use-task'

export const TaskEditPanel = memo(() => {
  const [{ taskId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')

  return <>{taskId && <TaskEditPanelInner key={taskId} taskId={taskId} onClose={closeRightPanel} />}</>
})

type TaskEditPanelInnerProps = {
  taskId: number
  onClose: CloseRightPanelAction
}

export const TaskEditPanelInner = ({ taskId, onClose }: TaskEditPanelInnerProps) => {
  const taskListTask = TaskModel.useGet(taskId)
  const [taskEditShowState, setTaskEditShowState] = useTaskEditUpdatedState()
  const getTaskShowPermissions = useGetTaskShowPermissions()
  const updateFunctionalOauthUser = TaskModel.useAction('update_functional_oauth_user')
  const { id: runbookId } = ActiveRunbookModel.useGet()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const currentUserId = CurrentUserModel.useId()

  /**
   * Initial data fetching and state updates (taskEditUpdatedState) is handled in this parent component,
   * resolving intermittent "snapshot released" errors caused by the edit form re-rendering during state updates.
   * These states are passed as props to the form, ensuring a stable and predictable rendering process.
   */
  useEffect(() => {
    const getData = async () => {
      const response = await getTask({ runbookId, runbookVersionId, taskId })
      const task = response?.task ?? {}

      updateFunctionalOauthUser(task)
      setTaskEditShowState({
        task,
        last_user_id: currentUserId,
        permissions: getTaskShowPermissions(response)
      })
    }
    getData()

    return () => setTaskEditShowState(undefined)
  }, [])

  // Close panel and reset state when task is deleted
  useEffect(() => {
    if (!taskListTask) {
      onClose()
      setTaskEditShowState(undefined)
    }
  }, [taskListTask])

  return (
    <>
      {taskListTask && (
        <TaskEditForm taskListTask={taskListTask} taskEditShowState={taskEditShowState} onClose={onClose} />
      )}
    </>
  )
}
