import { Fragment, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { useLanguage } from 'main/services/hooks'
import { FormModal, SelectField, TextAreaField, TextInputField, UserSelectField } from 'main/components/shared/form'
import { EmptyRoleType, WorkspaceCreatePayload, WorkspaceTypeType } from 'main/services/queries/types'
import { useRoleTypes } from 'main/services/queries/use-role-types'
import { useWorkspaceCreate } from 'main/services/queries/use-workspace'

type WorkspaceCreateFormType = yup.InferType<typeof formSchema>

export const WorkspaceCreateModal = ({
  onClose,
  workspaceTypes
}: {
  onClose?: () => void
  workspaceTypes?: WorkspaceTypeType[]
}) => {
  const { t } = useLanguage('accounts', { keyPrefix: 'createModal' })
  const { data, isLoading } = useRoleTypes({ contextTypeId: 3, enabled: true })
  const roleTypes = data?.role_types

  const { mutateAsync: createWorkspace } = useWorkspaceCreate()

  const handleSubmit = async (data: WorkspaceCreatePayload) => {
    return await createWorkspace(data)
  }

  // Note: should be able to pass null here & have the backend deal with it
  const customWorkspaceType = workspaceTypes?.find(workspaceType => workspaceType.name === 'Custom')
  const defaultValues = {
    account_type_id: customWorkspaceType?.id
  }

  const orderedRoleTypes = useMemo(() => (roleTypes || []).sort((a, b) => a.name.localeCompare(b.name)), [roleTypes])

  return (
    <FormModal<WorkspaceCreateFormType, WorkspaceCreatePayload>
      open
      title={t('title')}
      successMessage={t('successMessage')}
      reValidateMode="onSubmit"
      onClose={onClose}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      onSuccess={onClose}
      transformer={transformer}
      schema={formSchema}
      confirmIcon="add"
      loading={isLoading}
      confirmText={t('submit')}
      preventAutoClose
    >
      {roleTypes && workspaceTypes && <FormFields roleTypes={orderedRoleTypes} workspaceTypes={workspaceTypes} />}
    </FormModal>
  )
}

const FormFields = ({
  roleTypes,
  workspaceTypes
}: {
  roleTypes: EmptyRoleType[]
  workspaceTypes: WorkspaceTypeType[]
}) => {
  const { t } = useLanguage('accounts', { keyPrefix: 'createModal' })
  const { register } = useFormContext<WorkspaceCreateFormType>()

  const workspaceTypeOptions = workspaceTypes?.map(workspaceType => {
    return { label: workspaceType.name, value: workspaceType.id }
  })

  return (
    <>
      <TextInputField<WorkspaceCreateFormType> name="title" label={t('fields.title')} />
      <TextInputField<WorkspaceCreateFormType> name="slug" label={t('fields.slug')} />
      <TextAreaField<WorkspaceCreateFormType> name="description" label={t('fields.description')} />
      <SelectField<WorkspaceCreateFormType>
        name="account_type_id"
        options={workspaceTypeOptions}
        label={t('fields.type')}
      />
      {roleTypes?.map((roleType, index) => {
        return (
          <Fragment key={`role_types.${roleType.id}`}>
            <UserSelectField<WorkspaceCreateFormType>
              name={`role_types.${index}.users`}
              label={roleType.name}
              helpText={roleType.description || undefined}
              // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
              required={false}
            />
            <input type="hidden" {...register(`role_types.${index}.id` as const)} defaultValue={roleType.id} />
          </Fragment>
        )
      })}
    </>
  )
}

const formSchema = yup.object({
  title: yup.string().required(),
  slug: yup.string().required(),
  description: yup.string(),
  account_type_id: yup.number().required(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          id: yup.number().required()
        })
      )
    })
  )
})

const transformer = (data: WorkspaceCreateFormType): WorkspaceCreatePayload => {
  return {
    account: {
      title: data.title?.trim() ?? '',
      description: data.description,
      slug: data.slug,
      account_type_id: data.account_type_id,
      roles:
        data.role_types?.flatMap(role =>
          (role.users || []).map(user => ({
            role_type_id: role.id,
            subject_id: user.id,
            subject_type: 'User'
          }))
        ) ?? []
    }
  }
}
