import { memo, useMemo } from 'react'

import { Badge, Box, Checkbox, FilterAccordionPanel, MultiSelectControl } from '@cutover/react-ui'
import { useRunbookTeamFilter, useRunbookTeamIncludeUsersFilter, useTeamIdToTaskRecord } from 'main/recoil/data-access'
import { RunbookTeamModel } from 'main/data-access'
import { AnyOrNoneControls, ValueType } from 'main/recoil/shared/filters/controls'

export const TeamGroup = memo(() => {
  const [value = [], setValue] = useRunbookTeamFilter()
  const onlyNoValue = value.length === 1 && value[0] === 0
  const [includeUsers, setIncludeUsers] = useRunbookTeamIncludeUsersFilter()
  const teamToFilteredTaskLookup = useTeamIdToTaskRecord()
  const teams = RunbookTeamModel.useGetAll()

  const options = useMemo(
    () =>
      teams.map(team => ({
        label: team.name,
        value: team.team_id,
        suffix: <Badge label={teamToFilteredTaskLookup[team.id].length ?? 0} textColor="white" color={team.color} />
      })),
    [teamToFilteredTaskLookup, teams]
  )

  return teams?.length ? (
    <FilterAccordionPanel label="Team" applied={value && !!value.length}>
      {!onlyNoValue && value.length !== 0 && (
        <Box pad={{ bottom: 'large' }}>
          <Checkbox
            checked={!!includeUsers}
            // this checkbox wraps its text label. This is the only case I know of that is like this so not sure
            // if we should build that into the component as an option at this point or have this be a logical one-off.
            css={`
              + div {
                span {
                  white-space: normal;
                }
                > div:last-child {
                  align-self: flex-start;
                }
              }
            `}
            label="Include tasks assigned to individuals on team"
            onChange={val => {
              const isChecked = val.target.checked
              if (isChecked) {
                setIncludeUsers(true)
              } else {
                setIncludeUsers(false)
              }
            }}
          />
        </Box>
      )}
      <MultiSelectControl
        a11yTitle="Team"
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          // need to preserve none checked if it was also selected
          if (!val || val.length === 0) {
            setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
          } else {
            const nextVals = val?.map(v => v.value)
            setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
          }
        }}
        options={options}
        plain
        additionalControls={<AnyOrNoneControls value={value} setValue={setValue as (value?: ValueType) => void} />}
      />
    </FilterAccordionPanel>
  ) : null
})
