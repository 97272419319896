import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

import { Message, ScrollContent, useDisableFilterPanel } from '@cutover/react-ui'
import { DashboardPageForm } from './dashboard-page-form'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import { Dashboard as DashboardType } from 'main/services/queries/types'
import { useDashboardQuery } from 'main/services/queries/use-dashboard-query'
import { useLanguage } from 'main/services/hooks'

type PirDashboardProps = {
  dashboard: DashboardType
  runbookId: number
  endActual: number
  readOnly: boolean
}

export const PirDashboard = ({ dashboard, runbookId, endActual, readOnly }: PirDashboardProps) => {
  const { accountId } = useParams<{ accountId?: string }>()
  const { t } = useLanguage('dashboard', { keyPrefix: 'banner' })

  useDisableFilterPanel()

  const { data } = useDashboardQuery(
    {
      accountId: accountId,
      dashboardKey: String(dashboard.id),
      runbookId: String(runbookId),
      filterParams: ''
    },
    { enabled: true }
  )

  const components = data?.dashboard?.components
  const date = format(endActual * 1000, 'd MMM HH:mm')
  const message = t('runComplete', { date })

  return (
    <>
      {components && (
        <DashboardPageForm components={components} dashboard={dashboard}>
          <ScrollContent>
            <Message message={message} type="success" icon="info" />
            <RunbookWidgetCollection components={components} media="screen" readOnly={readOnly} isPir />
          </ScrollContent>
        </DashboardPageForm>
      )}
    </>
  )
}
