import parse from 'html-react-parser'

import { Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useWorkspaceArchive } from 'main/services/queries/use-workspace'

export const WorkspaceArchiveModal = ({
  onClose,
  workspaceId,
  workspaceName
}: {
  onClose?: () => void
  workspaceId: number
  workspaceName: string
}) => {
  const { t } = useLanguage('accounts', { keyPrefix: 'archiveModal' })
  const { closeRightPanel } = useSetActiveRightPanelState()
  const { mutateAsync: archiveWorkspace, isLoading } = useWorkspaceArchive(workspaceId)

  const handleSubmit = async () => {
    return await archiveWorkspace()
  }

  const handleSuccess = () => {
    onClose?.()
    closeRightPanel()
  }

  return (
    <FormModal
      title={t('title')}
      loading={isLoading}
      open
      confirmText={t('delete')}
      confirmIcon="delete"
      onClose={onClose}
      successMessage={t('deleteSuccess')}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      formElementWrapper={false} // Having to add this on most 'FormModal' comps seems wrong
    >
      <Text css="margin-bottom: 8px;">{parse(t('areYouSure', { workspaceName: workspaceName }))}</Text>
    </FormModal>
  )
}
