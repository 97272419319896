import { useCallback, useMemo } from 'react'

import { Box, ColumnConfig, NoResourceFound, RunbookTypeIcon, Table, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookTypeType } from 'main/services/queries/types'
import { useRunbookTypesQuery } from './use-runbook-type'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
/**
 * Note: We're using recoil sync to get applied filters in this global admin settings page.
 * This is *not* a pattern CFE is recommending as Recoil should only be using within the context
 * of a runbook currently, and we will transition away from Recoil in the future.
 */
import { useAppliedFilters, useClearAllFilterState } from 'main/recoil/data-access'

export const RunbookTypesListPage = () => {
  const { t } = useLanguage('runbookTypes')
  const { openRightPanel } = useSetActiveRightPanelState()

  const appliedFilters = useAppliedFilters() || {}
  const clearAllFilters = useClearAllFilterState()

  const { data: runbookTypesData } = useRunbookTypesQuery(appliedFilters)
  const items = runbookTypesData?.runbook_types || []

  // NOTE, The render for every attribute is done to conditionally manipulate the opacity of the whole row
  // there is no other way to do this at the moment given the limitations of grommet's DataTable
  // specifically the rowProps prop being quite limited
  const runbookTypeColumns: ColumnConfig<RunbookTypeType>[] = useMemo(() => {
    return [
      {
        header: t('list.tableHeadings.icon'),
        property: 'display_name',
        sortable: false,
        render: (datum: RunbookTypeType) => (
          <Box css={datum.disabled ? 'opacity: 0.5' : undefined}>
            <RunbookTypeIcon size="small" icon={datum.icon_name} color={datum.icon_color} />
          </Box>
        )
      },
      {
        header: t('list.tableHeadings.name'),
        property: 'name',
        sortable: true,
        render: (datum: RunbookTypeType) => <CustomText datum={datum}>{datum.name}</CustomText>
      },
      {
        header: t('list.tableHeadings.accountName'),
        property: 'account_name',
        sortable: true,
        render: (datum: RunbookTypeType) => <CustomText datum={datum}>{datum.account_name}</CustomText>
      },
      {
        header: t('list.tableHeadings.default'),
        property: 'default',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.default ? <YesText datum={datum} /> : <NoText datum={datum} />)
      },
      {
        header: t('list.tableHeadings.dynamic'),
        property: 'dynamic',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.dynamic ? <YesText datum={datum} /> : <NoText datum={datum} />)
      },
      {
        header: t('list.tableHeadings.incident'),
        property: 'incident',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.incident ? <YesText datum={datum} /> : <NoText datum={datum} />)
      },
      {
        header: t('list.tableHeadings.disabled'),
        property: 'disabled',
        sortable: true,
        render: (datum: RunbookTypeType) => (datum.disabled ? <YesText datum={datum} /> : <NoText datum={datum} />)
      }
    ]
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: RunbookTypeType }) => {
      openRightPanel({ type: 'runbook-type-edit-panel', runbookTypeId: datum?.id })
    },
    [openRightPanel]
  )

  return (
    <Box pad={{ right: '16px' }}>
      {items.length ? (
        <Table columns={runbookTypeColumns} data={items} onClickRow={handleClickRow} />
      ) : (
        <NoResourceFound context={t('list.runbookType')} clearAllFilters={clearAllFilters} />
      )}
    </Box>
  )
}

const YesText = ({ datum }: { datum: RunbookTypeType }) => {
  const { t } = useLanguage('common')
  const color = datum.disabled ? 'text-light' : 'default'
  return <Text color={color}>{t('yes')}</Text>
}

const NoText = ({ datum }: { datum: RunbookTypeType }) => {
  const { t } = useLanguage('common')
  const color = datum.disabled ? 'text-light' : 'default'
  return <Text color={color}>{t('no')}</Text>
}

const CustomText = ({ children, datum }: { children: React.ReactNode; datum: RunbookTypeType }) => {
  const color = datum.disabled ? 'text-light' : 'default'
  return <Text color={color}>{children}</Text>
}
