import { ReactNode } from 'react'
import styled from 'styled-components'

import { Box, breakpoints, colors, px2rem } from '@cutover/react-ui'

export type MrdDashboardWidgetProps = {
  title: string
  height?: 300
  fullWidth?: boolean
  notes?: string | null | undefined
  errors?: string[] | null
  children: ReactNode
  pageBreakType?: 'full-width' | 'never'
}

export const MrdDashboardWidget = ({
  title,
  fullWidth,
  children,
  errors,
  notes,
  pageBreakType = 'full-width'
}: MrdDashboardWidgetProps) => {
  return (
    <Widget fullWidth={fullWidth} pageBreakType={pageBreakType}>
      <Box margin={{ bottom: '16px' }}>
        <Header data-testid="dashboard-widget">{title}</Header>
        {notes && <Notes>{notes}</Notes>}
        {errors && <Errors>{errors}</Errors>}
      </Box>
      {children}
    </Widget>
  )
}

const Header = styled.h3`
  color: ${colors.textDefault};
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`

const Notes = styled.div`
  margin-top: 16px;
  font-size: ${px2rem(13)};
  color: ${colors.textLight};
`

const Errors = styled.div`
  margin-top: 16px;
  color: ${colors.error};
  font-size: ${px2rem(13)};
`

const Widget = styled(Box)<{ fullWidth?: boolean; pageBreakType: 'full-width' | 'never' }>`
  display: block;
  width: 100%;
  padding: 16px;
  /* using padding rather than margin to give donut-chart legends some breathing room */
  padding-bottom: 36px;
  overflow-y: auto;

  // TODO: screen breakpoints are not optimized so fixing width for now.
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};

  @media print, ${breakpoints.gtLg} {
    float: left;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    break-inside: ${({ fullWidth, pageBreakType }) => (fullWidth && pageBreakType === 'full-width' ? 'auto' : 'avoid')};
  }
`
