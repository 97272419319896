import { useState } from 'react'
import * as yup from 'yup'

import { Box, Message, Text } from '@cutover/react-ui'
import { TextInputField } from 'main/components/shared/form/form-fields'
import { TaskLineItemWrapper } from 'main/components/shared/task-line-item'
import { useTaskNotifications } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { bulkSkipTasks } from 'main/services/queries/use-task'
import { RunbookTaskBulkSkipResponse } from 'main/services/api/data-providers/runbook-types'
import { FormModal } from 'main/components/shared/form'
import { ActiveRunbookModel, ActiveRunbookVersionModel, TaskModel } from 'main/data-access'
import { useModalBulkTaskErrorsAndValidIds } from 'main/components/runbook/modals/task-modals/use-bulk-actions'

const validationSchema = yup.object({
  comment: yup.string().trim().required()
})

export type SkipTaskFormSchema = yup.InferType<typeof validationSchema>

type TaskSkipProps = {
  ids: number[]
  onClose: () => void
}

export const TaskSkip = ({ ids, onClose }: TaskSkipProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskSkipModal' })
  const [loading, setLoading] = useState(false)

  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const processTaskSkipResponse = TaskModel.useOnAction('bulk_skip')

  const { notifyTaskAction } = useTaskNotifications()

  const { tasks, taskErrors, errorTaskIds, isValidTaskIdsEmpty } = useModalBulkTaskErrorsAndValidIds({
    bulkAction: 'bulk_skip',
    taskIds: ids
  })

  const taskIds = tasks.map(task => task.id)
  const hasLinkedTasks = tasks.some(task => !!task.linked_resource?.id)

  const defaultValues = {
    comment: ''
  }

  const handleSubmit = async (data: SkipTaskFormSchema) => {
    setLoading(true)
    const payload = {
      comment: data.comment,
      task_ids: ids
    }
    return await bulkSkipTasks({ runbookId, runbookVersionId, payload })
  }

  return (
    <FormModal<SkipTaskFormSchema>
      open
      data-testid="modal-task-skip"
      loading={loading}
      confirmText={t('confirmText')}
      confirmIcon="chevron-right"
      confirmDisabled={isValidTaskIdsEmpty}
      title={t('title')}
      key="task-skip-form"
      onError={() => setLoading(false)}
      onSubmit={handleSubmit}
      onClose={onClose}
      onSuccess={(response: RunbookTaskBulkSkipResponse) => {
        setLoading(false)
        processTaskSkipResponse(response)
        notifyTaskAction(response)
        onClose()
      }}
      defaultValues={defaultValues}
      schema={validationSchema}
      preventAutoClose
      customErrors={
        tasks.length === 0
          ? [t('tasksNotAvailable', { count: ids.length })]
          : isValidTaskIdsEmpty
          ? [t('noSkipableTasksWarning', { count: ids.length })]
          : []
      }
    >
      {taskIds?.length > 0 && (
        <Box direction="column" gap="small">
          {errorTaskIds.length > 0 && !isValidTaskIdsEmpty && (
            <Message data-testid="task-skip-modal-unskipable-tasks-warning" type="warning">
              {t('unskipableTasksWarning', { count: errorTaskIds.length })}
            </Message>
          )}
          <Text>{t('content')}</Text>
          {hasLinkedTasks && <Text weight="bold">{t('linkedTaskWarning')}</Text>}
          <Box direction="column">
            {taskIds.map(id => (
              <TaskLineItemWrapper key={id} taskId={id} errors={!!taskErrors[id] ? [t('unableToSkip')] : undefined} />
            ))}
          </Box>
          <TextInputField label={t('reasonLabel')} name="comment" required />
        </Box>
      )}
    </FormModal>
  )
}
