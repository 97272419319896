import { TaskListTask } from 'main/services/queries/types'

type UniqueTimes = {
  [key: number]: {
    [key: number]: number[]
  }
}

type ParentLookup = {
  [key: number]: number[]
}

/**
 * Build sort reference data
 */
const setUniqueTimes = (uniqueTimes: UniqueTimes, task: TaskListTask) => {
  if (task.start_latest_planned) {
    if (!uniqueTimes[task.start_latest_planned]) {
      // More than one task at this timestamp
      uniqueTimes[task.start_latest_planned] = {}
    }
    uniqueTimes[task.start_latest_planned][task.id] = task.predecessor_ids
  }
  return uniqueTimes
}

/**
 * Recursive function to build lookup object of all tasks
 * with all predecessors (and predecessors of predecessors)
 * as the value
 */
const setParentLookup = (
  taskId: number,
  timestamp: number,
  predecessors: number[] = [],
  uniqueTimes: UniqueTimes,
  parentLookup: ParentLookup
) => {
  // Loop through predecessors
  for (let p = 0; p < predecessors.length; p++) {
    let currentPredecessor = predecessors[p]

    // Prevent recursive nature by checking whether we have already assigned this child
    if (
      uniqueTimes[timestamp].hasOwnProperty(currentPredecessor) &&
      parentLookup[taskId].indexOf(currentPredecessor) === -1
    ) {
      // Is this pred in list of tasks at same time? if not, ignore
      // If this predecessor is in the list of tasks occuring at this same time (otherwise not relevant)
      parentLookup[taskId].push(currentPredecessor)
      setParentLookup(taskId, timestamp, uniqueTimes[timestamp][currentPredecessor], uniqueTimes, parentLookup)
    }
  }

  return parentLookup
}

/**
 * Generates a lookup object containing each necessary task,
 * with its relevant ancestors
 */
const getParentLookup = (uniqueTimes: UniqueTimes) => {
  let parentLookup: ParentLookup = {}

  for (let timestamp in uniqueTimes) {
    if (Object.keys(uniqueTimes[timestamp]).length > 1) {
      // More than one task at this same timestamp
      for (let taskId in uniqueTimes[timestamp]) {
        // Loop through tasks occuring at this time
        parentLookup[taskId] = []
        parentLookup = setParentLookup(
          parseInt(taskId),
          parseInt(timestamp),
          uniqueTimes[timestamp][taskId],
          uniqueTimes,
          parentLookup
        )
      }
    }
  }
  return parentLookup
}

/**
 * Tasks are ordered by
 *  - forecast start (start_latest_planned),
 *  - then dependancy,
 *  - then task created date/time (created_at),
 *  - then alphabetical order (name, case insensitive)
 *  - is a sortOption or sortDir is provided, it will sort by that
 */
export const sort = (
  tasks: TaskListTask[],
  sortOption: string = 'start_latest_planned',
  sortDir: string = 'asc'
): TaskListTask[] => {
  let uniqueTimes = {}

  for (var i = 0, j = tasks.length; i < j; i++) {
    uniqueTimes = setUniqueTimes(uniqueTimes, tasks[i])
  }
  const parents = getParentLookup(uniqueTimes)
  if (sortOption === 'end_display') {
    return tasks.sort(function (a, b) {
      return a.end_display - b.end_display
    })
  } else if (sortOption === 'name') {
    return tasks.sort(function (a, b) {
      var A = a.name.toLowerCase()
      var B = b.name.toLowerCase()
      if (sortDir === 'asc') {
        return A < B ? -1 : A > B ? 1 : 0
      } else {
        return A > B ? -1 : A < B ? 1 : 0
      }
    })
  } else {
    return [...tasks].sort((a: TaskListTask, b: TaskListTask) => {
      if (a.start_latest_planned && b.start_latest_planned && a.start_latest_planned !== b.start_latest_planned) {
        // Dates not equal, sort by planned start
        return a.start_latest_planned - b.start_latest_planned
      } else if (
        parents[a.id]?.length < parents[b.id]?.length ||
        (parents.hasOwnProperty(b.id) && parents[b.id].indexOf(a.id) !== -1)
      ) {
        // a is in b's predecessors
        return -1
      } else if (
        parents[b.id]?.length < parents[a.id]?.length ||
        (parents.hasOwnProperty(a.id) && parents[a.id].indexOf(b.id) !== -1)
      ) {
        // b is in a's predecessors
        return 1
      } else if (a.created_at !== b.created_at) {
        return b.created_at - a.created_at
      } else {
        // No dependency connection or predecessors are the same, sort alphabetically
        var A = a.name.toLowerCase()
        var B = b.name.toLowerCase()
        if (A < B) {
          return -1
        } else if (A > B) {
          return 1
        } else {
          return 0
        }
      }
    })
  }
}
