// @ts-nocheck
import { useState, useEffect } from 'react'
import { container } from 'app/Config/IOC'
import { ConfirmMode, CustomFieldsViewPresenter } from './CustomFieldsViewPresenter'
import { CustomFieldsPagePresenter } from './CustomFieldsPagePresenter'
import { CustomFieldEditPresenter } from 'CustomFieldEdit/CustomFieldEditPresenter'
import { Modal, GenericAddModal } from 'Components/Atoms'
import { EditPanelArchiveModal } from 'Components/Templates'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import CustomFieldAddSection from './CustomFieldAdd/CustomFieldAddSection'
import { confirmSectionHooks, addSectionHooks } from 'Shared/HooksAndPresenters/AddEditSection'
import { EditPanelConfirmModal } from 'Shared/Components/Templates/EditPanelConfirmModal/EditPanelConfirmModal'
import { Submitted } from 'Shared/Entities/Enums/Submitted'

interface ICustomFieldsPageModals extends IPageModalProps {
  confirmMode: ConfirmMode
}

export const CustomFieldsPageModals = (props: ICustomFieldsPageModals) => {
  const {
    addModalOpenState,
    addFormPresenter,
    addFormCancelCallback,
    setAddModalOpenState,
    archiveModalOpenState,
    archiveConfirmName,
    cancelArchiveCallback,
    continueArchiveCallback,
    archiveResponseErrors,
    editSubmittedState,
    setEditSubmittedState,
    addPostSuccessCallback,
    archiveModalPresenter,
    language,
    confirmMode,
    usedInIntegration
  } = props

  const customFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  const { addModalPresenter } = addSectionHooks(setAddModalOpenState, addModalOpenState, addFormPresenter)
  const { confirmModalPresenter } = confirmSectionHooks(editSubmittedState)

  const [editConfirmTitle, setEditConfirmTitle] = useState('')
  const [editConfirmMessage, setEditConfirmMessage] = useState('')
  const [archiveConfirmTitle, setArchiveConfirmTitle] = useState('')
  const [archiveConfirmMessage, setArchiveConfirmMessage] = useState('')

  useEffect(() => {
    if (archiveModalOpenState) {
      archiveModalPresenter.onOpen()
    } else {
      archiveModalPresenter.onClose()
    }
  }, [archiveModalOpenState])

  // Used for archive/delete
  useEffect(() => {
    if (usedInIntegration) {
      setArchiveConfirmTitle(language.get('customFields:archive:modal:title'))
      setArchiveConfirmMessage(language.get('customFields:archive:modal:usedInIntegrationConfirmation'))
    } else {
      setArchiveConfirmTitle(language.get('customFields:archive:modal:title'))
      setArchiveConfirmMessage(language.get('customFields:archive:modal:archiveConfirmation'))
    }
  }, [usedInIntegration])

  // Used for edit
  useEffect(() => {
    if (confirmMode === ConfirmMode.Delete) {
      setEditConfirmTitle(language.get('customFields:edit:deleteConfirmationTitle'))
      setEditConfirmMessage(language.get('customFields:edit:deleteConfirmation'))
    } else {
      setEditConfirmTitle(language.get('common:confirmTitle'))
      setEditConfirmMessage(language.get('customFields:edit:confirmation'))
    }
  }, [confirmMode])

  return (
    <>
      <GenericAddModal
        title={language.get('customFields:add:title')}
        presenter={addModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormCancelCallback()
        }}
        viewPresenter={customFieldsViewPresenter}
        language={language}
      >
        <CustomFieldAddSection
          postSuccessCallback={() => {
            addModalPresenter.onClose()
            setAddModalOpenState(false)
            addPostSuccessCallback()
          }}
          addFormPresenter={addFormPresenter}
        />
      </GenericAddModal>

      <Modal presenter={archiveModalPresenter.withOnCloseExtra(cancelArchiveCallback)}>
        <EditPanelArchiveModal
          archiveConfirmName={usedInIntegration ? undefined : archiveConfirmName}
          cancelCallback={cancelArchiveCallback}
          continueCallback={continueArchiveCallback}
          responseErrors={archiveResponseErrors}
          modalTitle={archiveConfirmTitle}
          modalMessage={archiveConfirmMessage}
        />
      </Modal>
      <Modal presenter={confirmModalPresenter.withOnCloseExtra(() => setEditSubmittedState(Submitted.Unsubmitted))}>
        <EditPanelConfirmModal
          modalTitle={editConfirmTitle}
          modalMessage={editConfirmMessage}
          continueCallback={() => {
            setEditSubmittedState(Submitted.SubmittedAndConfirmed)
          }}
          cancelCallback={() => {
            setEditSubmittedState(Submitted.Unsubmitted)
          }}
        />
      </Modal>
    </>
  )
}
