import { memo } from 'react'
import dompurify from 'dompurify'

import { Text, TextProps } from '@cutover/react-ui'

// target="_blank" is stripped during sanitize() (see https://github.com/cure53/DOMPurify/issues/317)
// so we set a temporary value and then pop '_blank' back in afterwards if we encounter it
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'
dompurify.addHook('beforeSanitizeAttributes', node => {
  if (node instanceof HTMLElement && node.hasAttribute('target') && node.getAttribute('target') === '_blank') {
    node.setAttribute(TEMPORARY_ATTRIBUTE, '_blank')
  }
})
dompurify.addHook('afterSanitizeAttributes', node => {
  if (node instanceof HTMLElement && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', '_blank')
    // according to https://html.spec.whatwg.org/multipage/links.html#link-type-noopener,
    // noreferrer implies noopener, but we set it just to be sure in case some browsers
    // do not implement the spec accordingly.
    node.setAttribute('rel', 'noopener noreferrer')
    node.removeAttribute(TEMPORARY_ATTRIBUTE)
  }
})

export const SanitizedString = memo(({ input, ...props }: { input: string } & Omit<TextProps, 'ref'>) => {
  const sanitize = (input: string) =>
    dompurify.sanitize(input, {
      ALLOWED_TAGS: [
        'b',
        'strong',
        'br',
        'li',
        'ul',
        'p',
        'ol',
        'i',
        'a',
        'u',
        'table',
        'thead',
        'tbody',
        'td',
        'tr',
        'span'
      ]
    })
  // Note: this is the only place in the codebase in which dangerouslySetInnerHTML is allowed and is strictly sanitized
  return <Text {...props} className="react-sanitized-string" dangerouslySetInnerHTML={{ __html: sanitize(input) }} />
})
