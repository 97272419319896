import { ChangeEvent, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import parse from 'html-react-parser'
import { useFormContext, UseFormReturn } from 'react-hook-form'

import { toSnakeCase } from '@cutover/api'
import { Box, Toggle, useNotify } from '@cutover/react-ui'
import { IntegrationAccordionPanel } from './integration-accordion-panel'
import { IntegrationActionButton } from './integration-action-button'
import { IntegrationActionLogs } from './integration-action-logs'
import {
  useRunbookIntegrationsCreate,
  useRunbookIntegrationsDestroy
} from '../../../../services/queries/use-runbook-integrations'
import { CustomFieldForm, ReadonlyCustomFields } from 'main/components/shared/custom-field-form'
import { IntegrationImage } from 'main/components/shared/integration-image'
import { useLanguage } from 'main/services/hooks'
import {
  CustomField,
  FieldValue,
  IntegrationEvent,
  RunbookIntegration,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'

type IntegrationItemProps = {
  runbookIntegration: RunbookIntegration
  runbookId: number
  runbookVersionId: number
  integrationLinkForm: UseFormReturn<RunbookIntegration, object>
  disableLink: boolean
  active: boolean
  accordionIndex: number
  setOpen: (arg: number[] | ((active: number[]) => number[])) => void
  taskLookup: Record<number, TaskListTask>
  taskTypeLookup: Record<number, TaskType>
  streamLookup: Record<number, StreamListStream>
}

/** NOTE: please do not access models in here until the runbook migration is complete */
export const IntegrationItem = ({
  runbookIntegration,
  runbookId,
  runbookVersionId,
  integrationLinkForm,
  disableLink,
  active,
  accordionIndex,
  setOpen,
  taskLookup,
  streamLookup,
  taskTypeLookup
}: IntegrationItemProps) => {
  const { t } = useLanguage()
  const notify = useNotify()
  const {
    setValue,
    formState: { errors }
  } = useFormContext()
  const { reset, setError } = integrationLinkForm
  const [integrationEvents, setIntegrationEvents] = useState<IntegrationEvent[] | []>([])

  const integrationLinkMutation = useRunbookIntegrationsCreate()
  const integrationUnlinkMutation = useRunbookIntegrationsDestroy()

  const onToggleIntegration = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const integrationNameObj = { integrationName: runbookIntegration.integrationActionItem.name }
    // prevent mutation if toggle is disableLink
    if (!disableLink) {
      if (!runbookIntegration.id && event.currentTarget.checked) {
        integrationLinkMutation.mutate(
          {
            linkableId: runbookIntegration.linkableId,
            linkableType: 'RunbookVersion',
            integrationActionItemId: runbookIntegration.integrationActionItemId
          },
          {
            onSuccess: (response: RunbookIntegration) => {
              runbookIntegration.id = response.id
              runbookIntegration.customFields = toSnakeCase(response.customFields)
              runbookIntegration.fieldValues = toSnakeCase(response.fieldValues)
              buildCustomFieldFormValues(runbookIntegration.customFields, runbookIntegration.fieldValues)
              setOpen(prev => Array.from(new Set([...prev, accordionIndex])))
              notify.success(parse(t('runbook:integrationsPanel:linked', integrationNameObj)) as string)
            },
            onError: response => {
              response.errors ? notify.error(response.errors[0]) : notify.error(t('runbook:integrationsPanel:error'))
            }
          }
        )
      } else if (runbookIntegration.id) {
        integrationUnlinkMutation.mutate(runbookIntegration.id, {
          onSuccess: (response: RunbookIntegration) => {
            runbookIntegration.id = response.id
            runbookIntegration.inProgress = false
            notify.success(parse(t('runbook:integrationsPanel:unlinked', integrationNameObj)) as string)
            setOpen(prev => prev.filter(i => i !== accordionIndex))
          },
          onError: response => {
            response.errors ? notify.error(response.errors[0]) : notify.error(t('runbook:integrationsPanel:error'))
          }
        })
      }
    }

    // Update app settings panel
    eventManager.emit('on-submit-integrations-panel')
    resetForm()
  }

  const buildCustomFieldFormValues = (customFields: CustomField[], fieldValues: FieldValue[]) => {
    customFields.forEach(cf => {
      const fieldValue = fieldValues?.find(fv => fv.custom_field_id === cf.id)
      setValue(`runbook.field_values.${cf.id}`, {
        id: fieldValue?.id,
        custom_field_id: cf.id,
        field_option_id: fieldValue?.field_option_id,
        remote_data_key_value: fieldValue?.remote_data_key_value,
        data_source_value_id: fieldValue?.data_source_value_id,
        value: fieldValue?.value
      })
    })
  }

  const handleUpdateIntegrationEvents = (event: IntegrationEvent) => {
    runbookIntegration.integrationEvents = runbookIntegration.integrationEvents.concat(event)
    setIntegrationEvents(runbookIntegration.integrationEvents)
  }

  const resetForm = () => {
    reset({ linkableId: runbookVersionId, linkableType: 'RunbookVersion' })
  }

  useEffect(() => {
    if (integrationLinkMutation.isError) {
      setError('checked', { message: integrationLinkMutation.error?.errors[0] })
    }

    if (integrationUnlinkMutation.isError) {
      setError('checked', { message: integrationUnlinkMutation.error?.errors[0] })
    }
  }, [])

  useEffect(() => {
    setIntegrationEvents(runbookIntegration.integrationEvents)
  }, [runbookIntegration.integrationActionItemId])

  return (
    <>
      <IntegrationAccordionPanel
        label={runbookIntegration.integrationSettingName}
        secondaryLabel={runbookIntegration.integrationActionItem.name}
        disabled={disableLink}
        toggledOn={!!active}
        icon={
          <IntegrationImage
            size="large"
            src={runbookIntegration.integrationActionItem.imageUrl}
            active={active}
            inProgress={runbookIntegration.inProgress || false}
          />
        }
        suffix={
          <Toggle
            disabled={disableLink}
            checked={active}
            onClick={e => e.stopPropagation()}
            value={runbookIntegration.integrationActionItemId || undefined}
            onChange={onToggleIntegration}
          />
        }
      >
        {active && (
          <IntegrationActionButton
            runbookId={runbookId}
            runbookIntegration={runbookIntegration}
            onUpdateIntegrationEvents={handleUpdateIntegrationEvents}
          />
        )}
        {active && runbookIntegration.customFields.length > 0 && (
          <>
            <Box direction="column" margin={{ vertical: 'small' }}>
              <CustomFieldForm
                taskLookup={taskLookup}
                streamLookup={streamLookup}
                taskTypeLookup={taskTypeLookup}
                errors={errors}
                customFields={toSnakeCase(
                  runbookIntegration.customFields.filter(cf => !cf.options || !cf.options.readonly)
                )}
                namePrefix="runbook."
              />
            </Box>
            <ReadonlyCustomFields
              customFields={toSnakeCase(
                runbookIntegration.customFields.filter(cf => cf.options && cf.options.readonly)
              )}
              fieldValues={toSnakeCase(runbookIntegration.fieldValues)}
            />
          </>
        )}
        {active && integrationEvents.length > 0 && <IntegrationActionLogs runbookIntegration={runbookIntegration} />}
      </IntegrationAccordionPanel>
    </>
  )
}
