import { useContext, useRef } from 'react'

import { GlobalConfigType } from 'main/services/hooks'
import { GlobalConfigContext } from 'main/context/global-config-context'

export const useGetConfig = () => {
  const config = useContext(GlobalConfigContext)
  const configRef = useRef(config)

  return configRef.current
}

export function useIsFeatureEnabled(feature: GlobalConfigType['enabledFeatures'][number]) {
  const globalConfig = useContext(GlobalConfigContext)
  return globalConfig.enabledFeatures.includes(feature)
}
