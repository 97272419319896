import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components/macro'

import { Box, BoxProps } from '../layout/box'
import { theme } from '../theme'

export type CollapsibleBoxProps = Omit<BoxProps, 'direction'> & {
  open?: boolean
  horizontal?: boolean
  className?: string
  trigger?: ReactNode
  minHeight?: number
  maxHeight?: number | string
  disableAnimation?: boolean
}

/**
 * Adapted from https://v2.grommet.io/collapsible
 * TODO: review/improve current implementation.
 */
export const CollapsibleBox = memo(
  forwardRef<HTMLDivElement, CollapsibleBoxProps>(
    (
      { children, trigger, horizontal = false, minHeight = 0, maxHeight, open: openArg, disableAnimation, ...props },
      ref
    ) => {
      const containerRef = useForwardedRef(ref)
      const [open, setOpen] = useState(openArg)
      const [speed, setSpeed] = useState(200)
      const dimension = useMemo(() => (!!horizontal ? 'width' : 'height'), [horizontal])
      const hasChildren = !!children
      const [hasRenderedChildren, setHasRenderedChildren] = useState(hasChildren && !!(open || openArg))
      const isOpen = open || openArg
      const shouldOpen = !open && openArg
      const shouldClose = open && !openArg
      const [maxHeightWhenOpen, setMaxHeightWhenOpen] = useState(maxHeight)
      const [animate, setAnimate] = useState(openArg !== open)

      useEffect(() => {
        if (containerRef.current && isOpen && !shouldOpen) {
          const container = containerRef.current as any
          const { [dimension]: size } = container.getBoundingClientRect()
          setMaxHeightWhenOpen(size)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [containerRef])
      const sizeRef = useRef(0)

      useEffect(() => {
        if (maxHeight !== undefined) {
          setMaxHeightWhenOpen(maxHeight)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [maxHeight])

      // when the caller changes openArg, trigger animation
      useEffect(() => {
        if (openArg !== open) {
          setAnimate(true)
          setOpen(openArg)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [openArg, open])

      useEffect(() => {
        if (hasChildren && isOpen) {
          setHasRenderedChildren(true)
        }
      }, [hasChildren, isOpen])

      // prepare to open or close
      useLayoutEffect(() => {
        const container = containerRef.current
        if (!container) return
        if (!container.parentNode) return

        if (shouldOpen) {
          // @ts-ignore
        } else if (shouldClose) {
          const { [dimension]: size } = container.getBoundingClientRect()
          // @ts-ignore
          container.style[`max-${dimension}`] = `${size}px`
        }

        if (isOpen) {
          const { [dimension]: size } = container.getBoundingClientRect()
          sizeRef.current = size
        }
      }, [shouldOpen, shouldClose, containerRef, dimension, minHeight, animate, isOpen])

      useEffect(() => {
        if (shouldOpen || shouldClose) {
          const container = containerRef.current as any
          const collapsible = theme.collapsible ?? {}
          const { minSpeed = 200, baseline = 500 } = collapsible
          const nextSpeed = Math.max((sizeRef.current / baseline) * minSpeed, minSpeed)
          setSpeed(nextSpeed)

          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              container.style[`max-${dimension}`] = shouldOpen ? `${maxHeightWhenOpen}px` : `${minHeight}px`
            })
          })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [shouldOpen, shouldClose, containerRef, dimension])

      useEffect(() => {
        let timer: NodeJS.Timeout | undefined
        if (animate) {
          const container = containerRef.current
          timer = setTimeout(() => {
            setAnimate(false)
            container?.removeAttribute('style')
          }, speed)
        }

        return () => {
          if (timer) clearTimeout(timer)
        }

        // we need open here to cancel the timer and restart it
      }, [animate, containerRef, speed, open])

      const animatedBoxContent = useMemo(() => {
        return (
          <>
            {trigger}
            {(isOpen || hasRenderedChildren) && children}
          </>
        )
      }, [children, hasRenderedChildren, isOpen, trigger])

      return (
        <AnimatedBox
          {...props}
          ref={containerRef}
          hidden={!open}
          flex={false}
          open={open}
          animate={animate}
          disable={disableAnimation}
          dimension={dimension}
          speedProp={speed}
          minHeight={minHeight}
          maxHeight={maxHeightWhenOpen}
          shouldOpen={!animate && shouldOpen}
        >
          {animatedBoxContent}
        </AnimatedBox>
      )
    }
  )
)

const useForwardedRef = <T extends HTMLElement = HTMLElement>(ref: ForwardedRef<T>) => {
  const innerRef = useRef<T>(null)
  // @ts-ignore
  useImperativeHandle(ref, () => innerRef.current, [innerRef])
  return innerRef
}

type AnimatedBoxProps = {
  shouldOpen?: boolean
  dimension?: 'width' | 'height'
  speedProp?: number
  open?: boolean
  animate?: boolean
  minHeight?: number
  maxHeight?: number
  disable?: boolean
}

const AnimatedBox = styled(Box)<AnimatedBoxProps>`
  max-height: ${props =>
    !props.open
      ? `${props.minHeight ?? 0}px`
      : `${
          typeof props.maxHeight !== undefined
            ? typeof props.maxHeight === 'number'
              ? props.maxHeight + 'px'
              : props.maxHeight
            : '100%'
        }`};
  min-height: ${props => props.minHeight && props.minHeight + 'px'};
  will-change: max-height;

  ${props => {
    return (
      !props.disable &&
      css`
        transition: max-height 200ms ease;
      `
    )
  }}
`
