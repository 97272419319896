import { PartialDeep } from 'type-fest'

import { ExtendedCustomField } from 'main/recoil/runbook'
import {
  useGetCustomField,
  useGetCustomFieldBy,
  useGetCustomFieldByCallback,
  useGetCustomFieldCallback,
  useGetCustomFieldLookup,
  useGetCustomFields,
  useGetCustomFieldsCallback,
  useGetCustomFieldsLookupCallback
} from '../hooks'
import { CollectionModelInterfaceBase, CollectionModelInterfaceWithSingleAccessBy as WithGetBy } from './types'

export type CustomFieldModelType = CollectionModelInterfaceBase<ExtendedCustomField> &
  WithGetBy<ExtendedCustomField, PartialDeep<ExtendedCustomField>>

export const CustomFieldModel: CustomFieldModelType = {
  useGet: useGetCustomField,
  useGetCallback: useGetCustomFieldCallback,

  useGetAll: useGetCustomFields,
  useGetAllCallback: useGetCustomFieldsCallback,

  useGetLookup: useGetCustomFieldLookup,
  useGetLookupCallback: useGetCustomFieldsLookupCallback,

  useGetBy: useGetCustomFieldBy,
  useGetByCallback: useGetCustomFieldByCallback
}
