import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query'

import { apiClient, ApiError } from '../api'
import {
  WorkspaceArchiveResponse,
  WorkspaceCreatePayload,
  WorkspaceCreateResponse,
  WorkspaceEditResponseType,
  WorkspaceUpdatePayload,
  WorkspaceUpdateResponse
} from './types'

export const useWorkspaceCreate = () => {
  const queryClient = useQueryClient()
  return useMutation<WorkspaceCreateResponse, ApiError, WorkspaceCreatePayload>(
    ['workspace-create'],
    async payload => {
      const { data } = await apiClient.post<WorkspaceCreatePayload, WorkspaceCreateResponse>({
        url: 'accounts',
        data: payload
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('accounts')
      }
    }
  )
}

export const useWorkspaceEdit = (workspaceId: number) => {
  return useQuery<WorkspaceEditResponseType, ApiError>(
    ['workspace-edit', workspaceId],
    async () => {
      const { data } = await apiClient.get<WorkspaceEditResponseType>({
        url: `accounts/${workspaceId}/edit`
      })

      data.account.name = unescape(data.account.name)

      return data
    },
    {
      cacheTime: 0
    }
  )
}

export const useWorkspaceUpdate = (
  id: number,
  options: UseMutationOptions<WorkspaceUpdateResponse, Error, WorkspaceUpdatePayload> = {}
) => {
  const queryClient = useQueryClient()
  return useMutation<WorkspaceUpdateResponse, ApiError, WorkspaceUpdatePayload>(
    'workspace-update',
    async (payload: WorkspaceUpdatePayload) => {
      const { data } = await apiClient.put({
        url: `accounts/${id}`,
        data: { account: payload }
      })

      return data as WorkspaceUpdateResponse
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('accounts')
        response.account.name = unescape(response.account.name)
        options?.onSuccess?.(response, variables, context)
      },
      ...options?.onError,
      ...options?.onMutate,
      ...options?.onSettled
    }
  )
}

export const useWorkspaceArchive = (
  workspaceId: number,
  options: UseMutationOptions<WorkspaceArchiveResponse, Error> = {}
) => {
  const queryClient = useQueryClient()
  return useMutation<WorkspaceArchiveResponse, ApiError>(
    'workspace-archive',
    async () => {
      const { data } = await apiClient.patch({
        url: `accounts/${workspaceId}/archive`
      })

      return data as WorkspaceArchiveResponse
    },
    {
      onSuccess: (response, variables, context) => {
        queryClient.invalidateQueries('accounts')
        options?.onSuccess?.(response, variables, context)
      },
      ...options?.onError,
      ...options?.onMutate,
      ...options?.onSettled
    }
  )
}
