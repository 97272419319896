import { memo, SyntheticEvent } from 'react'
import { Placement } from '@popperjs/core'
import { ThemeContext } from 'grommet'

import { Icon } from '../icon'
import { Box } from '../layout/box'
import { SIZE_MAP } from '../list/list-item/people/size-config'
import { Tooltip } from '../overlays/tooltip'
import { themeColor } from '../theme'
import { Text } from '../typography'

// Very loose type due to the hugely inconsistent inputs we have
export type AvatarSubject = {
  id?: number | string
  first_name?: string
  last_name?: string
  firstName?: string // need to make consistent
  lastName?: string
  name?: string // some api requests return users of a type with only a name, no first_name, last_name
  online?: boolean | null
  color?: string | null
  archived?: boolean | null
  linked?: boolean | null
  highlight?: boolean | null
  type?: 'user' | 'team'
}

// Subject can be null in order to render a 'no user' graphic
type AvatarProps = {
  subject?: AvatarSubject | AvatarSubject[]
  size?: 'small' | 'medium' | 'large'
  tooltip?: string | boolean
  tooltipPlacement?: Placement
  draggable?: boolean
  onClick?: (e: SyntheticEvent, subject?: AvatarSubject) => void
  'data-testid'?: string
}

export const Avatar = memo(
  ({
    size = 'medium',
    subject,
    tooltip,
    tooltipPlacement = 'auto',
    draggable = false,
    onClick,
    'data-testid': dataTestId
  }: AvatarProps) => {
    const isArray = Array.isArray(subject)
    const isMoreThanOne = isArray && subject.length > 1
    const isSingleItemArray = isArray && subject.length === 1
    const isEmpty = !subject || (isArray && !subject.length)
    const singleUserOrTeam = isSingleItemArray ? subject[0] : !isArray && !isEmpty ? subject : null
    const isUser = isSubjectUser(singleUserOrTeam)
    const avatarText = isMoreThanOne ? multiToInitials(subject) : subjectToInitials(singleUserOrTeam, isUser)
    const tooltipText = subjectToTooltip(subject, tooltip)
    const isTeam = !isMoreThanOne && avatarText.length === 1
    const isOnline = isUser && singleUserOrTeam?.online
    const isLinked = isTeam && singleUserOrTeam?.linked
    const color = isArray ? subject[0]?.color : subject?.color

    const linkedIcon = (
      <Box
        align="center"
        justify="center"
        background="bg"
        round
        height={SIZE_MAP[size].linked.size}
        width={SIZE_MAP[size].linked.size}
        css={`
          position: absolute;
          bottom: ${SIZE_MAP[size].linked.bottom};
          right: ${SIZE_MAP[size].linked.right};
        `}
      >
        <Icon
          icon="link"
          size={SIZE_MAP[size].linked.icon}
          color="text"
          css="position: relative; bottom: 0; right: 0;"
        />
      </Box>
    )

    const onlineIcon = (
      <>
        <Box
          height={`calc(${SIZE_MAP[size].online.size} + 1.5px)`}
          width={`calc(${SIZE_MAP[size].online.size} + 1.5px)`}
          css={`
            clip-path: ellipse(
              calc(${SIZE_MAP[size].online.size} - ${SIZE_MAP[size].online.border})
                calc(${SIZE_MAP[size].online.size} - ${SIZE_MAP[size].online.border}) at 0% 0%
            );
            position: absolute;
            bottom: -${SIZE_MAP[size].online.border};
            right: -${SIZE_MAP[size].online.border};
          `}
          round="full"
        />
        <Box
          height={SIZE_MAP[size].online.size}
          width={SIZE_MAP[size].online.size}
          background="rag-green"
          border={{ size: SIZE_MAP[size].online.border, color: 'bg' }}
          css={`
            position: absolute;
            bottom: -${SIZE_MAP[size].online.border};
            right: -${SIZE_MAP[size].online.border};
          `}
          round="full"
        />
      </>
    )

    const content = (
      <ThemeContext.Extend value={{ dark: false }}>
        {avatarText.length ? (
          <Text color="white" css="text-transform: uppercase; font-weight:700" size={SIZE_MAP[size].text}>
            {avatarText}
          </Text>
        ) : (
          <Icon icon="user" size={SIZE_MAP[size].icon} color={color ? 'bg' : 'text-disabled'} />
        )}
      </ThemeContext.Extend>
    )

    const teamCircle = (
      <Box
        align="center"
        justify="center"
        border={{ size: SIZE_MAP[size].online.border, color: 'bg' }}
        round
        height={SIZE_MAP[size].inset}
        width={SIZE_MAP[size].inset}
        css={`
          position: absolute;
          top: ${SIZE_MAP[size].insetOffset};
          left: ${SIZE_MAP[size].insetOffset};
        `}
      ></Box>
    )

    const onDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
      if (!draggable || !singleUserOrTeam || !singleUserOrTeam.id) {
        event.preventDefault()
        return
      }
      const type = isUser ? 'user' : 'runbook_team'
      const id = singleUserOrTeam.id

      const taskListWrapper = document.getElementById('layout-main')
      if (taskListWrapper) {
        taskListWrapper.classList.add('dragging-user')
      }

      event.dataTransfer && event.dataTransfer.setData('text', JSON.stringify({ type, id }))
    }

    const onDragEnd = () => {
      const taskListWrapper = document.getElementById('layout-main')
      if (taskListWrapper) {
        taskListWrapper.classList.remove('dragging-user')
      }
    }

    // Note - opacity 0.4 was previously set on the avatar itself
    // if disabled. This makes no sense, it should be on the
    // row item itself rather than each child elem separately

    const avatarOutput = (
      <Box
        data-testid={
          dataTestId ? `${dataTestId}${isEmpty ? ' avatar-empty' : ''}` : isEmpty ? 'avatar-empty' : undefined
        }
        align="center"
        justify="center"
        height={SIZE_MAP[size].size}
        width={SIZE_MAP[size].size}
        round="full"
        draggable={draggable}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        css={`
          background-color: ${isEmpty ? themeColor('bg-2') : color};
          position: relative;
          flex-shrink: 0;
          transform: translate(0px, 0px);
          cursor: ${draggable ? 'move' : onClick ? 'pointer' : 'default'};
        `}
        onClick={e => onClick?.(e, Array.isArray(subject) ? subject[0] : subject)}
      >
        {content}
        {isOnline && onlineIcon}
        {isLinked && linkedIcon}
        {isTeam && teamCircle}
      </Box>
    )

    return (
      <>
        {tooltipText.length ? (
          <Box
            height={SIZE_MAP[size].size}
            width={SIZE_MAP[size].size}
            css={`
              flex-shrink: 0;
              transform: translate(0px, 0px);
            `}
          >
            <Tooltip content={tooltipText} placement={tooltipPlacement} disableOnClickAsTriggerProp>
              {avatarOutput}
            </Tooltip>
          </Box>
        ) : (
          <>{avatarOutput}</>
        )}
      </>
    )
  }
)

// work out if object is user or team
function isSubjectUser(userOrTeam: AvatarSubject | null): boolean {
  if (!userOrTeam) {
    return false
  }
  if (userOrTeam.type === 'user') {
    //one api call gives us this, so we know for sure
    return true
  }

  if (userOrTeam.hasOwnProperty('highlight') || userOrTeam.hasOwnProperty('linked')) {
    return false
  }
  return (
    userOrTeam.hasOwnProperty('first_name') ||
    userOrTeam.hasOwnProperty('firstName') ||
    userOrTeam.hasOwnProperty('online')
  )
}

function subjectToTooltip(subject?: AvatarSubject | AvatarSubject[], tooltip?: boolean | string): string {
  if (!tooltip) {
    return ''
  } else if (typeof tooltip === 'string') {
    // user has passed in a custom tooltip
    return tooltip
  } else if (!subject) {
    return ''
  } else if (Array.isArray(subject)) {
    let nameString = ''
    subject.map(userOrTeam => {
      nameString += userToFullName(userOrTeam) + ', '
    })
    return nameString.substring(0, nameString.length - 2)
  } else {
    return userToFullName(subject)
  }
}

function userToFullName(subject: AvatarSubject): string {
  if (subject.first_name && subject.last_name) {
    return subject.first_name + ' ' + subject.last_name
  } else if (subject.firstName && subject.lastName) {
    return subject.firstName + ' ' + subject.lastName
  } else {
    return subject.name ? subject.name : ''
  }
}

// In future we can improve this, by standarising API
function subjectToInitials(arg: AvatarSubject | null, isUser: boolean): string {
  if (!arg) {
    return ''
  } else if (isUser) {
    return userToInitials(arg)
  } else {
    return teamToInitials(arg)
  }
}

function multiToInitials(arg: AvatarSubject[]): string {
  return arg.length > 9 ? '9+' : String(arg.length)
}

function userToInitials(arg: AvatarSubject): string {
  // at least 1 api endpoint returns users with a 'name' and not fname/lname
  if (arg.first_name && arg.last_name) {
    return arg.first_name[0] + arg.last_name[0]
  } else if (arg.firstName && arg.lastName) {
    return arg.firstName[0] + arg.lastName[0]
  } else if (arg.name) {
    const names = arg.name?.split(' ')
    const [firstName, ...restNames] = names
    const lastName = restNames[restNames.length - 1]
    return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`
  } else {
    return ''
  }
}

function teamToInitials(arg: AvatarSubject): string {
  return arg.name && arg.name.length > 0 ? arg.name[0] : ''
}
