import { Simplify } from 'type-fest'

import { FieldValue } from 'main/services/queries/types'
import { CollectionModelInterfaceWithCollectionAccessBy as WithGetAllBy } from './types'
import { useGetAllFieldValuesBy, useGetAllFieldValuesByCallback } from '../hooks'

export type FieldValueModelType = Simplify<WithGetAllBy<FieldValue, { taskId: number }, 'extended'>>

/**
 * FieldValueModel
 * @description `{scope: 'extended'}` refers to field values from the task and runbook level field values (eg. applied to 'runbook_edit' etc)
 * that have been added to the runbook by merging or adding a snippet. These show up in the runbook filter panel (if 'display in filter'
 * is applied) and the merged tasks from that runbook
 */
export const FieldValueModel: FieldValueModelType = {
  useGetAllBy: useGetAllFieldValuesBy,
  useGetAllByCallback: useGetAllFieldValuesByCallback
}
