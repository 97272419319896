import * as yup from 'yup'
import { Controller, useFormContext } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

import { Box, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormModal, RadioboxGroupField, TextAreaField, TextEditorField } from 'main/components/shared/form'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  ConfigModel
} from 'main/data-access'
import { RecipientMultiselect } from 'main/components/recipients-multiselect'
import { AdHocCommsPayload, useSendAdHocComms } from 'main/services/queries/use-runbook-versions'

type AdHocCommsModalProps = {
  onClose?: () => void
}

type CommsType = 'sms' | 'email' | 'call'

const validationSchema = yup.object({
  commsType: yup.string().oneOf<CommsType>(['sms', 'email', 'call']),
  message: yup
    .string()
    .required()
    .when('commsType', {
      is: (commsType: string) => commsType === 'call',
      then: schema => schema.notRequired()
    }),
  recipients: yup
    .array()
    .min(1, 'must be at least one')
    .of(yup.mixed<{ type: 'user' | 'team'; id: number }>().required())
    .required()
})

export type AdHocCommsFormType = yup.InferType<typeof validationSchema>

export const AdHocCommsModal = ({ onClose }: AdHocCommsModalProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'adHocCommsModal' })
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const runbookId = ActiveRunbookModel.useId()
  const accountId = ActiveAccountModel.useId()
  const run = ActiveRunModel.useGet()
  const isLiveRun = run?.run_type === 'live'
  const notify = useNotify()
  const { mutateAsync, isLoading } = useSendAdHocComms(runbookId, runbookVersionId)

  const handleSubmit = async (data: AdHocCommsPayload) => {
    return await mutateAsync(data)
  }

  // Note: there is no response data
  const handleSuccess = () => {
    notify.success(t('successText'))
    onClose?.()
  }

  return (
    <FormModal<AdHocCommsFormType, AdHocCommsPayload>
      open
      title={t('title')}
      confirmText={t('confirmText')} // how to condtionally changet this to 'call' since cant use watch when not within formContext
      confirmIcon={'send'}
      loadingText={t('confirmingText')}
      loading={isLoading}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
      schema={validationSchema}
      transformer={dataTransformer}
      defaultValues={{
        commsType: isLiveRun ? 'sms' : 'email'
      }}
      preventAutoClose
    >
      <AdHocCommsModalInner
        isLiveRun={isLiveRun}
        accountId={accountId}
        runbookId={runbookId}
        runbookVersionId={runbookVersionId}
      />
    </FormModal>
  )
}

const dataTransformer = ({ recipients, ...data }: AdHocCommsFormType): AdHocCommsPayload => {
  const users = (recipients || []).filter(recipient => recipient.type === 'user')
  const teams = (recipients || []).filter(recipient => recipient.type === 'team')

  return {
    commsType: data.commsType,
    message: data.commsType !== 'call' ? data.message : undefined,
    runbookTeams: teams.map(team => team.id).sort() ?? [],
    users: users.flatMap(user => (user.id ? [user.id] : [])).sort() ?? []
  }
}

const AdHocCommsModalInner = ({
  accountId,
  runbookId,
  runbookVersionId,
  isLiveRun
}: {
  accountId: number
  runbookId: number
  runbookVersionId: number
  isLiveRun: boolean
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'adHocCommsModal' })
  const { taskTypeSms: isEnabledSms, taskTypeCall: isEnabledCall } = ConfigModel.useGet()
  const { watch, control, resetField } = useFormContext<AdHocCommsFormType>()
  const commsType = watch('commsType')

  // Note: isEnabledSms etc more or less follows angular behaviour. Leaving email as always enabled
  // otherwise this modal can't be used. I think this is due to the comms settings being turned off at instance level sometimes
  const commsOptions: { label: string; value: 'sms' | 'email' | 'call'; disabled?: boolean }[] = [
    { label: t('commsType.sms'), value: 'sms', disabled: !isLiveRun || !isEnabledSms },
    { label: t('commsType.email'), value: 'email' },
    { label: t('commsType.call'), value: 'call', disabled: !isLiveRun || !isEnabledCall }
  ]

  useUpdateEffect(() => resetField('message'), [commsType])

  return (
    <Box>
      <Controller
        name="recipients"
        control={control}
        render={({ field: { onChange }, formState: { errors } }) => (
          <RecipientMultiselect
            initialSelected={[]}
            accountId={accountId}
            runbookId={runbookId}
            runbookVersionId={runbookVersionId}
            label={t('recipients')}
            hasError={!!errors.recipients}
            onChange={onChange}
            required
          />
        )}
      />

      <RadioboxGroupField<AdHocCommsFormType>
        name="commsType"
        label={isLiveRun ? t('commsType.label') : t('commsType.labelPlanning')}
        direction="row"
        options={commsOptions}
      />
      {commsType === 'email' ? (
        <TextEditorField<AdHocCommsFormType> name="message" label={t('message')} />
      ) : commsType === 'sms' ? (
        <TextAreaField<AdHocCommsFormType> name="message" label={t('message')} />
      ) : null}
    </Box>
  )
}
