import { Box, CompletionMetricTile, Text } from '@cutover/react-ui'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { TaskWastageStatisticsComponent } from 'main/components/dashboards/widgets/types'

export type TaskWastageStatisticsWidgetProps = {
  data: TaskWastageStatisticsComponent
}
export const TaskWastageStatisticsWidget = ({ data }: TaskWastageStatisticsWidgetProps) => {
  const order = ['total', 'average', 'percent']

  // Transform and reorder data
  const transformedValues = order
    .map(id => {
      const value = data.values.find(item => item.id === id)
      if (!value) return null

      return {
        ...value,
        customText: `${value.type === 'percent' ? `${value.value}%` : formatDuration(value.value)}`
      }
    })
    .filter(Boolean)

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {transformedValues.length === 0 ? (
        <Text color="text-light">No Data Available</Text>
      ) : (
        <Box justify="between" direction="row" align="stretch" width="100%">
          {transformedValues.map(
            value =>
              value && (
                <Box key={value.id} width="32.5%" flex={{ grow: 0, shrink: 1 }}>
                  <CompletionMetricTile
                    label={value.name}
                    customText={{ value: value.customText, type: data.is_late ? 'late' : 'early' }}
                  />
                </Box>
              )
          )}
        </Box>
      )}
    </MrdDashboardWidget>
  )
}

const formatDuration = (value: number) => {
  if (value < 60) {
    return `${value}s`
  }

  const minutes = Math.round(value / 60)

  return `${minutes}m`
}
