import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, CodeEditor, Message, Text } from '@cutover/react-ui'
import { DataSourceEditFormType } from './data-source-edit'
import { SelectField, TextInputField } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import { DataSource } from 'main/services/queries/types/data-sources'

type DataSourceEditType = {
  dataSource: DataSource
  apiError?: string
}

export const DataSourcesEditForm = ({ dataSource, apiError }: DataSourceEditType) => {
  const { t } = useLanguage('dataSources')
  const methods = useForm<DataSourceEditFormType>()

  const primaryKeyOption = useMemo(() => {
    return dataSource.json_mappings
      ? dataSource.json_mappings.map((key: any) => ({ label: key.mapping, value: key.mapping }))
      : []
  }, [dataSource])

  return (
    <>
      {apiError && (
        <Box margin={{ bottom: '16px' }}>
          <Message message={apiError} type="error" />
        </Box>
      )}
      <Box>
        <TextInputField<DataSourceEditFormType> name="name" label={t('dataSources.formData.name.label')} required />
        <Box
          align="center"
          direction="row"
          gap="8px"
          css={`
            > div {
              flex: 1;
            }
          `}
        >
          <TextInputField<DataSourceEditFormType> label={t('dataSources.formData.url.label')} name="url" required />
          <SelectField<DataSourceEditFormType>
            required
            name="http_method"
            label={t('dataSources.formData.httpMethod.label')}
            options={[
              { label: 'GET', value: 'get' },
              { label: 'POST', value: 'post' }
            ]}
            icon={'streams'}
          />
        </Box>
        <TextInputField<DataSourceEditFormType>
          name="search_parameter_name"
          label={t('dataSources.formData.searchParameterName.label')}
        />
        <Text color="text-light" size="13px" weight={400}>
          {t('dataSources.formData.httpHeaders.label')}
        </Text>
        <Controller
          control={methods.control}
          name="http_headers"
          render={() => {
            return (
              <CodeEditor
                readOnly={true}
                value={
                  typeof dataSource.http_headers === 'string'
                    ? dataSource.http_headers
                    : JSON.stringify(dataSource.http_headers, null, 2)
                }
                defaultLanguage="json"
                resize="horizontal"
              />
            )
          }}
        />
        <SelectField<DataSourceEditFormType>
          name="json_mappings"
          options={primaryKeyOption}
          label={t('dataSources.formData.jsonMapping.label')}
          icon={'streams'}
          required
        />
        <SelectField<DataSourceEditFormType>
          required
          name="persist_mode"
          label={t('dataSources.formData.persistMode.label')}
          options={[
            { label: 'Query cache', value: 'query_cache' },
            { label: 'Persist all', value: 'persist_all' }
          ]}
          helpText={t('dataSources.formData.persistMode.helpText')}
        />
      </Box>
    </>
  )
}
