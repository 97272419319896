import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { get } from 'lodash'

import { Select, SelectProps } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { PermittedProjectOption, useFolderOptions } from './use-folder-options'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { FolderListFolder } from 'main/services/queries/types'

type FolderSelectProps = Omit<SelectProps<any>, 'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'>

export type FolderFieldProps<TFieldValues extends FieldValues> = Omit<
  FieldProps<FolderSelectProps, TFieldValues> & {
    folders: PermittedProject[] | FolderListFolder[]
    currentFolder?: Partial<PermittedProjectOption>
    initialValue?: number
  },
  'defaultValue'
>

export const FolderSelectField = <TFieldValues extends FieldValues>({
  folders,
  currentFolder,
  ...props
}: FolderFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const flattenedProjectHierarchy = useFolderOptions({ folders, currentFolder })

  return (
    <Controller
      name={props.name}
      control={formContext.control}
      render={({ field: { onChange, value, onBlur, ref }, formState: { defaultValues } }) => (
        <Select<PermittedProjectOption>
          {...props}
          {...inputProps}
          labelKey="name"
          icon="folder-open"
          valueKey="id"
          optionToString={option => option.name_hierarchy ?? option.name}
          filterKeys={['name']}
          inputRef={ref}
          onBlur={onBlur}
          onChange={onChange}
          options={flattenedProjectHierarchy}
          value={value ?? get(defaultValues, props.name)}
          includeParentsOnSearch
        />
      )}
    />
  )
}
