import { atom, selector, selectorFamily } from 'recoil'

import { toSnakeCase } from '@cutover/api'
import { GlobalConfigType } from 'main/services/hooks/config-types'
import { queryClient as queryClientStatic } from 'main/query-client'

// WARNING: This is a temporary "solution" to solve for our test setup while we fully depend on both global
// react context and recoil global state for our global config.
export const testGlobalConfig = atom<GlobalConfigType>({
  key: 'config:test',
  default: {} as GlobalConfigType
})

export const globalConfig = selector({
  key: 'config:default',
  get: async ({ get }) => {
    // should never make a request here
    const cachedConfig = queryClientStatic.getQueryData<GlobalConfigType>('configs')
    const testConfig = get(testGlobalConfig)
    return cachedConfig ?? testConfig
  }
})

export const globalConfigProperty = selectorFamily<
  GlobalConfigType[keyof GlobalConfigType],
  { attribute: keyof GlobalConfigType }
>({
  key: 'config:property',
  get:
    ({ attribute }) =>
    ({ get }) => {
      return get(globalConfig)[attribute]
    }
})

// Using this to build filter labels (checking that the exist in configs)
export const convertConfigObjectKeysToSnakeCase = (originalObject: any) => {
  const snakeKeysConfig = {} as Record<string, any>
  for (const camelKey in originalObject) {
    snakeKeysConfig[toSnakeCase(camelKey)] = originalObject[camelKey]
  }
  return snakeKeysConfig
}
