import { useCallback } from 'react'

import {
  RunbookMergeResponse,
  RunbookRefreshCustomFieldResponse,
  RunbookResponse,
  RunbookRestoreResponse,
  RunbookUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel } from 'main/data-access'

export const useProcessRunbookResponse = () => {
  const processRunbookMergeResponse = ActiveRunbookModel.useOnAction('merge')
  const processRunbookUpdateResponse = ActiveRunbookModel.useOnAction('update')
  const processRestoreResponse = ActiveRunbookModel.useOnAction('restore')
  const processRunbookRefreshCustomFieldResponse = ActiveRunbookModel.useOnAction('refresh_custom_field')

  return useCallback(
    (response: RunbookResponse) => {
      switch (response?.meta.headers.request_class) {
        case 'Runbook':
          switch (response.meta.headers.request_method) {
            case 'update':
              processRunbookUpdateResponse(response as RunbookUpdateResponse)
              break
            case 'merge':
              processRunbookMergeResponse(response as RunbookMergeResponse)
              break
            case 'restore':
              processRestoreResponse(response as RunbookRestoreResponse)
              break
            case 'refresh_custom_field':
              processRunbookRefreshCustomFieldResponse(response as RunbookRefreshCustomFieldResponse)
              break
            default:
              return
          }
      }
    },
    [
      processRunbookMergeResponse,
      processRunbookUpdateResponse,
      processRestoreResponse,
      processRunbookRefreshCustomFieldResponse
    ]
  )
}
