import { Trans } from 'react-i18next'

import { Modal, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useCreateNewRunbookVersion } from 'main/services/queries/use-runbook-versions'

export const RunbookVersionRestoreConfirmModal = ({
  runbookVersionId,
  runbookId,
  onClose
}: {
  runbookId: number
  runbookVersionId: number
  onClose: () => void
}) => {
  const { t: tCommon } = useLanguage('common')
  const { t } = useLanguage('runbook', { keyPrefix: 'restoreVersionModal' })
  const { mutate: createVersion } = useCreateNewRunbookVersion(runbookId)

  const handleConfirm = async () => {
    createVersion({ base_version_id: runbookVersionId })
  }

  return (
    <Modal
      onClickConfirm={handleConfirm}
      onClose={onClose}
      open
      title={tCommon('confirmTitle')}
      confirmText={tCommon('continueButton')}
      confirmIcon="chevron-right"
    >
      <Text>
        <Trans>{t('body')}</Trans>
      </Text>
    </Modal>
  )
}
