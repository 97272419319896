// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action, computed } from 'mobx'
import { container } from 'app/Config/IOC'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { GenericPageEditPresenter } from 'Shared/Presenters/GenericPageEditPresenter'
import { CustomFieldFormPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldForm/CustomFieldFormPresenter'
import { CustomFieldsViewPresenter } from 'app/Settings/CustomFields/CustomFieldsPage/CustomFieldsViewPresenter'
import { endpointFieldType } from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import { ToasterService } from 'Shared/Components/Molecules'
import { ModalPresenter } from 'Components/Atoms'

@injectable()
export class CustomFieldEditPresenter extends GenericPageEditPresenter {
  protected viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)
  private setEditState: (state: Submitted) => void
  public title: string = this.language.get('customFields:edit:title')
  public archiveTooltip: string = this.language.get('customFields:edit:archiveTooltip')
  public refreshTooltip: string = this.language.get('customFields:edit:refreshTooltip')
  protected toasterService: ToasterService = container.get(ToasterService)

  @observable
  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  @observable
  public formPresenter: CustomFieldFormPresenter = null

  @action
  public withFormPresenter = (formPresenter: CustomFieldFormPresenter) => {
    this.formPresenter = formPresenter
    this.formPresenter
      .withDirtyCallback(() => {
        this.setEditPanelDirty()
      })
      .withResetCallback(() => {
        this.setEditPanelClean()
      })
    return this
  }

  public withEditSetters = (setEditState: (submitted: Submitted) => void, postSuccessCallback: () => void) => {
    this.setEditState = setEditState
    this.withEditPostSuccessCallback(postSuccessCallback)
    return this
  }

  public saveConfirm = () => {
    // No need for confirmation dialog if the field isn't currently in use
    const editState = this.viewPresenter.editRecordVM.used ? Submitted.Submitted : Submitted.SubmittedAndConfirmed
    this.setEditState(editState)
  }

  public archiveConfirm = () => {
    // Always give a confirm dialog
    this.setArchiveState(Submitted.Submitted)
    this.viewPresenter.pageVM.archiveModalOpenState = true
  }

  public refresh = async () => {
    const response = await this.viewPresenter.refreshCache()

    if (response.success) {
      this.toasterService.pop(
        this.language.get('common:notification:successTitle'),
        this.language.get('customFields:edit:notification:refreshSuccess'),
        'success'
      )
    } else {
      this.toasterService.pop(
        this.language.get('common:notification:errorTitle'),
        this.language.get('customFields:edit:notification:refreshError') + ' ' + response.errorMessages[0],
        'error'
      )
    }
  }

  @computed
  public get showRefresh(): boolean {
    if (!this.viewPresenter.editRecordVM) return false
    return this.viewPresenter.editRecordVM.fieldType === endpointFieldType
  }

  protected submitResetCallback = () => {
    this.setEditState(Submitted.Unsubmitted)
  }
}
