import { ReactNode } from 'react'
import { AccordionPanelExtendedProps, AccordionPanel as GrommetAccordionPanel, Heading, HeadingProps } from 'grommet'
import styled, { css } from 'styled-components/macro'

import { Box, ColorProp, Icon, IconName, resolveColor, themeColor } from '@cutover/react-ui'

// IMPORTANT: This is a one-off accordion that uses Grommet accordion directly, not the react-ui Accordion components.
// This should be addressed, but at the time of this note, there is no resolution for supporting the needs of this component
// and what the react-ui Accordion components provide.
type IntegrationAccordionPanelProps = Omit<AccordionPanelExtendedProps, 'header' | 'label' | 'prefix'> & {
  active?: boolean
  avatar?: ReactNode
  error?: boolean // TODO: visual error state
  /** the icon to be rendered to the left of the label text */
  icon?: IconName | ReactNode
  /** defaults to iconColor passed into the parent Accordion */
  iconColor?: string // may come from the backend
  label: string
  secondaryLabel?: string
  truncateHeading?: boolean
  headingLevel?: number
  /** further components to be rendered next to the label */
  labelSuffix?: ReactNode
  /** further components to be rendered on the right hand side of the header */
  suffix?: ReactNode
  disabled?: boolean
  toggledOn?: boolean
}

// FIXME: this uses the grommet accordion panel directly becuase our react-ui accordion does not support
// custom heading content. Either need to adjust the react-ui component or change this UI.
export const IntegrationAccordionPanel = ({
  active,
  toggledOn,
  icon,
  iconColor,
  label,
  secondaryLabel,
  truncateHeading = true,
  headingLevel = 4,
  labelSuffix,
  suffix,
  children,
  disabled,
  ...restProps
}: IntegrationAccordionPanelProps) => {
  const panelIcon =
    typeof icon === 'string' ? (
      <Icon
        css={`
          ${disabled && 'opacity: 0.5'}
        `}
        icon={icon as IconName}
        color={iconColor as ColorProp}
        size="medium"
      />
    ) : (
      <Box
        css={`
          min-width: auto;
          ${disabled ? 'opacity: 0.5' : ''}
        `}
      >
        {icon}
      </Box>
    )

  return (
    <>
      {/* @ts-ignore */}
      <StyledGrommetAccordionPanel
        {...restProps}
        disabled={disabled}
        active={!toggledOn ? false : active}
        className="accordion-item-panel"
        a11yTitle={label}
        toggledOn={toggledOn}
        label={
          <LabelContainer active={toggledOn} direction="row" align="center" justify="between" width="100%">
            <Box direction="row" gap="small" align="center" pad={{ left: 'xsmall' }}>
              {icon && panelIcon}
              <Box margin={{ vertical: 'medium' }}>
                <AccordionHeading
                  disabled={disabled}
                  truncate={truncateHeading}
                  active={active}
                  headingLevel={headingLevel}
                >
                  {label}
                </AccordionHeading>
                {secondaryLabel && (
                  <AccordionHeading
                    disabled={disabled}
                    truncate={truncateHeading}
                    active={toggledOn}
                    color="text-light"
                    headingLevel={headingLevel + 1}
                  >
                    {secondaryLabel}
                  </AccordionHeading>
                )}
              </Box>
              {labelSuffix}
            </Box>
            {suffix && <SuffixContainer>{suffix}</SuffixContainer>}
          </LabelContainer>
        }
      >
        <Box
          pad={{
            right: '4px',
            bottom: '16px',
            left: '4px'
          }}
        >
          {children}
        </Box>
      </StyledGrommetAccordionPanel>
    </>
  )
}

const StyledIcon = styled(Icon)``

const LabelContainer = styled(Box)<{ disabled?: boolean; active?: boolean }>`
  cursor: ${({ disabled, active }) => (disabled || !active ? 'default' : 'pointer')};
`

export const ExpandIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-right',
  size: 'medium',
  color: 'text-disabled'
}))``

export const CollapseIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-down',
  size: 'medium',
  color: 'text-light'
}))``

const SuffixContainer = styled(props => <Box {...props} />)`
  fill: ${({ theme }) => resolveColor('text-light', theme)};
  color: ${({ theme }) => resolveColor('text-light', theme)};
  transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  flex-shrink: 0;
`

const StyledHeading = styled(Heading)``

type AccordionHeadingProps = HeadingProps & {
  active?: boolean
  disabled?: boolean
  toggledOn?: boolean
}

const StyledGrommetAccordionPanel = styled(GrommetAccordionPanel)<AccordionHeadingProps>`
  ${StyledIcon},
  ${StyledHeading},
  & {
    // TODO: should find a better place to define and apply this theme-wide?
    transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  }

  ${LabelContainer} {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;
      `}
  }

  ${StyledIcon} {
    // if disabled don't show icons
    ${({ disabled }) =>
      disabled &&
      css`
        fill: transparent;
      `}
  }

  ${StyledHeading} {
    color: ${themeColor('text-light')};
    button:not([aria-expanded='true']):hover & {
      color: ${themeColor('text')};
    }
    font-weight: 400;
  }

  [aria-expanded='true'] & {
    ${StyledHeading} {
      color: ${themeColor('text')};
      font-weight: 500;
    }

    ${StyledIcon} {
      transform: rotate(90deg);
      fill: ${themeColor('text-light')};
    }
  }

  ${({ toggledOn }) =>
    !toggledOn &&
    css`
      ${StyledHeading} {
        color: ${themeColor('text-light')} !important;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledHeading} {
        color: ${themeColor('text-disabled')} !important;
        cursor: default;
      }

      ${StyledIcon} {
        transform: rotate(90deg);
        fill: ${themeColor('text-light')};
        opacity: 0.5;
      }
    `}




  &:hover ${StyledHeading} {
    color: ${({ disabled, theme, active }) => !disabled && active && resolveColor('text', theme)};
  }

  &:hover ${SuffixContainer} {
    fill: ${({ theme }) => resolveColor('text', theme)};
    color: ${({ theme }) => resolveColor('text', theme)};
  }

  &:hover ${ExpandIcon} {
    fill: ${({ theme, disabled }) => !disabled && resolveColor('text', theme)};
  }
`

const AccordionHeading = styled(StyledHeading).attrs(
  ({ headingLevel }: { headingLevel: number; color?: ColorProp }) => ({
    level: headingLevel,
    responsive: false
  })
)<AccordionHeadingProps>`
  line-height: normal;
`
