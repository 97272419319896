import { Ref } from 'react'
import { selector } from 'recoil'

import { MenuListItemProps } from '@cutover/react-ui'
import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'
import { LinkTemplateData } from 'main/components/runbook/modals/runbook-modals'
import { IntegrationActionItem, TaskListTask } from 'main/services/queries/types'

export type TaskCSVImportModalType = {
  id: never
  type: 'tasks-csv-import'
  data: { status?: 'submitting' | 'success' | 'error' | 'idle'; context?: string[] }
}

type TasksCreateAsSnippetData = {
  runbookId: number
  taskInternalIds: number[]
}

type OptionalData<T> = T extends { data: never } ? Omit<T, 'data'> : T
type OptionalId<T> = T extends { id: never } ? Omit<T, 'id'> : T

export type ModalActiveTaskProgressionType =
  | { id: number; type: 'task-action'; data: never }
  | { id: number; type: 'task-override'; data: never }
  | { id: number; type: 'task-override-optional'; data: never }
  | { id: number; type: 'task-override-fixed-start'; data: never }
  | { id: number; type: 'task-finish-confirm'; data: never }
  | { id: never; type: 'task-unable-to-perform-action'; data: never }
  | { id: never; type: 'task-start-block'; data: never }
  | { id: number; type: 'task-abandon-confirm'; data: TaskAbandonConfirmData }
  | { id: number; type: 'task-oauth'; data: TaskOauthData }

export type ModalActiveTypeBase =
  | { type: 'audit-log-download'; id: never; data: never }
  | { type: 'cancel-approval'; id: never; data: never }
  | { type: 'integration-abort'; taskId: number; name: string; data: never }
  | { type: 'linked-runbook-add'; id: number; data: never }
  | { type: 'linked-template-selector'; id: never; data: LinkTemplateData }
  | { type: 'mark-as-approved'; id: never; data: never }
  | { type: 'request-approval'; id: never; data: never }
  | { type: 'request-reapproval'; id: never; data: never }
  | { type: 'run-pause'; id: never; data: never }
  | { type: 'run-resume'; id: never; data: never }
  | { type: 'run-start'; id: never; data: never }
  | { type: 'run-update'; id: never; data: never }
  | { type: 'runbook-ad-hoc-comms'; id: never; data: never }
  | { type: 'runbook-archive'; id: never; data: never }
  | { type: 'runbook-dashboard-share'; id: never; data: never }
  | { type: 'runbook-duplicate'; id: never; data: never }
  | { type: 'runbook-make-template'; id: never; data: never }
  | { type: 'runbook-merge'; id: never; data: never }
  | { type: 'runbook-restore'; id: never; data: never }
  | { type: 'runbook-save-as-saved-view'; id: never; data: never }
  | { type: 'runbook-schedule'; id: never; data: never }
  | { type: 'runbook-selected-timezone'; id: never; data: never }
  | { type: 'runbook-users-export'; id: never; data: ExportUsersData }
  | { type: 'runbook-version-restore'; id: never; data: { runbookId: number; runbookVersionId: number } }
  | { type: 'runbook-view-history'; id: never; data: never }
  | { type: 'snippet-add'; id: number; data: never }
  | { type: 'submit-review'; id: never; data: never }
  | { type: 'task-duplicate'; taskId: number; taskName: string; data: never }
  | { type: 'tasks-create-as-snippet'; id: never; data: TasksCreateAsSnippetData }
  | { type: 'tasks-delete'; id: number[]; data: never }
  | { type: 'tasks-export'; id: never; data: never }
  | { type: 'tasks-paste'; taskId?: number; taskName?: string; data: never }
  | { type: 'tasks-skip'; id: number[]; data: never }
  | { type: 'template-draft-version-delete'; id: never; data: never }
  | { type: 'version-create'; id: never; data: never }
  | { type: 'view-review'; id: never; data: never }
  | TaskCSVImportModalType
  | ModalActiveTaskProgressionType

export type ModalActiveType = OptionalId<OptionalData<ModalActiveTypeBase>>

export type TaskListMenu = {
  open: boolean
  taskId?: number
  type?: 'options' | 'predecessors' | 'successors'
  triggerRef?: Ref<HTMLElement>
  keyPrefix?: string
  isOpen?: boolean
  items: MenuListItemProps[]
  minWidth?: number
  maxWidth?: number
  maxHeight?: number
}

export type SortTasks = {
  option: 'start_latest_planned' | 'name' | 'end_display'
  direction?: 'asc' | 'desc'
}

export type IntegrationRequestType = 'refire' | 'cancel' | 'resumePolling'
export type IntegrationRequest = {
  taskId: number
  type: IntegrationRequestType
}

export type TaskAbandonConfirmData = {
  field_values?: any
  tasks: TaskListTask[]
  selected_successor_ids: number[]
}

type TaskOauthData = {
  integrationActionItem: IntegrationActionItem
}

type ExportUsersData = {
  runbookId: number
  runbookVersionId: number
  runbookName: string
}

export type RunbookViewStateType<TModalHistory extends object = {}> = {
  loadingIds: Record<number, boolean>
  selectedIds: number[]
  selectedEdges: string[]
  selectedTimezone?: string | null
  activeTimezone?: string | null
  copyIds: number[]
  highlightMode: boolean
  modal: {
    active?: ModalActiveType
    history: (ModalActiveType & { context?: TModalHistory })[]
  }
  menu: TaskListMenu
  sort: SortTasks
  newCommentsCount: number
  integrationRequest: { [x: number]: IntegrationRequestType }
  taskCreate: {
    name: string | undefined
    predecessor: undefined | number
  }
  nodeMap: {
    graphVersionFlag: number
    dataVersionFlag: number
  }
}

export const runbookViewState_INTERNAL = runbookAtom<RunbookViewStateType>({
  key: 'runbook-view-state',
  default: {
    loadingIds: {},
    selectedIds: [],
    selectedEdges: [],
    selectedTimezone: undefined,
    activeTimezone: undefined,
    copyIds: [],
    highlightMode: false,
    modal: {
      active: undefined,
      history: []
    },
    menu: {
      open: false,
      taskId: undefined,
      type: undefined,
      items: [],
      triggerRef: undefined,
      keyPrefix: undefined,
      minWidth: undefined,
      maxWidth: undefined,
      maxHeight: undefined
    },
    sort: { option: 'end_display', direction: 'asc' },
    newCommentsCount: 0,
    integrationRequest: {},
    taskCreate: {
      name: undefined,
      predecessor: undefined
    },
    nodeMap: {
      graphVersionFlag: 0,
      dataVersionFlag: 0
    }
  }
})

export const runbookViewStateSortValue_INTERNAL = selector({
  key: 'runbook-view-state-sort-value',
  get: ({ get }) => get(runbookViewState_INTERNAL).sort
})
