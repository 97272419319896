import { BarExtendedDatum } from '@nivo/bar'
import { keyBy } from 'lodash'

import { BarChart, Box, resolveColor, Text, useTheme } from '@cutover/react-ui'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DashboardComponent } from 'main/components/dashboards/widgets/types'

export type UserParticipationWidgetProps = {
  data: DashboardComponent
}

export const UserParticipationWidget = ({ data }: UserParticipationWidgetProps) => {
  return (
    <MrdDashboardWidget title={data.name || 'User Participation'}>
      {data.errors ? (
        <Text color="error" size="small">
          {data.errors}
        </Text>
      ) : data.values.length === 0 ? (
        <Text color="text-light">No Data Available</Text>
      ) : (
        <WidgetContent values={data.values} />
      )}
    </MrdDashboardWidget>
  )
}

type WidgetContentProps = {
  values: { id: string; name: string; count: number }[]
}

const WidgetContent = ({ values }: WidgetContentProps) => {
  const theme = useTheme()
  const keyedValues = keyBy(values, 'id')
  const orderedValues = [keyedValues.total, keyedValues.read, keyedValues.write]

  const barChartData = orderedValues.map(value => {
    return {
      category: {
        name: 'labels',
        value: value.name
      },
      values: [
        {
          group: 'users',
          value: value.count
        }
      ]
    }
  })

  const colors = [
    resolveColor('bar-chart-primary', theme),
    resolveColor('bar-chart-primary-light', theme),
    resolveColor('bar-chart-grey', theme)
  ]
  const getColors = (bar: BarExtendedDatum) => colors[bar.index]

  return (
    <Box>
      <BarChart
        data={barChartData}
        width={430}
        height={140}
        colors={getColors}
        labelFontSize={12}
        labelTextColor={resolveColor('text', theme)}
        padding="10px 0 10px 20px"
        showTotals
        hasBackground={false}
        hasAxes={false}
        xAxis={{
          id: 'labels'
        }}
        yAxis={{
          id: 'users'
        }}
      />
    </Box>
  )
}
