import { Abandoned } from './abandoned'
import { AddToList } from './add-to-list'
import { Add } from './add'
import { AlertDecrease } from './alert-decrease'
import { AlertIncrease } from './alert-increase'
import { Alert } from './alert'
import { AlignCenter } from './align-center'
import { AlignLeft } from './align-left'
import { AlignRight } from './align-right'
import { Ancestors } from './ancestors'
import { Apps } from './apps'
import { Archive } from './archive'
import { ArrowBack } from './arrow-back'
import { ArrowDown } from './arrow-down'
import { ArrowForward } from './arrow-forward'
import { ArrowUp } from './arrow-up'
import { Attachment } from './attachment'
import { AuditLog } from './audit-log'
import { Blockquote } from './blockquote'
import { Bold } from './bold'
import { Bolt } from './bolt'
import { BookmarkFilled } from './bookmark-filled'
import { Bookmark } from './bookmark'
import { BracketMinus } from './bracket-minus'
import { BracketPlus } from './bracket-plus'
import { Bracket } from './bracket'
import { Calendar } from './calendar'
import { Cancel } from './cancel'
import { CaretDown } from './caret-down'
import { CaretLeft } from './caret-left'
import { CaretRight } from './caret-right'
import { CaretUp } from './caret-up'
import { ChangeFreeze } from './change-freeze'
import { CheckDashed } from './check-dashed'
import { CheckSolid } from './check-solid'
import { Check } from './check'
import { CheckboxChecked } from './checkbox-checked'
import { CheckboxDeselect } from './checkbox-deselect'
import { Checkbox } from './checkbox'
import { ChevronDown } from './chevron-down'
import { ChevronLeft } from './chevron-left'
import { ChevronRight } from './chevron-right'
import { ChevronUp } from './chevron-up'
import { ClearFormat } from './clear-format'
import { Close } from './close'
import { CloudUpload } from './cloud-upload'
import { Cloud } from './cloud'
import { CodeBlock } from './code-block'
import { Code } from './code'
import { Collapse } from './collapse'
import { ColumnAddAfter } from './column-add-after'
import { ColumnAddBefore } from './column-add-before'
import { ColumnDelete } from './column-delete'
import { CommandPrompt } from './command-prompt'
import { CommentAdd } from './comment-add'
import { CommentDelete } from './comment-delete'
import { Conflicts } from './conflicts'
import { Copies } from './copies'
import { Copy } from './copy'
import { CriticalPath } from './critical-path'
import { CustomField } from './custom-field'
import { Dashboard } from './dashboard'
import { DaySummary } from './day-summary'
import { Delete } from './delete'
import { Dependencies } from './dependencies'
import { Descendants } from './descendants'
import { DislikeFilled } from './dislike-filled'
import { Dislike } from './dislike'
import { DndForwardslash } from './dnd-forwardslash'
import { Download } from './download'
import { Drag } from './drag'
import { Duplicate } from './duplicate'
import { EditApps } from './edit-apps'
import { Edit } from './edit'
import { Email } from './email'
import { Emoji } from './emoji'
import { Expand } from './expand'
import { Export } from './export'
import { FieldGroup } from './field-group'
import { Filter } from './filter'
import { FixedStart } from './fixed-start'
import { FolderOpen } from './folder-open'
import { Folder } from './folder'
import { FormatColorFill } from './format-color-fill'
import { Globe } from './globe'
import { GraphBar } from './graph-bar'
import { Grid } from './grid'
import { GroupX } from './group-x'
import { GroupY } from './group-y'
import { Heading } from './heading'
import { HealthCheck } from './health-check'
import { Heartbeat } from './heartbeat'
import { HelpCircle } from './help-circle'
import { Help } from './help'
import { HighlightMode } from './highlight-mode'
import { History } from './history'
import { Home } from './home'
import { Image } from './image'
import { Import } from './import'
import { Info } from './info'
import { Integrations } from './integrations'
import { Italic } from './italic'
import { Key } from './key'
import { Layout } from './layout'
import { Lightbulb } from './lightbulb'
import { LikeFilled } from './like-filled'
import { Like } from './like'
import { Link } from './link'
import { ListBulleted } from './list-bulleted'
import { ListNumbered } from './list-numbered'
import { List } from './list'
import { Lock } from './lock'
import { Logout } from './logout'
import { Mail } from './mail'
import { MakeTemplate } from './make-template'
import { Menu } from './menu'
import { Merge } from './merge'
import { MessageBox } from './message-box'
import { MessageSms } from './message-sms'
import { Message } from './message'
import { Minimise } from './minimise'
import { MoreHorizontal } from './more-horizontal'
import { MoreVertical } from './more-vertical'
import { Move } from './move'
import { NewMessage } from './new-message'
import { Nodemap } from './nodemap'
import { Notifications } from './notifications'
import { OpenNew } from './open-new'
import { PageEmpty } from './page-empty'
import { PageGraph } from './page-graph'
import { PageText } from './page-text'
import { Paragraph } from './paragraph'
import { Paste } from './paste'
import { Pause } from './pause'
import { PersonAdd } from './person-add'
import { Phone } from './phone'
import { PinFilled } from './pin-filled'
import { Pin } from './pin'
import { PlayArrow } from './play-arrow'
import { Play } from './play'
import { Predecessors } from './predecessors'
import { Radar } from './radar'
import { RadioboxChecked } from './radiobox-checked'
import { Radiobox } from './radiobox'
import { Redo } from './redo'
import { Refresh } from './refresh'
import { Remedy } from './remedy'
import { RemoveRedundant } from './remove-redundant'
import { ReportProblem } from './report-problem'
import { Rocket } from './rocket'
import { RowAddAfter } from './row-add-after'
import { RowAddBefore } from './row-add-before'
import { RowDelete } from './row-delete'
import { RunbookCancel } from './runbook-cancel'
import { RunbookCompleted } from './runbook-completed'
import { RunbookCreated } from './runbook-created'
import { RunbookEdited } from './runbook-edited'
import { RunbookIntegrations } from './runbook-integrations'
import { RunbookPaused } from './runbook-paused'
import { Runbook } from './runbook'
import { Save } from './save'
import { ScheduledShare } from './scheduled-share'
import { Search } from './search'
import { Send } from './send'
import { Server } from './server'
import { Settings } from './settings'
import { Share } from './share'
import { ShowRedundant } from './show-redundant'
import { Skip } from './skip'
import { Skipped } from './skipped'
import { Snippet } from './snippet'
import { Spinner } from './spinner'
import { Spotlight } from './spotlight'
import { StarFilled } from './star-filled'
import { Star } from './star'
import { Statistics } from './statistics'
import { Stopwatch } from './stopwatch'
import { Streams } from './streams'
import { Strikethrough } from './strikethrough'
import { Subscript } from './subscript'
import { Successors } from './successors'
import { Superscript } from './superscript'
import { Swap } from './swap'
import { SwitchVertical } from './switch-vertical'
import { Table } from './table'
import { Tag } from './tag'
import { Target } from './target'
import { TaskAdded } from './task-added'
import { TaskDeleted } from './task-deleted'
import { TaskEdited } from './task-edited'
import { TaskFinished } from './task-finished'
import { TaskList } from './task-list'
import { TaskSkipped } from './task-skipped'
import { TaskStarted } from './task-started'
import { TaskTypes } from './task-types'
import { Team } from './team'
import { Template } from './template'
import { Time } from './time'
import { Timeline } from './timeline'
import { Timezones } from './timezones'
import { TrashO } from './trash-o'
import { Unarchive } from './unarchive'
import { Underline } from './underline'
import { Undo } from './undo'
import { Unlink } from './unlink'
import { Unlock } from './unlock'
import { Upload } from './upload'
import { UserAdd } from './user-add'
import { UserEdit } from './user-edit'
import { UserFinish } from './user-finish'
import { UserList } from './user-list'
import { UserRemove } from './user-remove'
import { UserRoles } from './user-roles'
import { UserStart } from './user-start'
import { User } from './user'
import { Users } from './users'
import { Video } from './video'
import { VisibilityHide } from './visibility-hide'
import { VisibilityShow } from './visibility-show'
import { VolumeHigh } from './volume-high'
import { VolumeLow } from './volume-low'
import { VolumeMute } from './volume-mute'
import { Webhooks } from './webhooks'
import { Workspace } from './workspace'
export {
  Abandoned,
  AddToList,
  Add,
  AlertDecrease,
  AlertIncrease,
  Alert,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Ancestors,
  Apps,
  Archive,
  ArrowBack,
  ArrowDown,
  ArrowForward,
  ArrowUp,
  Attachment,
  AuditLog,
  Blockquote,
  Bold,
  Bolt,
  BookmarkFilled,
  Bookmark,
  BracketMinus,
  BracketPlus,
  Bracket,
  Calendar,
  Cancel,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChangeFreeze,
  CheckDashed,
  CheckSolid,
  Check,
  CheckboxChecked,
  CheckboxDeselect,
  Checkbox,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ClearFormat,
  Close,
  CloudUpload,
  Cloud,
  CodeBlock,
  Code,
  Collapse,
  ColumnAddAfter,
  ColumnAddBefore,
  ColumnDelete,
  CommandPrompt,
  CommentAdd,
  CommentDelete,
  Conflicts,
  Copies,
  Copy,
  CriticalPath,
  CustomField,
  Dashboard,
  DaySummary,
  Delete,
  Dependencies,
  Descendants,
  DislikeFilled,
  Dislike,
  DndForwardslash,
  Download,
  Drag,
  Duplicate,
  EditApps,
  Edit,
  Email,
  Emoji,
  Expand,
  Export,
  FieldGroup,
  Filter,
  FixedStart,
  FolderOpen,
  Folder,
  FormatColorFill,
  Globe,
  GraphBar,
  Grid,
  GroupX,
  GroupY,
  Heading,
  HealthCheck,
  Heartbeat,
  HelpCircle,
  Help,
  HighlightMode,
  History,
  Home,
  Image,
  Import,
  Info,
  Integrations,
  Italic,
  Key,
  Layout,
  Lightbulb,
  LikeFilled,
  Like,
  Link,
  ListBulleted,
  ListNumbered,
  List,
  Lock,
  Logout,
  Mail,
  MakeTemplate,
  Menu,
  Merge,
  MessageBox,
  MessageSms,
  Message,
  Minimise,
  MoreHorizontal,
  MoreVertical,
  Move,
  NewMessage,
  Nodemap,
  Notifications,
  OpenNew,
  PageEmpty,
  PageGraph,
  PageText,
  Paragraph,
  Paste,
  Pause,
  PersonAdd,
  Phone,
  PinFilled,
  Pin,
  PlayArrow,
  Play,
  Predecessors,
  Radar,
  RadioboxChecked,
  Radiobox,
  Redo,
  Refresh,
  Remedy,
  RemoveRedundant,
  ReportProblem,
  Rocket,
  RowAddAfter,
  RowAddBefore,
  RowDelete,
  RunbookCancel,
  RunbookCompleted,
  RunbookCreated,
  RunbookEdited,
  RunbookIntegrations,
  RunbookPaused,
  Runbook,
  Save,
  ScheduledShare,
  Search,
  Send,
  Server,
  Settings,
  Share,
  ShowRedundant,
  Skip,
  Skipped,
  Snippet,
  Spinner,
  Spotlight,
  StarFilled,
  Star,
  Statistics,
  Stopwatch,
  Streams,
  Strikethrough,
  Subscript,
  Successors,
  Superscript,
  Swap,
  SwitchVertical,
  Table,
  Tag,
  Target,
  TaskAdded,
  TaskDeleted,
  TaskEdited,
  TaskFinished,
  TaskList,
  TaskSkipped,
  TaskStarted,
  TaskTypes,
  Team,
  Template,
  Time,
  Timeline,
  Timezones,
  TrashO,
  Unarchive,
  Underline,
  Undo,
  Unlink,
  Unlock,
  Upload,
  UserAdd,
  UserEdit,
  UserFinish,
  UserList,
  UserRemove,
  UserRoles,
  UserStart,
  User,
  Users,
  Video,
  VisibilityHide,
  VisibilityShow,
  VolumeHigh,
  VolumeLow,
  VolumeMute,
  Webhooks,
  Workspace
}
export const ICON_NAMES = [
  'abandoned',
  'add-to-list',
  'add',
  'alert-decrease',
  'alert-increase',
  'alert',
  'align-center',
  'align-left',
  'align-right',
  'ancestors',
  'apps',
  'archive',
  'arrow-back',
  'arrow-down',
  'arrow-forward',
  'arrow-up',
  'attachment',
  'audit-log',
  'blockquote',
  'bold',
  'bolt',
  'bookmark-filled',
  'bookmark',
  'bracket-minus',
  'bracket-plus',
  'bracket',
  'calendar',
  'cancel',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'change-freeze',
  'check-dashed',
  'check-solid',
  'check',
  'checkbox-checked',
  'checkbox-deselect',
  'checkbox',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clear-format',
  'close',
  'cloud-upload',
  'cloud',
  'code-block',
  'code',
  'collapse',
  'column-add-after',
  'column-add-before',
  'column-delete',
  'command-prompt',
  'comment-add',
  'comment-delete',
  'conflicts',
  'copies',
  'copy',
  'critical-path',
  'custom-field',
  'dashboard',
  'day-summary',
  'delete',
  'dependencies',
  'descendants',
  'dislike-filled',
  'dislike',
  'dnd-forwardslash',
  'download',
  'drag',
  'duplicate',
  'edit-apps',
  'edit',
  'email',
  'emoji',
  'expand',
  'export',
  'field-group',
  'filter',
  'fixed-start',
  'folder-open',
  'folder',
  'format-color-fill',
  'globe',
  'graph-bar',
  'grid',
  'group-x',
  'group-y',
  'heading',
  'health-check',
  'heartbeat',
  'help-circle',
  'help',
  'highlight-mode',
  'history',
  'home',
  'image',
  'import',
  'info',
  'integrations',
  'italic',
  'key',
  'layout',
  'lightbulb',
  'like-filled',
  'like',
  'link',
  'list-bulleted',
  'list-numbered',
  'list',
  'lock',
  'logout',
  'mail',
  'make-template',
  'menu',
  'merge',
  'message-box',
  'message-sms',
  'message',
  'minimise',
  'more-horizontal',
  'more-vertical',
  'move',
  'new-message',
  'nodemap',
  'notifications',
  'open-new',
  'page-empty',
  'page-graph',
  'page-text',
  'paragraph',
  'paste',
  'pause',
  'person-add',
  'phone',
  'pin-filled',
  'pin',
  'play-arrow',
  'play',
  'predecessors',
  'radar',
  'radiobox-checked',
  'radiobox',
  'redo',
  'refresh',
  'remedy',
  'remove-redundant',
  'report-problem',
  'rocket',
  'row-add-after',
  'row-add-before',
  'row-delete',
  'runbook-cancel',
  'runbook-completed',
  'runbook-created',
  'runbook-edited',
  'runbook-integrations',
  'runbook-paused',
  'runbook',
  'save',
  'scheduled-share',
  'search',
  'send',
  'server',
  'settings',
  'share',
  'show-redundant',
  'skip',
  'skipped',
  'snippet',
  'spinner',
  'spotlight',
  'star-filled',
  'star',
  'statistics',
  'stopwatch',
  'streams',
  'strikethrough',
  'subscript',
  'successors',
  'superscript',
  'swap',
  'switch-vertical',
  'table',
  'tag',
  'target',
  'task-added',
  'task-deleted',
  'task-edited',
  'task-finished',
  'task-list',
  'task-skipped',
  'task-started',
  'task-types',
  'team',
  'template',
  'time',
  'timeline',
  'timezones',
  'trash-o',
  'unarchive',
  'underline',
  'undo',
  'unlink',
  'unlock',
  'upload',
  'user-add',
  'user-edit',
  'user-finish',
  'user-list',
  'user-remove',
  'user-roles',
  'user-start',
  'user',
  'users',
  'video',
  'visibility-hide',
  'visibility-show',
  'volume-high',
  'volume-low',
  'volume-mute',
  'webhooks',
  'workspace'
] as const
type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
export type IconProps = PropsOf<typeof Abandoned>
export type IconName = typeof ICON_NAMES[number]
export const ICON_NAME_MAP: Record<IconName, typeof Abandoned> = {
  'abandoned': Abandoned,
  'add-to-list': AddToList,
  'add': Add,
  'alert-decrease': AlertDecrease,
  'alert-increase': AlertIncrease,
  'alert': Alert,
  'align-center': AlignCenter,
  'align-left': AlignLeft,
  'align-right': AlignRight,
  'ancestors': Ancestors,
  'apps': Apps,
  'archive': Archive,
  'arrow-back': ArrowBack,
  'arrow-down': ArrowDown,
  'arrow-forward': ArrowForward,
  'arrow-up': ArrowUp,
  'attachment': Attachment,
  'audit-log': AuditLog,
  'blockquote': Blockquote,
  'bold': Bold,
  'bolt': Bolt,
  'bookmark-filled': BookmarkFilled,
  'bookmark': Bookmark,
  'bracket-minus': BracketMinus,
  'bracket-plus': BracketPlus,
  'bracket': Bracket,
  'calendar': Calendar,
  'cancel': Cancel,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'change-freeze': ChangeFreeze,
  'check-dashed': CheckDashed,
  'check-solid': CheckSolid,
  'check': Check,
  'checkbox-checked': CheckboxChecked,
  'checkbox-deselect': CheckboxDeselect,
  'checkbox': Checkbox,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'clear-format': ClearFormat,
  'close': Close,
  'cloud-upload': CloudUpload,
  'cloud': Cloud,
  'code-block': CodeBlock,
  'code': Code,
  'collapse': Collapse,
  'column-add-after': ColumnAddAfter,
  'column-add-before': ColumnAddBefore,
  'column-delete': ColumnDelete,
  'command-prompt': CommandPrompt,
  'comment-add': CommentAdd,
  'comment-delete': CommentDelete,
  'conflicts': Conflicts,
  'copies': Copies,
  'copy': Copy,
  'critical-path': CriticalPath,
  'custom-field': CustomField,
  'dashboard': Dashboard,
  'day-summary': DaySummary,
  'delete': Delete,
  'dependencies': Dependencies,
  'descendants': Descendants,
  'dislike-filled': DislikeFilled,
  'dislike': Dislike,
  'dnd-forwardslash': DndForwardslash,
  'download': Download,
  'drag': Drag,
  'duplicate': Duplicate,
  'edit-apps': EditApps,
  'edit': Edit,
  'email': Email,
  'emoji': Emoji,
  'expand': Expand,
  'export': Export,
  'field-group': FieldGroup,
  'filter': Filter,
  'fixed-start': FixedStart,
  'folder-open': FolderOpen,
  'folder': Folder,
  'format-color-fill': FormatColorFill,
  'globe': Globe,
  'graph-bar': GraphBar,
  'grid': Grid,
  'group-x': GroupX,
  'group-y': GroupY,
  'heading': Heading,
  'health-check': HealthCheck,
  'heartbeat': Heartbeat,
  'help-circle': HelpCircle,
  'help': Help,
  'highlight-mode': HighlightMode,
  'history': History,
  'home': Home,
  'image': Image,
  'import': Import,
  'info': Info,
  'integrations': Integrations,
  'italic': Italic,
  'key': Key,
  'layout': Layout,
  'lightbulb': Lightbulb,
  'like-filled': LikeFilled,
  'like': Like,
  'link': Link,
  'list-bulleted': ListBulleted,
  'list-numbered': ListNumbered,
  'list': List,
  'lock': Lock,
  'logout': Logout,
  'mail': Mail,
  'make-template': MakeTemplate,
  'menu': Menu,
  'merge': Merge,
  'message-box': MessageBox,
  'message-sms': MessageSms,
  'message': Message,
  'minimise': Minimise,
  'more-horizontal': MoreHorizontal,
  'more-vertical': MoreVertical,
  'move': Move,
  'new-message': NewMessage,
  'nodemap': Nodemap,
  'notifications': Notifications,
  'open-new': OpenNew,
  'page-empty': PageEmpty,
  'page-graph': PageGraph,
  'page-text': PageText,
  'paragraph': Paragraph,
  'paste': Paste,
  'pause': Pause,
  'person-add': PersonAdd,
  'phone': Phone,
  'pin-filled': PinFilled,
  'pin': Pin,
  'play-arrow': PlayArrow,
  'play': Play,
  'predecessors': Predecessors,
  'radar': Radar,
  'radiobox-checked': RadioboxChecked,
  'radiobox': Radiobox,
  'redo': Redo,
  'refresh': Refresh,
  'remedy': Remedy,
  'remove-redundant': RemoveRedundant,
  'report-problem': ReportProblem,
  'rocket': Rocket,
  'row-add-after': RowAddAfter,
  'row-add-before': RowAddBefore,
  'row-delete': RowDelete,
  'runbook-cancel': RunbookCancel,
  'runbook-completed': RunbookCompleted,
  'runbook-created': RunbookCreated,
  'runbook-edited': RunbookEdited,
  'runbook-integrations': RunbookIntegrations,
  'runbook-paused': RunbookPaused,
  'runbook': Runbook,
  'save': Save,
  'scheduled-share': ScheduledShare,
  'search': Search,
  'send': Send,
  'server': Server,
  'settings': Settings,
  'share': Share,
  'show-redundant': ShowRedundant,
  'skip': Skip,
  'skipped': Skipped,
  'snippet': Snippet,
  'spinner': Spinner,
  'spotlight': Spotlight,
  'star-filled': StarFilled,
  'star': Star,
  'statistics': Statistics,
  'stopwatch': Stopwatch,
  'streams': Streams,
  'strikethrough': Strikethrough,
  'subscript': Subscript,
  'successors': Successors,
  'superscript': Superscript,
  'swap': Swap,
  'switch-vertical': SwitchVertical,
  'table': Table,
  'tag': Tag,
  'target': Target,
  'task-added': TaskAdded,
  'task-deleted': TaskDeleted,
  'task-edited': TaskEdited,
  'task-finished': TaskFinished,
  'task-list': TaskList,
  'task-skipped': TaskSkipped,
  'task-started': TaskStarted,
  'task-types': TaskTypes,
  'team': Team,
  'template': Template,
  'time': Time,
  'timeline': Timeline,
  'timezones': Timezones,
  'trash-o': TrashO,
  'unarchive': Unarchive,
  'underline': Underline,
  'undo': Undo,
  'unlink': Unlink,
  'unlock': Unlock,
  'upload': Upload,
  'user-add': UserAdd,
  'user-edit': UserEdit,
  'user-finish': UserFinish,
  'user-list': UserList,
  'user-remove': UserRemove,
  'user-roles': UserRoles,
  'user-start': UserStart,
  'user': User,
  'users': Users,
  'video': Video,
  'visibility-hide': VisibilityHide,
  'visibility-show': VisibilityShow,
  'volume-high': VolumeHigh,
  'volume-low': VolumeLow,
  'volume-mute': VolumeMute,
  'webhooks': Webhooks,
  'workspace': Workspace
}