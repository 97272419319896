import { Simplify } from 'type-fest'

import { RunbookVersion } from 'main/services/queries/types'
import {
  useCanActionRunbookVersion,
  useCanActionRunbookVersionCallback,
  useCanRunbookVersion,
  useGetRunbookVersion,
  useGetRunbookVersionCallback,
  useGetRunbookVersionId,
  useGetRunbookVersionIdCallback,
  useGetRunbookVersionLoadable,
  useGetRunbookVersionLoadableCallback,
  useOnActionRunbookVersion,
  useReloadRunbookVersion,
  useReloadRunbookVersionSync
} from '../hooks'
import {
  ActiveModelInterfaceBase,
  ActiveModelInterfaceWithActions as WithActions,
  ActiveModelInterfaceWithReload as WithReload
} from './types'
import { RunbookVersionPermissionsResponse } from 'main/services/queries/use-runbook-versions'

export type ActiveRunbookVersionModelType = Simplify<
  ActiveModelInterfaceBase<RunbookVersion, RunbookVersionPermissionsResponse> &
    WithActions<
      RunbookVersion,
      'RunbookVersion' | 'RunbookVersionApproval',
      | 'convert_to_template'
      | 'create'
      | 'runbook_version_import'
      | 'mark_as_approved'
      | 'request_approval'
      | 'cancel_approval'
      | 'submit_review'
      | 'runbook_merged_after_task'
      | 'update_roles'
    > &
    WithReload
>

export const ActiveRunbookVersionModel: ActiveRunbookVersionModelType = {
  useGet: useGetRunbookVersion,
  useGetCallback: useGetRunbookVersionCallback,

  useGetLoadable: useGetRunbookVersionLoadable,
  useGetLoadableCallback: useGetRunbookVersionLoadableCallback,

  useCan: useCanRunbookVersion,

  useOnAction: useOnActionRunbookVersion,

  usePermission: useCanActionRunbookVersion,
  usePermissionCallback: useCanActionRunbookVersionCallback,

  useId: useGetRunbookVersionId,
  useIdCallback: useGetRunbookVersionIdCallback,

  useReload: useReloadRunbookVersion,
  useReloadSync: useReloadRunbookVersionSync
}
