import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { apiClient, apiClientCompat } from 'main/services/api'
import { ApiError } from 'main/services/api/http-gateway-adapter'

type CsvImportArgs = {
  runbookId: number | string
  runbookVersionId: number | string
  formData: any
}

export function useImportTasksCsv() {
  return useMutation<AxiosResponse<null>, ApiError, CsvImportArgs>(
    ['import-tasks'],
    async (args: CsvImportArgs): Promise<any> => {
      const response = await importTasksCsv(args)

      return response
    }
  )
}

export const importTasksCsv = async ({ formData, runbookId, runbookVersionId }: CsvImportArgs) => {
  const { data } = await apiClient.post({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/csvimport`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return data
}

export type ApiResponse = {
  data: Blob
}

export const downloadRunbookCSVTemplate = async (): Promise<ApiResponse> => {
  const response = await apiClientCompat.get({
    url: 'runbooks/csv_import_template'
  })
  return { data: response.data } as ApiResponse
}
