import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { keyBy } from 'lodash'

import { Box, CompletionMetricTile, duration as formatDuration, Text } from '@cutover/react-ui'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { DashboardComponent } from 'main/components/dashboards/widgets/types'

export type OverallTimingsWidgetProps = {
  data: DashboardComponent
}

/**
 * Displays the Overall Timings on PIR Dashboard
 */
export const OverallTimingsWidget = ({ data }: OverallTimingsWidgetProps) => {
  const values = data.values.map(value => {
    const difference =
      value.actual && value.planned
        ? value.id === 'duration'
          ? value.actual - value.planned
          : (new Date(value.actual).getTime() - new Date(value.planned).getTime()) / 1000
        : undefined

    return {
      id: value.id,
      actual: value.actual,
      diff: difference
        ? {
            value: formatDuration(difference),
            type: (difference > 0 ? 'late' : 'early') as 'late' | 'early'
          }
        : undefined
    }
  })

  const { start, end, duration } = keyBy(values, 'id')

  return (
    <MrdDashboardWidget title={data.name || 'Overall Timings'} fullWidth>
      {data.values.length === 0 ? (
        <Text color="text-light">No Data Available</Text>
      ) : (
        <WidgetContent timezone={data.timezone} start={start} end={end} duration={duration} />
      )}
    </MrdDashboardWidget>
  )
}

type CompletionMetricData = {
  id: 'start' | 'end' | 'duration'
  actual?: number | string
  diff?: {
    value: string
    type: 'late' | 'early'
  }
}

type WidgetContentProps = {
  start: CompletionMetricData
  end: CompletionMetricData
  duration: CompletionMetricData
  timezone: string
}

const WidgetContent = ({ start, end, duration, timezone }: WidgetContentProps) => {
  return (
    <Box justify="between" direction="row" align="stretch" width="100%">
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile
          label="start"
          timeString={start.actual ? formatTime(start.actual as string, timezone) : undefined}
          dateString={start.actual ? formatDate(start.actual as string, timezone) : undefined}
          diff={start.diff}
          dataTestId="overall-timings-start"
        />
      </Box>
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile
          label="finish"
          timeString={end.actual ? formatTime(end.actual as string, timezone) : undefined}
          dateString={end.actual ? formatDate(end.actual as string, timezone) : undefined}
          diff={end.diff}
          dataTestId="overall-timings-end"
        />
      </Box>
      <Box width="32.5%" flex={{ grow: 0, shrink: 1 }}>
        <CompletionMetricTile
          label="duration"
          timeString={duration.actual ? formatDuration(duration.actual as number) : undefined}
          diff={duration.diff}
          dataTestId="overall-timings-duration"
        />
      </Box>
    </Box>
  )
}

const formatTime = (utcDate: string, timezone: string): string => {
  const dateInZone = utcToZonedTime(utcDate, timezone)
  return format(dateInZone, 'HH:mm')
}

const formatDate = (utcDate: string, timezone: string): string => {
  const dateInZone = utcToZonedTime(utcDate, timezone)
  return format(dateInZone, 'd LLL yyyy')
}
