import { useMutation, useQuery } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import { QueryKeys } from './query-keys'
import { ChangeRequest, ChangeRequestCreatePayload, ChangeRequestsBroadcastResponse } from './types'

export function useGetRunbookChangeRequests(runbookId: string) {
  return useQuery([QueryKeys.ChangeRequests], async () => {
    const { data } = await apiClient.get<ChangeRequestsBroadcastResponse>({
      url: `runbooks/${runbookId}/change_requests`
    })

    return data.change_requests
  })
}

export function useChangeRequestCreate() {
  return useMutation(async (payload: ChangeRequestCreatePayload) => {
    const { data } = await apiClient.post<ChangeRequestCreatePayload, { change_requests: ChangeRequest[] }>({
      url: `runbooks/${payload.runbook_id}/change_requests`,
      data: payload
    })

    return data?.change_requests as ChangeRequest[]
  })
}
