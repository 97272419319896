import { DashboardComponentProps, MrdDonutData } from '../../types'
import { BrowserWidget, EmailWidget, PirWidget } from '../dashboard-donut-widget'

type LatenessWidgetProps = DashboardComponentProps<MrdDonutData> & { isPir?: boolean }

export const LatenessWidget = (props: LatenessWidgetProps) => {
  const { media, isPir } = props

  // NOTE: display non interactive widget for PIR
  const mediaType = isPir ? 'pir' : media

  switch (mediaType) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <EmailWidget {...props} />
    case 'pir':
      return <PirWidget {...props} />
  }
}
