import { useContext } from 'react'

import { AccountDataContext } from './account-data-provider'

// need to expose the state of the request for the runbook page loading
export const useAccountDataState = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  return accountData
}

export const useAccountCustomFields = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { customFields, customFieldsLookup, isError, isLoading } = accountData

  return { customFields, customFieldsLookup, isError, isLoading }
}

export const useAccountRunbookTypes = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { runbookTypes, activeRunbookTypes, disabledRunbookTypes, runbookTypeLookup, isError, isLoading } = accountData

  return { runbookTypes, activeRunbookTypes, disabledRunbookTypes, runbookTypeLookup, isError, isLoading }
}

export const useAccountTaskTypes = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { taskTypes, taskTypeLookup, isError, isLoading } = accountData

  return { taskTypes, taskTypeLookup, isError, isLoading }
}

export const useAccount = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { account, isError, isLoading } = accountData

  return { account, isError, isLoading }
}

export const useAccountDashboards = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { dashboards, dashboardLookup, isError, isLoading } = accountData

  return { dashboards, dashboardLookup, isError, isLoading }
}

export const useAccountCustomFieldLookups = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { fieldOptionLookup, customFieldsLookup, customFieldUserLookup } = accountData

  return { fieldOptionLookup, customFieldsLookup, customFieldUserLookup }
}

export const useAccountFieldOptions = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { fieldOptions, fieldOptionLookup, isError, isLoading } = accountData

  return { fieldOptions, fieldOptionLookup, isError, isLoading }
}

export const useAccountCustomFieldUsers = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { customFieldUsers, customFieldUserLookup, isError, isLoading } = accountData

  return { customFieldUsers, customFieldUserLookup, isError, isLoading }
}

export const useAccountCustomFieldGroups = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }

  const { customFieldGroups, customFieldGroupsLookup, isError, isLoading } = accountData

  return { customFieldGroups, customFieldGroupsLookup, isError, isLoading }
}

export const useAccountViewPermissions = () => {
  const accountData = useContext(AccountDataContext)

  if (!accountData) {
    throw new Error('You are not currently nested within an AccountDataContext.')
  }
  const { viewPermissions, isError, isLoading } = accountData

  return { viewPermissions, isError, isLoading }
}
