import { useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { isEmpty, pullAll } from 'lodash'

import { Box, Message } from '@cutover/react-ui'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { FormEditPanel } from 'main/components/shared/form'
import { FieldValue, TaskListTask, TaskType } from 'main/services/queries/types'
import { bulkUpdateTasks, TaskBulkUpdatePayload } from 'main/services/queries/use-task'
import { customFieldValidation, useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { RunbookTaskBulkUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { useTaskNotifications } from 'main/recoil/data-access'
import { TasksBulkEditForm } from './tasks-bulk-edit-form'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  CustomFieldGroupModel,
  CustomFieldModel,
  CustomFieldUserModel,
  RunbookViewModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { useUneditableTaskIds } from './use-uneditable-task-ids'
import { TaskEditDeleteCfData } from '../task-edit-panel/task-edit-delete-cf-data-content'
import { TaskEditUnlinkContent } from '../task-edit-panel/task-edit-unlink-content'

export const TasksBulkEditPanel = () => {
  const [{ taskIds }, { closeRightPanel }] = useRightPanelTypeState('tasks-bulk-edit')

  return <>{taskIds && <TasksBulkEditFormPanel taskIds={taskIds} onClose={closeRightPanel} />}</>
}

export const TasksBulkEditFormPanel = ({ taskIds, onClose }: { taskIds: number[]; onClose: () => void }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'bulkEditPanel' })
  const processTasksBulkUpdateResponse = TaskModel.useOnAction('bulk_update')
  const { notifyTaskAction } = useTaskNotifications()

  const { id: runbookId, is_template: isTemplate } = ActiveRunbookModel.useGet()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const customFieldGroups = CustomFieldGroupModel.useGetAll()
  const customFieldsGroupsLookup = CustomFieldGroupModel.useGetLookup()
  const customFieldUsers = CustomFieldUserModel.useGetAll()
  const tasksRecord = TaskModel.useGetLookup()
  const taskTypesLookup = TaskTypeModel.useGetLookup()
  const taskLookup = TaskModel.useGetLookup()
  const toggleAllSelectedIds = RunbookViewModel.useAction('selectedIds:toggleAll')
  const [customFieldConstraints, setCustomFieldConstraints] = useState<Record<number, number[]>>({})

  const taskTypeIds = taskIds.map(id => tasksRecord[id]?.task_type_id)
  const hasMoreThanOneTaskTypeSelected = new Set(taskTypeIds).size > 1
  const [selectedTaskType, setSelectedTaskType] = useState<TaskType | undefined>(undefined)
  const showFixedStartField = selectedTaskType
    ? selectedTaskType.enable_start_fixed
    : taskTypeIds.every(id => taskTypesLookup[id].enable_start_fixed)
  const showFixedEndField = selectedTaskType
    ? selectedTaskType.enable_end_fixed
    : taskTypeIds.every(id => taskTypesLookup[id].enable_end_fixed)
  const linkedTasks: TaskListTask[] = taskIds
    .map(id => {
      const task = tasksRecord[id]
      const taskType = taskTypesLookup[task.task_type_id]
      return taskType.linkable && taskType.key !== 'snippet' ? task : undefined
    })
    .filter((task): task is TaskListTask => task !== undefined)
  const [unlinkedResources, setUnlinkedResources] = useState<boolean | undefined>(undefined)

  const calculateUnlinkedResources = useMemo(() => {
    return !!linkedTasks.length && !selectedTaskType?.linkable
  }, [linkedTasks, selectedTaskType])

  const calculateCfConstraints = useCallback(() => {
    if (!selectedTaskType) return

    let cfConstraints: Record<number, number[]> = {}

    for (const taskId of taskIdsToEdit) {
      const taskSnapshot = taskLookup[taskId]
      const customFieldIds = taskSnapshot.field_values.map(fv => fv.custom_field_id)
      for (const id of customFieldIds) {
        if (
          customFieldsLookup[id].constraint &&
          !customFieldsLookup[id].constraint?.task_type_id?.includes(selectedTaskType.id)
        ) {
          if (!cfConstraints[taskSnapshot.id]) {
            cfConstraints[taskSnapshot.id] = []
          }
          cfConstraints[taskSnapshot.id].push(id)
        }
      }
    }

    return cfConstraints
  }, [selectedTaskType])

  useEffect(() => {
    setUnlinkedResources(calculateUnlinkedResources)
    setCustomFieldConstraints(calculateCfConstraints() ?? {})
  }, [selectedTaskType, calculateUnlinkedResources])

  const {
    data: { customFields, groupedCustomFields },
    buildFieldValuesAttributesRequestData,
    fieldValueValidation
  } = useCustomFieldForm({
    applyToSlugs: ['task_edit'],
    customFieldsLookup,
    customFieldGroups,
    alwaysNotRequired: true,
    constraintContext: { task_type_id: taskTypeIds[0] },
    isTasksBulkEdit: true
  })

  const uneditableIds = useUneditableTaskIds(taskIds)
  const taskIdsToEdit = pullAll([...taskIds], uneditableIds)

  const errorMessage =
    taskIdsToEdit.length === 0
      ? t('error.noneEditable')
      : taskIds.length !== taskIdsToEdit.length
      ? t('error.someEditable')
      : null

  const defaultValues = {
    disable_notify: null,
    description: '',
    duration: undefined,
    end_requirements: null,
    field_values: hasMoreThanOneTaskTypeSelected
      ? undefined
      : buildDefaultFieldValues({ customFields, groupedCustomFields, fieldValues: {} }),
    level: null,
    predecessor_modify_type: 'add',
    start_requirements: null,
    task_type_id: undefined,
    user_modify_type: 'add'
  }

  const handleSubmit = useCallback(
    async (values: TaskBulkUpdatePayload) => {
      return bulkUpdateTasks({ runbookId, runbookVersionId, payload: values })
    },
    [runbookId, runbookVersionId]
  )

  const dataTransformer = ({
    field_values,
    ...rest
  }: {
    field_values: Record<number, FieldValue>
  } & TasksBulkEditFormType) => {
    return {
      changes: {
        description: rest.description ?? undefined,
        duration: rest.duration ?? undefined,
        disable_notify: rest.disable_notify === 'true',
        end_requirements: rest.end_requirements ?? undefined,
        start_requirements: rest.start_requirements ?? undefined,
        level: rest.level ?? undefined,
        predecessor_modify_type: rest.predecessor_modify_type as 'add' | 'replace' | 'remove',
        predecessor_ids: rest.predecessor_ids ?? undefined,
        users: rest.user_ids ?? undefined,
        runbook_teams: rest.runbook_team_ids ?? undefined,
        user_modify_type: rest.user_modify_type as 'add' | 'replace' | 'remove',
        end_fixed: rest.end_fixed ?? undefined,
        start_fixed: rest.start_fixed ?? undefined,
        field_values_attributes: buildFieldValuesAttributesRequestData(field_values),
        stream_id: rest.stream_id ?? undefined,
        task_type_id: rest.task_type_id ?? undefined
      },
      task_ids: taskIdsToEdit
    }
  }

  const handleSuccess = useCallback(
    (response: RunbookTaskBulkUpdateResponse) => {
      notifyTaskAction(response)
      processTasksBulkUpdateResponse(response)
      toggleAllSelectedIds()
      onClose()
    },
    [processTasksBulkUpdateResponse, notifyTaskAction]
  )

  return (
    <FormEditPanel<TasksBulkEditFormType, TaskBulkUpdatePayload>
      title={t('title', { count: taskIds.length })}
      onClose={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      schema={buildTasksBulkEditFormSchema(fieldValueValidation)}
      transformer={dataTransformer}
      defaultValues={defaultValues}
      disabled={taskIdsToEdit.length === 0}
      hasConfirmModal={!!unlinkedResources || !isEmpty(customFieldConstraints)}
      confirmModalTitle={
        !!unlinkedResources ? t('fields.linkedResource.unlinkTitle') : t('customFieldDeleteData.title')
      }
      confirmModalDescription={
        !!unlinkedResources ? (
          <TaskEditUnlinkContent
            resourceType={isTemplate ? 'template' : 'runbook'}
            linkedTasks={linkedTasks}
            displayType="task"
          />
        ) : (
          <TaskEditDeleteCfData constraints={customFieldConstraints} bulk />
        )
      }
      confirmModalButtonLabel={
        !!unlinkedResources ? t('fields.linkedResource.unlinkButton') : t('customFieldDeleteData.buttonLabel')
      }
      onCloseConfirmModal={() => {
        setUnlinkedResources(calculateUnlinkedResources)
        setCustomFieldConstraints(calculateCfConstraints() ?? {})
      }}
      onContinueConfirmModal={() => setUnlinkedResources(undefined)}
      canContinueConfirmModal={() =>
        (!unlinkedResources && !isEmpty(customFieldConstraints)) ||
        (!!unlinkedResources && isEmpty(customFieldConstraints))
      }
    >
      {errorMessage && (
        <Box margin={{ bottom: '16px' }}>
          <Message type="error" message={errorMessage} />
        </Box>
      )}
      <TasksBulkEditForm
        customFields={customFields}
        customFieldGroupsLookup={customFieldsGroupsLookup}
        groupedCustomFields={groupedCustomFields}
        customFieldUsers={customFieldUsers}
        disabled={taskIdsToEdit.length === 0}
        hasMoreThanOneTaskTypeSelected={hasMoreThanOneTaskTypeSelected}
        showFixedStartField={showFixedStartField}
        showFixedEndField={showFixedEndField}
        setTaskType={setSelectedTaskType}
      />
    </FormEditPanel>
  )
}

const buildTasksBulkEditFormSchema = (fieldValueValidation: ReturnType<typeof customFieldValidation>) => {
  return yup.object({
    disable_notify: yup.string().oneOf(['true', 'false']).nullable(),
    description: yup.string().nullable(),
    duration: yup.number().nullable(),
    end_fixed: yup.date().nullable(),
    end_requirements: yup.string().oneOf(['any_can_end', 'all_must_end', 'same_must_end']).nullable(),
    field_values: fieldValueValidation,
    level: yup.string().oneOf(['level_1', 'level_2', 'level_3']).nullable(),
    predecessor_ids: yup.array().of(yup.number().required()).notRequired(),
    predecessor_modify_type: yup.string().oneOf(['add', 'replace', 'remove']),
    runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    start_fixed: yup.date().nullable(),
    start_requirements: yup.string().oneOf(['any_can_start', 'all_must_start']).nullable(),
    stream_id: yup.number().nullable(),
    task_type_id: yup.number().nullable(),
    user_ids: yup.array().of(yup.number().required()).notRequired(),
    user_modify_type: yup.string().oneOf(['add', 'replace', 'remove'])
  })
}

export type TasksBulkEditFormType = yup.InferType<ReturnType<typeof buildTasksBulkEditFormSchema>>
