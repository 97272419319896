import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isArray, isObject } from 'lodash'

import { LoadingPanel, NoResourceFound, Pill, Table } from '@cutover/react-ui'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useDataSourceValuesQuery } from 'main/services/queries/use-data-source-values'
import { useAccount, useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { useSetDefaultDatasource } from './use-default-workspace-datasource'
import { useFilters } from 'main/components/shared/filter/filter-provider'
import { CustomField, DataSourceValues } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing'
import { useLanguage } from 'main/services/hooks'

export const WorkspaceDataSourceDetails = () => {
  const navigate = useNavigate()
  const { toWorkspace } = useRouting()

  const { account } = useAccount()
  const accountId = account?.id

  const { customFieldId } = useParams()
  const { isLoading, customFieldsLookup } = useAccountCustomFields()

  if (isLoading) {
    return <LoadingPanel />
  }

  if (accountId && customFieldId && customFieldsLookup) {
    const customField = customFieldsLookup[parseInt(customFieldId)]

    if (customField && isCustomFieldApplicable(customField, accountId)) {
      return (
        <WorkspaceDataSourceDetailsInner
          accountId={accountId}
          customField={customField}
          customFieldsLookup={customFieldsLookup}
        />
      )
    } else {
      navigate(toWorkspace({ accountSlug: account.slug }))
      return null
    }
  }

  return null
}

const WorkspaceDataSourceDetailsInner = ({
  accountId,
  customField,
  customFieldsLookup
}: {
  accountId: number
  customField: CustomField
  customFieldsLookup: any
}) => {
  const { openRightPanel } = useSetActiveRightPanelState()
  const { filters } = useFilters()
  const { t } = useLanguage('dataSources')

  useSetDefaultDatasource({ accountId, customFieldId: customField.id })

  const { data, isLoading } = useDataSourceValuesQuery({
    cf_id: customField.id,
    account_id: accountId
  })

  const tableColumns = useMemo(() => {
    const dependentCustomFields = Object.values(customFieldsLookup).filter(
      cf => (cf as CustomField).source_custom_field_id === customField.id
    )

    return [
      {
        header: t('dataSources.table.columnHeaders.id'),
        property: 'key',
        render: (datum: DataSourceValuesRow) => <strong>{datum.key}</strong>
      },
      {
        header: t('dataSources.table.columnHeaders.templates'),
        property: 'templates',
        render: (datum: DataSourceValuesRow) =>
          datum.templates > 0 ? (
            <Pill
              color="primary"
              label={
                datum.templates > 1
                  ? t('dataSources.multipleTemplates', { count: datum.templates })
                  : t('dataSources.singleTemplate')
              }
            />
          ) : (
            'None'
          )
      },
      ...((dependentCustomFields as CustomField[])?.map(dependentCustomField => ({
        header: dependentCustomField.display_name || '',
        property: dependentCustomField.value_key || '',
        render: (datum: any) => {
          const value = dependentCustomField.value_key ? datum[dependentCustomField.value_key] : undefined

          if (isArray(value)) {
            return (
              <ul>
                {value.map(i => (
                  <li key={i}>{i}</li>
                ))}
              </ul>
            )
          } else if (isObject(value)) {
            // Value of type object is not supported for attribute
            return ''
          } else {
            return value
          }
        }
      })) || [])
    ]
  }, [customFieldsLookup])

  const tableData = useMemo(() => buildData(data), [data])

  // Filter data, first on search then template filter
  const filteredTableData = filters.q
    ? tableData?.filter(value => value.key.toLowerCase().includes((filters.q?.toString() || '').toLocaleLowerCase()))
    : filters.t_filter
    ? filters.t_filter === 'has_templates'
      ? tableData?.filter(value => value.templates > 0)
      : tableData?.filter(value => value.templates === 0)
    : tableData

  const handleClickRow = ({ datum }: { datum: DataSourceValuesRow }) => {
    openRightPanel({
      type: 'runbook-templates-with-datasources',
      key: datum.key,
      customField: customField,
      templates: datum.templates ?? 0
    })
  }

  if (isLoading) {
    return <LoadingPanel />
  } else if (filteredTableData?.length > 0) {
    return <Table columns={tableColumns} data={filteredTableData} onClickRow={handleClickRow} />
  } else {
    return <NoResourceFound context="data source value" />
  }
}

type DataSourceValuesRow = {
  id: number
  key: string
  templates: number
}

const buildData = (data?: DataSourceValues): DataSourceValuesRow[] => {
  const templatesData = data?.data_source_values_map.templates
  return (
    data?.data_source_values.map(value => {
      return {
        id: value.id,
        key: value.key,
        templates: templatesData?.[value.id] || 0,
        ...value.values
      }
    }) || []
  )
}

// Note: might need to rename to something like isDataViewEnabled and use this as a filter function in the workspace-header too
const isCustomFieldApplicable = (customField: CustomField, selectedAccountId: number) => {
  const {
    field_type: { slug },
    global,
    account_id
  } = customField

  return (slug === 'searchable' || slug === 'multi_searchable') && (global || selectedAccountId === account_id)
}
