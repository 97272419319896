import { DashboardComponentProps, MrdDonutData } from '../../types'
import { BrowserWidget, EmailWidget } from '../dashboard-donut-widget'

type RtoStatusWidgetProps = DashboardComponentProps<MrdDonutData>

export const RtoStatusWidget = (props: RtoStatusWidgetProps) => {
  const { media } = props

  switch (media) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
    case 'print':
      return <EmailWidget {...props} />
  }
}
