import { DashboardComponent, DashboardMediaType } from '../../types'
import { ContentWidget } from '../content/content-widget'

export type RunbookSummaryWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
  readOnly: boolean
}

export const RunbookSummaryWidget = ({ data, media, readOnly }: RunbookSummaryWidgetProps) => {
  const pirCustomFields = data.custom_fields.filter(field => field.apply_to.slug === 'pir_summary')

  return (
    <>
      {pirCustomFields.map(field => {
        const dataCopy = { ...data, settings: { custom_field_id: field.id } }
        return <ContentWidget key={field.id} media={media} data={dataCopy} readOnly={readOnly} />
      })}
    </>
  )
}
