import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import {
  Box,
  Button,
  Checkbox,
  CodeEditor,
  Form,
  Grid,
  Heading,
  Select,
  Text,
  TextArea,
  TextInput,
  useNotify
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { InstanceSettingsFormType } from './instance-settings-types'
import { useUpdateInstanceSettingsMutation } from './use-instance-settings'
import { ConfigModel } from 'main/data-access'

type InstanceSettingsFormProps = {
  settings: InstanceSettingsFormType
}

export const InstanceSettingsForm = ({ settings }: InstanceSettingsFormProps) => {
  // TODO: rm reload and update cache when angular migration is complete
  const mutation = useUpdateInstanceSettingsMutation({ onSuccess: () => window.location.reload() })
  const { t } = useLanguage('instanceSettings')
  const { awsProxyUrls = [] } = ConfigModel.useGet()

  // Using the first item from the awsProxyUrls in Global config as the Default AWS Proxy URL value.
  const defaultAwsProxyUrl = awsProxyUrls[0]

  const awsProxyUrlOptions = awsProxyUrls?.map(url => {
    return { label: url, value: url }
  })

  const methods = useForm<InstanceSettingsFormType>({
    mode: 'onTouched',
    defaultValues: settings
  })

  const { formState, register, control } = methods

  const onSubmit = async (values: InstanceSettingsFormType) => {
    mutation.mutate(values)
  }

  const notify = useNotify()

  useEffect(() => {
    if (mutation.isSuccess) {
      notify.success(t('form.updated.success'))
    }
  }, [mutation.isSuccess])

  useEffect(() => {
    if (mutation.isError) {
      notify.error(t('form.updated.error'))
    }
  }, [mutation.isError])

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <StyledHeading as="h3">{t('form.headings.login')}</StyledHeading>
        <TextArea {...register('login_warning_message')} label={t('form.fields.loginWarningMessage.label')} />
        <TextInput
          {...register('user_app_token_expiry')}
          type="number"
          label={t('form.fields.userAppTokenExpiry.label')}
        />

        <StyledHeading as={'h3'}>{t('form.headings.limits')}</StyledHeading>
        <Grid>
          <TextInput {...register('max_root_tasks')} type="number" label={t('form.fields.maxRootTasks.label')} />
          <TextInput
            {...register('max_runbook_columns')}
            type="number"
            label={t('form.fields.maxRunbookColumns.label')}
          />
          <TextInput {...register('max_runbook_users')} type="number" label={t('form.fields.maxRunbookUsers.label')} />
          <TextInput {...register('max_runbook_teams')} type="number" label={t('form.fields.maxRunbookTeams.label')} />
          <TextInput {...register('max_streams')} type="number" label={t('form.fields.maxStreams.label')} />
          <TextInput {...register('max_tasks')} type="number" label={t('form.fields.maxTasks.label')} />
          <TextInput
            {...register('max_tasks_at_runbook_start')}
            type="number"
            label={t('form.fields.maxTasksAtRunbookStart.label')}
          />
          <TextInput
            {...register('max_task_successors')}
            type="number"
            label={t('form.fields.maxTaskSuccessors.label')}
          />
          <TextInput
            {...register('api_max_requests_per_minute')}
            type="number"
            label={t('form.fields.apiMaxRequestsPerMinute.label')}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.changeRequests')}</StyledHeading>
        <Grid>
          <TextInput
            {...register('change_request_refresh_interval')}
            type="number"
            label={t('form.fields.changeRequestRefreshInterval.label')}
          />
          <Box>
            <Checkbox
              {...register('change_request_interactive')}
              label={t('form.fields.changeRequestInteractive.label')}
              defaultChecked={settings.change_request_interactive}
            />
          </Box>
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.forecasting')}</StyledHeading>
        <Grid>
          <TextInput
            {...register('forecast_refresh_interval')}
            type="number"
            label={t('form.fields.forecastRefreshInterval.label')}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.datadogRUM')}</StyledHeading>
        <Grid>
          <TextInput {...register('datadog_rum.app_id')} label={t('form.fields.datadogRUMAppId.label')} />
          <TextInput {...register('datadog_rum.client_id')} label={t('form.fields.datadogRUMClientId.label')} />
          <Controller
            name="datadog_rum.datacenter"
            control={control}
            defaultValue={settings.datadog_rum?.datacenter}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                filterKeys={['label']}
                onChange={onChange}
                required
                inputRef={ref}
                label={t('form.fields.datadogRUMDatacenter.label')}
                value={value}
                options={[
                  { label: 'EU', value: 'eu' },
                  { label: 'US', value: 'us' }
                ]}
              />
            )}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.cutoverConnect')}</StyledHeading>
        <Grid>
          <TextInput
            {...register('cutover_connect.transform_gateway_url')}
            label={t('form.fields.connectGatewayURL.label')}
          />
          <TextInput
            {...register('cutover_connect.transform_gateway_dynamo_table')}
            label={t('form.fields.connectGatewayDynamo.label')}
          />
          <TextInput
            {...register('cutover_connect.transform_gateway_role_arn')}
            label={t('form.fields.connectGatewayRoleArn.label')}
          />
          <TextInput {...register('cutover_connect.core_app_role_arn')} label={t('form.fields.coreAppRoleArn.label')} />
          <TextInput
            {...register('cutover_connect.transform_gateway_external_id')}
            label={t('form.fields.connectGatewayExternalId.label')}
          />
          <TextInput
            {...register('cutover_connect.cutover_connect_limit')}
            label={t('form.fields.connectLimit.label')}
          />
          <TextInput
            {...register('cutover_connect.automation_user_email')}
            label={t('form.fields.automationUserEmail.label')}
          />
          <Controller
            name="cutover_connect.global_proxy_url"
            control={control}
            defaultValue={defaultAwsProxyUrl}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                filterKeys={['label']}
                onChange={onChange}
                required
                inputRef={ref}
                label={t('form.fields.globalProxyUrl.label')}
                value={value}
                options={awsProxyUrlOptions}
              />
            )}
          />
          <Checkbox
            {...register('cutover_connect.multi_regional_environment')}
            defaultChecked={settings.cutover_connect?.multi_regional_environment}
            label={t('form.fields.multiRegionalEnv.label')}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.heap')}</StyledHeading>
        <Grid>
          <TextInput {...register('heap_app_id')} label={t('form.fields.heapAppId.label')} />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.sentry')}</StyledHeading>
        <Grid>
          <TextInput {...register('sentry_dsn')} label={t('form.fields.sentryDsn.label')} />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.comms')}</StyledHeading>
        <Grid>
          <TextInput {...register('sms_comms_provider')} label={t('form.fields.smsCommsProvider.label')} />
          <TextInput {...register('voice_comms_provider')} label={t('form.fields.voiceCommsProvider.label')} />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.allowAttachments')}</StyledHeading>
        <Grid>
          <Checkbox
            {...register('allow_attachments')}
            defaultChecked={settings.allow_attachments}
            label={t('form.fields.allowAttachments.label')}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.taskDescriptionSharing')}</StyledHeading>
        <Grid>
          <Checkbox
            label={t('form.fields.taskDescriptionSharing.text')}
            {...register('task_description_sharing_default.enabled')}
            defaultChecked={settings.task_description_sharing_default.enabled}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.taskMarkLock')}</StyledHeading>
        <Grid>
          <Box>
            <Checkbox
              label={t('form.fields.taskMarkLockEnabled.label')}
              {...register('task_mark_lock.enabled')}
              defaultChecked={settings.task_mark_lock.enabled}
            />
          </Box>
          <TextInput
            {...register('task_mark_lock.expires_in')}
            type="number"
            label={t('form.fields.taskMarkLockExpiresIn.label')}
          />
          <TextInput
            {...register('task_mark_lock.wait')}
            type="number"
            label={t('form.fields.taskMarkLockWait.label')}
          />
          <Controller
            name="task_mark_lock.after_wait"
            control={control}
            defaultValue={settings.task_mark_lock?.after_wait}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                filterKeys={['label']}
                onChange={onChange}
                required
                inputRef={ref}
                label={t('form.fields.taskMarkLockAfterWait.label')}
                value={value}
                options={[
                  { label: 'continue', value: 'continue' },
                  { label: 'raise', value: 'raise' }
                ]}
              />
            )}
          />
        </Grid>

        <StyledHeading as={'h3'}>{t('form.headings.activityConfig')}</StyledHeading>
        <Controller
          name="custom_activity_config"
          control={control}
          defaultValue={settings.custom_activity_config}
          render={({ field: { value, onChange } }) => {
            return (
              <CodeEditor
                value={typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                onChange={newValue => {
                  if (newValue) {
                    try {
                      onChange(JSON.parse(newValue))
                    } catch {
                      onChange(newValue)
                    }
                  }
                }}
                defaultLanguage="json"
                resize="vertical"
              />
            )
          }}
        />

        <StyledHeading as={'h3'}>{t('form.headings.aiConfig')}</StyledHeading>
        <Grid>
          <TextInput type="password" {...register('ai_config.api_key')} label={t('form.fields.apiKey')} />
          <TextInput {...register('ai_config.dev_api_url')} label={t('form.fields.devApiUrl')} />
        </Grid>

        <Grid>
          <TextInput {...register('ai_config.api_gateway_url')} label={t('form.fields.aiApiGatewayUrl')} />
        </Grid>
        <Text color="text-light" size="small">
          {t('form.headings.aiPrompts')}
        </Text>
        <Controller
          name="ai_config.ai_prompts_config"
          control={control}
          defaultValue={settings.ai_config.ai_prompts_config}
          render={({ field: { value, onChange } }) => {
            return (
              <CodeEditor
                value={typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                onChange={newValue => {
                  if (newValue) {
                    try {
                      onChange(JSON.parse(newValue))
                    } catch {
                      onChange(newValue)
                    }
                  }
                }}
                defaultLanguage="json"
                resize="vertical"
              />
            )
          }}
        />

        <StyledHeading as={'h3'}>{t('form.headings.featureFlags')}</StyledHeading>
        <Grid>
          {Object.entries(settings.feature_flags)
            .sort(([, a], [, b]) => (a.name ?? '').toLowerCase().localeCompare((b.name ?? '').toLowerCase()))
            .map(([k, v]) => {
              return (
                <Controller
                  key={k}
                  name={`feature_flags.${k}.enabled`}
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      key={k}
                      label={v.name}
                      defaultChecked={settings.feature_flags[k].enabled}
                      onChange={e => onChange(e.target.checked)}
                    />
                  )}
                />
              )
            })}
        </Grid>

        <Button
          primary
          icon="save"
          label={t('forms:save')}
          onClick={methods.handleSubmit(onSubmit)}
          loading={mutation.isLoading}
          disabled={!formState.isValid}
          loadingLabel={t('forms:saving')}
        />
      </Form>
    </FormProvider>
  )
}

const StyledHeading = styled(Heading)`
  font-size: 18px;
  font-weight: 600;
`
