import { useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { FormProvider, useForm } from 'react-hook-form'

import { Box, Message, Text, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useChangeRequestLink } from 'main/services/queries/use-change-request-link'
import { ChangeRequestLinkInput } from 'main/services/queries/types'

export type LinkChangeRequestFormProps = {
  setOnClickConfirm: (onClickConfirm: (() => void) | undefined) => void
  setSubmitting: (submitting: boolean) => void
  onSuccess?: () => void
  onError?: () => void
  runbookId: string
}

export const LinkChangeRequestForm = ({
  setOnClickConfirm,
  setSubmitting,
  onSuccess,
  runbookId
}: LinkChangeRequestFormProps) => {
  const { t } = useLanguage('changeRequests', { keyPrefix: 'modal' })
  const [apiError, setApiError] = useState<string | null>(null)
  const linkMutation = useChangeRequestLink()
  const methods = useForm<ChangeRequestLinkInput>({
    mode: 'onChange',
    defaultValues: {
      change_request_ids: ''
    }
  })

  const {
    register,
    formState: { isValid },
    handleSubmit
  } = methods

  useEffect(() => {
    const onClickConfirm = (values: ChangeRequestLinkInput) => {
      setSubmitting(true)

      const data = buildLinkChangeRequestData(values)

      linkMutation.mutate(
        { runbook_id: runbookId, change_requests: data },
        {
          onSuccess: data => {
            eventManager.emit('change-request-created', { changeRequests: data })
            onSuccess?.()
          },
          onError: error => {
            setApiError(error.errors.failures ?? t('link_error'))
          },
          onSettled: () => {
            setSubmitting(false)
          }
        }
      )
    }

    setOnClickConfirm(() => handleSubmit(onClickConfirm))
  }, [])

  useEffect(() => {
    if (isValid) {
      setApiError(null)
    }
  }, [isValid])

  return (
    <Box>
      {apiError && (
        <Box margin={{ bottom: 'small' }}>
          <Message type="error" message={apiError} />
        </Box>
      )}
      <Text>{t('link_desc_1')}</Text>
      <Text as="p">{t('link_desc_2')}</Text>
      <FormProvider {...methods}>
        <form>
          {/* TODO: label content to translation files */}
          <TextInput {...register('change_request_ids', { required: true })} label={t('link_input_label')} />
        </form>
      </FormProvider>
    </Box>
  )
}

function buildLinkChangeRequestData(value: ChangeRequestLinkInput) {
  const changeRequestIds = value.change_request_ids?.split(',').map(id => id.trim()) || []
  const uniqueIds = changeRequestIds.filter((id, index, ids) => ids.indexOf(id) === index)
  return uniqueIds.map(id => ({
    change_request_type: 'change_request',
    external_id: id
  }))
}
