import * as Sentry from '@sentry/react'

export const addRunbookActionHandlerBreadcrumb = (model: string, response: any) => {
  try {
    Sentry.addBreadcrumb({
      category: `action-handler.${model}`,
      message: `${response?.meta.headers.request_class}:${response?.meta.headers.request_method}`,
      type: 'debug',
      data: createEventMetaData(response)
    })
  } catch (e) {}
}

export const addOnActionBreadcrumb = (model: string, action: string, data?: any) => {
  try {
    Sentry.addBreadcrumb({
      category: `on-action.${model}`,
      message: `Action ${model}: ${action}`,
      type: 'debug',
      data
    })
  } catch (e) {}
}

export const addReceiveWebsocketMessageBreadcrumb = (model: string, response: any) => {
  try {
    Sentry.addBreadcrumb({
      category: `websocket.${model}`,
      message: `${response?.meta.headers.request_class}:${response?.meta.headers.request_method}`,
      type: 'debug',
      data: createEventMetaData(response)
    })
  } catch (e) {}
}

const createEventMetaData: (response: any) => any = response => {
  const data: any = {
    request_hash: response?.meta?.headers?.request_hash
  }

  if (response.runbook || response.meta.runbook) {
    data.runbook_id = response.runbook.id || response.meta.runbook
  }
  if (response.runbook_version || response.version || response.meta.version_data) {
    data.runbook_version_id = response.runbook_version?.id || response.version?.id || response.meta.version_data?.id
  }
  if (response.task) data.task_id = response.task.id
  if (response.comment) data.comment_id = response.comment.id
  if (response.stream) data.stream_id = response.stream.id
  if (response.filter) data.filter_id = response.filter.id
  if (response.run) data.run_id = response.run.id
  if (response.runbook_team) data.runbook_team_id = response.runbook_team.id

  return data
}
