import { useMutation, useQuery, UseQueryOptions } from 'react-query'

import { apiClient_UNSTABLE } from '../api/api-client-unstable'
import {
  RunbookCreateResponse,
  RunbookEditResponse,
  RunbookGetResponse,
  RunbookUpdateResponse
} from '../api/data-providers/runbook-types'
import { RunbookCreateRunbook, RunbookUpdateRunbook } from 'main/services/queries/types'
import { apiClient, ApiError } from '../api'
import { QueryKeys } from './query-keys'

let controller: AbortController | undefined

export const getRunbook = async (runbookId?: number | string) => {
  if (controller) controller.abort()
  controller = new AbortController()
  const signal = controller.signal

  try {
    const { data } = await apiClient_UNSTABLE.get<RunbookGetResponse>(`runbooks/${runbookId}`, { signal })
    return data
  } catch (error: any) {
    // TODO: handle any errors
    throw error
  }
}

export const useGetRunbook = (
  { runbookId }: { runbookId?: number | string },
  options?: UseQueryOptions<RunbookGetResponse, ApiError>
) =>
  useQuery<RunbookGetResponse, ApiError>([QueryKeys.Runbooks, runbookId], async () => await getRunbook(runbookId), {
    enabled: !!runbookId,
    ...options
  })

export const getRunbookEdit = async (runbookId: number | string) => {
  const { data } = await apiClient.get<RunbookEditResponse>({
    url: `runbooks/${runbookId}/edit`
  })

  return data
}

/* --------------------------------- update --------------------------------- */

export type RunbookUpdatePayload = {
  runbook: RunbookUpdateRunbook
  timezone: string | null
  shift_time?: boolean
}

export const updateRunbook = async (runbookId: number | string, payload: RunbookUpdatePayload) => {
  const { data } = await apiClient.put<RunbookUpdatePayload, RunbookUpdateResponse>({
    url: `runbooks/${runbookId}`,
    data: payload
  })

  return data
}

/* --------------------------------- create --------------------------------- */

export type RunbookCreatePayload = {
  runbook: RunbookCreateRunbook
  base_runbook_id?: number | null
  shift_time?: boolean
  ai_config?: {
    purpose?: string
    supporting_ai_options?: string
    supporting_ai_text?: string
    supporting_ai_file?: any
  }
}

export function useRunbookCreateMutation() {
  return useMutation<RunbookCreateResponse, ApiError, RunbookCreatePayload>(
    [QueryKeys.Runbooks],
    async (payload: RunbookCreatePayload) => {
      const { data } = await apiClient.post<RunbookCreatePayload, RunbookCreateResponse>({
        url: 'runbooks',
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    }
  )
}
