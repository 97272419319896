import styled, { keyframes } from 'styled-components/macro'

import { resolveColor } from '../../theme'

export const TOOLTIP_MAX_WIDTH = 240
const ARROW_SIZE = 4

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const TooltipContainer = styled.span<{ width?: number }>`
  background: ${({ theme }) => resolveColor('tooltip-bg', theme)};
  color: ${({ theme }) => resolveColor('tooltip-text', theme)};
  border-radius: 4px;
  font-size: 15px;
  padding: 4px 8px;
  max-width: ${TOOLTIP_MAX_WIDTH}px;
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  text-align: center;
  line-height: 18px;
  animation: ${fadeIn} 250ms ease-in-out;
  z-index: 1000;
  overflow-wrap: break-word;

  .react-ui-tooltip-arrow {
    &,
    &::before {
      position: absolute;
      width: ${ARROW_SIZE * 2}px;
      height: ${ARROW_SIZE * 2}px;
      display: block;
    }

    background: transparent;

    &::before {
      background-color: ${({ theme }) => resolveColor('tooltip-bg', theme)};
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }

  &[data-popper-placement^='bottom'] .react-ui-tooltip-arrow {
    top: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='top'] .react-ui-tooltip-arrow {
    bottom: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='left'] .react-ui-tooltip-arrow {
    right: -${ARROW_SIZE}px;
  }

  &[data-popper-placement^='right'] .react-ui-tooltip-arrow {
    left: -${ARROW_SIZE}px;
  }
`
