import { useMemo } from 'react'
import { groupBy, partition } from 'lodash'

import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { FolderListFolder, RunbookBaseRunbook } from 'main/services/queries/types'

export type PermittedProjectOption = (PermittedProject | FolderListFolder) & {
  name_hierarchy?: string
  level?: number
}

// Sort the folders into their parent/child order, alphabtical by name, but flattened
// Note: pass in the current folder, since we still need to display in when user has no permission
// (meaning the given runbook.project_id would not be found in permittedProjects)
export const useFolderOptions = ({
  folders,
  currentFolder
}: {
  folders: PermittedProject[] | FolderListFolder[]
  currentFolder?: Partial<PermittedProjectOption>
}): PermittedProjectOption[] => {
  const permittedFolderIds = folders.map(f => f.id)
  const currentFolderIdAlreadyInPermitted = currentFolder?.id && permittedFolderIds.includes(currentFolder?.id)
  const currentFolderParentIdNotPresentInPermitted =
    currentFolder?.parent_id && !permittedFolderIds.includes(currentFolder?.parent_id)

  // Push a mock current folder to the hierarchy so it is available to populate the select menu
  // The only time it wont be in the permitted folders is when the current runbook's folder cannot be edited by current user
  // If a runbook's current folder is a child that the user doesn't have permission on, there could be a parent_id key
  // in groupedChildFolders that does not exist in sortedParentFolders therefore would not be returned in by this hook
  // as a workaround (without editing runbook#show api call), need to push a dummy parent folder. We do not know
  // the name of this parent, so can ignore ordering
  const mockParentFolder = currentFolderParentIdNotPresentInPermitted
    ? { id: currentFolder?.parent_id, name: 'Unpermitted parent' }
    : null // Todo: lang

  const foldersIncludingCurrent = currentFolderIdAlreadyInPermitted
    ? folders
    : [
        ...folders,
        ...(currentFolder ? [currentFolder as PermittedProjectOption] : []),
        ...(mockParentFolder ? [mockParentFolder as PermittedProjectOption] : [])
      ]

  const [parentFolders, childFolders] = partition(foldersIncludingCurrent, f => !f.parent_id)
  const sortedParentFolders = parentFolders.sort((a, b) => a.name.localeCompare(b.name))
  const groupedChildFolders = groupBy(childFolders, 'parent_id')

  return useMemo(() => {
    return sortedParentFolders.reduce((hierarchy: PermittedProjectOption[], parentFolder) => {
      hierarchy.push({ ...parentFolder, level: 1 })

      const parentName = parentFolder.name.length > 10 ? `${parentFolder.name.slice(0, 10)}...` : parentFolder.name

      // This parent folder has children
      const childrenOfThisParent = groupedChildFolders[parentFolder.id]
      if (childrenOfThisParent) {
        const childFolders = childrenOfThisParent.sort((a, b) => a.name.localeCompare(b.name))
        const folderOptions: PermittedProjectOption[] = childFolders.map(childFolder => ({
          ...childFolder,
          name_hierarchy: `${parentName} / ${childFolder.name}`,
          level: 2
        }))
        hierarchy.push(...folderOptions)
      }

      return hierarchy
    }, [])
  }, [folders])
}

type UseFolderDefaultValueProps = {
  folders: PermittedProject[] | FolderListFolder[]
  runbook?: RunbookBaseRunbook
  filter?: number[]
}

export const useFolderDefaultOption = ({ folders, runbook, filter }: UseFolderDefaultValueProps) => {
  // only PermittedProject type has disabled flag
  const flattenedEnabledFolders = useFolderOptions({ folders }).filter(f => !(f as PermittedProject).disabled)

  let defaultFolder: PermittedProject | FolderListFolder | undefined

  if (runbook) {
    defaultFolder = flattenedEnabledFolders.find(f => f.id === runbook.project_id)
  } else if (filter && filter.length) {
    defaultFolder = flattenedEnabledFolders.find(f => f.id === filter[0])
  }

  return defaultFolder ?? flattenedEnabledFolders[0]
}
