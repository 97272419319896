import { atom, AtomEffect, DefaultValue, selector } from 'recoil'

import { getRunbookId } from 'main/recoil/shared/nav-utils'
import { RunbookShowRunbook, RunbookVersion } from 'main/services/queries/types'
import { getRunbook } from 'main/services/queries/use-runbook'
import { runbookTypeState } from '../account/runbook-types'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'

// *******************************
// Runbook api response
// *******************************

const syncRunbookResponseEffect: AtomEffect<RunbookGetResponse> = ({ setSelf }) => {
  const getInitialRunbook = async () => {
    const runbookId = getRunbookId()

    if (runbookId) {
      const data = await getRunbook(runbookId)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'runbook') {
      getInitialRunbook()
    }
  }

  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated, but via the runbookState selector
export const runbookResponseState_INTERNAL = atom<RunbookGetResponse>({
  key: 'runbook:runbook-response',
  effects: [syncRunbookResponseEffect]
})

// *******************************
// Runbook
// *******************************

export const runbookState = selector<RunbookShowRunbook>({
  key: 'runbook:runbook',
  get: ({ get }) => {
    const resp = get(runbookResponseState_INTERNAL)
    return resp.runbook
  }
})

export const runbookIdState = selector({
  key: 'runbook:runbook-id',
  get: ({ get }) => {
    return get(runbookState).id
  }
})

// TODO: how to name this? it is the active runbook's type, but we don't have a
// naming convention for "active", it's just "runbook".
export const runbookRunbookTypeState = selector({
  key: 'runbook:runbook-type',
  get: ({ get }) => {
    const runbook = get(runbookState)
    return get(runbookTypeState(runbook.runbook_type_id))
  }
})

export const runbookCurrentVersionState = selector<RunbookVersion>({
  key: 'runbook:current_version',
  get: ({ get }) => {
    const runbook = get(runbookState)
    return runbook.current_version
  }
})

export const runbookPermissionsState = selector({
  key: 'runbook:permissions',
  get: ({ get }) => {
    const runbookMeta = get(runbookMetaState)
    return runbookMeta.permissions
  }
})

// *******************************
// Runbook meta
// *******************************

export const runbookMetaState = selector({
  key: 'runbook:runbook-meta',
  get: ({ get }) => {
    const resp = get(runbookResponseState_INTERNAL)
    return resp.meta
  }
})
