import { useMemo } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { produce } from 'immer'
import { extend, filter, find } from 'lodash'

import {
  defaultSavedFilterState,
  runbookResponseState_INTERNAL,
  savedFilterState,
  savedFilterStateLookup
} from 'main/recoil/runbook'
import {
  RunbookFilterCreateResponse,
  RunbookFilterDestroyResponse,
  RunbookFilterSetAsDefaultResponse,
  RunbookFilterToggleGlobalResponse,
  SavedFilter
} from 'main/services/api/data-providers/runbook-types'
import { SavedFilterModelType } from 'main/data-access/models'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { useActiveRunbookId, useActiveRunbookIdCallback } from './active-runbook'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetSavedFilter: SavedFilterModelType['useGet'] = (identifier: number) => {
  const lookup = useRecoilValue(savedFilterStateLookup)
  return lookup[identifier]
}

export const useGetSavedFilterCallback: SavedFilterModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) => {
        const lookup = await snapshot.getPromise(savedFilterStateLookup)
        return lookup[identifier]
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                     Get By                                 */
/* -------------------------------------------------------------------------- */

export const useGetBySavedFilter: SavedFilterModelType['useGetBy'] = getBy => {
  useEnsureStableArgs(getBy)

  /* eslint-disable react-hooks/rules-of-hooks */
  if (getBy.default) {
    return useRecoilValue(defaultSavedFilterState) as SavedFilter
  }
  /* eslint-enable react-hooks/rules-of-hooks */
  return {} as SavedFilter
}

export const useGetBySavedFilterCallback: SavedFilterModelType['useGetByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async getBy => {
        if (getBy.default) {
          const defaultFilter = await snapshot.getPromise(defaultSavedFilterState)
          return defaultFilter as SavedFilter
        }
        return {} as SavedFilter
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetAllSavedFilters: SavedFilterModelType['useGetAll'] = () => {
  return sortFiltersDefaultFirst(useRecoilValue(savedFilterState))
}

export const useGetAllSavedFiltersCallback: SavedFilterModelType['useGetAllCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const filters = await snapshot.getPromise(savedFilterState)
        return sortFiltersDefaultFirst(filters)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                     Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useGetSavedFilterLookup: SavedFilterModelType['useGetLookup'] = () => {
  return useRecoilValue(savedFilterStateLookup)
}

export const useGetSavedFilterLookupCallback: SavedFilterModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const savedFiltersLookup = await snapshot.getPromise(savedFilterStateLookup)
        return savedFiltersLookup
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                 Get all by                                 */
/* -------------------------------------------------------------------------- */

export const useGetAllSavedFiltersBy: SavedFilterModelType['useGetAllBy'] = getBy => {
  useEnsureStableArgs(getBy)

  const filters = useGetAllSavedFilters()
  return filter(filters, getBy) as SavedFilter[]
}

export const useGetAllSavedFiltersByCallback: SavedFilterModelType['useGetAllByCallback'] = () => {
  const getSavedFilters = useGetAllSavedFiltersCallback()

  return useRecoilCallback(
    () => async getBy => {
      const filters = await getSavedFilters()
      return filter(filters, getBy) as SavedFilter[]
    },
    [getSavedFilters]
  )
}

/* -------------------------------------------------------------------------- */
/*                                     Action                                 */
/* -------------------------------------------------------------------------- */

// @ts-ignore
export const useOnActionSavedFilter: SavedFilterModelType['useOnAction'] = action => {
  useEnsureStableArgs(action)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (action) {
    case 'create':
      return useProcessFilterCreateResponse()
    case 'destroy':
      return useProcessFilterDestroyResponse()
    case 'toggle_global':
      return useProcessFilterToggleGlobalResponse()
    case 'set_as_default':
      return useProcessFilterSetAsDefaultResponse()
    default:
      return handleNoMatchInSwitch(action, 'useOnAction')
  }
  /* eslint-enable react-hooks/rules-of-hooks */
}

/* -------------------------------- Updaters -------------------------------- */

const useProcessFilterCreateResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookFilterCreateResponse) => {
        set(runbookResponseState_INTERNAL, prevRunbookResponse =>
          produce(prevRunbookResponse, draftRunbookResponse => {
            const existingFilters = draftRunbookResponse.meta.filters
            const existingFilterIndex = existingFilters.findIndex(f => f.id === response.filter.id)

            if (existingFilterIndex !== -1) {
              extend(existingFilters[existingFilterIndex], response.filter)
            } else {
              existingFilters.push(response.filter as unknown as SavedFilter)
            }
          })
        )
      },
    []
  )

const useProcessFilterDestroyResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookFilterDestroyResponse) => {
        set(runbookResponseState_INTERNAL, prevRunbookResponse =>
          produce(prevRunbookResponse, draftRunbookResponse => {
            const existingFilters = draftRunbookResponse.meta.filters
            const existingFilterIndex = existingFilters.findIndex(f => f.id === response.filter.id)

            if (existingFilterIndex !== -1) {
              existingFilters.splice(existingFilterIndex, 1)
            }
          })
        )
      },
    []
  )

const useProcessFilterSetAsDefaultResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookFilterSetAsDefaultResponse) => {
        set(runbookResponseState_INTERNAL, prevRunbookResponse =>
          produce(prevRunbookResponse, draftRunbookResponse => {
            const existingFilters = draftRunbookResponse.meta.filters
            const existingFilterIndex = existingFilters.findIndex(f => f.id === response.filter.id)

            if (existingFilterIndex === -1) return

            const existingFilter = existingFilters[existingFilterIndex]
            if (existingFilter.default) {
              existingFilters[existingFilterIndex].default = false
            } else {
              existingFilters.forEach(f => (f.default = false))
              existingFilters[existingFilterIndex].default = true
            }
          })
        )
      },
    []
  )

const useProcessFilterToggleGlobalResponse = () =>
  useRecoilCallback(
    ({ set }) =>
      async (response: RunbookFilterToggleGlobalResponse) => {
        set(runbookResponseState_INTERNAL, prevRunbookResponse =>
          produce(prevRunbookResponse, draftRunbookResponse => {
            const existingFilters = draftRunbookResponse.meta.filters
            const existingFilterIndex = existingFilters.findIndex(f => f.id === response.filter.id)
            if (existingFilterIndex !== -1) {
              const existingFilter = existingFilters[existingFilterIndex]
              existingFilters[existingFilterIndex].global = !existingFilter.global
            }
          })
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                               get PIR Filter                               */
/* -------------------------------------------------------------------------- */

export const useGetSavedPirFilter: SavedFilterModelType['useGetSavedPirFilter'] = () => {
  const runbookId = useActiveRunbookId()
  const filters = useGetAllSavedFilters()

  return useMemo(
    () =>
      find(filters, {
        resource_id: runbookId,
        resource_type: 'Runbook',
        name: `Runbook ${runbookId} PIR`
      }),
    [runbookId, filters]
  )
}

export const useGetSavedPirFilterCallback: SavedFilterModelType['useGetSavedPirFilterCallback'] = () => {
  const getFilters = useGetAllSavedFiltersCallback()
  const getRunbookId = useActiveRunbookIdCallback()

  return useRecoilCallback(
    () => async () => {
      const rbId = await getRunbookId()
      const filters = await getFilters()
      return find(filters, {
        resource_id: rbId,
        resource_type: 'Runbook',
        name: `Runbook ${rbId} PIR`
      })
    },
    [getFilters, getRunbookId]
  )
}

/* -------------------------------- Internal -------------------------------- */

const handleNoMatchInSwitch = (type: string, fn: string): never => {
  throw new Error(`${type} in ${fn} not yet handled.`)
}

const sortFiltersDefaultFirst = (f: SavedFilter[]) =>
  produce(f, draft => draft.sort((a, b) => (a.default && !b.default ? -1 : 0)))
