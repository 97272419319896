import { useEffect } from 'react'

import { CustomField } from 'main/services/queries/types'

const DATASOURCE_LOCAL_STORAGE_KEY = 'last_viewed_workspace_datasource'

export const getDefaultDatasource = ({
  accountId,
  customFields
}: {
  accountId: number
  customFields?: CustomField[]
}) => {
  const settings = JSON.parse(localStorage.getItem(DATASOURCE_LOCAL_STORAGE_KEY) ?? '{}')

  // Return the last visited data source, or undefined if there wasn't any yet
  return settings.hasOwnProperty(accountId) ? customFields?.find(cf => cf.id === settings[accountId]) : undefined
}

export const useSetDefaultDatasource = ({ accountId, customFieldId }: { accountId: number; customFieldId: number }) => {
  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem(DATASOURCE_LOCAL_STORAGE_KEY) ?? '{}')
    // If we already have a saved customFieldId for this workspace, overwrite it
    settings[accountId] = customFieldId
    localStorage.setItem(DATASOURCE_LOCAL_STORAGE_KEY, JSON.stringify(settings))
  }, [accountId, customFieldId])
}
