import { pullAll } from 'lodash'

import { TaskModel } from 'main/data-access'

export function useModalBulkTaskErrorsAndValidIds({
  bulkAction,
  taskIds
}: {
  bulkAction: 'bulk_delete' | 'bulk_skip'
  taskIds: number[]
}) {
  const getBulkPermission = TaskModel.usePermissionCallbackSync(bulkAction)

  const tasksLookup = TaskModel.useGetLookup()
  const tasks = taskIds.map(id => tasksLookup[id]).filter(Boolean) // task can be deleted in another session or window

  const taskErrors = getBulkPermission(taskIds).errors

  const errorTaskIds = Object.keys(taskErrors).map(id => parseInt(id))
  const validTaskIds = pullAll(
    tasks.map(({ id }) => id),
    errorTaskIds
  )
  const isValidTaskIdsEmpty = validTaskIds.length === 0

  return {
    tasks,
    taskErrors,
    errorTaskIds,
    validTaskIds,
    isValidTaskIdsEmpty
  }
}
