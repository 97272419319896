import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Accordion as GrommetAccordion, ThemeContext } from 'grommet'
import styled from 'styled-components'

import { Box, Icon, Loader, Message } from '@cutover/react-ui'
import { IntegrationItem } from './integration-item'
import { RunbookIntegration, StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'

type IntegrationsListProps = {
  disabled: boolean
  runbookIntegrations: RunbookIntegration[]
  runbookId: number
  runbookVersionId: number
  disableLinks: boolean
  taskLookup: Record<number, TaskListTask>
  taskTypeLookup: Record<number, TaskType>
  streamLookup: Record<number, StreamListStream>
}
// FIXME: this uses the grommet accordion directly because our react-ui accordion does not support
// custom heading content for its panels. Either need to adjust the react-ui component or change this UI.
export const IntegrationsList = ({
  disabled: allDisabled,
  runbookIntegrations,
  runbookId,
  runbookVersionId,
  disableLinks,
  ...props
}: IntegrationsListProps) => {
  const integrationLinkForm = useForm<RunbookIntegration>({
    defaultValues: {
      id: null,
      checked: false,
      integrationActionItemId: null,
      linkableId: runbookVersionId,
      linkableType: 'RunbookVersion'
    }
  })
  const { formState } = integrationLinkForm
  const [openIndices, setOpenIndices] = useState<number[]>([])

  const availableIndices = (indices: number[]) => indices.filter(i => !!runbookIntegrations?.[i]?.id)

  return (
    <>
      {!runbookIntegrations ? (
        <Loader />
      ) : (
        <ThemeContext.Extend
          value={{
            accordion: {
              border: {
                color: 'bg-2'
              },
              hover: {
                heading: {
                  color: 'text'
                }
              },
              icons: {
                collapse: () => <CollapseIcon />,
                expand: () => <ExpandIcon />
              }
            }
          }}
        >
          <GrommetAccordion
            multiple
            onActive={indices => setOpenIndices(availableIndices(indices))}
            activeIndex={allDisabled ? [] : openIndices}
          >
            {formState?.errors?.checked?.message && (
              <Box margin={{ bottom: '16px' }}>
                <Message type="error" message={formState?.errors?.checked?.message} />
              </Box>
            )}

            {runbookIntegrations.map((runbookIntegration, i) => (
              <IntegrationItem
                key={`${runbookIntegration.integrationActionItemId}-${i}`}
                runbookIntegration={runbookIntegration}
                runbookId={runbookId}
                runbookVersionId={runbookVersionId}
                integrationLinkForm={integrationLinkForm}
                disableLink={disableLinks}
                setOpen={setOpenIndices}
                accordionIndex={i}
                active={!!runbookIntegration.id}
                {...props}
              />
            ))}
          </GrommetAccordion>
        </ThemeContext.Extend>
      )}
    </>
  )
}

const StyledIcon = styled(Icon)``

export const ExpandIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-right',
  size: 'medium',
  color: 'text-disabled',
  'aria-hidden': true
}))``

export const CollapseIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-down',
  size: 'medium',
  color: 'text-light',
  'aria-hidden': true
}))``
