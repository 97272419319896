import * as yup from 'yup'

import { CustomField, CustomFieldDisplayType, FieldValue } from 'main/services/queries/types'

export const customFieldValidation = (
  customFieldsLookup: Record<CustomField['id'], CustomField>,
  alwaysNotRequired: boolean = false
) =>
  yup.lazy((fieldValues: Record<CustomField['id'], FieldValue> = {}) => {
    const cfValidationEntries = Object.keys(fieldValues).reduce((acc, cfId) => {
      const cf = customFieldsLookup[cfId as unknown as number] as CustomField
      const cfRequired = alwaysNotRequired ? false : cf?.required ?? false

      if (cf?.archived) {
        return acc
      }
      const cfSlug =
        cf.type === 'SearchableCustomField' || cf.type === 'MultiSearchableCustomField'
          ? 'searchable'
          : cf.field_type.slug
      switch (cfSlug) {
        case 'text':
        case 'datetime':
        case 'code_editor':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required()
                })
                .nullable()
            })
          }
        // }
        case 'searchable':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .array()
                .when([], {
                  is: () => cfRequired,
                  then: schema =>
                    schema.required().test('valid-array', 'Must contain at least 1 valid item', value => {
                      if (!Array.isArray(value) || value.length === 0) {
                        return false
                      }
                      // Ensure at least one object isnt set to destroy
                      return value.some(item => item && item.destroy !== true)
                    })
                })
                .nullable()
            })
          }
        case 'textarea':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup.string().when([], {
                is: () => cfRequired,
                then: schema =>
                  schema.matches(/<[^>]+>\s*[^<\s]+.*<\/[^>]+>/, 'Must contain at least 1 non-empty element').required()
              })
            })
          }
        case 'select_menu':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              field_option_id: yup
                .number()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required()
                })
                .nullable()
            })
          }
        case 'radiobox':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              field_option_id: yup
                .number()
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required()
                })
                .nullable()
            })
          }
        case 'checkboxes':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                // .matches(/\[\]/) TODO: matches a stringified array of numbers, min length 0
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required() // TODO: matches a stringified array of numbers, min length 1
                })
                .nullable()
            })
          }
        case 'task_picker':
        case 'user_select':
          return {
            ...acc,
            [cfId]: yup.object().shape({
              value: yup
                .string()
                // .matches(/\[\]/) TODO: matches a stringified array of numbers, min length 0
                .when([], {
                  is: () => cfRequired,
                  then: schema => schema.required() // TODO: matches a stringified array of numbers, min length 1
                })
                .nullable()
            })
          }
        case 'temporary':
        case 'endpoint':
          return acc

        default:
          return unhandledCFTypeError(cf.field_type.slug)
      }
    }, {})

    return yup.object().shape(cfValidationEntries)
  })

const unhandledCFTypeError = (type: CustomFieldDisplayType): never => {
  throw new Error(
    `Custom field type ${type} not validated. If this is expected please handle it by returning the accumulator`
  )
}
