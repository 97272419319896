import { useMemo, useState } from 'react'
import * as yup from 'yup'

import { Box, IconButton, Menu, MenuListItem, useNotify } from '@cutover/react-ui'
import { DataSourcesEditForm } from './data-sources-edit-form'
import { dataSourceFormValidationSchema } from '../data-sources-form-validation-schema'
import { ArchiveDatasourceModal } from '../modals/archive-datasource-modal'
import { FormEditPanel } from 'main/components/shared/form'
import { queryClient } from 'main/query-client'
import { ApiError } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { QueryKeys } from 'main/services/queries/query-keys'
import { DataSource } from 'main/services/queries/types'
import { useUpdateDataSourceMutation } from 'main/services/queries/use-data-sources'
import { usePermissions } from 'main/services/queries/use-permissions'
import { UnableToArchiveDatasourceModal } from '../modals/unable-to-archive-datasource-modal'
import { RefreshDataSourceModal } from '../modals/refresh-data-source-modal'
import { useDataSourceValuesImportMutation } from 'main/services/queries/use-data-source-values'

type DataSourceEditPanelType = {
  dataSource: DataSource
  onClose: () => void
}

const formType = dataSourceFormValidationSchema()
export type DataSourceEditFormType = yup.InferType<typeof formType>

export const DataSourcesEdit = ({ dataSource, onClose }: DataSourceEditPanelType) => {
  const { t } = useLanguage('dataSources')
  const notify = useNotify()
  const permissions = usePermissions('data-source')
  const patchMutation = useUpdateDataSourceMutation()
  const importMutation = useDataSourceValuesImportMutation()
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isUnableToArchiveModalOpen, setIsUnableToArchiveModalOpen] = useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const [apiError, setApiError] = useState<string | undefined>(undefined)
  const canArchive = permissions('archive')
  const canEdit = permissions('update')
  const metaStoreCompliantDataSource = dataSource?.persist_mode === 'persist_all'

  const findPrimaryKey = useMemo(() => {
    let primaryKey = ''
    if (dataSource?.json_mappings) {
      dataSource.json_mappings.forEach((key: any) => {
        if (key.primary) {
          primaryKey = key.mapping
        }
      })
      return primaryKey
    }
  }, [dataSource])

  const defaultEditPanelValues = {
    json_mappings: findPrimaryKey,
    name: dataSource.name,
    url: dataSource.url,
    http_method: dataSource.http_method,
    search_parameter_name: dataSource.search_parameter_name,
    http_headers: dataSource.http_headers,
    persist_mode: dataSource.persist_mode
  }

  const handleOnSubmit = async (formData: DataSourceEditFormType) => {
    formData.id = dataSource.id
    patchMutation.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.DataSource])
        notify.success(t('dataSources.modals.updateDataSource.toasters.success'))
        onClose()
      },
      onError: error => {
        const castedError = error as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(', '))
        }
        notify.error(t('dataSources.modals.updateDataSource.toasters.genericError'))
      }
    })
  }

  const handleSubmitImportValues = () => {
    const data = { id: dataSource.id as number }
    importMutation.mutate(data, {
      onSuccess: () => {
        notify.success(t('dataSources.editPanel.main.importDataButton.successToasterText'))
      },
      onError: () => {
        notify.error('dataSources.editPanel.main.importDataButton.failToasterText')
      }
    })
  }

  const handleClickArchive = () => {
    dataSource.archivable ? setIsArchiveModalOpen(true) : setIsUnableToArchiveModalOpen(true)
  }

  return (
    <FormEditPanel<DataSourceEditFormType>
      onClose={onClose}
      onSubmit={handleOnSubmit}
      defaultValues={defaultEditPanelValues}
      readOnly={!canEdit}
      headerItems={[
        <Box align="center" direction="row" margin={{ left: 'xsmall' }}>
          <Menu
            trigger={
              <IconButton
                tertiary
                data-testid="data-source-more-options-menu"
                label="More options"
                disableTooltip
                icon="more-vertical"
              />
            }
          >
            <MenuListItem
              label={t('dataSources.editPanel.main.refreshButton.label')}
              key={t('dataSources.editPanel.main.refreshButton.label')}
              data-testid="refresh-action-option"
              icon="refresh"
              onClick={() => {
                setIsRefreshModalOpen(true)
              }}
            />
            {metaStoreCompliantDataSource && (
              <MenuListItem
                label={t('dataSources.editPanel.main.importDataButton.label')}
                key={t('dataSources.editPanel.main.importDataButton.label')}
                data-testid="refresh-action-option"
                icon="import"
                onClick={handleSubmitImportValues}
              />
            )}
            {canArchive && (
              <MenuListItem
                label={t('dataSources.editPanel.main.archiveButton.label')}
                key={t('dataSources.editPanel.main.archiveButton.label')}
                data-testid="archive-action-option"
                icon="trash-o"
                onClick={handleClickArchive}
                destructive
              />
            )}
          </Menu>
        </Box>
      ]}
      title={t('dataSources.editPanel.main.title.label')}
      schema={dataSourceFormValidationSchema()}
    >
      <DataSourcesEditForm dataSource={dataSource} apiError={apiError} />
      <ArchiveDatasourceModal
        open={isArchiveModalOpen}
        setOpen={setIsArchiveModalOpen}
        dataSourceId={dataSource.id as number}
        dataSourceName={dataSource.name}
        onSubmit={() => {
          onClose()
        }}
      />
      <UnableToArchiveDatasourceModal
        open={isUnableToArchiveModalOpen}
        setOpen={setIsUnableToArchiveModalOpen}
        dataSourceName={dataSource.name}
      />
      <RefreshDataSourceModal open={isRefreshModalOpen} setOpen={setIsRefreshModalOpen} dataSource={dataSource} />
    </FormEditPanel>
  )
}
