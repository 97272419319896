import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import {
  RunbookCommentCreateResponse,
  RunbookCommentsGetResponse,
  RunbookCommentToggleFeaturedResponse
} from '../api/data-providers/runbook-types'

type CommentCreatePayload = {
  comment: {
    content: string
    task_id?: number
    attachment?: File
  }
}

export function useRunbookCommentCreate(
  runbookId: string | number,
  runbookVersionId: number | string = 'current_version',
  taskId?: number
) {
  return useMutation(async ({ comment, file }: { comment: string; file?: File }) => {
    const payload = buildCommentPayload(comment, file, taskId)
    const contentType = file ? 'multipart/form-data' : 'application/json'

    const { data } = await apiClient.post<CommentCreatePayload | FormData, RunbookCommentCreateResponse>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments`,
      headers: {
        'Content-Type': contentType
      },
      data: payload
    })

    return data
  })
}

export const getRunbookComments = async ({
  runbookId,
  runbookVersionId
}: {
  runbookId: number
  runbookVersionId: number
}) => {
  const { data } = await apiClient.get<RunbookCommentsGetResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments?include_all=true`
  })

  return data
}

export const toggleCommentFeatured = async ({
  runbookId,
  runbookVersionId,
  commentId
}: {
  runbookId: number
  runbookVersionId: number
  commentId: number
}) => {
  const { data } = await apiClient.patch<{}, RunbookCommentToggleFeaturedResponse>({
    url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/comments/${commentId}/toggle_featured`
  })

  return data
}

function buildCommentPayload(comment: string, file?: File, taskId?: number) {
  if (file) {
    const formData = new FormData()
    !!comment ? formData.append('comment[content]', comment) : null
    formData.append('comment[attachments][]', file)
    return formData
  }

  return {
    comment: {
      content: comment,
      task_id: taskId ?? undefined
    }
  }
}
