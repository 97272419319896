import { useRecoilCallback } from 'recoil'

import { currentUserState } from 'main/recoil/current-user'
import {
  accountResponseState_INTERNAL,
  getAccountTaskType,
  ModalActiveType,
  runbookState,
  taskListTaskState,
  TaskProgressionState,
  taskProgressionState
} from 'main/recoil/runbook'
import {
  Account,
  CustomField,
  IntegrationActionItem,
  RunbookShowRunbook,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { CurrentUser } from 'main/services/queries/use-get-validate-token'

/* -------------------------------------------------------------------------- */
/*                                  onAction                                  */
/* -------------------------------------------------------------------------- */

export const useNextProgressionModal = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, { from }: { from?: ModalActiveType } = {}): Promise<ModalActiveType | undefined> => {
        const progressionState = await snapshot.getPromise(taskProgressionState(id))
        const {
          account,
          meta: { custom_fields }
        } = await snapshot.getPromise(accountResponseState_INTERNAL)
        const task = await snapshot.getPromise(taskListTaskState(id))
        const taskType = await snapshot.getPromise(getAccountTaskType(task.task_type_id))
        const currentUser = (await snapshot.getPromise(currentUserState)) as CurrentUser
        const runbook = await snapshot.getPromise(runbookState)

        return resolveNextModal({
          task,
          taskType,
          progressionState,
          accountName: account.name,
          customFields: parseCustomFieldsConstraint(custom_fields),
          currentUser,
          runbook,
          from
        })
      },
    []
  )

export const useNextProgressionModalSync = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (id: number, { from }: { from?: ModalActiveType } = {}): ModalActiveType | undefined => {
        const progressionState = snapshot.getLoadable(taskProgressionState(id)).getValue()
        const {
          account,
          meta: { custom_fields }
        } = snapshot.getLoadable(accountResponseState_INTERNAL).getValue()
        const task = snapshot.getLoadable(taskListTaskState(id)).getValue()
        const taskType = snapshot.getLoadable(getAccountTaskType(task.task_type_id)).getValue()
        const currentUser = snapshot.getLoadable(currentUserState).getValue() as CurrentUser
        const runbook = snapshot.getLoadable(runbookState).getValue()

        return resolveNextModal({
          task,
          taskType,
          progressionState,
          accountName: account.name,
          customFields: parseCustomFieldsConstraint(custom_fields),
          currentUser,
          runbook,
          from
        })
      },
    []
  )

/* -------------------------------- Internal -------------------------------- */

// CF constraints are returned as stringified JSON from the endpoint
const parseCustomFieldsConstraint = (custom_fields: CustomField[]) => {
  return custom_fields?.map(cf => ({
    ...cf,
    constraint: cf.constraint ? JSON.parse(cf.constraint) : null
  }))
}

const resolveNextModal = ({
  accountName,
  customFields,
  task,
  taskType,
  progressionState,
  from,
  currentUser,
  runbook
}: {
  task: TaskListTask
  taskType: TaskType
  progressionState?: TaskProgressionState
  accountName: Account['name']
  customFields: CustomField[]
  currentUser: CurrentUser
  runbook: RunbookShowRunbook
  from?: ModalActiveType
}): ModalActiveType | undefined => {
  if (!progressionState) return

  const integrationActionItem: IntegrationActionItem | undefined = taskType.integration_action_items[0]
  const functionalOauthUser = integrationActionItem?.functional_oauth_user?.has_session
  const isOAuthIntegration = integrationActionItem?.settings?.auth_type === 'OAuth'
  const isAuthenticated = !!currentUser.oauth_client_sessions?.find(ocs => ocs.entity_id === integrationActionItem?.id)
  if (isOAuthIntegration && !functionalOauthUser && !isAuthenticated && progressionState.stage === 'startable') {
    return { type: 'task-oauth', id: task.id, data: { integrationActionItem } }
  }

  if ((!from || !from.type || !from.type.includes('task-override')) && progressionState.override)
    return {
      type: progressionState.override.type === 'fixed-start' ? 'task-override-fixed-start' : 'task-override',
      id: task.id
    }

  if (progressionState.stage === 'startable') {
    const hasCfs = customFields.find(
      ({ archived, apply_to, account_name, constraint }) =>
        !archived &&
        apply_to.slug === 'task_start' &&
        (account_name === accountName || account_name === 'Global') &&
        (!constraint ||
          (constraint.task_type_id || []).includes(task.task_type_id) ||
          (constraint.integration_action_item_id || []).includes(integrationActionItem?.id))
    )

    const shouldShowDescription = task.has_description && runbook.settings_task_description_on_task_start

    return shouldShowDescription ||
      hasCfs ||
      taskType.key === 'snippet' ||
      (taskType.auto_finish && taskType.conditional_progression)
      ? { type: 'task-action', id: task.id }
      : undefined
  }

  if (progressionState.stage === 'finishable') {
    const hasCfs = customFields.find(
      cf =>
        !cf.archived &&
        cf.apply_to.slug === 'task_end' &&
        (cf.account_name === accountName || cf.account_name === 'Global') &&
        (!cf.constraint ||
          (cf.constraint.task_type_id || []).includes(task.task_type_id) ||
          (cf.constraint.integration_action_item_id || []).includes(integrationActionItem?.id))
    )

    if (
      !hasCfs &&
      !taskType.conditional_progression &&
      !from &&
      !currentUser?.frontend_user_setting?.data?.task_finish_confirm_hidden?.includes(runbook.id)
    )
      return { type: 'task-finish-confirm', id: task.id }

    return hasCfs || taskType.conditional_progression ? { type: 'task-action', id: task.id } : undefined
  }
}
