import { useDefaultLayout } from '@cutover/react-ui'
import { Layout } from 'main/components/layout'
import { WorkspacesHeader } from './workspaces-header'
import { WorkspaceEditPanel } from './right-panels/workspace-edit-panel'
import { useAccountsQuery } from 'main/services/queries/use-accounts'
import { WorkspacesListPage } from './workspaces-list-page'

export const WorkspacesLayout = () => {
  const { data } = useAccountsQuery()
  const canCreate = !!data?.meta?.permissions?.['create']?.[0]
  const workspaceTypes = data?.meta?.account_types

  useDefaultLayout({
    filterPanel: false,
    rightNav: false,
    subHeader: false
  })

  return (
    <Layout
      header={<WorkspacesHeader canCreate={canCreate} workspaceTypes={workspaceTypes} />}
      rightPanels={<WorkspaceEditPanel />}
    >
      <WorkspacesListPage workspaces={data?.accounts} />
    </Layout>
  )
}
