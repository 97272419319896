import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFocusVisible } from '@react-aria/interactions'
import { Button as GrommetButton } from 'grommet'
import styled, { css } from 'styled-components'
import { useMergeRefs } from 'use-callback-ref'

import { Box, Icon, SidebarIconButton, TextInput, themeColor, ThemeContext, Tooltip } from '@cutover/react-ui'
import { SectionSearchResults } from './section-search-results'
import { LogoLoader } from '../../shared/logo-loader'
import { useSidebarNavContext } from '../nav-context'
import { FloatingToggleButton } from '../shared'
import { useLoadingState } from 'main/context/loading-state-provider'
import { useLanguage } from 'main/services/hooks'
import { useRunbooks } from 'main/services/queries/use-runbooks'
import { ConfigModel } from 'main/data-access'
import { useSidebarNavigate } from '../hooks'

type NavGlobalSearchProps = {
  showResults: boolean
  toggleSearchResults: (show: boolean) => void
}

export const NavGlobalSearch = forwardRef<HTMLInputElement, NavGlobalSearchProps>(
  ({ showResults, toggleSearchResults }, ref) => {
    const { isLeftSidebarOpen, leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()
    const {
      version: { label: versionLabel }
    } = ConfigModel.useGet()
    const { t } = useLanguage('sidebarNav', { keyPrefix: 'search' })
    const { isLoading } = useLoadingState()
    const [showBackButton, setShowBackButton] = useState(false)
    const localRef = useRef<HTMLInputElement>(null)
    const inputRef = useMergeRefs([localRef, ref])
    const { isFocusVisible } = useFocusVisible({ isTextInput: true })
    const navigate = useSidebarNavigate()
    const [shouldHideResultsForFocusVisible, setShouldHideResultsForFocusVisible] = useState(
      !showResults && isFocusVisible
    )
    const [searchQuery, setSearchQuery] = useState('')
    const {
      data: searchResults
      // TODO: implement loading indicator in searchbar suffix
      // isLoading: isLoadingSearch
    } = useRunbooks(
      {
        limit: 10,
        q: searchQuery
      },
      {
        enabled: searchQuery?.length > 0 // min was 3 before but since we're only returning 10 seems like we shouldn't have a min...
      }
    )

    const handleFocus = () => {
      if (!showResults && !isFocusVisible) {
        setShowBackButton(true)
        toggleSearchResults(true)
      }

      if (!showResults) {
        setShouldHideResultsForFocusVisible(isFocusVisible)
      }
    }

    const handleClickBack = () => {
      setShowBackButton(false)
      toggleSearchResults(false)
      inputRef.current?.blur()
    }

    const handleInput = (input: string) => {
      setShowBackButton(true)
      toggleSearchResults(true)
      setShouldHideResultsForFocusVisible(false)
      setSearchQuery(input)
    }

    useEffect(() => {
      if (!isLeftSidebarOpen) {
        setShowBackButton(false)
        toggleSearchResults(false)
      }
    }, [isLeftSidebarOpen])

    useEffect(() => {
      if (shouldHideResultsForFocusVisible) {
        toggleSearchResults(false)
      }
    }, [shouldHideResultsForFocusVisible])

    const topLeftSidebarButton = useMemo(() => {
      const backButton = (
        <Box pad={{ left: '12px' }} width={{ min: 'min-content' }}>
          <SidebarIconButton label={t('tooltip.back')} icon="arrow-back" onClick={handleClickBack} />
        </Box>
      )

      const toggleMenuButton = (
        <Box pad={{ left: '12px' }} width={{ min: 'min-content' }}>
          <SidebarIconButton label={t('tooltip.toggle')} icon="menu" onClick={() => toggleLeftSidebar()} />
        </Box>
      )

      const loadingButton = (
        <Tooltip content={versionLabel}>
          <GrommetButton
            aria-label="Cutover logo - click to return home"
            css={`
              opacity: 0.7;
              &:hover {
                opacity: 1;
              }
              padding-top: 2px;
              border-radius: 50%;
              outline-offset: -5px !important; // seems random but -5px is just an optimal value visually due to design of the cutover O icon with breaks in the circle
            `}
            onClick={() => navigate('/app/my-cutover')}
            plain
            icon={<LogoLoader loading={isLoading} size={60} />}
          />
        </Tooltip>
      )

      switch (leftSidebarSize) {
        case 'hidden':
          return <></>
        case 'small':
        case 'default':
          return showBackButton ? backButton : loadingButton
        case 'full':
          return showBackButton ? backButton : toggleMenuButton
      }
    }, [leftSidebarSize, showBackButton, isLoading])

    return (
      <Box css="position: relative;">
        <Box
          direction="row"
          gap={leftSidebarSize === 'full' || showBackButton ? '4px' : undefined}
          justify="between"
          align="center"
          height={{ min: '72px' }}
          pad={{ right: '12px', left: '2px' }}
        >
          {topLeftSidebarButton}
          {leftSidebarSize !== 'small' && leftSidebarSize !== 'hidden' && (
            <SidebarSearchInput
              ref={inputRef}
              placeholder={t('placeholder')}
              defaultValue={searchQuery}
              aria-label={t('label')}
              onFocus={handleFocus}
              // @ts-ignore
              onInput={evt => handleInput(evt.target.value)}
            />
          )}
        </Box>
        {leftSidebarSize !== 'full' && leftSidebarSize !== 'hidden' && <FloatingToggleButton />}
        {showResults && (
          <Box pad={{ horizontal: '12px' }}>
            <SectionSearchResults
              runbooks={searchResults?.runbooks || []}
              hasProcessedSearchResults={Boolean(searchResults)}
            />
          </Box>
        )}
      </Box>
    )
  }
)

const SidebarSearchInput = forwardRef<
  HTMLInputElement,
  {
    placeholder: string
    defaultValue: string
    'aria-label': string
    onFocus: (evt: any) => void
    onInput: (evt: any) => void
  }
>(({ onFocus, ...inputProps }, ref) => {
  const localRef = useRef<HTMLInputElement>(null)
  const inputRef = useMergeRefs<HTMLInputElement>([localRef, ref])

  const handleFocus = useCallback(
    (evt: any) => {
      onFocus?.(evt)
    },
    [onFocus]
  )

  return (
    <ThemeContext.Extend
      value={{
        textInput: {
          extend: () => {
            return css`
              border-radius: 20px;
              font-weight: normal;
              background: ${themeColor('nav-search')};
              color: ${themeColor('nav-search-text')};

              ${CloseIcon} {
                display: none;
              }

              outline-offset: 2px !important;
              padding-left: 36px !important;
              font-size: 15px;

              ::placeholder {
                color: ${themeColor('nav-search-placeholder')};
              }

              &:focus {
                // don't like this behavior, should consider the design more
                outline: none !important;
                background: ${themeColor('nav-search-active')};
              }
            `
          },
          container: {
            extend: () => {
              return css`
                svg {
                  position: relative;
                  left: -4px;
                  stroke: ${themeColor('nav-search-placeholder')};
                  fill: ${themeColor('nav-search-placeholder')};
                }
              `
            }
          }
        }
      }}
    >
      <TextInput {...inputProps} onFocus={handleFocus} ref={inputRef} plain icon="search" />
    </ThemeContext.Extend>
  )
})

const CloseIcon = styled(Icon)``
