import { useCallback, useMemo } from 'react'

import { Box, BrandIcon, ColumnConfig, Table, TextLink } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useRouting } from 'main/services/routing'
import { WorkspaceListType } from 'main/services/queries/types'

export const WorkspacesListPage = ({ workspaces }: { workspaces: WorkspaceListType[] | undefined }) => {
  const { t } = useLanguage('accounts')
  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
  const { toWorkspaceUsers } = useRouting()

  closeRightPanel()

  const items = workspaces ?? []

  const columns: ColumnConfig<WorkspaceListType>[] = useMemo(() => {
    return [
      {
        header: '',
        property: 'icon_name',
        sortable: false,
        render: (datum: WorkspaceListType) => <BrandIcon icon={datum.icon_name} size="24px" color="primary" />
      },
      {
        header: t('list.tableHeadings.title'),
        property: 'title',
        sortable: true
      },
      {
        header: t('list.tableHeadings.usersCount'),
        property: 'users_count',
        render: (datum: WorkspaceListType) => {
          const usersLink = '#' + toWorkspaceUsers({ workspaceId: String(datum.id) })
          return <TextLink href={usersLink} label={String(datum.users_count)} onClick={e => e.stopPropagation()} />
        },
        sortable: true
      },
      {
        header: t('list.tableHeadings.foldersCount'),
        property: 'projects_count',
        sortable: true
      },
      {
        header: t('list.tableHeadings.runbooksCount'),
        property: 'runbooks_count',
        sortable: true
      },
      {
        header: t('list.tableHeadings.type'),
        property: 'account_type_name',
        sortable: true
      }
    ]
  }, [])

  const handleClickRow = useCallback(
    ({ datum }: { datum: WorkspaceListType }) => {
      openRightPanel({ type: 'workspace-edit', workspaceId: datum?.id })
    },
    [openRightPanel]
  )

  return (
    <Box pad={{ right: '16px' }}>
      <Table columns={columns} data={items} onClickRow={handleClickRow} sortable="client" />
    </Box>
  )
}
