import { TaskCompletionType, TaskListTask, TaskStage } from 'main/services/queries/types'
import { TaskFilterContext } from './filters'

export type { TaskFilterContext } from './filter-context'

export type DateWithin = 'hour' | 'halfday' | 'day' | 'week' | 'month' | '3months' | 'year'

// TODO: need to resolve this and the RunbookFilterType
// https://cutover.atlassian.net/browse/CFE-1439

export type RunbookFilterType = {
  /** is assigned to any user/team */
  a?: boolean
  /** is active */
  at?: boolean
  /** has comments */
  c?: boolean
  /** connected tasks? @note not sure if this key is a thing but the old filters type created for dashboard tasks has this */
  connected?: number[]
  /** critical path tasks up task with internal id */
  critical_to_here?: number
  /** is on the critical path */
  critical?: boolean
  /** completion type */
  ct?: TaskCompletionType[]
  /** named value for date starts within, relative to now */
  dd?: DateWithin
  /** unix time for date from (starts after date) */
  df?: number
  /** unix time for date to (starts before date) */
  dt?: number
  /** end requirements - (permissions for who can end task) */
  er?: string[]
  /** custom fields JSON */
  f?: { [key: number]: number[] | string | number }
  /** has fixed end */
  fe?: boolean
  /** has fixed start */
  fs?: boolean
  /** has errors */
  he?: boolean
  /** has predecessors */
  hp?: boolean
  /** has successors */
  hs?: boolean
  /** has team member of filtered teams @note team filter must also apply  */
  includeUsers?: boolean
  /** is late */
  l?: boolean
  /** task level */
  lv?: string[]
  /** is milestone task type */
  m?: boolean // milestone
  /** my tasks (assigned to current user or any current user's team) */
  mt?: boolean
  /** is over running */
  or?: boolean
  /** predecessors of task @note task.internal_id */
  predecessors_to_here?: number
  /** search string (query) */
  q?: string
  /** runbook component */
  rbc?: number[]
  /** has start notification enabled */
  sn?: boolean
  /** start requirements - (permissions for who can start tasks)  */
  sr?: string[]
  /** task stage */
  stage?: TaskStage[]
  /** task stream @note stream.internal_id @note this is either a parent stream or a substream if it's parent is not selected   */
  stream?: number[]
  task_type_id?: number // NOTE: is this just a single task type applicable when filtering runbook components?
  /** runbook team @note runbook_team.team_id */
  team?: number[]
  /** task type @note task_type.id */
  type?: number[]
  /** assigned users @note user.id */
  user?: number[]
}

export type AuditLogFilterType = {
  created_before?: number
  created_after?: number
  author_id?: number[]
  object_type?: string[]
  task_id?: string
}

export type RunbookUsersFilterType = {
  order?: string
  sort_direction?: string
}

// TODO: fix key formatting and type organization as part of https://cutover.atlassian.net/browse/CFE-1439

export type TaskFilterFunction = (
  tasks: TaskListTask[],
  filters: RunbookFilterType,
  context: TaskFilterContext
) => TaskListTask[]

/**
 * Creates a wrapper function with the code common to all filters
 *
 * @param key The filter key to look for
 * @param callback The filter function to call: takes tasks and filters and return boolean
 * @returns A function that takes an array of tasks, a filters object and returns an array of tasks
 */
export const createTaskFilterFunction = (
  key: string,
  callback: (task: TaskListTask, filters: RunbookFilterType, context: TaskFilterContext) => boolean
) => {
  return (tasks: TaskListTask[], filters: RunbookFilterType, context: TaskFilterContext = {}): TaskListTask[] => {
    if (!Object.prototype.hasOwnProperty.call(filters, key)) {
      return tasks
    }
    return tasks.filter(task => {
      return callback(task, filters, context)
    })
  }
}

/**
 * Creates a UNIX timestamp from a human-readable date string
 * @param date Date string like 25 Dec 2020 21:00:30 +0100
 * @returns
 */
export const unixTimeFor = (date: string): number => {
  return Math.floor(new Date(date).getTime() / 1000)
}
