import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { Box, ColorProp, duration, Text } from '@cutover/react-ui'
import { DashboardComponent, DashboardMediaType } from 'main/components/dashboards/widgets/types'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { useLanguage } from 'main/services/hooks/use-language'

export type PerformanceVsPlannedWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export const PerformanceVsPlannedWidget = ({ data }: PerformanceVsPlannedWidgetProps) => {
  const { t } = useLanguage('dashboard', { keyPrefix: 'performanceVsPlanned' })

  const maxDuration = useMemo(() => {
    return Math.max(...data.values.map(val => Math.max(val.total_planned_duration, val.total_actual_duration)))
  }, [data.values])

  const calculatePercentage = useCallback(
    (duration: number): number => {
      return Math.round((Number(duration) / maxDuration) * 100)
    },
    [maxDuration]
  )

  const getLatenessColor = (
    totalActualDuration: number,
    totalPlannedDuration: number,
    threshold: number
  ): ColorProp => {
    if (totalPlannedDuration >= totalActualDuration) {
      return 'success'
    }

    return threshold < totalActualDuration - totalPlannedDuration ? 'error' : 'warning'
  }

  const displayDiff = (totalPlannedDuration: number, totalActualDuration: number) =>
    totalPlannedDuration - totalActualDuration !== 0

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {data.notes && (
        <Text color="text-light" size="small">
          {data.notes}
        </Text>
      )}
      {data.errors && (
        <Text color="error" size="small">
          {data.errors}
        </Text>
      )}
      {data.values.length > 0 &&
        data.values.map(val => {
          return (
            <Box direction="row" justify="between" margin={{ top: '10px' }} key={val.id} gap="10px">
              <Box direction="column" width={'50%'} justify="between">
                <Box direction="row" justify="between">
                  <Box>
                    <Text color="text-light">{val.name}</Text>
                    <Text color="text-light">
                      {t('task', { count: val.count })}
                      {val.total_skipped > 0 && ` (${val.total_skipped} ${t('skipped')})`}
                    </Text>
                  </Box>
                  <Box>
                    {!!val.total_actual_duration && (
                      <>
                        <Text color="text-light" css={{ textAlign: 'right' }}>
                          {duration(val.total_planned_duration)}
                        </Text>
                        <Box direction="row">
                          <Text
                            color="text-light"
                            css={{
                              paddingRight: displayDiff(val.total_planned_duration, val.total_actual_duration)
                                ? '8px'
                                : '0px'
                            }}
                          >
                            {duration(val.total_actual_duration)}
                          </Text>
                          {displayDiff(val.total_planned_duration, val.total_actual_duration) && (
                            <Text
                              color={getLatenessColor(
                                val.total_actual_duration,
                                val.total_planned_duration,
                                data.lateness_threshold || 0
                              )}
                            >
                              {`${val.total_planned_duration >= val.total_actual_duration ? '-' : '+'}${duration(
                                val.total_planned_duration - val.total_actual_duration,
                                3
                              )}`}
                            </Text>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box direction="column" width={'50%'} gap={'xsmall'}>
                {!!val.total_actual_duration ? (
                  <>
                    <Box direction="row">
                      <ProgressBar>
                        <ProgressBarItem
                          background={{ light: 'bg-3' }}
                          basis={`${calculatePercentage(val.total_planned_duration)}%`}
                          data-testid="progress-bar-item"
                        />
                      </ProgressBar>
                    </Box>
                    <Box direction="row">
                      <ProgressBar>
                        <ProgressBarItem
                          background={getLatenessColor(
                            val.total_actual_duration,
                            val.total_planned_duration,
                            data.lateness_threshold || 0
                          )}
                          basis={`${calculatePercentage(val.total_actual_duration)}%`}
                          data-testid="progress-bar-item"
                        />
                      </ProgressBar>
                    </Box>
                  </>
                ) : (
                  <Box direction="row">
                    <Text color="text-light" size="xsmall">
                      {t('missingDurations')}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )
        })}
    </MrdDashboardWidget>
  )
}

const ProgressBar = styled(Box)`
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  margin-right: 60px;
  overflow: hidden;
  width: 100%;
  height: 16px;
  gap: 1px;
`

const ProgressBarItem = styled(Box)`
  justify-content: center;
  padding-right: 3px;
  opacity: 0.8;
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
  border-radius: 16px;
  &:hover {
    opacity: 1;
  }
`
