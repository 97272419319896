import { useEffect } from 'react'
import { useIsActivityFeedRoute } from 'single-spa/route-matchers'

import { useActivityFeedLayout } from '@cutover/react-ui'
import { useSearchQueryFilter } from 'main/recoil/data-access'
import { ActiveRunbookModel, RunbookViewModel } from 'main/data-access'
import { ActivityFeed } from 'main/components/activity-feed/activity-feed'

export const RunbookActivityFeed = () => {
  const runbookType = ActiveRunbookModel.useRunbookType()
  const runbookModel = ActiveRunbookModel.useGet()
  const { showActivityFeed, hideActivityFeed } = useActivityFeedLayout()
  const { can: canEditRunbook } = RunbookViewModel.usePermission('edit:runbook') || !runbookModel.archived
  const [_, setTaskInternalId] = useSearchQueryFilter()
  const isActivityFeedRoute = useIsActivityFeedRoute()
  const isActivityFeedVisible = isActivityFeedRoute && runbookType.incident && !runbookModel.is_template

  const filterTaskList = (internalIdsSearchParam: string | undefined) => {
    setTaskInternalId(internalIdsSearchParam)
  }

  useEffect(() => {
    isActivityFeedVisible && showActivityFeed()

    return () => {
      hideActivityFeed()
    }
  }, [isActivityFeedRoute])

  return (
    <>
      {isActivityFeedVisible ? (
        <ActivityFeed disableCommentField={!canEditRunbook} filterTaskList={filterTaskList} />
      ) : null}
    </>
  )
}
