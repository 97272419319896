import { useMemo } from 'react'
import styled from 'styled-components'

import { Button, themeColor, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunModel,
  CurrentUserModel,
  RunbookViewModel,
  TaskModel,
  TaskProgressionViewModel,
  TaskTypeModel
} from 'main/data-access'

export const StartableTaskButton = ({ taskId }: { taskId: number }) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.startable' })

  const task = TaskModel.useGet(taskId)
  const taskType = TaskTypeModel.useGet(task.task_type_id)
  const isLocked = useMemo(() => (task.start_fixed ? task.start_fixed * 1000 > Date.now() : false), [task.start_fixed])
  const runMode = ActiveRunModel.useMode()
  const getStartTaskPermission = TaskModel.usePermissionCallbackSync('start')
  const currentUserId = CurrentUserModel.useId()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const hasUserStartedTask = task.started_user_ids?.includes(currentUserId!)
  const progressTask = TaskModel.useAction('progress')
  const getProgressTaskPermission = TaskModel.usePermissionCallbackSync('progress')
  const { can: canProgressTask } = getProgressTaskPermission(task.id)
  const getNextProgressionModal = TaskProgressionViewModel.getCallback('nextModal')
  const openModal = RunbookViewModel.useAction('modal:open')
  const notify = useNotify()

  const handleClickStartTask = async () => {
    if (['comms_email', 'comms_sms'].includes(taskType.comms) && !canProgressTask) {
      notify.warning(
        task.errors
          .map(apiError => {
            return t('errors.' + apiError)
          })
          .join(', ') ?? '',
        { title: t('errors.title') }
      )
      return
    }
    const nextModal = await getNextProgressionModal(task.id)
    nextModal ? openModal(nextModal) : progressTask(task.id)
  }

  if (getStartTaskPermission(taskId).can) {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        icon="play"
        label={isLocked ? t('fixedStart') : t('start')}
        onClick={handleClickStartTask}
      />
    )
  } else if (hasUserStartedTask) {
    return <TaskActionButton label={t('pendingApproval')} icon="play" disabled />
  } else if (!isLocked) {
    return <TaskActionButton label={t('startable')} icon="play" disabled />
  } else {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        icon="play"
        label={t('fixedStart')}
        onClick={handleClickStartTask}
      />
    )
  }
}

export const InProgressTaskButton = ({ taskId }: { taskId: number }) => {
  const task = TaskModel.useGet(taskId)
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.inProgress' })
  const getFinishTaskPermission = TaskModel.usePermissionCallbackSync('finish')
  const currentUserId = CurrentUserModel.useId()
  const runMode = ActiveRunModel.useMode()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const hasUserEndedTask = task.ended_user_ids?.includes(currentUserId!)
  const progressTask = TaskModel.useAction('progress')
  const getNextProgressionModal = TaskProgressionViewModel.getCallback('nextModal')
  const openModal = RunbookViewModel.useAction('modal:open')

  const handleClickFinishTask = async () => {
    const nextModal = await getNextProgressionModal(task.id)
    nextModal ? openModal(nextModal) : progressTask(task.id)
  }

  if (getFinishTaskPermission(taskId)?.can) {
    return (
      <TaskActionButton
        disabled={runMode === 'paused'}
        label={t('finishTask')}
        onClick={handleClickFinishTask}
        icon="check-dashed"
      />
    )
  } else if (hasUserEndedTask) {
    return <TaskActionButton label={t('pendingApproval')} icon="check-dashed" disabled />
  } else {
    return <TaskActionButton label={t('inProgress')} disabled />
  }
}

export const CompletedTaskButton = ({ taskId }: { taskId: number }) => {
  const task = TaskModel.useGet(taskId)
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer.complete' })

  // TODO: Address completion type in the context of linked resources. Angular computes a
  // frontend attribute for it. See angular's task_active_model _getTaskCompletionStatus function
  switch (task.completion_type) {
    case 'complete_normal':
      return <TaskActionButton label={t('normal')} icon="check" disabled />
    case 'complete_abandoned':
      return <TaskActionButton label={t('abandoned')} icon="close" disabled />
    case 'complete_skipped':
      return <TaskActionButton label={t('skipped')} icon="skip" disabled />
    default:
      return null
  }
}

export const CancelledLinkedResourceButton = () => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel.footer' })

  return (
    <Button
      label={t('cancelled')}
      icon="close"
      secondary
      full
      disabled
      css={`
        color: ${themeColor('text')};
        // iconColor prop is not being honoured in svg, so we need to override it here
        svg {
          fill: ${themeColor('text')};
        }
      `}
    />
  )
}

const TaskActionButton = styled(Button).attrs({
  full: true,
  primary: true,
  'data-testid': 'edit-panel-task-action-button'
})``
