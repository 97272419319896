import { atom, AtomEffect, DefaultValue, selector } from 'recoil'

import { getRunbookId, getRunbookVersionId } from 'main/recoil/shared/nav-utils'
import { RunbookVersion } from 'main/services/queries/types'
import {
  getRunbookVersion,
  GetRunbookVersionResponse,
  RunbookVersionMeta
} from 'main/services/queries/use-runbook-versions'
import { runbookRunbookTypeState, runbookState } from '../runbook/runbook'

// *******************************
// Runbook version api response
// *******************************

const syncRunbookVersionResponseEffect: AtomEffect<GetRunbookVersionResponse> = ({ setSelf }) => {
  const getInitialRunbookVersion = async () => {
    const runbookId = getRunbookId()
    const runbookVersionId = getRunbookVersionId()

    if (runbookId && runbookVersionId) {
      const data = await getRunbookVersion(runbookId, runbookVersionId)
      setSelf(data)
    } else {
      return new DefaultValue()
    }
  }

  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'runbook-version') {
      getInitialRunbookVersion()
    }
  }

  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated, but via the runbookVersionState selector
export const runbookVersionResponseState_INTERNAL = atom<GetRunbookVersionResponse>({
  key: 'runbook-version:response',
  effects: [syncRunbookVersionResponseEffect]
})

// *******************************
// Runbook version
// *******************************

export const runbookVersionState = selector<RunbookVersion>({
  key: 'runbook-version',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const resp = get(runbookVersionResponseState_INTERNAL)
    return resp.runbook_version
  }
})

export const runbookVersionIdState = selector({
  key: 'runbook-version:id',
  get: ({ get }) => {
    return get(runbookVersionState).id
  }
})

export const runbookVersionStageState = selector({
  key: 'runbook-version:stage',
  get: ({ get }) => {
    return get(runbookVersionState).stage
  }
})

export const runbookVersionPermissionsState = selector({
  key: 'runbook-version:permissions',
  get: ({ get }) => {
    return get(runbookVersionMetaState).permissions
  }
})

export const isVersionCurrentState = selector({
  key: 'runbook-version:is-current',
  get: ({ get }) => {
    const runbookVersion = get(runbookVersionState)
    const runbook = get(runbookState)
    return runbookVersion.id === runbook.current_version.id
  }
})

export const isVersionEditable = selector({
  key: 'runbook-version:is-editable',
  get: ({ get }) => {
    const runbook = get(runbookState)
    const runbookVersion = get(runbookVersionState)
    const runbookVersionIsCurrent = get(isVersionCurrentState)
    const { dynamic: isDynamic } = get(runbookRunbookTypeState)
    if (runbook.archived) return false
    if (!runbookVersionIsCurrent) return false
    if (runbookVersion.stage === 'complete' || runbookVersion.stage === 'cancelled') return false
    if (runbookVersion.stage === 'planning' || runbookVersion.stage === 'paused') return true

    // If active run, allow edits if dynamic
    return isDynamic
  }
})

// *******************************
// Runbook version meta
// *******************************

export const runbookVersionMetaState = selector<RunbookVersionMeta>({
  key: 'runbook-version:meta',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const resp = get(runbookVersionResponseState_INTERNAL)
    return resp.meta
  }
})
