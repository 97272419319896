import { useState } from 'react'

import { TabGroup } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNodeOption } from '../apps-types'

type TabGroupNodeProps = AppComponentNodeProps & {
  options: ContentNodeOption[]
  action?: string
  selected?: string
  name?: string
}

export const TabGroupNode = ({ appId, resourceId, action, id, ...props }: TabGroupNodeProps) => {
  const { componentProps, state, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const { name = 'tab_group', meta = {}, options, selected } = componentProps as TabGroupNodeProps
  const [tabValue, setTabValue] = useState(selected ?? '')

  const postAppEvents = usePostAppEvents()

  const handleTabSelection = (option: ContentNodeOption) => {
    setTabValue(option.value)
    setState({ name, value: option.value })
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [{ type: 'tab_group', action, id, meta, name, options, selected }],
      state: { ...state, [name]: option.value }
    }
    postAppEvents(payload)
  }

  const tabs = options.map((option: ContentNodeOption) => {
    return {
      ...option,
      onClick: () => handleTabSelection(option),
      active: tabValue === option.value
    }
  })

  return <TabGroup items={tabs} />
}
