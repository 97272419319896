import { LoadableType } from '@cutover/utility-types'
import { GetAccountResponseType } from 'main/services/api/data-providers/account/use-get-account-data'
import { RunbookGetResponse } from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'
import { TaskListResponseType } from 'main/services/queries/use-tasks'
import { useLoadableGlobalState, useResetGlobalState, useSetGlobalState } from '../hooks/recoil/global-state-setup'
import {
  GetAccountResponseAndComputedPropertiesState,
  TaskListResponseAndComputedPropertiesState
} from 'main/recoil/runbook'

export type ResponseDataMap = {
  account: GetAccountResponseType
  runbookVersion: GetRunbookVersionResponse
  runbook: RunbookGetResponse
  tasks: TaskListResponseType
}

export type RecoilDataMap = {
  account: GetAccountResponseAndComputedPropertiesState
  runbookVersion: GetRunbookVersionResponse
  runbook: RunbookGetResponse
  tasks: TaskListResponseAndComputedPropertiesState
}

export type GlobalStateSetupModel = {
  useSet: <TRequestType extends keyof ResponseDataMap>(
    requestType: TRequestType
  ) => (response: ResponseDataMap[TRequestType]) => void
  useReset: <TRequestType extends keyof ResponseDataMap>(requestType: TRequestType) => () => void
  useLoadable: <TRequestType extends keyof ResponseDataMap>(
    requestType: TRequestType
  ) => LoadableType<RecoilDataMap[TRequestType]>
}

export const GlobalStateSetupModel: GlobalStateSetupModel = {
  useSet: useSetGlobalState,
  useReset: useResetGlobalState,
  useLoadable: useLoadableGlobalState
}
