import { ReactNode, useEffect, useMemo } from 'react'
import { format } from 'date-fns'

import { Box, IconButton, Message, ScrollContent } from '@cutover/react-ui'
import { RunbookWidgetCollection } from 'main/components/dashboards/dashboard-widget-collection'
import { useAppliedFilters, useFilteredTasksState, useRunbookVersionUsers } from 'main/recoil/data-access'
import {
  ActiveDashboardModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CommentModel,
  ConfigModel,
  CurrentUserModel,
  CustomFieldModel,
  DashboardModel,
  RunbookViewModel,
  StreamModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { useRunbookSubHeader } from '../../runbook-sub-header/use-runbook-sub-header'
import { DashboardPageForm, DashboardSubHeaderRightMenu, PirDashboard } from './dashboard-layout'
import { Dashboard as DashboardType, RunbookShowRunbook, RunbookVersion } from 'main/services/queries/types'

export const Dashboard = () => {
  const runbook = ActiveRunbookModel.useGet()
  const runbookVersion = ActiveRunbookVersionModel.useGet()

  const dashboard = ActiveDashboardModel.useGet()
  const pirDashboard = DashboardModel.useGetBy({ key: 'pir' })

  const isPirDashboard = !!(pirDashboard && dashboard && dashboard?.id === pirDashboard?.id)
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')

  return isPirDashboard ? (
    <PirDashboard
      dashboard={dashboard}
      runbookId={runbook.id}
      endActual={runbookVersion.end_actual as number}
      readOnly={!canUpdateRunbook}
    />
  ) : (
    <DashboardInner dashboard={dashboard} runbook={runbook} runbookVersion={runbookVersion} />
  )
}

export const DashboardInner = ({
  dashboard,
  runbook,
  runbookVersion
}: {
  dashboard?: DashboardType
  runbook: RunbookShowRunbook
  runbookVersion: RunbookVersion
}) => {
  const { t } = useLanguage('dashboard', { keyPrefix: 'banner' })
  const currentUserId = CurrentUserModel.useId()
  const customFields = CustomFieldModel.useGetAll()
  const streams = StreamModel.useGetAll()
  const taskTypes = TaskTypeModel.useGetAll()
  const tasks = TaskModel.useGetAll()
  const { taskStageTypes: taskStages } = ConfigModel.useGet()
  const pirDashboard = DashboardModel.useGetBy({ key: 'pir' })
  const filtersState = useAppliedFilters()

  const hasLinkedTaskType = tasks.some(task => task.linked_resource !== null && task.linked_resource !== undefined)

  const { state: commentsLoadingState, contents: featuredCommentContents } = CommentModel.useGetAllLoadable({
    scope: 'featured'
  })
  const comments = commentsLoadingState === 'hasValue' ? featuredCommentContents : []
  const filteredTasks = useFilteredTasksState()
  const users = useRunbookVersionUsers()
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')

  const components =
    dashboard?.dashboard_components.map(component => {
      return {
        ...component,
        name: component.display_name,
        type: component.component_type,
        custom_fields: customFields,
        current_user: currentUserId,
        runbook,
        streams,
        task_types: taskTypes,
        tasks,
        filtered_tasks: filteredTasks,
        stage_types: taskStages,
        users,
        comments,
        filters: component.filters && JSON.parse(component.filters),
        settings: component.settings && JSON.parse(component.settings),
        task_filters: filtersState
      }
    }) || []

  const hasContentComponents = useMemo(
    () => components.some(component => component.component_type === 'content'),
    [components, dashboard, pirDashboard]
  )
  const showDashboardForm = hasContentComponents

  const dashboardBanner = useMemo(() => {
    const runCancelled = !!runbookVersion.cancelled_at
    const runComplete = !!runbookVersion.end_actual
    const runPaused = !!runbookVersion.paused_at

    let date = ''
    if (runCancelled && runbookVersion.cancelled_at) {
      date = format(runbookVersion.cancelled_at * 1000, 'd MMM HH:mm')
    } else if (runComplete && runbookVersion.end_actual) {
      date = format(runbookVersion.end_actual * 1000, 'd MMM HH:mm')
    } else if (runPaused && runbookVersion.paused_at) {
      date = format(runbookVersion.paused_at * 1000, 'd MMM HH:mm')
    }

    let message = ''
    if (runCancelled) {
      message = t('runCancelled', { date })
    } else if (runComplete) {
      message = t('runComplete', { date })
    } else if (runPaused) {
      message = t('runPaused', { date })
    }

    return <Message message={message} type={runComplete ? 'success' : 'error'} icon="info" />
  }, [runbookVersion])

  const showDashboardBanner = useMemo(() => {
    return !!runbookVersion.paused_at || !!runbookVersion.end_actual || !!runbookVersion.cancelled_at
  }, [runbookVersion])

  return showDashboardForm ? (
    <>
      {dashboard && (
        <DashboardPageForm components={components} dashboard={dashboard}>
          <ScrollContent>
            <RunbookWidgetCollection components={components} media="screen" readOnly={!canUpdateRunbook} />
          </ScrollContent>
        </DashboardPageForm>
      )}
    </>
  ) : (
    <DashboardPage>
      <ScrollContent>
        {showDashboardBanner && dashboardBanner}
        {dashboard?.key === 'linked-runbooks' && !hasLinkedTaskType && (
          <Message message={t('noLinkedTaskTypes')} type="error" icon="info" />
        )}
        <RunbookWidgetCollection components={components} media="screen" />
      </ScrollContent>
    </DashboardPage>
  )
}

const DashboardPage = ({ children }: { children: ReactNode }) => {
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()

  useEffect(() => {
    addSubHeaderContent({
      right: <DashboardSubHeaderRightContent />
    })

    return resetSubHeaderContent
  }, [])

  return <>{children}</>
}

const DashboardSubHeaderRightContent = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader' })

  const openModal = RunbookViewModel.useAction('modal:open')
  const canUpdateRunbook = ActiveRunbookModel.useCan('update')
  const { restricted: isRestrictedRunbook } = ActiveRunbookModel.useGet()
  const run = ActiveRunModel.useGet()

  const showShareRunbookDashboardButton =
    canUpdateRunbook && run?.mode !== 'cancelled' && run?.mode !== 'complete' && !isRestrictedRunbook

  return (
    <>
      {showShareRunbookDashboardButton && (
        <Box width={{ min: '40px' }}>
          <IconButton
            tertiary
            data-testid="sub-header-share-button"
            label={t('share')}
            onClick={() => {
              openModal({ type: 'runbook-dashboard-share' })
            }}
            icon="share"
          />
        </Box>
      )}
      <DashboardSubHeaderRightMenu showShareRunbookDashboardButton={showShareRunbookDashboardButton} />
    </>
  )
}
