import { forwardRef } from 'react'

import { StyledIcon, StyledIconProps } from '../../styled-icon'

const Remedy = forwardRef<SVGSVGElement, StyledIconProps>((props, ref) => (
  <StyledIcon ref={ref} viewBox="0 0 36 36" a11yTitle="Remedy" {...props}>
    <path d="M11.133 5.467v4.066c0 .667.6 1.733 1.2 2.066l7.8 4.6 4.6-2.733-13.6-7.999Zm9 14.4-7.8 4.601c-.6.333-1.2 1.4-1.2 2.067v4.067l13.533-8-4.533-2.734ZM10.6 34C9.334 34 8 33 8 31.067v-4.533c0-1.8 1.133-3.866 2.734-4.734l6.333-3.733-6.333-3.732C9.2 13.334 8 11.334 8 9.533v-4.6C8 3 9.334 2 10.666 2c.533 0 1.133.134 1.667.467L26.6 10.933c1 .6 1.534 1.534 1.534 2.467 0 1-.6 1.934-1.534 2.466l-3.4 2 3.4 2c1 .6 1.6 1.534 1.6 2.467 0 1-.6 1.934-1.6 2.467l-14.267 8.733c-.6.334-1.133.467-1.733.467Z" fill="#000" />
  </StyledIcon>
))

Remedy.displayName = 'Remedy'

export { Remedy }
