export const routes = {
  toWorkspaceTemplates: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=default`
  },
  toWorkspaceSnippets: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=snippet`
  },
  toWorkspaceRunbooks: ({ accountSlug }: { accountSlug: string }) => {
    return `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc`
  },

  toWorkspaceUsers: ({ workspaceId }: { workspaceId: string }) => {
    return `/app/access/users/list?account_id=${workspaceId}`
  },

  toRunbook: ({
    accountSlug,
    runbookId,
    versionId,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    params?: string
  }) => `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/list${params}`,

  toDashboard: ({
    accountSlug,
    runbookId,
    versionId,
    dashboardId,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    dashboardId?: number
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${
      versionId ? versionId : 'current_version'
    }/tasks/dashboard/${dashboardId}${params}`,

  toPage: ({
    accountSlug,
    runbookId,
    versionId,
    dashboardId,
    params = ''
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    dashboardId?: number
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${
      versionId ? versionId : 'current_version'
    }/page/content/${dashboardId}${params}`,

  toRunbookUsers: ({
    accountSlug,
    runbookId,
    versionId
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
  }) => `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/users`,

  toRunbookAuditLog: ({
    accountSlug,
    runbookId,
    versionId
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
  }) => `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/auditlog`
}
