import { forwardRef, TextareaHTMLAttributes, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { mergeRefs, useCallbackRef } from 'use-callback-ref'
import { v4 } from 'uuid'

import { Icon, IconName } from '../../icon/icon'
import { FieldLabel, FieldStateProps, FormField, FormFieldWrapper } from './text-area-components'
import { Box } from '../../layout'
import { resolveColor, themeColor } from '../../theme'
import { HelpText } from '../internal/form-field'
import { useInitialMount } from '../../utilities'

// WARNING - THIS FILE IS PARTIAL LEGACY!!!! It should be fully refactored but needs more work to get
// the autosizing textarea to work properly. Could get it to work with the old appropach but wasn't able to migrate
// it over after some time and had to move on, but would like to revisit.

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  // TODO: doesn't support inline error yet until we migrate over into new form field component structure
  // inlineError?: string
  hasError?: boolean
  tooltipText?: string
  leftIcon?: IconName
  icon?: IconName
  iconSize?: string
  iconPlain?: boolean
  name?: string
  resizable?: boolean
  plain?: boolean
  alwaysShowPlaceholder?: boolean
}

// TODO: NEED TO PLEASE pull out a hook for useAnimatedFormField soon - haven't gotten to work cleanly yet
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      hasError,
      disabled,
      readOnly,
      rows = 1,
      required,
      resizable = false,
      onFocus,
      onBlur,
      onInput,
      tooltipText,
      leftIcon,
      icon,
      iconSize,
      iconPlain,
      placeholder,
      name,
      plain,
      autoFocus,
      alwaysShowPlaceholder = false,
      ...props
    },
    ref
  ) => {
    const isInitialMount = useInitialMount()

    const localRef = useCallbackRef<HTMLTextAreaElement>(null, node => {
      if (node && node.parentNode) {
        // @ts-ignore
        node.parentNode.dataset.value = node?.value
      }

      // Set autofocus at the end of the textarea content
      if (node && isInitialMount && autoFocus) {
        // @ts-ignore
        node.focus()
        const valueLength = node?.value.length || 0
        node.setSelectionRange(valueLength, valueLength)
      }
    })
    const mergedRef = mergeRefs([localRef, ref])

    const [id] = useState(v4())
    const [isFocused, setFocused] = useState(false)
    const [isLabelShrunk, setLabelShrunk] = useState(false)
    const [animationInitialized, setAnimationInitialized] = useState(false)

    const hasValueOrFocus = useCallback(
      () => Boolean(mergedRef?.current?.value) || (isFocused && !readOnly),
      [isFocused, mergedRef, readOnly]
    )

    useEffect(() => {
      setLabelShrunk(Boolean(localRef?.current?.value))
    }, [])

    useEffect(() => {
      setLabelShrunk(hasValueOrFocus())
    }, [isFocused, localRef.current?.value, props.value])

    const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      if (!animationInitialized) setAnimationInitialized(true)
      setFocused(true)
      onFocus?.(event)
    }

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false)
      setLabelShrunk(hasValueOrFocus())
      onBlur?.(event)
    }

    const handleHelpClick = () => {
      mergedRef.current?.focus()
    }

    const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
      if (mergedRef.current && mergedRef.current.parentNode) {
        // @ts-ignore
        mergedRef.current.parentNode.dataset.value = mergedRef.current?.value
      }
      onInput?.(event)
    }

    return (
      <FormFieldWrapper error={hasError} disabled={disabled} readOnly={readOnly} withBorder={!plain} animated>
        <TextAreaField
          hasLeftIcon={!!leftIcon}
          withBorder={!plain}
          error={hasError}
          readOnly={readOnly}
          disabled={disabled}
          resizable={resizable}
          css={`
            grid-template-columns: 1fr min-content;
            &::after,
            textarea {
              grid-area: 2 / 1;
            }
          `}
          animated
        >
          {leftIcon && (
            <Box
              flex={false}
              css={`
                position: absolute;
                top: 3px;
                left: 3px;
              `}
            >
              <Icon
                icon={leftIcon}
                color={hasError ? 'error' : disabled ? 'text-disabled' : 'text-light'}
                aria-hidden
              />
            </Box>
          )}
          <Label
            htmlFor={id}
            isShrunk={isLabelShrunk}
            error={hasError}
            readOnly={readOnly}
            disabled={disabled}
            animate={animationInitialized}
            withIcon={!!leftIcon}
            required={required}
          >
            {label}
          </Label>
          <TextAreaStyled
            {...props}
            required={required}
            id={id}
            name={name}
            ref={mergedRef}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            rows={rows}
            onInput={handleInput}
            placeholder={isLabelShrunk || alwaysShowPlaceholder ? placeholder : undefined}
            readOnly={readOnly}
          />
          {tooltipText && (
            <Box
              css={`
                grid-area: 2 / 2;
                align-self: flex-start;
                position: relative;
                top: ${icon !== 'help' ? '0' : '8.5px'};
                right: 3px;
              `}
            >
              <HelpText
                text={tooltipText}
                icon={icon}
                iconSize={iconSize}
                iconPlain={iconPlain}
                onClick={handleHelpClick}
              />
            </Box>
          )}
        </TextAreaField>
      </FormFieldWrapper>
    )
  }
)

// TODO: sloppy css -- needs cleanup
const TextAreaField = styled(FormField)<
  FieldStateProps & { maxHeight?: number; resizable?: boolean; hasLeftIcon?: boolean }
>`
  color: ${({ theme }) => resolveColor('text', theme)};
  display: inline-grid;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  vertical-align: top;
  align-items: center;
  position: relative;
  justify-content: initial;
  min-height: 32px;
  align-items: stretch;
  color: ${props => themeColor(props.disabled ? 'text-disabled' : 'text')};

  &::after,
  textarea {
    width: auto;
    min-width: 1em;
    font-size: 15px;
    line-height: 21px;
    color: ${themeColor('text')};
    padding: 5px 0 6px ${props => (props.hasLeftIcon ? '32px' : '0')};
    margin: 0;
    resize: ${props => (props.resizable ? 'vertical' : 'none')};
    background: none;
    appearance: none;
    outline: none;
    border: none;
    min-height: 32px;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  textarea {
    color: ${props => props.disabled && themeColor('text-disabled')};
  }
`

const TextAreaStyled = styled.textarea<TextAreaProps>`
  padding-top: 8px;

  ::placeholder {
    color: ${themeColor('text-disabled')};
  }
`

type LabelProps = {
  isShrunk?: boolean
  withIcon?: boolean
}

const Label = styled(FieldLabel)<LabelProps & FieldStateProps>`
  font-size: ${props => (props.isShrunk ? '13px' : '15px')};
  position: absolute;
  top: ${props => (props.isShrunk ? '-12px' : '0')};
  left: ${props => (props.withIcon && !props.isShrunk ? '32px' : '0')};
  height: ${props => (props.isShrunk ? '13px' : '32px')};
  display: flex;
  align-items: center;
`
