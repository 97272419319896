import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getItemTitle } from '../../components/settings/integration-settings/action-items/integration-action-item-form-helper'
import { IntegrationActionItemRequestType } from '../../components/settings/integration-settings/action-items/integration-action-item-types'
import { apiClient } from 'main/services/api'
import { IntegrationConnectionConfigCamel } from 'main/services/hooks'
import { QueryKeys } from 'main/services/queries/query-keys'
import { IntegrationActionItem } from 'main/services/queries/types'

export function useIntegrationActionItemsQuery() {
  return useQuery<IntegrationActionItem[], Error, IntegrationActionItem[]>(
    [QueryKeys.IntegrationActionItems],
    async () => {
      const { data } = await apiClient.get<{ integration_action_items: IntegrationActionItem[] }>({
        url: 'integration_action_items'
      })

      return data.integration_action_items ?? []
    }
  )
}

export function useIntegrationActionItemCreate() {
  const queryClient = useQueryClient()

  // what is a mutation key and should it match query keys?
  return useMutation<IntegrationActionItem, Error, IntegrationActionItemRequestType>(
    [QueryKeys.IntegrationActionItems],
    async values => {
      const { data } = await apiClient.post<
        { integration_action_item: IntegrationActionItemRequestType },
        { integration_action_item: IntegrationActionItem }
      >({
        url: 'integration_action_items',
        data: { integration_action_item: values }
      })
      // TODO: Fix this when API client type is fixed
      return data?.integration_action_item as IntegrationActionItem
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.IntegrationActionItems])
      }
    }
  )
}

export function useIntegrationActionItemArchive(id: number) {
  const queryClient = useQueryClient()

  const mutationFn = async () => {
    const { data } = await apiClient.delete<{ integration_action_item: IntegrationActionItem }>({
      url: `integration_action_items/${id}`
    })
    return data?.integration_action_item
  }

  return useMutation(mutationFn, {
    onMutate: async () => {
      await queryClient.cancelQueries([QueryKeys.IntegrationActionItems])

      const previousIntegrationActionItems = queryClient.getQueryData<IntegrationActionItem[]>([
        QueryKeys.IntegrationActionItems
      ])

      const updatedIntegrationActionItems =
        previousIntegrationActionItems?.map(item => {
          return {
            ...item,
            ...(item.id === id
              ? {
                  archived: true
                }
              : {})
          }
        }) ?? []

      queryClient.setQueryData<IntegrationActionItem[]>(
        [QueryKeys.IntegrationActionItems],
        updatedIntegrationActionItems
      )
      return { previousIntegrationSettings: previousIntegrationActionItems }
    },

    onError: (context: { previousIntegrationActionItems: IntegrationActionItem[] | undefined }) => {
      if (context.previousIntegrationActionItems) {
        queryClient.setQueryData<IntegrationActionItem[]>(
          [QueryKeys.IntegrationActionItems],
          context.previousIntegrationActionItems
        )
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.IntegrationActionItems])
    }
  })
}

export function useIntegrationActionItemUpdate(id: number, integrations: IntegrationConnectionConfigCamel[]) {
  const queryClient = useQueryClient()

  const mutationFn = async (values: IntegrationActionItemRequestType) => {
    return apiClient.put<{ integration_action_item: IntegrationActionItemRequestType }, IntegrationActionItem>({
      url: `integration_action_items/${id}`,
      data: { integration_action_item: values }
    })
  }

  return useMutation(mutationFn, {
    onMutate: async values => {
      await queryClient.cancelQueries([QueryKeys.IntegrationActionItems])

      const previousIntegrationActionItems = queryClient.getQueryData<IntegrationActionItem[]>([
        QueryKeys.IntegrationActionItems
      ])

      const updatedIntegrationActionItems =
        previousIntegrationActionItems?.map(item => {
          const global = values.additional_settings?.visibility === -1
          const accountId =
            values.additional_settings?.visibility !== -1 ? values.additional_settings?.visibility : undefined
          return {
            ...item,
            ...(item.id === values.id
              ? {
                  name: values.name,
                  on: values.on,
                  entity: {
                    id: values.entity_id,
                    auto_start: values.additional_settings?.auto_start,
                    enable_start_fixed: values.additional_settings?.enable_start_fixed,
                    global: global,
                    account_id: accountId
                  },
                  global: global,
                  account_id: accountId,
                  integration_action: values.integration_action,
                  settings: values.settings as IntegrationActionItem['settings'],
                  options: values.options,
                  title: getItemTitle(values.integration_action, integrations)
                }
              : {})
          }
        }) ?? []

      queryClient.setQueryData<IntegrationActionItem[]>(
        [QueryKeys.IntegrationActionItems],
        updatedIntegrationActionItems as IntegrationActionItem[]
      )

      return { previousIntegrationActionItems }
    },

    onError: (context: { previousIntegrationActionItems: IntegrationActionItem[] | undefined }) => {
      if (context.previousIntegrationActionItems) {
        queryClient.setQueryData<IntegrationActionItem[]>(
          [QueryKeys.IntegrationActionItems],
          context.previousIntegrationActionItems
        )
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries([QueryKeys.IntegrationActionItems])
    }
  })
}
