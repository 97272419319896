import { useMatch } from 'react-router-dom'
import { useIsAuditLogMatch } from 'single-spa/route-matchers'

import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { RunbookBaseRunbook, RunbookTypeType } from 'main/services/queries/types'
import { ActiveDashboardModel, DashboardModel } from 'main/data-access'
import { DisplayType } from 'main/components/runbook/runbook-sub-header/view-navigation'

// are all of these approval statuses?
export const APPROVAL_STATUS_FOR_TEMPLATE_EDIT_LOCK = ['open', 'approved', 'rejected', 'expired', 'expiring']

export const useRunbookTypeById = (
  runbookTypeId?: number | null,
  { excludeIncidentRunbook }: { excludeIncidentRunbook?: boolean } = {}
) => {
  const { runbookTypes } = useAccountRunbookTypes()

  const runbookType = runbookTypes?.find(
    rt => rt.id === runbookTypeId && (excludeIncidentRunbook ? rt.key !== 'incident-runbook' : true)
  )

  return runbookType
}

export const useChangeRequestTypes = (runbook: RunbookBaseRunbook) => {
  const type = useRunbookTypeById(runbook.id)
  return type?.change_request_types
}

// shouldn't want to use this without a runbook, but architecture of edit-runbook doesn't require a runbook.
// that file should be updated so this can be called with a runbook only
export const useIsTemplateLocked = (approvalStatus?: string | null) => {
  return !!approvalStatus && APPROVAL_STATUS_FOR_TEMPLATE_EDIT_LOCK.includes(approvalStatus)
}

export const usePermitRoleAddition = (runbookType: RunbookTypeType) => {
  // Phase 1 - This is temporary. A ticket exists to use a new setting on the integration action item
  return !runbookType.incident
}

type ActiveTab = DisplayType | 'pir' | 'auditLog'

export const useGetRunbookPageActiveTab = () => {
  const activeDashboard = ActiveDashboardModel.useGet()
  const pirDashboard = DashboardModel.useGetBy({ key: 'pir' })
  const isAuditLog = useIsAuditLogMatch()

  const reactPageMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/page/content/:dashboardId')
  const displayMatch = useMatch('/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/:displayType/*')

  const pageId = reactPageMatch?.params.dashboardId
  return (
    isAuditLog
      ? 'auditLog'
      : pirDashboard?.id === activeDashboard?.id
      ? 'pir'
      : pageId
      ? 'page'
      : displayMatch?.params.displayType
  ) as ActiveTab
}
