import { useMemo } from 'react'

import { Box, Message, Text, useNotify } from '@cutover/react-ui'
import { TaskLineItemWrapper } from 'main/components/shared/task-line-item'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { deleteTasks } from 'main/services/queries/use-tasks'
import { FormModal } from 'main/components/shared/form'
import { RunbookTaskBulkDeleteResponse } from 'main/services/api/data-providers/runbook-types'
import { ActiveRunbookModel, ActiveRunbookVersionModel, TaskModel } from 'main/data-access'
import { useModalBulkTaskErrorsAndValidIds } from 'main/components/runbook/modals/task-modals/use-bulk-actions'

export const TasksDeleteModal = ({
  open,
  taskIds: initialTaskIds = [],
  onClose,
  onSuccess
}: {
  open: boolean
  taskIds: number[]
  onClose?: () => void
  onSuccess?: () => void
}) => {
  const notify = useNotify()
  const { t } = useLanguage('tasks', { keyPrefix: 'tasksDeleteModal' })

  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()

  const handleSuccess = TaskModel.useOnAction('bulk_delete')

  const [{ taskId: editPanelId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')

  const { tasks, taskErrors, errorTaskIds, validTaskIds, isValidTaskIdsEmpty } = useModalBulkTaskErrorsAndValidIds({
    bulkAction: 'bulk_delete',
    taskIds: initialTaskIds
  })

  const sortedTaskList = useMemo(
    () => tasks.sort((a, b) => (a?.start_latest_planned || 0) - (b?.start_latest_planned || 0)),
    [tasks]
  )
  const tasksWithMultiplePredsAndSuccs = useMemo(
    () => tasks.filter(task => (task ? task.predecessor_ids.length > 1 && task?.successor_ids.length > 1 : false)),
    [tasks]
  )

  const hasLinkedTasks = tasks.some(task => task.linked_resource)

  const handleSubmit = async () => {
    return await deleteTasks(runbookId, runbookVersionId, validTaskIds)
  }

  return (
    <FormModal
      formElementWrapper={false}
      onClose={onClose}
      onSuccess={(response: RunbookTaskBulkDeleteResponse) => {
        handleSuccess(response)
        notify.success(t('success', { count: validTaskIds.length }))
        if (editPanelId && validTaskIds.includes(editPanelId)) closeRightPanel()
        onSuccess?.()
      }}
      onSubmit={handleSubmit}
      open={open}
      title={t('title', { count: initialTaskIds.length })}
      confirmText={t('submit', { count: initialTaskIds.length })}
      confirmIcon="delete"
      customErrors={
        tasks.length === 0
          ? [t('tasksNotAvailable', { count: initialTaskIds.length })]
          : isValidTaskIdsEmpty
          ? [t('noDeletableTasksWarning', { count: initialTaskIds.length })]
          : []
      }
      confirmDisabled={initialTaskIds.length === 0 || isValidTaskIdsEmpty}
    >
      {tasks.length > 0 && (
        <Box gap="small" margin={{ bottom: 'small' }}>
          {errorTaskIds.length > 0 && !isValidTaskIdsEmpty && (
            <Message data-testid="task-delete-modal-undeletable-tasks-warning" type="warning">
              {t('undeletableTasksWarning', { count: errorTaskIds.length })}
            </Message>
          )}
          <Text>
            {hasLinkedTasks
              ? t('descriptionLinkedTask', { count: initialTaskIds.length })
              : t('description', { count: initialTaskIds.length })}
          </Text>
          <Box>
            {sortedTaskList.map(
              task =>
                task && (
                  <TaskLineItemWrapper
                    key={task.id}
                    taskId={task.id}
                    errors={!!taskErrors[task.id] ? [t('unableToDelete')] : undefined}
                  />
                )
            )}
          </Box>
          {tasksWithMultiplePredsAndSuccs.length > 0 && (
            <Message
              type="warning"
              message={t('multiplePredecessorAndSuccessorWarning', {
                count: tasksWithMultiplePredsAndSuccs.length,
                taskIds: tasksWithMultiplePredsAndSuccs.map(t => `#${t.internal_id}`)
              })}
            />
          )}
        </Box>
      )}
    </FormModal>
  )
}
