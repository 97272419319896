import { useState } from 'react'

import { Box, IconButton, LoadingPanel, Message, RightPanel, Text } from '@cutover/react-ui'
import { CloseRightPanelAction, useRightPanelTypeState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { CreateOrLinkChangeRequestModal } from '../../pages/task-list-ng-connector/change-requests/create-or-link-change-request-modal'
import { useGetRunbookChangeRequests } from 'main/services/queries/use-change-requests'
import { ChangeRequest } from 'main/services/queries/types'

export const ChangeRequestsPanel = () => {
  const [{ runbookId }, { closeRightPanel }] = useRightPanelTypeState('runbook-change-requests')
  const { data: changeRequests, isLoading } = useGetRunbookChangeRequests(String(runbookId))

  return (
    <>
      {!isLoading && changeRequests ? (
        <ChangeRequestsPanelInner onClose={closeRightPanel} changeRequests={changeRequests} />
      ) : (
        <LoadingPanel />
      )}
    </>
  )
}

type ChangeRequestsPanelInnerProps = {
  onClose: CloseRightPanelAction
  changeRequests?: ChangeRequest[]
}

export const ChangeRequestsPanelInner = ({ onClose, changeRequests }: ChangeRequestsPanelInnerProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel' })
  const runbook = ActiveRunbookModel.useGet()
  const [isCreateOrLinkCRQModalOpen, setCreateOrLinkCRQModalOpen] = useState(false)

  const handleLinkCRQ = () => {
    setCreateOrLinkCRQModalOpen(true)
  }

  const handleCloseModal = () => {
    setCreateOrLinkCRQModalOpen(false)
  }

  return (
    <>
      <RightPanel
        title={t('title')}
        onClose={onClose}
        headerItems={[
          <IconButton
            key="link-button"
            primary
            label={t('linkButton')}
            icon="add"
            onClick={handleLinkCRQ}
            tipPlacement="top"
          />
        ]}
      >
        {runbook.change_requests_count === 0 ? (
          <Message type="error" icon="alert" message={t('linkErrorMessage')} />
        ) : (
          <Box>
            {changeRequests?.map(changeRequest => (
              <Box key={changeRequest.id}>
                <Text>{changeRequest.external_id}</Text>
              </Box>
            ))}
          </Box>
        )}
      </RightPanel>
      <CreateOrLinkChangeRequestModal
        open={isCreateOrLinkCRQModalOpen}
        runbookId={String(runbook.id)}
        changeRequestType="change_request"
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}
