import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { IconName, Select, SelectListItem } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNodeOption } from '../apps-types'

type SingleSelectNodeProps = AppComponentNodeProps & {
  type: string
  label: string
  name: string
  options: ContentNodeOption[]
  autocomplete: boolean
  selected: string
  action?: string
  icon?: IconName
  readOnly?: boolean
  error?: boolean
}

const DEBOUNCE_TIME_MILIS = 200
const MIN_CHARS = 1

export const SingleSelectFieldNode = ({ appId, resourceId, action, id, ...props }: SingleSelectNodeProps) => {
  const { componentProps, setState } = useComponentPropsAndState(appId, resourceId, id, props)
  const {
    label,
    name,
    options,
    autocomplete,
    icon,
    meta,
    selected: initialSelected,
    readOnly,
    error,
    type
  } = componentProps as unknown as SingleSelectNodeProps

  const postAppEvents = usePostAppEvents()
  const [resolvedOptions, setResolvedOptions] = useState(options)
  const [selectedOption, setSelectedOption] = useState<string>(initialSelected)
  const [previousOptions, setPreviousOptions] = useState<ContentNodeOption[]>()
  const [loading, setLoading] = useState(false)
  const node = { id, type, name, label }

  const handleValueChange = (value: string) => {
    setSelectedOption(value)
    setState({ name, value })
    window.dispatchEvent(new CustomEvent('selecteditem', { detail: { node, value } }))
    if (autocomplete) {
      setPreviousOptions(resolvedOptions)
    }
  }

  const handleKeyDown = debounce((event: any) => {
    const value = event.target?.value || ''
    if (!autocomplete || value.trim().length < MIN_CHARS) {
      return
    }
    setLoading(true)
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [
        {
          type: 'trigger',
          on: 'autocomplete',
          action,
          id,
          meta,
          value
        }
      ]
    }
    postAppEvents(payload)
    event.preventDefault()
  }, DEBOUNCE_TIME_MILIS)

  const handleBlur = () => {
    if (autocomplete) {
      setResolvedOptions(previousOptions || [])
    }
  }

  useEffect(() => {
    setResolvedOptions(options)
    if (autocomplete) {
      setLoading(false)
    }
  }, [componentProps])

  return (
    <Select
      readOnly={readOnly}
      options={resolvedOptions || []}
      filterKeys={['value', 'label']}
      label={label}
      icon={icon}
      value={selectedOption}
      optionToString={opt => opt.label}
      loading={loading}
      onKeyDown={event => handleKeyDown(event)}
      onChange={value => handleValueChange(value ?? '')}
      onBlur={handleBlur}
      debounceEmptyMessageTime={DEBOUNCE_TIME_MILIS * 2}
      renderOption={(option, renderProps) => <SelectListItem {...renderProps} label={option.label} />}
      clearable
      hasError={error}
    />
  )
}
