import { useMemo } from 'react'

import { DonutChartDatum } from '@cutover/react-ui'
import { DonutSegment, Values } from '../types'

export type DonutChartData = {
  name: string
  total?: string
  notes?: string
  errors?: string[]
  values: DonutChartDatum[]
}

// use this hook for client side rendering - minimizes re-renders (graph interactions when filtering, etc.)
export function useDonutChart<T extends Record<string, unknown> & { name: string; total: string; values: Values[] }>(
  data: T
): DonutChartData {
  return useMemo(() => getDonutChartData(data), [data])
}

// use directly for anything requiring server side rendering
export function getDonutChartData<
  T extends Record<string, unknown> & {
    name: string
    total: string
    notes?: string
    errors?: string[]
    values: Values[]
  }
>(data: T): DonutChartData {
  const { name, total, values, type, notes, errors } = data
  return {
    name,
    total: total?.toString(),
    notes: notes,
    errors: errors,
    values: mapValuesToDonutSegmentData(values || [], Number(total), typeof type === 'string' ? type : '')
  }
}

export const mapValuesToDonutSegmentData = (values: Values[], total: number, type: string) => {
  return updateLabelWithCounts(
    values.map(({ id, color, name, count, total }) => ({
      id,
      color,
      name: name?.toString(),
      label: name,
      value: count ?? total ?? 0
    })),
    total,
    type
  )
}

export const updateLabelWithCounts = (donutData: DonutSegment[], total: number, type: string) => {
  return donutData.map(segment => {
    const updatedSegment = { ...segment }
    const percentValue =
      (segment.value / total) * 100 < 1 && segment.value / total > 0
        ? '<1%'
        : `${Math.round((segment.value / total) * 100)}%`
    updatedSegment.label = `${segment.value}${type === 'lateness' ? ` (${percentValue})` : ''} ${segment.label}`
    return updatedSegment
  })
}
