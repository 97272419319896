/* eslint-disable react-hooks/rules-of-hooks */

import { SharedViewStateType } from 'main/recoil/shared/models/view/view'
import { ViewModelInterface } from './types'
import { handleUnknownKey, useEnsureStableArgs } from '../models/model-utils'
import {
  useCustomFieldRefreshUpdate,
  useCustomFieldRefreshValue,
  useCustomFieldRefreshValueCallback
} from '../hooks/recoil/shared-view'

type SharedViewModelActionHandlers = {
  'customFieldRefresh:update': ReturnType<typeof useCustomFieldRefreshUpdate>
}

export const SharedViewModel: Omit<
  ViewModelInterface<SharedViewStateType, SharedViewModelActionHandlers, ''>,
  'usePermission' | 'usePermissionCallback'
> = {
  useGet: <TKey extends keyof SharedViewStateType>(viewKey: TKey) => {
    const key = useEnsureStableArgs(viewKey)

    switch (key) {
      case 'customFieldRefresh':
        return useCustomFieldRefreshValue() as SharedViewStateType[TKey]
      default:
        return handleUnknownKey(viewKey, 'get')
    }
  },
  useGetCallback: <TKey extends keyof SharedViewStateType>(viewKey: TKey) => {
    const key = useEnsureStableArgs(viewKey)

    switch (key) {
      case 'customFieldRefresh':
        return useCustomFieldRefreshValueCallback() as () => Promise<SharedViewStateType[TKey]>
      default:
        return handleUnknownKey(viewKey, 'getCallback')
    }
  },
  useAction: <TAction extends keyof SharedViewModelActionHandlers>(viewAction: TAction) => {
    const action = useEnsureStableArgs(viewAction)

    switch (action) {
      case 'customFieldRefresh:update':
        return useCustomFieldRefreshUpdate() as SharedViewModelActionHandlers[TAction]
      default:
        return handleUnknownKey(viewAction, 'onAction')
    }
  }
}
