export const taskEditApplyTo = 'task_edit'
export const taskStartApplyTo = 'task_start'
export const taskEndApplyTo = 'task_end'
export const taskAddEditApplyTo = 'task_add_edit'
export const runbookAddEditApplyTo = 'runbook_add_edit'
export const runbookEditApplyTo = 'runbook_edit'
export const projectAddEditApplyTo = 'project_add_edit'
export const projectEditApplyTo = 'project_edit'
export const eventAddEditApplyTo = 'event_add_edit'
export const eventEditApplyTo = 'event_edit'
export const runbookPageApplyTo = 'runbook_page'
export const runbookEndApplyTo = 'runbook_end'
