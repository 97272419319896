import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { extend, keyBy } from 'lodash'
import { eventManager } from 'event-manager'
import { produce } from 'immer'

import {
  runbookVersionResponseState_INTERNAL,
  streamsFlattenedState,
  streamsInternalIdLookupState,
  streamsLookupState,
  streamsPermission,
  streamsPermittedState,
  streamsState,
  streamState,
  userStreamLookupState
} from 'main/recoil/runbook'
import { StreamChangedStream, StreamListStream } from 'main/services/queries/types'
import { setChangedTasks } from 'main/recoil/data-access'
import { filterSelector } from 'main/recoil/shared/filters'
import {
  RunbookResponse,
  RunbookStreamCreateResponse,
  RunbookStreamDestroyResponse,
  RunbookStreamUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { StreamModelType } from 'main/data-access/models'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { addUsersOrTeams } from './shared-updates'
import { addRunbookActionHandlerBreadcrumb } from 'main/components/support-and-analytics/sentry'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetStream: StreamModelType['useGet'] = (identifier: number) => {
  return useRecoilValue(streamState({ id: identifier }))
}

export const useGetStreamCallback: StreamModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (identifier: number) => {
        return await snapshot.getPromise(streamState({ id: identifier }))
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                   Get By                                   */
/* -------------------------------------------------------------------------- */

export const useGetStreamBy: StreamModelType['useGetBy'] = getBy => {
  useEnsureStableArgs(getBy)

  const internalIdLookup = useRecoilValue(streamsInternalIdLookupState)
  return internalIdLookup[getBy.internal_id]
}

export const useGetStreamByCallback: StreamModelType['useGetByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async getBy => {
        const internalIdLookup = await snapshot.getPromise(streamsInternalIdLookupState)
        return internalIdLookup[getBy.internal_id]
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const useGetAllStreams: StreamModelType['useGetAll'] = options => {
  useEnsureStableArgs(options)

  let streams: StreamListStream[]
  /* eslint-disable react-hooks/rules-of-hooks */
  switch (options?.scope) {
    case 'permitted':
      streams = useRecoilValue(streamsPermittedState)
      break
    case 'flattened':
      streams = useRecoilValue(streamsFlattenedState)
      break
    default:
      streams = useRecoilValue(streamsState)
      break
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return streams
}

export const useGetAllStreamsCallback: StreamModelType['useGetAllCallback'] = options => {
  const scope = options?.scope

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        let streams: StreamListStream[]
        switch (scope) {
          case 'permitted':
            streams = await snapshot.getPromise(streamsPermittedState)
            break
          case 'flattened':
            streams = await snapshot.getPromise(streamsFlattenedState)
            break
          default:
            streams = await snapshot.getPromise(streamsState)
            break
        }

        return streams
      },
    [scope]
  )
}

/* -------------------------------------------------------------------------- */
/*                                 Get all by                                 */
/* -------------------------------------------------------------------------- */

export const useGetAllStreamsBy: StreamModelType['useGetAllBy'] = getBy => {
  useEnsureStableArgs(getBy)

  if (getBy.userId) {
    /* eslint-disable react-hooks/rules-of-hooks */
    return useRecoilValue(userStreamLookupState)[getBy.userId]
  }
  return useGetAllStreams()
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useGetAllStreamsByCallback: StreamModelType['useGetAllByCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async getBy => {
        const userStreamLookup = await snapshot.getPromise(userStreamLookupState)
        return userStreamLookup[getBy.userId] || []
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const useGetStreamsLookup: StreamModelType['useGetLookup'] = () => {
  return useRecoilValue(streamsLookupState)
}

export const useGetStreamsLookupCallback: StreamModelType['useGetLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const streamsLookup = await snapshot.getPromise(streamsLookupState)
        return streamsLookup
      },
    []
  )

export const useGetStreamsLookupLoadable: StreamModelType['useGetLookupLoadable'] = () =>
  useRecoilValueLoadable(streamsLookupState)

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */

export const useCanStream: StreamModelType['useCan'] = permission => {
  useEnsureStableArgs(permission)

  return useRecoilValue(streamsPermission({ attribute: permission }))
}

/* -------------------------------------------------------------------------- */
/*                                     Action                                 */
/* -------------------------------------------------------------------------- */

export const useOnActionStream: StreamModelType['useOnAction'] = () => {
  const processStreamCreateResponse = useProcessStreamCreateResponse()
  const processStreamUpdateResponse = useProcessStreamUpdateResponse()
  const processStreamDeleteResponse = useProcessStreamDeleteResponse()

  return useCallback(
    (response: RunbookResponse) => {
      addRunbookActionHandlerBreadcrumb('stream', response)
      switch (response.meta.headers.request_method) {
        case 'create':
          return processStreamCreateResponse(response as RunbookStreamCreateResponse)
        case 'update':
          return processStreamUpdateResponse(response as RunbookStreamUpdateResponse)
        case 'destroy':
          return processStreamDeleteResponse(response as RunbookStreamDestroyResponse)
        default:
          return
      }
    },
    [processStreamCreateResponse, processStreamUpdateResponse, processStreamDeleteResponse]
  )
}

// NOTE: all the streams updates currently need to update the people panel through the even manager. Once that is
// no longer the case, we can transactionalize these updates.

const useProcessStreamCreateResponse = () =>
  useRecoilCallback(
    ({ snapshot, set }) =>
      async (data: RunbookStreamCreateResponse) => {
        const prevRunbookVersionResponse = await snapshot.getPromise(runbookVersionResponseState_INTERNAL)
        const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
          const newStreamListItem = data.meta.changed_streams.find(s => s.id === data.stream.id)
          if (newStreamListItem) {
            // Note: we are not adding data.stream directly as it uses the show serializer
            addStream(newStreamListItem, draftRunbookVersionResponse.meta.streams)
          }

          updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
          updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, 1)
        })

        set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

        updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
      },
    []
  )

const useProcessStreamUpdateResponse = () =>
  useRecoilTransaction_UNSTABLE(
    transactionInterface => (data: RunbookStreamUpdateResponse) => {
      const { get, set } = transactionInterface

      const prevRunbookVersionResponse = get(runbookVersionResponseState_INTERNAL)
      const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
      })
      const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
      const runbookComponentLookup = keyBy(runbookComponents, 'id')

      set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

      setChangedTasks(transactionInterface)({ changedTasks: data.meta.changed_tasks, runbookComponentLookup })
      addUsersOrTeams(transactionInterface)({ roleTypes: data.stream.role_types })

      updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
    },
    []
  )

const useProcessStreamDeleteResponse = () =>
  useRecoilTransaction_UNSTABLE(
    transactionInterface => async (data: RunbookStreamDestroyResponse) => {
      const { get, set } = transactionInterface
      const prevRunbookVersionResponse = get(runbookVersionResponseState_INTERNAL)
      const nextRunbookVersionResponse = produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        updateChangedStreams(data.meta.changed_streams, draftRunbookVersionResponse.meta.streams)
        removeDeletedStream(data.stream.id, draftRunbookVersionResponse.meta.streams)
        updateVersionStreamsCount(draftRunbookVersionResponse.runbook_version.streams_count, -1)
      })
      const runbookComponents = nextRunbookVersionResponse.meta.runbook_components
      const runbookComponentLookup = keyBy(runbookComponents, 'id')

      set(runbookVersionResponseState_INTERNAL, nextRunbookVersionResponse)

      setChangedTasks(transactionInterface)({
        changedTasks: data.meta.changed_tasks,
        runbookComponentLookup: runbookComponentLookup
      })

      // need to unfilter from a stream that no longer exists if necessary
      const streamFilterValue = get(filterSelector({ attribute: 'stream' })) as number[]
      if (streamFilterValue?.includes(data.stream.internal_id)) {
        set(
          filterSelector({ attribute: 'stream' }),
          streamFilterValue.filter(internalId => internalId !== data.stream.internal_id)
        )
      }

      updatePeoplePanel(nextRunbookVersionResponse.meta.streams)
    },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                Update utils                                */
/* -------------------------------------------------------------------------- */

const updatePeoplePanel = (streams: StreamListStream[]) => {
  // NOTE: we're using the event emitter here to communicate with people panel
  // specifically which is tightly reliant on external sources for updating data.
  // This is a temporary solution until we can refactor the people panel.
  eventManager.emit('runbook-streams-updated', {
    streams: streams.map(stream => {
      return { id: stream.id, name: stream.name }
    })
  })
}

// TODO: settings_substreams_inherit_color logic
const updateChangedStreams = (changedStreams: StreamChangedStream[], existingStreams: StreamListStream[]) => {
  changedStreams.forEach(changedStream => {
    // Loop through changed streams, if it exists in the master data (incl as a substream), update
    const index = existingStreams.findIndex(stream => stream.id === changedStream.id)
    if (index > -1) {
      // Not a substream
      extend(existingStreams[index], changedStream)
    } else {
      // Substream
      for (let i = 0; i < existingStreams.length; i++) {
        const children = existingStreams[i].children
        if (!children) continue
        const subStreamIndex = children.findIndex(subStream => subStream.id === changedStream.id)

        if (subStreamIndex > -1) {
          extend(existingStreams[i].children[subStreamIndex], changedStream)
          break
        }
      }
    }
  })
}

const removeDeletedStream = (deletedStreamId: number, existingStreams: StreamListStream[]) => {
  const deletedIndex = existingStreams.findIndex(stream => stream.id === deletedStreamId)

  if (deletedIndex > -1) {
    existingStreams.splice(deletedIndex, 1)
  } else {
    for (let i = 0; i < existingStreams.length; i++) {
      const children = existingStreams[i].children
      if (!children) continue
      const subStreamIndex = children.findIndex(subStream => subStream.id === deletedStreamId)

      if (subStreamIndex > -1) {
        existingStreams[i].children.splice(subStreamIndex, 1)
        break
      }
    }
  }
}

// TODO: fix typings with streams during refactor
const addStream = (stream: StreamChangedStream, existingStreams: StreamListStream[]) => {
  if (stream.parent_id) {
    const parentStream = existingStreams.find(st => st.id === stream.parent_id)

    if (!parentStream) {
      console.warn('Parent stream not found for stream with parent_id', stream)
      return
    }
    // @ts-ignore
    parentStream.children = parentStream.children || []
    // @ts-ignore
    parentStream.children.push(stream)
  } else {
    // @ts-ignore
    existingStreams.push(stream)
  }
}

const updateVersionStreamsCount = (property: number, count: number) => {
  property = Math.max(0, (property ?? 0) + count)
}
