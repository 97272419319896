// @ts-nocheck
import 'reflect-metadata'
import { injectable } from 'inversify'
import { observable, computed, reaction, action } from 'mobx'
import { container } from 'app/Config/IOC'
import { CustomFieldListPresenter } from './CustomFieldList/CustomFieldListPresenter'
import { CustomFieldPageHeadingPresenter } from './CustomFieldPageHeading/CustomFieldPageHeadingPresenter'
import { ConfirmMode, CustomFieldsViewPresenter } from './CustomFieldsViewPresenter'
import { CustomFieldFilterPresenter } from './CustomFieldFilter/CustomFieldFilterPresenter'
import { CustomFieldFormPresenter } from './CustomFieldForm/CustomFieldFormPresenter'
import { GenericAdminPagePresenter } from 'Shared/Presenters/GenericAdminPagePresenter'
import { Submitted } from 'Shared/Entities/Enums/Submitted'

@injectable()
export class CustomFieldsPagePresenter extends GenericAdminPagePresenter {
  public viewPresenter: CustomFieldsViewPresenter = container.get(CustomFieldsViewPresenter)

  @observable
  public addFormPresenter: CustomFieldFormPresenter

  @observable
  public editFormPresenter: CustomFieldFormPresenter

  @observable
  public listSectionPresenter: CustomFieldListPresenter = new CustomFieldListPresenter()

  @observable
  public headingSectionPresenter: CustomFieldPageHeadingPresenter = new CustomFieldPageHeadingPresenter()

  @observable
  public filterSectionPresenter: CustomFieldFilterPresenter = new CustomFieldFilterPresenter()

  @observable
  public editSubmittedState: Submitted = Submitted.Unsubmitted

  @action
  public setEditSubmittedState = (submitted: Submitted) => {
    this.editSubmittedState = submitted
  }

  private getAccountsByName = (): string[] => {
    return Object.values(this.viewPresenter.accountsVM)
  }

  private setupFormPresenters = () => {
    const accounts = this.getAccountsByName()
    this.addFormPresenter.withVisibilityAccounts(accounts)
    this.editFormPresenter.withVisibilityAccounts(accounts)
  }

  public setConfirmationOpen = (open: boolean) => {
    this.viewPresenter.setPageVMValue('confirmModalOpenState', open)
  }

  constructor() {
    super()

    this.addFormPresenter = new CustomFieldFormPresenter()
      .withViewModelMiddleware(this.viewPresenter.updateAddViewModel)
      .withViewModelOptionsMiddleware(this.viewPresenter.updateAddViewModelOptions)
      .withGenericCheckboxMiddleware(this.viewPresenter.updateAddViewModelWithGenericCheckboxAction)

    this.editFormPresenter = new CustomFieldFormPresenter()
      .withViewModelMiddleware(this.viewPresenter.updateEditViewModel)
      .withViewModelOptionsMiddleware(this.viewPresenter.updateEditViewModelOptions)
      .withGenericCheckboxMiddleware(this.viewPresenter.updateEditViewModelWithGenericCheckboxAction)

    this.setupReactions()
  }

  private setupReactions = () => {
    if (Object.keys(this.viewPresenter.accountsVM).length === 0) {
      reaction(
        () => this.viewPresenter.accountsVM,
        (accountsVm, reaction) => {
          this.setupFormPresenters()
          reaction.dispose()
        }
      )
    } else {
      this.setupFormPresenters()
    }
  }

  @computed
  public get archiveConfirmName(): string {
    if (!this.viewPresenter.editRecordVM) return ''
    return this.viewPresenter.editRecordVM.name
  }

  public launchAddSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFields:add:notification:message'))
  }

  public launchEditSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFields:edit:notification:message'))
  }

  public launchArchiveSuccessToaster = () => {
    this.launchSuccessToaster(this.language.get('customFields:archive:notification:message'))
  }

  @computed
  public get confirmMode(): ConfirmMode {
    return this.viewPresenter.confirmMode
  }

  @computed
  public get usedInIntegration(): boolean {
    return this.viewPresenter.usedInIntegration
  }
}
