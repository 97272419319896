import { Dispatch, SetStateAction, useMemo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FolderDeleteFolderPayload, FolderListFolder } from 'main/services/queries/types'
import { useFolderDelete } from 'main/services/queries/use-folders'
import { FolderSelectField, FormModal } from 'main/components/shared/form'

type FolderArchiveModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSuccess?: () => void
  onClose?: () => void
  folderId: number
  folderName: string
  hasRunbooks: boolean
  folders?: FolderListFolder[]
}

export const FolderArchiveModal = ({
  open,
  setOpen,
  onClose,
  onSuccess,
  folderId,
  folderName,
  hasRunbooks,
  folders
}: FolderArchiveModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'folder.folderArchiveModal' })
  const { mutateAsync, isLoading } = useFolderDelete(folderId)

  const { reset } = useForm<FolderDeleteFolderPayload>({
    resolver: yupResolver(
      yup.object({
        new_project_id: yup.number().when([], {
          is: () => hasRunbooks,
          then: schema => schema.required()
        })
      })
    )
  })

  const handleSubmit = async ({ new_project_id }: FolderDeleteFolderPayload) => {
    return await mutateAsync({ new_project_id: new_project_id || null })
  }

  const handleSuccess = () => {
    onSuccess?.()
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
    reset()
    onClose?.()
  }

  // Note: normally couldn't filter here since we still need the parent to display if any children
  // are in the list. But in this case, you can't archive parents that have children.
  // So folder options menu will never need to display the folder being deleted, as it cant be parent
  const folderOptions = useMemo(
    () => (!hasRunbooks ? [] : folders?.filter(folder => folder.id !== folderId)),
    [folderId, folders, hasRunbooks]
  )

  return (
    <FormModal
      preventAutoClose
      title={t('title')}
      open={open}
      confirmText={t('confirmText')}
      successMessage={t('success')}
      confirmIcon="trash-o"
      onClose={handleClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      loading={isLoading}
    >
      <Box gap="small" direction="column">
        <Text data-testid="archive-folder-warning">
          {t('warning')} <Text weight="bold">{folderName}?</Text>
          {hasRunbooks && <Text> {t('selectNewFolderInstruction')}</Text>}
        </Text>
        {hasRunbooks && folderOptions && (
          <FolderSelectField name="new_project_id" label={t('selectNewFolderLabel')} folders={folderOptions} />
        )}
      </Box>
    </FormModal>
  )
}
