import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Box, Form, IconButton, Message, px2rem, TextInput, useNotify } from '@cutover/react-ui'
import { isConnectionConfig, mergeSettings } from './integration-setting-form-helper'
import { IntegrationSettingFormType } from './integration-setting-types'
import { fetchIntegrationSettingWebhookAuthToken } from '../../../../services/queries/use-integration-settings'
import { IntegrationFormSettingFields } from '../shared-integration-components/integration-form-setting-fields'
import { IntegrationConnectionConfigCamel, useLanguage } from 'main/services/hooks'
import { IntegrationSetting } from 'main/services/queries/types'

export type IntegrationSettingFormProps = {
  integration: IntegrationSetting | IntegrationConnectionConfigCamel
  apiError?: string
  webhookAuthToken?: string
}

export const IntegrationSettingForm = ({ integration, apiError }: IntegrationSettingFormProps) => {
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'form' })
  const {
    formState: { errors },
    register
  } = useFormContext<IntegrationSettingFormType>()
  const mask: string = 'XXXXXXXXXX'
  const [webhookAuthTokenShown, setWebhookAuthTokenShown] = useState<boolean>(false)
  const [webhookAuthToken, setWebhookAuthToken] = useState<string>(mask)
  const notify = useNotify()

  const settings = mergeSettings(integration)

  const toggleWebhookAuthToken = async () => {
    if (webhookAuthToken === mask && !webhookAuthTokenShown && integration) {
      await fetchIntegrationSettingWebhookAuthToken(integration.id)
        .then(data => {
          setWebhookAuthToken(data)
        })
        .catch(() => {
          notify.error(t('fetchWebhookAUthToken.error'), { title: t('fetchWebhookAUthToken.errorTitle') })
        })
    }
    setWebhookAuthTokenShown(!webhookAuthTokenShown)
  }

  useEffect(() => {
    setWebhookAuthToken(mask)
    setWebhookAuthTokenShown(false)
  }, [integration])

  return (
    <Form>
      {apiError ? (
        <Box
          css={`
            margin-bottom: ${px2rem(26)};
          `}
        >
          <Message type="error" message={apiError} />
        </Box>
      ) : null}
      <TextInput
        {...register('name')}
        label={t('name')}
        required
        hasError={Boolean(errors['name'])}
        defaultValue={integration.name}
      />
      <TextInput
        {...register('imageUrl')}
        label={t('imageUrl')}
        required
        hasError={Boolean(errors['imageUrl'])}
        inlineError={errors['imageUrl']?.message}
        defaultValue={isConnectionConfig(integration) ? integration.imageUrl : integration.image_url}
      />
      <Box direction="column">
        <IntegrationFormSettingFields settings={settings} />
        {integration?.id && (
          <TextInput
            disabled
            readOnly
            label={t('webhookAuthTokenLabel')}
            tooltipText={t('webhookAuthTokenTooltip')}
            type={webhookAuthTokenShown ? 'text' : 'password'}
            value={webhookAuthToken}
            endComponent={
              <IconButton
                label={webhookAuthTokenShown ? t('webhookAuthTokenHideTooltip') : t('webhookAuthTokenShowTooltip')}
                icon={webhookAuthTokenShown ? 'visibility-hide' : 'visibility-show'}
                onClick={() => toggleWebhookAuthToken()}
                size="small"
              />
            }
          />
        )}
      </Box>
    </Form>
  )
}
