import { ReactNode, useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// add anything here to match console breadcrumbs to filter them out.
const FILTER_CONSOLE_BREADCRUMB_MATCH = /React\.createFactory|webpack-dev-server/

import { ConfigModel } from 'main/data-access'

// This must be done outside of the app render code for the tracing to work with performance
// monitoring.
if (!Sentry.isInitialized()) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.CUTOVER_REACT_SENTRY_DSN || undefined,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
      // Need to change the header of the root index get on the server side for this to work
      // https://docs.sentry.io/platforms/javascript/profiling/browser-profiling/#step-2-add-document-policy-js-profiling-header
      // Sentry.browserProfilingIntegration()
    ],
    // Sentry.Replay configuration
    // Capture Replay for 10% of all sessions,
    replaysSessionSampleRate: 0.1,
    // Plus, capture Replays for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,

    // Sentry.BrowserTracing configuration
    // Send all transactions to sentry
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/.*cutover\.(com|cloud)/, /localhost/],
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console' && FILTER_CONSOLE_BREADCRUMB_MATCH.test(breadcrumb.message ?? '')) {
        return null
      }
      return breadcrumb
    }
  })
}

export const SentryWrapper = ({ children }: { children: ReactNode }) => {
  const { version, enabledFeatures } = ConfigModel.useGet()

  useEffect(() => {
    Sentry.setTags({
      version: version.label,
      // @ts-ignore exists in browsers that support navigator
      'device.memory': navigator?.deviceMemory || 'unknown',
      'device.cpu_count': navigator?.hardwareConcurrency || 'unknown',
      // @ts-ignore exists in browsers that support navigator
      'network.connection_type': navigator?.connection?.effectiveType || 'unknown'
    })
    Sentry.setContext('Enabled features', Object.fromEntries(enabledFeatures.map(f => [f, true])))
  }, [])

  return <>{children}</>
}
