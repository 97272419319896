import { memo, MouseEvent, MouseEventHandler, ReactNode, useCallback } from 'react'
import { Bar, BarExtendedDatum, BarMouseEventHandler, BarSvgProps, ResponsiveBar } from '@nivo/bar'
import { LegendAnchor, LegendDirection } from '@nivo/legends'
import { useTooltip } from '@nivo/tooltip'
import { v4 } from 'uuid'

import { LegacyFlex as Flex } from '../../layout'
import { px2rem } from '../../utils'
import { ChartAxesLayer, ChartBackgroundRowsLayer, createChartYAxisLabel } from '../shared'
import { nivoTheme } from '../shared/nivo-theme'

export type BarChartData = {
  category: {
    name: string
    value: string
  }
  values: {
    group: string
    value: any
  }[]
}

export type BarChartProps = {
  data: BarChartData[]
  colors?: Record<string, string> | ((props: { id: string }) => string) | ((bar: BarExtendedDatum) => string)
  height?: number
  width?: number
  padding?: string | number | number[]
  legend?: {
    position: LegendAnchor
    direction: LegendDirection
  }
  xAxis: {
    id: string // or name?
    label?: string
  }
  yAxis: {
    id: string // or name?
    label?: string
  }
  stacked?: boolean
  showTotals?: boolean
  layers?: [(props: any) => ReactNode]
  hasBackground?: boolean
  hasAxes?: boolean
  onSelect?: BarMouseEventHandler
  labelFontSize?: number
  labelTextColor?: string
}

const BAR_MAX_WIDTH = 200 // should be configurable, many of analytics likely do not need or want this

export function BarChart(props: BarChartProps) {
  const {
    data: inputData,
    colors,
    height = 300,
    width,
    padding: initPadding = '30px 0 50px 31px',
    legend,
    xAxis,
    yAxis,
    stacked,
    showTotals,
    layers = [],
    hasBackground = true,
    hasAxes = true,
    onSelect,
    labelFontSize,
    labelTextColor
  } = props

  const { keys, indexBy, data } = transformInputData({ data: inputData, xAxis })
  const chartColors = colors
    ? typeof colors === 'object'
      ? ({ id }: { id: string }) => {
          return colors?.[id]
        }
      : colors
    : undefined

  const padding = getPaddingString(initPadding)
  // We need to know chart dimensions if we want to render Bar nivo component instead of ResponsiveBar.
  // Bar component used for SSR as ResponsiveBar doesn't work for this case.
  const { chartHeight, chartWidth } = getChartDimensions(height, width, padding)

  const BarComponent = createBarComponent(props)

  const commonProps: BarSvgProps = {
    data,
    theme: {
      ...nivoTheme,
      ...(labelFontSize ? { fontSize: labelFontSize } : {}),
      ...(labelTextColor ? { textColor: labelTextColor } : {})
    },
    layers: [
      ...(hasBackground ? [ChartBackgroundRowsLayer] : []),
      ...(yAxis.label ? [createChartYAxisLabel(yAxis.label)] : []),
      ...(hasAxes ? [ChartAxesLayer] : []),
      'axes',
      'bars',
      ...(showTotals ? [TotalLabels] : []),
      'markers',
      'legends',
      ...layers
    ],
    barComponent: BarComponent,
    keys,
    indexBy,
    padding: 0.4,
    colors: chartColors || { scheme: 'paired' },
    enableLabel: false,
    enableGridY: false,
    groupMode: stacked ? 'stacked' : 'grouped',
    axisLeft: hasAxes
      ? {
          tickSize: 0,
          tickPadding: 10,
          tickValues: 5 // how many ticks on the left
        }
      : null,
    axisBottom: {
      tickSize: 0,
      ...(xAxis.label
        ? {
            tickSize: 0,
            legend: xAxis.label,
            legendPosition: 'middle',
            legendOffset: 32
          }
        : {})
    },
    legends: legend
      ? [
          {
            anchor: legend.position,
            dataFrom: 'keys',
            direction: legend.direction,
            itemHeight: 20,
            itemWidth: 100,
            symbolShape: 'circle',
            symbolSize: 12,
            translateY: getLegendPosition({ legend, xAxis })
          }
        ]
      : [],
    onClick: (data, event) => {
      onSelect?.(data, event)
    }
  }

  return (
    <Flex
      flexDirection="column"
      height={px2rem(height)}
      // positioning relative to the chart svg, must stay pixel based
      css={`
        padding: ${padding ?? '30px 0 50px 31px'};
        svg {
          overflow: visible;
        }
      `}
    >
      {chartWidth && chartHeight ? (
        <Bar {...commonProps} width={chartWidth} height={chartHeight} />
      ) : (
        <ResponsiveBar {...commonProps} />
      )}
    </Flex>
  )
}

// need to consider what would make sense if the legend wasn't positioned at the bottom
const getLegendPosition = ({ legend, xAxis }: Required<Pick<BarChartProps, 'legend' | 'xAxis'>>) => {
  if (legend.direction === 'column') {
    return xAxis.label ? 85 : 70
  } else {
    return xAxis.label ? 65 : 50
  }
}

const createBarComponent = (componentProps: BarChartProps) => (props: any) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip()

  let isTopSegment = true

  if (componentProps.stacked) {
    const currentComponentValues =
      componentProps.data.find(d => d.category.value === props.data.indexValue)?.values ?? []

    const groups = Array.from(new Set(componentProps?.data?.flatMap(d => d.values).map(v => v.group))).reverse()
    let topValueGroup = currentComponentValues?.length > 0 ? currentComponentValues[0].group : undefined

    for (const group of groups) {
      const component = currentComponentValues.find(v => v.group === group)
      if (component && component.value > 0) {
        topValueGroup = component.group
        break
      }
    }

    isTopSegment = props.data.id === topValueGroup
  }

  const { x, y, height, width, color } = props
  const barWidth = width >= BAR_MAX_WIDTH ? BAR_MAX_WIDTH : width
  const rectProps = {
    x: x + (width - barWidth) / 2,
    y,
    height,
    width: barWidth,
    fill: color,
    cursor: componentProps.onSelect ? 'pointer' : undefined
  }

  const handleMouseEnter = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      props.onMouseEnter?.(props.data, event)
      showTooltipFromEvent(
        props.tooltip({
          data: props.data,
          color: props.color,
          getTooltipLabel: ({ data }: { data: any }) => `${data.id} - ${data.value}`
        }),
        event
      )
    },
    [props.data, props.onMouseEnter, showTooltipFromEvent, props.tooltip]
  )
  const handleMouseLeave = useCallback(
    (event: MouseEvent<SVGRectElement>) => {
      props.onMouseLeave?.(props.data, event)
      hideTooltip()
    },
    [props.data, hideTooltip, props.onMouseLeave]
  )

  const handleClick = useCallback(
    (e: any) => {
      componentProps.onSelect?.(props.data, e)
    },
    [componentProps.data, componentProps.onSelect]
  )

  return (
    <BarComponent
      {...rectProps}
      isTopSegment={isTopSegment}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    />
  )
}

type BarComponentProps = {
  isTopSegment: boolean
  onMouseEnter: MouseEventHandler<any>
  onMouseLeave: MouseEventHandler<any>
  onClick: MouseEventHandler<any>
  x: number
  y: number
  height: number
  width: number
  fill: string
  cursor: string | undefined
}

// because we're using a random id generator in this component, we should memoize it so it doesn't
// re-render every time its parent component re-renders (e.g. mousing when over a bar and showing a tooltip)
const BarComponent = memo(({ isTopSegment, onMouseEnter, onMouseLeave, onClick, ...rectProps }: BarComponentProps) => {
  if (!isTopSegment) {
    return <rect onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rectProps}></rect>
  } else {
    // id must be unique for each clip path on entire page of bar charts so we cannot easily create an id
    // based only on bar's data
    const clipId = v4()
    return (
      <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        <clipPath id={clipId}>
          <rect {...rectProps} height={rectProps.height + 5} rx="5" ry="5" />
        </clipPath>

        <g clipPath={`url(#${clipId})`}>
          <rect {...rectProps}></rect>
        </g>
      </g>
    )
  }
})

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

/** Labels with the total above each bar */
const TotalLabels = (props: any) => {
  const groupMode: 'stacked' | 'grouped' = props.groupMode
  const bars: any[] = props.bars
  const yScale: any = props.yScale

  // space between top of stacked bars and total label
  const labelMargin = 20

  return bars.map(({ data: { data, indexValue, id }, x, width }, i) => {
    // for stacked, filter out whatever the indexBy value is and combine the rest
    // for grouped just need the actual data value
    const total =
      groupMode === 'stacked'
        ? Object.keys(data)
            .filter(key => key !== props.indexBy)
            .reduce((a, key) => a + data[key], 0)
        : data[id]

    return total ? (
      <g transform={`translate(${x}, ${yScale(total) - labelMargin})`} key={`${indexValue}-${i}`}>
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          style={{
            fontFamily: nivoTheme?.fontFamily,
            fontSize: `${nivoTheme?.fontSize}px`,
            fill: nivoTheme?.textColor
          }}
        >
          {total}
        </text>
      </g>
    ) : (
      <text key={`${indexValue}-${i}`} />
    )
  })
}

const transformInputData = (props: {
  data: BarChartData[]
  xAxis: BarChartProps['xAxis']
}): { data: Record<string, unknown>[]; keys: string[]; indexBy: string } => {
  const keys = new Set(props.data.flatMap(d => d.values).map(value => value.group))
  const indexBy = props.xAxis.id
  const transformedData = props.data.map(d => {
    const item: Record<string, unknown> = {
      [indexBy]: d.category.value
    }

    d.values.forEach(val => {
      item[val.group] = val.value
    })

    return item
  })

  return {
    data: transformedData,
    keys: Array.from(keys),
    indexBy
  }
}

const getPaddingString = (padding: number | number[] | string) => {
  if (typeof padding === 'string') {
    return padding
  } else if (typeof padding === 'number') {
    return px2rem(padding)
  }

  return px2rem(...padding)
}

const getChartDimensions = (height: number, width: number | undefined, padding: string) => {
  if (width) {
    const paddings = padding.split(' ')

    let indexes = [0, 1, 2, 3]

    if (paddings.length === 1) {
      indexes = [0, 0, 0, 0]
    } else if (paddings.length === 2) {
      indexes = [0, 1, 0, 1]
    } else if (paddings.length === 3) {
      indexes = [0, 1, 2, 1]
    }

    const paddingTop = Number.parseInt(paddings[indexes[0]])
    const paddingRight = Number.parseInt(paddings[indexes[1]])
    const paddingBottom = Number.parseInt(paddings[indexes[2]])
    const paddingLeft = Number.parseInt(paddings[indexes[3]])

    const chartHeight = height - paddingTop - paddingBottom
    const chartWidth = width - paddingRight - paddingLeft
    return { chartHeight, chartWidth }
  }

  return { chartHeight: null, chartWidth: null }
}
