import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useMount, useUnmount } from 'react-use'
import { eventManager } from 'event-manager'
import { Heading } from 'grommet'
import queryString from 'query-string'
import { InfiniteQueryObserverResult } from 'react-query'
import { css } from 'styled-components'

import { Box, IconButton, LoadingPanel, media, NoResourceFound, PlainTextButton, Text } from '@cutover/react-ui'
import { ActivitiesResponse, ActivityConfig, ActivityVM, useActivities } from 'main/services/queries/use-activities'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { useSetAppsTimezone } from 'main/recoil/data-access'
import { ConfigModel, CurrentUserModel, RunbookViewModel } from 'main/data-access'
import { RolesUpdatedResponse } from 'main/services/api/data-providers/user/user-channel-response-types'
import { Activities } from './activities'
import { ActivityFilterPanel } from './filter/activity-filter-panel'
import { ActivityFeedInput } from './input/activity-feed-input'
import { getCreateActivityPermission, getUpdateActivityPermission } from './activity/activity-helper'

export type ActivityFeedProps = {
  currentUserId?: number
  timezone?: string
  disableCommentField?: boolean
  filterTaskList?: (internalIdsSearchParam: string | undefined) => void
}

export const ActivityFeed = ({ currentUserId, timezone, disableCommentField, filterTaskList }: ActivityFeedProps) => {
  const { runbookId } = useParams()
  const { t } = useLanguage('activities')
  const selectedTimezone = RunbookViewModel.useGet('selectedTimezone')
  const userId = CurrentUserModel.useId() ?? currentUserId
  const { allowAttachments } = ConfigModel.useGet()
  const [searchParams] = useSearchParams()
  const setTimezone = useSetAppsTimezone()
  const [filters, setFilters] = useState(getFilters(searchParams.toString()))
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [canUpdate, setCanUpdate] = useState<boolean>(false)
  const [activityConfig, setActivityConfig] = useState<ActivityConfig[] | undefined>(undefined)
  const initialFilterKeys = ['runbook.commented', 'task.commented']
  const [selectedActivityTypeFilters, setSelectedActivityTypeFilters] = useState<string[]>(initialFilterKeys)
  const keys = (filters.key as string[]) || []
  const { listen } = useUserWebsocket()

  const appliedFilterCount = keys.filter(key => key !== 'run.finished').length + Number(!!filters.featured)
  const { isLoading, data, isError, fetchNextPage } = useActivities(
    parseInt(runbookId || '0'),
    { keys, featured: !!filters.featured },
    lastPage => lastPage?.activities[lastPage.activities.length - 1]?.id
  )

  useEffect(() => {
    if (!activityConfig && data) {
      setActivityConfig(data?.pages[0]?.meta?.activity_config)
      setCanUpdate(getUpdateActivityPermission(data, userId))
    }
  }, [activityConfig, data])

  useEffect(() => {
    updateTimezone({ timezone: selectedTimezone ?? timezone ?? '' })
  }, [timezone, selectedTimezone])

  useMount(() => {
    eventManager.on('update-timezone', updateTimezone)
  })

  useUnmount(() => {
    eventManager.off('update-timezone', updateTimezone)
  })

  useEffectOnce(() => {
    const handleUserChannelResponse = (data: RolesUpdatedResponse) => {
      if (data?.meta?.headers?.request_method === 'roles_updated') {
        setCanUpdate(getCreateActivityPermission(data, userId))
      }
    }

    listen(data => handleUserChannelResponse(data as RolesUpdatedResponse))
  })

  const updateTimezone = useCallback(({ timezone }: { timezone: string }) => {
    setTimezone(timezone)
  }, [])

  const onFilterChange = (filters: string[]) => {
    const filterParamsString = getFilterParamsString(filters)
    setFilters(getFilters(encodeURI(filterParamsString)))
  }

  const getFilterParamsString = (filters: string[] | string[]) => {
    const filterParams = filters.map(filter => {
      if (filter === 'featured') return '&featured=true'
      return `&key[]=${filter}`
    })
    return filterParams.join('')
  }

  function getFilters(query: string) {
    return queryString.parse(query, { arrayFormat: 'bracket' })
  }
  const includeLiveActivityInFeed = (item: ActivityVM) => {
    const hasKey = keys.length && keys.includes(item.key)
    const isFeatured = !!filters.featured && item.featured
    const noKeysNoFeatured = !keys.length && !filters.featured

    return hasKey || isFeatured || noKeysNoFeatured
  }

  const items = data?.pages
    .flatMap(x => x?.activities)
    .filter(item => {
      return includeLiveActivityInFeed(item)
    })

  const badgeProp = useMemo(() => {
    if (appliedFilterCount === 0) {
      return undefined
    }
    return {
      type: 'dark' as const,
      label: appliedFilterCount,
      'data-testid': 'runbooks-filter-panel-toggle-badge'
    }
  }, [appliedFilterCount])

  return (
    <Box height="100%" direction="column">
      <Box
        id="header"
        direction="row"
        width="100%"
        justify="between"
        align="center"
        gap="small"
        height="32px"
        margin={{ bottom: '12px' }}
      >
        <Heading
          css={`
            ${media.sm(
              css`
                margin-left: 20px;
              `
            )}
          `}
          style={{ fontSize: '18px' }}
          level={3}
        >
          Activity Feed
        </Heading>
        <Box direction="row" align="center" gap="small" pad={{ right: 'large' }}>
          {selectedActivityTypeFilters.length > 0 && (
            <PlainTextButton
              onClick={() => setSelectedActivityTypeFilters([])}
              css={{ fontSize: '13px', marginBottom: '4px' }}
              data-cy="clear-all-filters"
            >
              {t('filters.clearAll')}
            </PlainTextButton>
          )}
          <IconButton
            icon="filter"
            data-testid="activity-type-filter-button"
            label={t('activities:feed:typeFilter')}
            onClick={() => setShowFilterPanel(prev => !prev)}
            isActive={showFilterPanel}
            badge={badgeProp}
            size="medium"
            tipPlacement="bottom"
          />
        </Box>
      </Box>
      <ActivityFilterPanel
        showFilterPanel={showFilterPanel}
        onFilterChange={onFilterChange}
        activityConfig={activityConfig}
        selectedActivityTypeFilters={selectedActivityTypeFilters}
        setSelectedActivityTypeFilters={setSelectedActivityTypeFilters}
      />
      <Box
        css={`
          position: relative;
          flex: 1;
        `}
      >
        {isLoading && <LoadingPanel />}
        {data && (
          <Activities<InfiniteQueryObserverResult<ActivitiesResponse, Error>>
            activities={items?.reverse()}
            activityConfig={activityConfig}
            getNextPage={fetchNextPage}
            canUpdate={canUpdate}
            hasNextPage={data?.pages[data?.pages.length - 1]?.meta.pagination.has_more}
            totalSize={data?.pages[0]?.meta.pagination.total_size}
            emptyRender={<NoResourceFound clearAllFilters={() => setSelectedActivityTypeFilters([])} context="item" />}
            filterTaskList={filterTaskList}
          />
        )}
      </Box>
      {isError && <Text>{t('feed.error')}</Text>}
      <ActivityFeedInput
        allowAttachments={allowAttachments}
        runbookId={runbookId as string}
        disabled={disableCommentField}
      />
    </Box>
  )
}
