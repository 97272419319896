import parse from 'html-react-parser'

import { Text, useNotify } from '@cutover/react-ui'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'
import { useCreateNewRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { FormModal } from 'main/components/shared/form'
import { RunbookVersionCreateResponse } from 'main/services/api/data-providers/runbook-types'

// Note: This currently only opens for rejected reviews. In future could be used for approved if needed
export const ViewReviewModal = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useLanguage('runbook')
  const runbookId = ActiveRunbookModel.useId()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const notify = useNotify()
  const { mutateAsync: createVersion, isLoading } = useCreateNewRunbookVersion(runbookId)
  const processRunbookVersionCreateResponse = ActiveRunbookVersionModel.useOnAction('create')
  const { reviews } = ActiveRunbookVersionModel.useGet()
  const reviewerName = reviews?.[0]?.reviewer.name
  const reviewComment = reviews?.[0]?.comment

  const handleSubmit = async () => {
    return await createVersion({ base_version_id: runbookVersionId })
  }

  const handleSuccess = (data: RunbookVersionCreateResponse) => {
    processRunbookVersionCreateResponse(data)
    onClose?.()
    notify.success(t('viewReviewModal.successText'))
  }

  return (
    <FormModal
      open
      formElementWrapper={false}
      title={t('viewReviewModal.title')}
      confirmIcon="chevron-right"
      confirmText={t('viewReviewModal.confirmText')}
      loading={isLoading}
      loadingText={t('viewReviewModal.confirmingText')}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onClose={onClose}
    >
      <Text css="margin-bottom: 8px;">
        {parse(t('viewReviewModal.description', { comment: reviewComment, reviewer: reviewerName }))}
      </Text>
    </FormModal>
  )
}
