import { useState } from 'react'
import * as yup from 'yup'

import { toSnakeCase } from '@cutover/api'
import { IntegrationConnectionForm } from './integration-connection-form'
import { buildValidationSchema } from './integrations/integration-setting-form-helper'
import { IntegrationConnectionFormType } from './integrations/integration-setting-types'
import { FormModal } from 'main/components/shared/form'
import { IntegrationConnectionConfigCamel, useLanguage } from 'main/services/hooks'
import { useIntegrationSettingCreate } from 'main/services/queries/use-integration-settings'
import { ConfigModel } from 'main/data-access'

type NewIntegrationConnectionModalType = {
  open: boolean
  onClose: () => void
}

export const NewIntegrationConnectionModal = ({ open, onClose }: NewIntegrationConnectionModalType) => {
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'newIntegrationConnectionModal' })
  const { integrations } = ConfigModel.useGet()

  const customIntegration = integrations?.find(
    integration => integration.klass === 'Integrations::PayloadTransformer::PayloadTransformer'
  )

  const initialValidationSchema = customIntegration ? buildValidationSchema(customIntegration, t) : yup.object()

  const [validationSchema, setValidationSchema] = useState(initialValidationSchema)
  const mutation = useIntegrationSettingCreate()

  const buildAndSetValidationSchema = (integrationConnection: IntegrationConnectionConfigCamel) => {
    const schema = buildValidationSchema(integrationConnection, t)
    setValidationSchema(schema)
  }

  const defaultValues = {
    integrationType: 'custom',
    name: customIntegration?.name,
    imageUrl: customIntegration?.imageUrl,
    integration: customIntegration?.klass
  }

  const onSubmit = async (data: IntegrationConnectionFormType) => {
    return mutation.mutateAsync(toSnakeCase(data) as any)
  }

  return (
    <FormModal<any> // TODO: remove 'any' from here, infer type from validation schema
      title={t('heading')}
      schema={validationSchema}
      defaultValues={defaultValues}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      confirmText={t('confirmText')}
      successMessage={t('newConnection.success')}
    >
      <IntegrationConnectionForm
        customIntegration={customIntegration}
        integrations={integrations}
        onIntegrationChange={buildAndSetValidationSchema}
      />
    </FormModal>
  )
}
