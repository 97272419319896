import { forwardRef, MouseEvent, ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

import { themeColor } from '../../theme'
import { Icon } from '../../icon'
import { Box } from '../../layout'
import { Menu, MenuListItem } from '../../menu'
import { IconButton } from '../../button'
import { Text } from '../../typography'
import { ListItemProps } from './list-item'
import { ListItemContainer, ListItemExpandableContent } from './list-item-box'

// NOTE: this is only separated out from the list item compontent because of circular dependency issues, probably
// around the list item importing the menu list item and the menu list item importing the list item.

type ListItemInnerProps = ListItemProps & { sortHandle?: ReactNode }

export const ListItemInner = forwardRef<HTMLElement, ListItemInnerProps>(
  (
    {
      hasNext,
      expandable = false,
      expandableContent,
      open: controlledOpen,
      initiallyOpen = false,
      startComponent,
      menuOptions,
      onClickRemove,
      removeTooltip,
      removeTooltipPlacement,
      onClick,
      onMouseDown,
      selected,
      disabled,
      active,
      className,
      level = 1,
      border,
      title,
      titleSuffix,
      suffixColor,
      subTitle,
      width,
      tabIndex,
      a11yTitle,
      prominence,
      'data-testid': dataTestId,
      role,
      hasError,
      sortHandle,
      hasCheckboxExpandToggle,
      size,
      ...props
    },
    ref
  ) => {
    const [isExpandedState, setIsExpandedState] = useState(initiallyOpen)
    const hasEndComponents = Array.isArray(props.endComponents) ? props.endComponents.length > 0 : !!props.endComponents
    const isSortable = !!sortHandle
    const isExpanded = controlledOpen ?? isExpandedState
    const isControlled = controlledOpen !== undefined

    const endComponents = hasEndComponents ? (
      !Array.isArray(props.endComponents) ? (
        <Box margin={{ right: size == 'small' ? '4px' : '6px' }}>{props.endComponents}</Box>
      ) : (
        <>
          {/* @ts-ignore */}
          {props.endComponents?.map((endComponent, i) => {
            if (endComponent) {
              return (
                <Box key={i} margin={{ right: size == 'small' ? '4px' : '6px' }}>
                  {endComponent}
                </Box>
              )
            }
          })}
        </>
      )
    ) : null

    const handleClick = (e: MouseEvent) => {
      // Prevent click action if text is selected (to allow copying)
      const selectedText = window.getSelection()?.toString()
      if (selectedText) return

      // Note: We only want the row click action to open the expandableContent if there is no custom onClick defined
      if (onClick) {
        onClick(e)
      } else if (expandable && !isControlled) {
        setIsExpandedState(!isExpandedState)
      }
    }

    const handleExpandToggle = (e: MouseEvent) => {
      if (isControlled) return
      e.stopPropagation()
      setIsExpandedState(!isExpandedState)
    }

    const expandToggle = expandable ? (
      <Box
        align="center"
        role="button"
        a11yTitle="Expand toggle"
        onClick={handleExpandToggle}
        css={`
          margin-right: ${startComponent ? '4px' : '-2px'};
          &:hover svg {
            fill: ${themeColor('text')};
          }
        `}
      >
        <ExpandCollapseIcon $isOpen={isExpanded} color={isExpanded ? 'text' : 'text-light'} />
      </Box>
    ) : undefined

    const removeTooltipText = (removeTooltip ?? `Remove ${a11yTitle ?? title ?? ''}`).trim()
    const showSubtitle = !!subTitle && size !== 'small'
    // Note: Title is medium bold if forced via prominence=high OR if a subtitle OR expandableConent exists (to differentiate)
    // Similarly, title color is 'text-light' unless subtitle exists or prominence=high
    const boldTitle = selected || prominence === 'high' || showSubtitle || !!expandableContent
    const titleColor = hasError ? 'error' : selected ? 'primary' : boldTitle ? 'text' : 'text-light'
    const subTitleColor = hasError ? 'error' : 'text-light'
    const baseSize = size === 'large' ? 40 : size === 'medium' ? 32 : 24
    const isClickable = !disabled && (!!onClick || expandable)

    return (
      <ListItemContainer
        ref={ref as any} // can be whatever type the list item is -- div, button, link
        data-testid={dataTestId}
        role={role}
        aria-disabled={disabled}
        tabIndex={tabIndex}
        onClick={isClickable ? handleClick : undefined}
        onMouseDown={onMouseDown}
        className={className}
        expandable={expandable}
        size={size}
        selected={selected}
        active={active}
        border={border}
        width={width}
        a11yTitle={a11yTitle}
        level={level}
        css={`
          background-color: ${selected ? themeColor('primary-bg') : active ? themeColor('bg-1-alpha') : undefined};
          opacity: ${disabled || prominence === 'low' ? 0.5 : 1};
          pointer-events: ${disabled ? 'none' : 'auto'};
          cursor: ${isClickable ? 'pointer' : undefined};
          div:last-child {
            margin-right: 0;
          }
          &:hover {
            background-color: ${hasError
              ? themeColor('error-bg')
              : isClickable && !selected
              ? themeColor('bg-1-alpha')
              : undefined};
            .circular-motion {
              background-color: ${themeColor('bg-1')};
              width: 15px;
            }
          }
        `}
      >
        <Box direction="row" width="100%" align="center">
          {isSortable && sortHandle}
          {expandable && expandToggle}
          {startComponent && (
            <StartComponentBox flex={false} baseSize={baseSize} hasCheckboxExpandToggle={hasCheckboxExpandToggle}>
              {startComponent}
            </StartComponentBox>
          )}

          <Box css="flex: 1;" pad={{ horizontal: '6px' }} alignSelf="center" className="text-content-wrap">
            <Text
              truncate="tip"
              tip={title}
              tag="div"
              css={`
                line-height: ${showSubtitle && size === 'medium' ? '18px' : undefined};
              `}
            >
              <Text weight={boldTitle ? 500 : 400} color={titleColor} css="line-height: inherit;">
                {title}
              </Text>
              {titleSuffix && (
                <Text color={suffixColor ?? subTitleColor} css="line-height: inherit; padding-left: 8px;">
                  {titleSuffix}
                </Text>
              )}
            </Text>
            {showSubtitle && (
              <Text
                truncate
                tag="div"
                size="13px"
                color={subTitleColor}
                css={`
                  line-height: ${showSubtitle && size === 'medium' ? '14px' : undefined};
                `}
              >
                {subTitle}
              </Text>
            )}
          </Box>
          {endComponents}
          {menuOptions && menuOptions.length && (
            <Menu
              align="end"
              onClick={e => e.stopPropagation()}
              trigger={
                <IconButton
                  icon="more-vertical"
                  disabled={disabled}
                  disableTooltip //for now until it stops wrapping in a span
                  label="More options"
                  size={size}
                  onClick={e => e.stopPropagation()}
                />
              }
            >
              {menuOptions.map((option, i) => {
                return (
                  <MenuListItem
                    key={`option-${i}`}
                    label={option.label}
                    icon={option.icon}
                    value={option.label}
                    onClick={option.onClick}
                  />
                )
              })}
            </Menu>
          )}
          {onClickRemove && (
            <IconButton
              icon="close"
              onClick={event => {
                event.stopPropagation()
                onClickRemove()
              }}
              disabled={disabled}
              label={removeTooltipText}
              tipPlacement={removeTooltipPlacement}
              size={size}
            />
          )}

          {hasNext && <Icon icon="chevron-right" color="text-light" />}
        </Box>
        {isExpanded && expandableContent && (
          <ListItemExpandableContent level={level} size={size} hasStartComponent={!!startComponent}>
            {expandableContent}
          </ListItemExpandableContent>
        )}
      </ListItemContainer>
    )
  }
)

const StartComponentBox = styled(Box).attrs(
  ({ baseSize, hasCheckboxExpandToggle }: { baseSize: number; hasCheckboxExpandToggle?: boolean }) => ({
    width: (hasCheckboxExpandToggle ? 26 + baseSize : baseSize) + 'px',
    height: baseSize + 'px',
    align: 'center',
    justify: 'center',
    className: 'list-item-left-component'
  })
)<{ baseSize: number; hasCheckboxExpandToggle?: boolean }>``

type ExpandCollapseIconProps = {
  $isOpen?: boolean
}

const ExpandCollapseIcon = styled(Icon).attrs(() => ({
  icon: 'caret-right'
}))<ExpandCollapseIconProps>`
  transition: transform 200ms ease;
  transform: rotate(${props => (props.$isOpen ? '90deg' : '0deg')});
`
