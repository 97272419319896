import { Simplify } from 'type-fest'

import { CollectionModelInterfaceBase, CollectionModelInterfaceWithActions as WithActions } from './types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import {
  useFilterIds,
  useGetAllRunbookUsers,
  useGetAllRunbookUsersCallback,
  useGetRunbookUser,
  useGetRunbookUserCallback,
  useGetRunbookUsersLookup,
  useGetRunbookUsersLookupCallback,
  useOnRunbookUserAction
} from '../hooks'
import { RunbookTeam, StreamListStream } from 'main/services/queries/types'

export type RunbookUserModelType = Simplify<
  CollectionModelInterfaceBase<RunbookVersionUser> &
    Omit<WithActions<'RunbookPerson'>, 'usePermission' | 'usePermissionCallback'>
> & {
  useFilterIds: () => (
    ids: number[],
    where: (context: {
      user: RunbookVersionUser
      streams: StreamListStream[]
      teams: RunbookTeam[]
      taskIds: number[]
    }) => boolean
  ) => Promise<number[]>
}

export const RunbookUserModel: RunbookUserModelType = {
  useGet: useGetRunbookUser,
  useGetCallback: useGetRunbookUserCallback,
  useGetAll: useGetAllRunbookUsers,
  useGetAllCallback: useGetAllRunbookUsersCallback,
  useGetLookup: useGetRunbookUsersLookup,
  useGetLookupCallback: useGetRunbookUsersLookupCallback,
  useOnAction: useOnRunbookUserAction,
  useFilterIds: useFilterIds
}
