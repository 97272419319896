import { isEqual, keyBy } from 'lodash'

import { TaskListResponseAndComputedPropertiesState } from 'main/recoil/runbook'
import { RunbookComponent, TaskListTask } from 'main/services/queries/types'
import { TaskListResponseType } from 'main/services/queries/use-tasks'

// NOTE: This function needs to work with immer, so please mutate the draft directly
// for example, prefer .push(newThing) over [...oldArray, newThing] so that referential
// equality is maintained
const extend = (
  draftTaskResponse: TaskListResponseAndComputedPropertiesState | TaskListResponseType,
  data: {
    runbookComponents: RunbookComponent[]
    previousData?: TaskListResponseAndComputedPropertiesState
  }
) => {
  const runbookComponentLookup = keyBy(data.runbookComponents, 'id')

  if (!(draftTaskResponse as TaskListResponseAndComputedPropertiesState).computed) {
    ;(draftTaskResponse as TaskListResponseAndComputedPropertiesState).computed = {
      taskIds: [],
      taskIdToExtendedFieldValueLookup: {}
    }
  }

  updateTaskIds(draftTaskResponse as TaskListResponseAndComputedPropertiesState, data.previousData)
  updateTaskIdToFieldValueLookup(
    draftTaskResponse as TaskListResponseAndComputedPropertiesState,
    runbookComponentLookup
  )

  return draftTaskResponse as TaskListResponseAndComputedPropertiesState
}

const updateTaskIds = (
  draftTaskResponse: TaskListResponseAndComputedPropertiesState,
  previousTaskResponse?: TaskListResponseAndComputedPropertiesState
) => {
  const taskIds = draftTaskResponse.tasks.map(task => task.id)
  const prevTaskIds = previousTaskResponse?.computed?.taskIds || []
  if (taskIds.length !== prevTaskIds.length || !isEqual(prevTaskIds, taskIds)) {
    draftTaskResponse.computed.taskIds = taskIds
  }
}

const updateTaskIdToFieldValueLookup = (
  draftTaskResponse: TaskListResponseAndComputedPropertiesState,
  runbookComponentLookup: Record<number, RunbookComponent>
) => {
  draftTaskResponse.tasks.forEach(t =>
    updateTaskIdToFieldValueLookupValue(t, draftTaskResponse, runbookComponentLookup)
  )
  return draftTaskResponse
}

const updateTaskIdToFieldValueLookupValue = (
  task: TaskListTask,
  draftTaskResponse: TaskListResponseAndComputedPropertiesState,
  runbookComponentLookup: Record<number, RunbookComponent>
) => {
  if (!draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id]) {
    draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id] = []
  }

  // User might not have permission to see runbook component e.g. 'Snippet'
  const rbcFieldValues = task.runbook_component_id
    ? runbookComponentLookup.hasOwnProperty(task.runbook_component_id)
      ? runbookComponentLookup[task.runbook_component_id].unpacked_source_runbook_custom_field_attributes || []
      : []
    : []
  const newFieldValues = [...task.field_values, ...rbcFieldValues]

  if (
    // if both empty
    draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id].length === 0 &&
    newFieldValues.length === 0
  ) {
    return draftTaskResponse
  }

  // if one's been deleted/added just update whole thing
  if (draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id].length !== newFieldValues.length) {
    draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id] = newFieldValues
    return draftTaskResponse
  }

  draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id].forEach((fv, index) => {
    if (fv.value !== newFieldValues[index].value) {
      draftTaskResponse.computed.taskIdToExtendedFieldValueLookup[task.id][index] = newFieldValues[index]
    }
  })

  return draftTaskResponse
}

export const taskListResponseDataHelpers = {
  extend,
  updateTaskIds,
  updateTaskIdToFieldValueLookup,
  updateTaskIdToFieldValueLookupValue
}
