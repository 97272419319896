import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'
import parse from 'html-react-parser'

import { Box, Icon, Text, useIsUserEngagedWithOverlay } from '@cutover/react-ui'
import { DashboardComponent, DashboardMediaType } from '../../types'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget'
import { TextEditorField } from 'main/components/shared/form'

export type ContentWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
  readOnly?: boolean
}

export const ContentWidget = ({ media, data, readOnly }: ContentWidgetProps) => {
  return media === 'screen' ? (
    <BrowserContentWidget data={data} readOnly={readOnly} />
  ) : (
    <EmailContentWidget data={data} />
  )
}

const EmailContentWidget = ({ data }: { data: DashboardComponent }) => {
  const {
    width,
    runbook: { field_values: fieldValues },
    custom_fields: customFields,
    settings
  } = data

  const customFieldId = settings.custom_field_id || {}
  const customField = customFields.find(field => field.id === customFieldId)
  const label = customField?.display_name || customField?.name
  const fullWidth = width === 100 || !width

  const defaultValue =
    fieldValues.find(field => field.custom_field_id === customFieldId)?.value ?? customField?.default_value ?? '<p></p>'

  return (
    <>
      <MrdDashboardWidget title={label ?? ''} fullWidth={fullWidth}>
        <Box>
          <Text>{parse(defaultValue)}</Text>
        </Box>
      </MrdDashboardWidget>
    </>
  )
}

const BrowserContentWidget = ({ data, readOnly = false }: { data: DashboardComponent; readOnly?: boolean }) => {
  const containerRef = useRef(null)
  const getIsUserEngagedWithOverlay = useIsUserEngagedWithOverlay()
  useClickAway(containerRef, e => {
    if (!getIsUserEngagedWithOverlay(e as MouseEvent)) setInternalReadOnly(true)
  })
  const { width } = data
  const [internalReadOnly, setInternalReadOnly] = useState(true)
  const customFieldId = data.settings.custom_field_id || {}
  const customField = data.custom_fields.find(field => field.id === customFieldId)
  const label = customField?.display_name || customField?.name
  const fullWidth = width === 100 || !width

  return (
    <>
      {customField && (
        <MrdDashboardWidget title={label ?? ''} fullWidth={fullWidth}>
          {!readOnly ? (
            <HoverIconTrigger>
              <HoverIconWrapper>
                <Icon
                  icon="edit"
                  size="medium"
                  css={`
                    position: absolute;
                    right: 0px;
                    top: -38px;
                  `}
                />
              </HoverIconWrapper>
              <Box
                ref={containerRef}
                onClick={() => {
                  setInternalReadOnly(false)
                }}
              >
                <TextEditorField readOnly={internalReadOnly} name={`${customField.id}`} />
              </Box>
            </HoverIconTrigger>
          ) : (
            <Box ref={containerRef}>
              <TextEditorField readOnly={readOnly} name={`${customField.id}`} />
            </Box>
          )}
        </MrdDashboardWidget>
      )}
    </>
  )
}

const HoverIconWrapper = styled(Box)`
  position: relative;
`
const HoverIconTrigger = styled(Box)`
  &:hover ${HoverIconWrapper} {
    display: block;
  }

  ${HoverIconWrapper} {
    display: none;
  }
`
