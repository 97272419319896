import { ReactNode, useEffect } from 'react'
import saveAs from 'file-saver'

import {
  Box,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  useNotify,
  useToggleFilterPanel
} from '@cutover/react-ui'
import { FilterPanelToggleButton, SubHeaderResultsText } from 'main/components/layout/shared/sub-header'
import { ActiveRunbookModel } from 'main/data-access'
import { useAppliedFilters, useFilterCount } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { useRunbookSubHeader } from '../../runbook-sub-header/use-runbook-sub-header'
import { downloadAuditLogCsv, useRunbookAuditLog } from 'main/services/queries/use-audit-log'

export const AuditLogSubHeader = ({ children, logsCount }: { children: ReactNode; logsCount?: number }) => {
  const { addSubHeaderContent, resetSubHeaderContent } = useRunbookSubHeader()

  useEffect(() => {
    addSubHeaderContent({
      left: <AuditLogSubHeaderLeft logsCount={logsCount} />,
      right: <AuditLogSubHeaderRight />
    })

    return resetSubHeaderContent
  }, [logsCount])

  return <>{children}</>
}

const AuditLogSubHeaderLeft = ({ logsCount }: { logsCount?: number }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog' })
  const toggleFilterPanel = useToggleFilterPanel()
  const numFilters = useFilterCount()

  return (
    <>
      <Box flex={false} gap="xsmall" align="center" direction="row">
        <FilterPanelToggleButton filterCount={numFilters} onClick={() => toggleFilterPanel()} onKeyUp={() => {}} />
      </Box>
      {logsCount ? <SubHeaderResultsText value={t('logEntries', { count: logsCount })} /> : null}
    </>
  )
}

const AuditLogSubHeaderRight = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog' })
  const notify = useNotify()
  const runbookId = ActiveRunbookModel.useId()
  const { name: runbookName } = ActiveRunbookModel.useGet()
  const filters = useAppliedFilters()

  const { refetch } = useRunbookAuditLog({
    runbookId,
    params: filters
  })

  const handleDownloadAuditLog = async () => {
    try {
      const data = await downloadAuditLogCsv(runbookId)
      const file = new Blob([data], { type: 'text/csv' })
      const fileName = runbookName.replace(/ /g, '-') + '_auditlog.csv'
      saveAs(file, fileName)
    } catch (error) {
      notify.error(t('auditLogDownloadError'))
    }
  }

  const menuItems: MenuListItemProps[] = [
    {
      icon: 'download' as const,
      label: t('download'),
      onClick: handleDownloadAuditLog
    },
    { icon: 'refresh' as const, label: t('refresh'), onClick: refetch }
  ].filter(Boolean) as MenuListItemProps[]

  return (
    <Menu trigger={<IconButton tertiary label={t('moreOptions')} icon="more-vertical" />}>
      {menuItems.map(item => (
        <MenuListItem icon={item.icon} label={item.label} key={item.label} onClick={item.onClick} />
      ))}
    </Menu>
  )
}
