import { memo, useMemo, useState } from 'react'
import * as yup from 'yup'

import { IconButton, LoadingPanel } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { FormEditPanel, TextAreaField, TextInputField, UserSelectField } from 'main/components/shared/form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { WorkspaceEditType, WorkspaceUpdatePayload } from 'main/services/queries/types'
import { useWorkspaceEdit, useWorkspaceUpdate } from 'main/services/queries/use-workspace'
import { WorkspaceArchiveModal } from '../modals/workspace-archive-modal'

export const WorkspaceEditPanel = memo(() => {
  const [{ workspaceId }, { closeRightPanel }] = useRightPanelTypeState('workspace-edit')
  if (!workspaceId) return null
  return <WorkspaceEditPanelInner workspaceId={workspaceId} closeRightPanel={closeRightPanel} />
})

const WorkspaceEditPanelInner = ({
  workspaceId,
  closeRightPanel
}: {
  workspaceId: number
  closeRightPanel: () => void
}) => {
  const { data, isLoading, isError } = useWorkspaceEdit(workspaceId)

  if (isError || isLoading || !data) return <LoadingPanel />
  return (
    <WorkspaceEdit
      workspace={data.account}
      canArchive={!!data.meta.permissions?.['archive']?.[0]}
      canUpdate={!!data.meta.permissions?.['update']?.[0]}
      onClose={closeRightPanel}
    />
  )
}

type WorkspaceEditFormType = yup.InferType<typeof validationSchema>

type WorkspaceEditProps = {
  onClose: () => void
  workspace: WorkspaceEditType
  canArchive: boolean
  canUpdate: boolean
}

const WorkspaceEdit = ({ workspace, onClose, canArchive, canUpdate }: WorkspaceEditProps) => {
  const { t } = useLanguage('accounts', { keyPrefix: 'editPanel' })
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const { mutateAsync } = useWorkspaceUpdate(workspace.id)
  const handleSubmit = async (data: WorkspaceUpdatePayload) => mutateAsync(data)

  const defaultValues = { ...(workspace ?? {}) }

  const orderedRoleTypes = useMemo(
    () => (defaultValues.role_types || []).sort((a, b) => a.name.localeCompare(b.name)),
    [defaultValues.role_types]
  )

  return (
    <>
      {showArchiveModal && (
        <WorkspaceArchiveModal
          onClose={() => setShowArchiveModal(false)}
          workspaceId={workspace.id}
          workspaceName={workspace.name}
        />
      )}
      <FormEditPanel<WorkspaceEditFormType, WorkspaceUpdatePayload>
        onClose={onClose}
        onSubmit={handleSubmit}
        transformer={dataTransformer}
        defaultValues={defaultValues}
        successMessage={t('successMessage')}
        readOnly={!canUpdate}
        title={t('title')}
        schema={validationSchema}
        headerItems={
          canArchive
            ? [
                <IconButton
                  label="Archive"
                  tipPlacement="top"
                  icon="delete"
                  onClick={() => setShowArchiveModal(true)}
                />
              ]
            : undefined
        }
      >
        <TextInputField<WorkspaceEditFormType> name="name" label={t('nameLabel')} autoFocus />
        <TextInputField<WorkspaceEditFormType> name="account_type_name" label={t('typeLabel')} disabled />
        <TextInputField<WorkspaceEditFormType> name="slug" label={t('shortNameLabel')} />
        <TextAreaField<WorkspaceEditFormType> name="description" label={t('descriptionLabel')} />
        {orderedRoleTypes.map((roleType, index) => {
          return (
            <UserSelectField<WorkspaceEditFormType>
              data-testid={`role-type-${roleType.id}`}
              key={`role_types.${roleType.id}`}
              name={`role_types.${index}.users`}
              label={roleType.name}
              helpText={roleType.description || undefined}
              // TODO: fix without hard coding required state https://cutover.atlassian.net/browse/CFE-1578
              required={false}
            />
          )
        })}
      </FormEditPanel>
    </>
  )
}

const dataTransformer = (data: WorkspaceEditFormType): WorkspaceUpdatePayload => {
  const { role_types, ...form } = data
  // NOTE: role_types[x].users currently can contain teams
  return {
    ...form,
    title: form.name,
    roles:
      role_types
        ?.map(role =>
          (role.users || []).map(user => ({
            id: user.role_id,
            resource_id: form.id,
            resource_type: 'Account',
            role_type_id: role.id,
            subject_id: user.id,
            subject_type: user.subject_type
          }))
        )
        .flat() ?? []
  }
}

const validationSchema = yup.object({
  id: yup.number().required(),
  description: yup.string().nullable().notRequired(),
  name: yup.string().required(),
  account_type_name: yup.string().required(),
  slug: yup.string().required(),
  role_types: yup.array().of(
    yup.object({
      id: yup.number().required(),
      users: yup.array().of(
        yup.object({
          role_id: yup.number(),
          subject_type: yup.string().required(),
          id: yup.number().required()
        })
      )
    })
  )
})
