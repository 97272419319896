import { createContext, ReactNode, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { CutoverConnectChannelResponse } from '../data-providers/cutover-connect/cutover-connect-channel-response-types'
import { useWebsockets } from 'main/services/hooks'
import { WebsocketKeys } from './websocket-keys'

const CUTOVER_CONNECT_CHANNEL_NAME = 'CutoverConnectChannel'
const DEFAULT_CUTOVER_CONNECT_CHANNEL_ID = 0 // Health check jobs are executed by a resque scheduler job and not part of any current user

export const CutoverConnectChannelContext = createContext<CutoverConnectChannelResponse | undefined>(undefined)

export const CutoverConnectChannelProvider: any = ({ children }: { children: ReactNode }) => {
  const websockets = useWebsockets()
  const [cutoverConnectChannelResponse, setCutoverConnectChannelResponse] = useState<CutoverConnectChannelResponse>()
  const uniqueChannelName = WebsocketKeys.cutoverConnectSubscriber(DEFAULT_CUTOVER_CONNECT_CHANNEL_ID)
  const connectChannelSubscription = websockets.findExistingSubscription(
    CUTOVER_CONNECT_CHANNEL_NAME,
    DEFAULT_CUTOVER_CONNECT_CHANNEL_ID,
    uniqueChannelName
  )

  useMount(() => {
    setCutoverConnectChannelResponse({} as CutoverConnectChannelResponse)
    if (!connectChannelSubscription) {
      websockets.subscribe(CUTOVER_CONNECT_CHANNEL_NAME, DEFAULT_CUTOVER_CONNECT_CHANNEL_ID, uniqueChannelName, {
        received: (data: CutoverConnectChannelResponse) => {
          setCutoverConnectChannelResponse(data)
        }
      })
    }
  })

  useUnmount(() => {
    connectChannelSubscription?.unsubscribe()
  })

  return (
    <CutoverConnectChannelContext.Provider value={cutoverConnectChannelResponse}>
      {children}
    </CutoverConnectChannelContext.Provider>
  )
}
