import { useCallback, useDeferredValue, useEffect, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'

import { useRightPanelTypeValue, useToggleRightPanel } from 'main/components/layout/right-panel'
import { TaskModel } from 'main/data-access'

export type TaskListInitialIdStore = {
  set: (id: string) => void
  get: () => string | null
  clear: () => void
  has: () => boolean
}

export function useTaskListInitialTaskIdStore(): TaskListInitialIdStore {
  return {
    set: useCallback((id: string) => sessionStorage.setItem('task_internal_id', String(id)), []),
    get: useCallback(() => sessionStorage.getItem('task_internal_id'), []),
    clear: useCallback(() => sessionStorage.removeItem('task_internal_id'), []),
    has: useCallback(() => !!sessionStorage.getItem('task_internal_id'), [])
  }
}

type UseTaskListInitialTaskProps = {
  listIds: number[]
  store?: ReturnType<typeof useTaskListInitialTaskIdStore> // custom store - useful for testing
}

export function useTaskListInitialTask({ listIds, store: customIdStore }: UseTaskListInitialTaskProps) {
  const defaultIdStore = useTaskListInitialTaskIdStore()
  const idStore = customIdStore || defaultIdStore
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { taskId: editingTaskId } = useRightPanelTypeValue('task-edit')

  const { get: getInitialTaskIdFromStore, clear: clearInitialTaskIdStore } = idStore
  const [selectedTaskInternalId, setSelectedTaskInternalId] = useState(getInitialTaskIdFromStore())

  // we update our state with the deferred value to avoid blocking the main thread
  const deferredSelectedTaskInteralId = useDeferredValue(selectedTaskInternalId)
  const selectedTask = TaskModel.useGetBy({ internal_id: Number(selectedTaskInternalId) })
  const [initialSelectedTask] = useState(selectedTask)

  // using read only state to avoid recomputing after first render. After the first render this would always compute
  // to undefined anyway because the selectedTaskInternalId is cleared.
  const [initialScrollIndex] = useState(
    listIds.indexOf(selectedTask?.id) > -1 ? listIds.indexOf(selectedTask?.id) : undefined
  )

  useEffectOnce(() => {
    if (selectedTask && selectedTask.id) toggleTaskEditPanel({ taskId: selectedTask.id })
  })

  useEffect(() => {
    if (editingTaskId) {
      setSelectedTaskInternalId(null)
      clearInitialTaskIdStore() // sync external store
    }
  }, [editingTaskId, clearInitialTaskIdStore])

  // only here as a failsafe in case the edit panel doesn't open for some reason.
  useUnmount(clearInitialTaskIdStore)

  return {
    initialScrollIndex: !initialSelectedTask ? undefined : initialScrollIndex,
    isReadyRender: !initialSelectedTask || !selectedTaskInternalId,
    isReadyShow: !initialSelectedTask || !deferredSelectedTaskInteralId,
    hasInitialScroll: !!initialSelectedTask && initialScrollIndex !== undefined
  }
}
