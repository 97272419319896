// @ts-nocheck
import { useEffect } from 'react'
import styled from 'styled-components'
import { Heading } from 'Components/Atoms'
import { ErrorAlert, ErrorAlertPresenter, Button, ButtonPresenter } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Helpers/Language/ILanguageService'
import { px2rem } from 'app/Styles/Variables'

const Wrapper = styled.div`
  padding: ${px2rem(16)};
`

const AlertWrapper = styled.div`
  margin-top: ${px2rem(12)};
`

const Content = styled.p`
  margin-top: ${px2rem(12)};
  margin-bottom: 0;
`

const ButtonContainer = styled.div`
  margin-top: ${px2rem(24)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IEditPanelArchiveModal {
  archiveConfirmName?: string
  continueCallback: () => void
  cancelCallback: () => void
  responseErrors: string[]
  modalTitle: string
  modalMessage: string
}

export const EditPanelArchiveModal = (props: IEditPanelArchiveModal) => {
  const language: ILanguageService = container.get(Types.ILanguageService)
  const alertPresenter = new ErrorAlertPresenter()
  const { archiveConfirmName, continueCallback, cancelCallback, responseErrors, modalMessage, modalTitle } = props

  useEffect(() => {
    if (!responseErrors || responseErrors.length === 0) {
      alertPresenter.reset()
    } else {
      alertPresenter.setErrorMessages(responseErrors)
    }
  }, [responseErrors])

  const continueArchiveButtonPresenter = new ButtonPresenter(language.get('common:archiveButton'), continueCallback)
    .withDisplayStyle('primary')
    .withIconName('trash-o')
    .withTextTransform('Uppercase')

  const cancelArchiveButtonPresenter = new ButtonPresenter(language.get('common:cancelButton'), cancelCallback)
    .withDisplayStyle('secondary')
    .withIconName('dnd-forwardslash')
    .withTextTransform('Uppercase')

  return (
    <Wrapper>
      <Heading>{modalTitle}</Heading>
      {responseErrors && responseErrors.length > 0 && (
        <AlertWrapper>
          <ErrorAlert presenter={alertPresenter} />
        </AlertWrapper>
      )}
      {archiveConfirmName ? (
        <Content>
          {modalMessage} <strong>{archiveConfirmName}?</strong>
        </Content>
      ) : (
        <Content>{modalMessage}</Content>
      )}
      <ButtonContainer>
        <Button presenter={cancelArchiveButtonPresenter} />
        <Button presenter={continueArchiveButtonPresenter} />
      </ButtonContainer>
    </Wrapper>
  )
}
