import { memo, useCallback, useMemo } from 'react'
import { getUnixTime } from 'date-fns'
import { debounce } from 'lodash'

import {
  CheckboxGroup,
  DateTimePicker,
  FilterAccordion,
  FilterAccordionPanel,
  MultiSelectControl,
  TextInput
} from '@cutover/react-ui'
import { FilterPanel } from 'main/components/layout/filter-panel'
import { IDS as LAYOUT_IDS } from 'main/components/layout/layout-constants'
import { useLanguage } from 'main/services/hooks'
import {
  useAuditLogUserFilter,
  useCreatedAfterFilter,
  useCreatedBeforeFilter,
  useObjectTypeFilter,
  useRunbookVersionUsers,
  useTaskInternalIdFilter
} from 'main/recoil/data-access'
import { GroupsHeader } from '../../runbook-filter/groups'

export const AuditLogFilter = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'filters' })

  return (
    <FilterPanel>
      <FilterAccordion
        a11yTitle={t('panelAriaLabel')}
        renderMode="active"
        isLayoutFilter
        scrollContainer={LAYOUT_IDS.FILTER_PANEL}
      >
        <GroupsHeader key="header" />
        <TaskGroup key="task" />
        <UserGroup key="user" />
        <ObjectTypeGroup key="objectType" />
        <DateGroup key="date" />
      </FilterAccordion>
    </FilterPanel>
  )
})

export const DateGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog.filters' })
  const [createdAfter, setCreatedAfter] = useCreatedAfterFilter()
  const [createdBefore, setCreatedBefore] = useCreatedBeforeFilter()

  const dateFromValue = createdAfter ? new Date(createdAfter * 1000) : null
  const dateToValue = createdBefore ? new Date(createdBefore * 1000) : null

  return (
    <FilterAccordionPanel label={t('date')} applied={!!createdAfter || !!createdBefore}>
      <DateTimePicker
        value={dateFromValue}
        onChange={date => setCreatedAfter(date ? getUnixTime(date) : undefined)}
        label={t('dateFrom')}
      />
      <DateTimePicker
        value={dateToValue}
        onChange={date => setCreatedBefore(date ? getUnixTime(date) : undefined)}
        label={t('dateTo')}
      />
    </FilterAccordionPanel>
  )
})

export const TaskGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog.filters' })
  const [taskInternalId, setTaskInternalId] = useTaskInternalIdFilter()

  const debouncedSetTaskInternalId = debounce((e: { target: { value: string } }) => {
    const nextText = e.target.value
    if (nextText) {
      setTaskInternalId(nextText)
    } else {
      setTaskInternalId(undefined)
    }
  }, 300)

  return (
    <FilterAccordionPanel label={t('task')} applied={!!taskInternalId}>
      <TextInput
        icon="search"
        label={t('taskId')}
        placeholder={t('taskPlaceholder')}
        defaultValue={taskInternalId}
        onChange={debouncedSetTaskInternalId}
      />
    </FilterAccordionPanel>
  )
})

export const UserGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog.filters' })
  const [value, setValue] = useAuditLogUserFilter()
  const users = useRunbookVersionUsers()
  const options = useMemo(
    () =>
      users.map(user => ({
        label: user.name,
        value: user.id
      })),
    [users]
  )

  return users?.length ? (
    <FilterAccordionPanel label={t('author')} applied={value && !!value.length}>
      <MultiSelectControl
        a11yTitle={t('author')}
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          // need to preserve none checked if it was also selected
          if (!val || val.length === 0) {
            setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
          } else {
            const nextVals = val?.map(v => v.value)
            setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
          }
        }}
        options={options}
        plain
      />
    </FilterAccordionPanel>
  ) : null
})

export const ObjectTypeGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'auditLog.filters' })

  const [objectTypes, setObjectType] = useObjectTypeFilter()

  const taskLevelOptions = [
    { value: 'Comment', label: t('comment') },
    { value: 'Run', label: t('run') },
    { value: 'Runbook', label: t('runbook') },
    { value: 'RunbookTeam', label: t('runbookTeam') },
    { value: 'Stream', label: t('stream') },
    { value: 'Task', label: t('task') }
  ]

  const handleChange = useCallback((event: any) => event && setObjectType(event.value), [setObjectType])

  return (
    <FilterAccordionPanel label={t('objectType')} applied={!!objectTypes?.length}>
      <CheckboxGroup
        plain
        a11yTitle={t('objectType')}
        options={taskLevelOptions}
        value={objectTypes}
        onChange={handleChange}
      />
    </FilterAccordionPanel>
  )
})
