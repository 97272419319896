import { formatDistanceToNow } from 'date-fns'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'

import { Text } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps, TextMark } from '../apps-types'
import { useLanguage } from 'main/services/hooks'
import { useAppsTimezoneValue } from 'main/recoil/data-access'
import { RunbookViewModel } from 'main/data-access'

type TextFormat = 'default' | 'date_time_long' | 'date_time_short' | 'date_time_last_updated'
type TextKind = 'default' | 'error' | 'label' | 'subtitle'

type TextNodeProps = AppComponentNodeProps & {
  value: string
  format?: TextFormat
  kind?: TextKind
  marks?: TextMark[]
  truncate?: boolean | 'tip'
}

type TextStyles = {
  size: string
  color: string
}

const textKindStyles: { [K in TextKind]: TextStyles } = {
  default: {
    size: '15px',
    color: 'text-light'
  },
  error: {
    size: '15px',
    color: 'rag-red'
  },
  label: {
    size: '13px',
    color: 'text-light'
  },
  subtitle: {
    size: '19px',
    color: 'text'
  }
}

export const TextNode = ({ appId, resourceId, id, ...props }: TextNodeProps) => {
  const {
    value,
    format = 'default',
    kind = 'default',
    truncate = false,
    marks = []
  } = useComponentProps(appId, resourceId, id, props) as TextNodeProps

  const { size, color } = kind ? textKindStyles[kind] : textKindStyles.default
  const { t } = useLanguage('apps')

  // NOTE: `selectedTimezone` is used only for the React-based runbook implementation.
  const selectedTimezone = RunbookViewModel.useGet('selectedTimezone')

  // NOTE: `timezone` is used as a fallback until the incident runbook is fully migrated to React.
  const timezone = useAppsTimezoneValue()
  let formattedValue = value

  switch (format) {
    case 'date_time_long':
      formattedValue = formatDate(utcToZonedTime(new Date(value), selectedTimezone ?? timezone), 'd MMM HH:mm')
      break
    case 'date_time_short':
      formattedValue = formatDate(utcToZonedTime(new Date(value), selectedTimezone ?? timezone), 'd/MM HH:mm')
      break
    case 'date_time_last_updated':
      formattedValue = t('updated', {
        date: formatDistanceToNow(new Date(value), { addSuffix: true })
      })
      break
  }

  return (
    <Text
      tip={truncate ? value : undefined}
      truncate={truncate}
      size={size}
      color={color}
      weight={marks.includes('bold') ? 'bold' : 'normal'}
      css={`
        white-space: ${marks.includes('nowrap') ? 'nowrap' : 'normal'};
        font-style: ${marks.includes('italic') ? 'italic' : 'normal'};
        text-decoration: ${marks.includes('underline') ? 'underline' : 'none'};
      `}
    >
      {formattedValue}
    </Text>
  )
}
