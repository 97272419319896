import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { useRecoilTransaction_UNSTABLE } from 'recoil'

import { accountSlugUrlParamState, runbookUrlParamState, runbookVersionUrlParamState } from 'main/recoil/runbook'
import { useUpdateEffect } from 'main/services/hooks'
import { parseResourceURLParams } from 'main/services/params-util'

export type CustomNavigationEventDetail = {
  pathname: string
  previousPathname: string | undefined
}

export const NavigationEventEmitter = ({ children }: { children?: ReactNode }) => {
  const { search, pathname } = useLocation()
  const previousPathname = usePrevious(pathname)

  useUpdateEffect(() => {
    window.dispatchEvent(new CustomEvent<CustomNavigationEventDetail>('searchchanged'))
  }, [search])

  // Would be good to consistently useParams from react router, but this requires some spec
  // consideration as we're currently able to inject param state by setting these in tests.
  const setUrlState = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (pathname: string) => {
        const { runbookId, accountSlug, runbookVersionId } = parseResourceURLParams(pathname)
        set(runbookUrlParamState, runbookId)
        set(accountSlugUrlParamState, accountSlug)
        set(runbookVersionUrlParamState, runbookVersionId)
      },
    []
  )

  useUpdateEffect(() => {
    setUrlState(pathname)

    window.dispatchEvent(
      new CustomEvent<CustomNavigationEventDetail>('pathnamechanged', { detail: { pathname, previousPathname } })
    )
  }, [pathname])

  return <>{children}</>
}
