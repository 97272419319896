import { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { eventManager } from 'event-manager'
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  EditPanel,
  Form,
  IconButton,
  Menu,
  MenuListItem,
  MenuListItemProps,
  Message,
  TextInput,
  useNotify
} from '@cutover/react-ui'
import { toCamelCase } from '@cutover/api'
import { TeamUnlinkModal } from './team-unlink-modal'
import { UserSelect } from '../../../../../components/shared/form/user-select'
import { ReplaceTeamModals } from '../replace-team/replace-team-modals'
import { Permissions, SelectedTeam, ValidationRunbookTeam } from '../types'
import {
  RunbookTeam,
  RunbookTeamUpdate,
  RunbookTeamUser,
  useRunbookTeamDelete,
  useRunbookTeamQuery,
  useRunbookTeamUpdate
} from '../use-runbook-team'
import { useLanguage } from 'main/services/hooks'
import { useIsOnReactRunbook } from 'main/services/routing'
import { StreamListStream, User } from 'main/services/queries/types'
import { useDeleteRunbookTeam } from 'main/services/queries/use-users-and-teams-query'
import { CurrentUserModel, RunbookTeamModel, RunbookUserModel, TaskModel } from 'main/data-access'
import {
  DeleteAndReassignUserTeamModal,
  DeleteUserTeamForm
} from '../delete-reassign-user-team/delete-and-reassign-user-team-modal'
import { RunbookTeamDeleteModal } from 'main/components/runbook/modals/delete-team/team-delete-modal'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'

type TeamDetailsProps = {
  accountId: number
  runbookId: number
  runbookVersionId: number
  team: SelectedTeam
  onClose: () => void
  onBack: () => void
  runbookTeams: ValidationRunbookTeam[]
  setRunbookTeams: (array: ValidationRunbookTeam[]) => void
  permissions: Permissions
  bypassNotification?: boolean
}

export type RunbookTeamUserForm = Omit<RunbookTeamUser, 'id'> & { id: string | number; key: string }

export type RunbookTeamForm = {
  name: string
  users: RunbookTeamUserForm[]
}

const schema = yup.object().shape({
  name: yup.string().required()
})

export function TeamDetails({
  accountId,
  runbookId,
  runbookVersionId,
  team,
  onClose,
  onBack,
  runbookTeams,
  setRunbookTeams,
  permissions,
  bypassNotification = false
}: TeamDetailsProps) {
  const { t } = useLanguage()
  const notify = useNotify()
  const user = toCamelCase(CurrentUserModel.useGet())
  const [isUnlinking, setIsUnlinking] = useState<boolean>(false)
  const [runbookTeamDeleteModalData, setRunbookTeamDeleteModalData] = useState<
    { type: 'delete:team' | 'reassign:users' | 'reassign:team'; ids?: number[] } | undefined
  >(undefined)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [previousTeamName, setPreviousTeamName] = useState<string | null>(null)
  const [isTeamReplaceModalOpen, setIsTeamReplaceModalOpen] = useState<boolean>(false)

  const processTeamDeleteResponse = RunbookTeamModel.useOnAction('destroy')
  const processRunbookTeamUpdateResponse = RunbookTeamModel.useOnAction('update')
  const filterUserIds = RunbookUserModel.useFilterIds()
  const getTasksBy = TaskModel.useGetIdsByCallback()
  const isOnReactRunbook = useIsOnReactRunbook()

  const { data, isLoading, isSuccess } = useRunbookTeamQuery({
    userId: user?.id,
    runbookId,
    runbookVersionId,
    teamId: team.id
  })

  const reassignTeamMutation = useRunbookTeamDelete({ runbookId, runbookVersionId, teamId: team.id })
  const deleteMutation = useDeleteRunbookTeam({ runbookId, runbookVersionId, runbookTeamId: team.id })
  const canUnlink = data && data.canUpdate && data.linked && !isLoading

  const mutation = useRunbookTeamUpdate({
    userId: user?.id,
    runbookId,
    runbookVersionId,
    teamId: team.id,
    originalRunbook: data
  })

  const methods = useForm<RunbookTeamForm>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: team.name,
      users: []
    }
  })

  useEffect(() => {
    if (!previousTeamName && data) {
      setPreviousTeamName(data.name)
    }
  }, [data])

  useEffect(() => {
    if (data) {
      methods.reset({
        name: data.name ?? '',
        users: data.users ?? []
      })
    }
  }, [methods.reset, data])

  useEffect(() => {
    eventManager.on('runbook-team-deleted', onDelete)

    return () => {
      eventManager.off('runbook-team-deleted', onDelete)
    }
  }, [])

  const handleReassignSubmit = async (data: DeleteUserTeamForm) => {
    return await reassignTeamMutation.mutateAsync(
      { reassign: data },
      {
        onSuccess: data => {
          handleSuccessDeleteModal()
          processTeamDeleteResponse(data)
          setRunbookTeamDeleteModalData(undefined)
        }
      }
    )
  }

  const onReset = () => {
    if (data) {
      methods.reset({ name: data.name, users: data.users })
    }
    setErrorMessage(null)
  }

  const findRemovedUsers = (updatedUsers?: RunbookTeamUserForm[]) => {
    const previousUsers = data?.users
    const updatedUserIds = updatedUsers?.map(user => Number(user.id)) || []
    const usersToRemove = previousUsers?.filter(({ id }) => !updatedUserIds.includes(id))

    return usersToRemove
  }

  const updateRunbookTeams = (index: number, team: ValidationRunbookTeam) => {
    if (index !== -1) {
      setRunbookTeams([...runbookTeams.slice(0, index), team, ...runbookTeams.slice(index + 1)])
    }
  }

  const continueUpdate =
    (updatedData: RunbookTeamForm) => async (usersToRemoveFromRunbook?: number[], reassignProps?: {}) => {
      const payload: RunbookTeamUpdate = {
        name: updatedData.name,
        reassign: reassignProps,
        usersToRemoveFromRunbook
      }

      payload.userIds = (updatedData.users ?? []).map(user => Number(user.id))

      await mutation.mutate(payload, {
        onSuccess: response => {
          if (isOnReactRunbook) {
            processRunbookTeamUpdateResponse(response)
            notify.success(t('runbook:peoplePanel:teams:updateSuccess'))
          }
        }
      })
      if (updatedData.name !== previousTeamName) {
        const indexOfPreviousName = runbookTeams.findIndex(team => team.name === previousTeamName)
        updateRunbookTeams(indexOfPreviousName, { name: updatedData.name, linked: team.linked })
      }
    }

  const shouldReassignUser = useCallback(
    ({
      user,
      taskIds,
      teams,
      streams
    }: {
      user: RunbookVersionUser
      taskIds: number[]
      teams: RunbookTeam[]
      streams: StreamListStream[]
    }) =>
      !user.is_admin && taskIds.length > 0 && streams.length === 0 && teams.filter(t => t.id !== team.id).length === 0,
    [team]
  )

  const shouldRemoveUser = useCallback(
    ({
      user,
      taskIds,
      teams,
      streams
    }: {
      user: RunbookVersionUser
      taskIds: number[]
      teams: RunbookTeam[]
      streams: StreamListStream[]
    }) =>
      !user.is_admin &&
      taskIds.length === 0 &&
      streams.length === 0 &&
      teams.filter(t => t.id !== team.id).length === 0,
    [team]
  )

  const onFormSubmit = async (updatedRunbookTeamData: RunbookTeamForm) => {
    const removedUsers = findRemovedUsers(updatedRunbookTeamData.users)
    if (removedUsers && removedUsers.length > 0) {
      if (isOnReactRunbook) {
        const userIdsToReassign = await filterUserIds(
          removedUsers.map(u => u.id),
          // @ts-ignore fix when we refactor the types here to use those in main/services
          shouldReassignUser
        )

        if (userIdsToReassign.length > 0)
          return setRunbookTeamDeleteModalData({ type: 'reassign:users', ids: userIdsToReassign })

        const usersToRemoveFromRunbook = await filterUserIds(
          removedUsers.map(u => u.id),
          // @ts-ignore fix when we refactor the types here to use those in main/services
          shouldRemoveUser
        )
        return await continueUpdate(updatedRunbookTeamData)(usersToRemoveFromRunbook)
      } else {
        eventManager.emit('open-delete-team-reassign-modal', {
          removedUsers: removedUsers,
          continueUpdate: continueUpdate(updatedRunbookTeamData)
        })
      }
    } else {
      await continueUpdate(updatedRunbookTeamData)()
    }
  }

  const onDelete = ({ deletedRunbookTeamName }: { deletedRunbookTeamName: string }) => {
    // remove deleted team from runbookTeams
    setRunbookTeams(runbookTeams.filter(team => team.name !== deletedRunbookTeamName))
    onBack()
  }

  const onUnlinkSave = () => {
    // unlink the runbook team in runbookTeams for FE validation
    const indexOfPreviousRunbookTeam = runbookTeams.findIndex(team => team.name === data?.name)

    if (data) {
      updateRunbookTeams(indexOfPreviousRunbookTeam, { name: data.name, linked: false })
    }

    setIsUnlinking(false)
  }

  const closeTeamReplaceModal = () => {
    setIsTeamReplaceModalOpen(false)
  }

  useEffect(() => {
    if (mutation.isError) {
      setErrorMessage(mutation.error.validationError(t('runbook:peoplePanel:teams:updateError')))
    }
    if (reassignTeamMutation.isError) {
      setErrorMessage(t('runbook:peoplePanel:teams:reassignError'))
    }
  }, [mutation.isError, reassignTeamMutation.isError])

  const handleSubmitDeleteModal = async () => {
    if (!data) return // just for ts checking, should never happen in reality

    // Take userIds and return a subset of the ones that have no other roles. Note the API should be doing this...
    const userIdsToReassign = await filterUserIds(
      data.users.map(user => user.id),
      // @ts-ignore fix when we refactor the types here to use those in main/services
      shouldReassignUser
    )

    const payload = { reassign: { selected: 'none', users_to_reassign: userIdsToReassign } }
    return await deleteMutation.mutateAsync(payload, {
      onSuccess: response => {
        processTeamDeleteResponse(response)
        handleSuccessDeleteModal()
      }
    })
  }

  const handleSuccessDeleteModal = () => {
    notify.success(t('runbook:peoplePanel:teams:deleteSuccessNotification:label'), {
      title: t('runbook:peoplePanel:teams:deleteSuccessNotification:title')
    })
    onBack()
  }

  const handleOpenDeleteTeamModal = async () => {
    const assignedTasks = await getTasksBy({ runbook_team_id: team.id })
    const openReassignModal = assignedTasks.length > 0
    setRunbookTeamDeleteModalData({
      type: openReassignModal ? 'reassign:team' : 'delete:team',
      ids: openReassignModal ? [team.id] : undefined
    })
  }

  const handleReassignUsersSubmit = (form: DeleteUserTeamForm) => {
    const values = methods.getValues()
    continueUpdate(values)(runbookTeamDeleteModalData?.ids || [], form)
  }

  return (
    <EditPanel
      title={
        data?.linked
          ? t('runbook:peoplePanel:teams:linkedTeamHeading')
          : t('runbook:peoplePanel:teams:customTeamHeading')
      }
      isDirty={methods.formState.isDirty}
      onSubmit={() => methods.handleSubmit(onFormSubmit)()}
      onBack={onBack}
      onClose={onClose}
      headerItems={[
        ...(methods.formState.isDirty || !data?.canDelete
          ? []
          : [
              <HeaderContent
                isOnReactRunbook={!!isOnReactRunbook}
                runbookTeam={data}
                setIsReplaceModalOpen={setIsTeamReplaceModalOpen}
                onDelete={handleOpenDeleteTeamModal}
                permissions={permissions}
                onUnlink={canUnlink ? () => setIsUnlinking(true) : undefined}
              />
            ])
      ]}
      onReset={onReset}
      isSubmitting={methods.formState.isSubmitting || mutation.isLoading}
    >
      <>
        <RunbookTeamForm
          serverError={errorMessage}
          accountId={accountId}
          methods={methods}
          onUnlink={() => setIsUnlinking(true)}
          isLoading={isLoading}
          isSuccess={isSuccess}
          data={data}
        />
        {runbookTeamDeleteModalData?.type === 'delete:team' && (
          <RunbookTeamDeleteModal
            loading={deleteMutation.isLoading}
            onClose={() => setRunbookTeamDeleteModalData(undefined)}
            onSubmit={handleSubmitDeleteModal}
            teamName={team.name}
          />
        )}
        {(runbookTeamDeleteModalData?.type === 'reassign:team' ||
          runbookTeamDeleteModalData?.type === 'reassign:users') && (
          <DeleteAndReassignUserTeamModal
            onClose={() => setRunbookTeamDeleteModalData(undefined)}
            onSubmit={
              runbookTeamDeleteModalData.type === 'reassign:team' ? handleReassignSubmit : handleReassignUsersSubmit
            }
            ids={runbookTeamDeleteModalData.ids || []}
            mode={runbookTeamDeleteModalData.type === 'reassign:team' ? 'team' : 'user'}
          />
        )}

        <TeamUnlinkModal
          isOpen={isUnlinking}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          teamId={team.id}
          onClose={() => setIsUnlinking(false)}
          onCancel={() => setIsUnlinking(false)}
          onSave={onUnlinkSave}
        />
        <ReplaceTeamModals
          isOpen={isTeamReplaceModalOpen}
          runbookTeamName={team.name}
          runbookTeamLinked={team.linked}
          accountId={accountId}
          runbookId={runbookId}
          runbookVersionId={runbookVersionId}
          runbookTeamId={team.id}
          runbookTeams={runbookTeams}
          setRunbookTeams={setRunbookTeams}
          closeModal={closeTeamReplaceModal}
          bypassNotification={bypassNotification}
        />
      </>
    </EditPanel>
  )
}

type RunbookTeamFormProps = {
  serverError: string | null
  accountId: number
  methods: UseFormReturn<RunbookTeamForm>
  isLoading?: boolean
  isSuccess?: boolean
  onUnlink: () => void
  data?: RunbookTeam
}

function RunbookTeamForm({ serverError, accountId, methods, isLoading, data }: RunbookTeamFormProps) {
  const { t } = useLanguage()

  const canUpdate = data && data.canUpdate && !data.linked && !isLoading

  const nameErrorMessage = methods.formState?.errors?.name?.message
  const errors = nameErrorMessage || serverError

  return (
    <FormProvider {...methods}>
      <Form
        aria-label="form"
        css={`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        {errors && (
          <Box margin={{ bottom: 'medium' }}>
            <Message type="error" message={errors} />
          </Box>
        )}

        <TextInput
          {...methods.register('name')}
          hasError={!!nameErrorMessage}
          label={t('runbook:peoplePanel:teams:teamName')}
          disabled={!canUpdate}
          required
          truncate
        />

        <Box data-testid="runbook-team-members">
          <Controller
            name="users"
            control={methods.control}
            render={({ field: { onChange, value, onBlur, ref } }) => {
              return (
                <UserSelect
                  disabled={!canUpdate}
                  minChars={2}
                  accountId={accountId}
                  value={(value || []) as unknown as User[]}
                  inputRef={ref}
                  onBlur={onBlur}
                  label={t('runbook:peoplePanel:teams:teamMembers')}
                  onChange={onChange}
                  draggable
                />
              )
            }}
          />
        </Box>
      </Form>
    </FormProvider>
  )
}

type HeaderProps = {
  isOnReactRunbook: boolean
  runbookTeam?: RunbookTeam
  setIsReplaceModalOpen: (value: boolean) => void
  onDelete: () => void
  permissions: Permissions
  onUnlink?: () => void
}

function HeaderContent({
  isOnReactRunbook,
  runbookTeam,
  permissions,
  setIsReplaceModalOpen,
  onDelete,
  onUnlink
}: HeaderProps) {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const { accountId: accountSlug } = useParams()

  const openDeleteTeamModal = () => {
    if (isOnReactRunbook) {
      onDelete()
    } else {
      eventManager.emit('open-delete-team-modal', runbookTeam)
    }
  }

  const openReplaceTeamModals = () => {
    setIsReplaceModalOpen(true)
  }

  const openCentralTeamsSettings = () => {
    navigate(`/app/${accountSlug}/settings/teams`, { state: { teamId: runbookTeam?.teamId } })
  }

  const menuItems: MenuListItemProps[] = [
    {
      icon: 'swap',
      onClick: openReplaceTeamModals,
      label: t('runbook:peoplePanel:teams:replaceHeaderTitle')
    },
    {
      icon: 'delete',
      onClick: openDeleteTeamModal,
      label: t('common:deleteTeamButton')
    }
  ]

  const editCentralTeamLink: MenuListItemProps = {
    icon: 'new-message',
    label: t('runbook:peoplePanel:teams:editCentralTeam'),
    onClick: openCentralTeamsSettings
  }

  if (permissions.canUpdateCentralTeams && runbookTeam?.linked) {
    menuItems.unshift(editCentralTeamLink)
  }

  if (onUnlink) {
    menuItems.unshift({
      icon: 'unlink',
      label: t('runbook:peoplePanel:teams:unlinkTooltip'),
      onClick: onUnlink
    })
  }

  return (
    <Menu
      trigger={
        <IconButton
          tertiary
          disableTooltip
          data-testid="runbook-team-more-options"
          label={t('common:moreOptions')}
          icon="more-vertical"
        />
      }
    >
      {menuItems.map(item => (
        <MenuListItem
          icon={item.icon}
          label={item.label}
          key={item.label}
          onClick={item.onClick}
          destructive={item.icon === 'delete'}
        />
      ))}
    </Menu>
  )
}
