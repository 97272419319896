import { useMemo } from 'react'
import parse from 'html-react-parser'
import { groupBy, sortBy } from 'lodash'

import { Badge, useNotify } from '@cutover/react-ui'
import {
  FilterGroup,
  FilterGroupCheckbox,
  FilterGroupDate,
  FilterGroupHierarchy,
  FilterGroupSelect,
  FilterGroupText,
  FilterOptionRadio
} from './filter-types'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import {
  ApprovalStatuses,
  RagStatus,
  RtoConfigurationStatus,
  RtoStatus,
  RunStages,
  RunTypes,
  TemplateStatus,
  useLanguage
} from 'main/services/hooks'
import {
  CustomField,
  CustomFieldApplyToSlug,
  FolderListFolder,
  RunbookTypeType,
  User
} from 'main/services/queries/types'
import { useFolderCreate } from 'main/services/queries/use-folders'
import { usePermissions } from 'main/services/queries/use-permissions'
import { CustomFieldOptionCounts, CustomFieldOptionId, RunbookCentralTeam } from 'main/services/queries/use-runbooks'
import { isSearchableCustomField } from 'main/services/tasks/filtering'
import { ConfigModel } from 'main/data-access'

type RunbookFilterData = Partial<{
  approvalStatuses: ApprovalStatuses
  centralTeams: RunbookCentralTeam[]
  customFieldIds: CustomField['id'][]
  customFieldOptionCounts: CustomFieldOptionCounts
  customFieldOptionIds: CustomFieldOptionId[]
  customFields: CustomField[]
  folders: FolderListFolder[]
  ragStatuses: RagStatus[]
  rtoStatuses: RtoStatus[]
  rtoConfigurationStatuses: RtoConfigurationStatus[]
  runbookTypes: RunbookTypeType[]
  runStages: RunStages
  runTypes: RunTypes
  templateStatuses: TemplateStatus
  users: User[]
}>

type RunbookFilterHeaders =
  | 'approvalStatus'
  | 'centralTeam'
  | 'customFields'
  | 'date'
  | 'folder'
  | 'other'
  | 'otherSnippet'
  | 'otherTemplate'
  | 'quickFilters'
  | 'reviewer'
  | 'runbookType'
  | 'runStage'
  | 'runTypes'
  | 'snippetStatus'
  | 'status'
  | 'rtoStatus'
  | 'rtoConfigurationStatus'
  | 'templateStatus'

const headers: { [key in 'runbook' | 'timeline' | 'template' | 'snippet']: RunbookFilterHeaders[] } = {
  runbook: [
    'folder',
    'quickFilters',
    'runTypes',
    'runStage',
    'centralTeam',
    'status',
    'rtoStatus',
    'rtoConfigurationStatus',
    'date',
    'runbookType',
    'other',
    'customFields'
  ],
  timeline: [
    'folder',
    'quickFilters',
    'runTypes',
    'runStage',
    'centralTeam',
    'status',
    'date',
    'runbookType',
    'other',
    'customFields'
  ],
  template: [
    'folder',
    'centralTeam',
    'runbookType',
    'templateStatus',
    'approvalStatus',
    'reviewer',
    'customFields',
    'otherTemplate'
  ],
  snippet: ['centralTeam', 'snippetStatus', 'otherSnippet']
}

export type FilterOverrides = Partial<FilterGroup>
type CustomFieldOverrides = {
  select?: (cf: CustomField) => FilterOverrides
  text?: (cf: CustomField) => FilterOverrides
  date?: (cf: CustomField) => FilterOverrides
}

export const useRunbooksFilterData = (
  {
    approvalStatuses,
    centralTeams,
    customFieldIds,
    customFieldOptionCounts,
    customFieldOptionIds,
    customFields,
    folders,
    ragStatuses,
    rtoStatuses,
    rtoConfigurationStatuses,
    runbookTypes,
    runStages,
    runTypes,
    templateStatuses,
    users
  }: RunbookFilterData,
  options: { view: 'runbook' | 'timeline' | 'template' | 'snippet' } = { view: 'runbook' }
): FilterGroup[] => {
  const { dateWithinOptions } = ConfigModel.useGet()
  const orderedDateWithinOptions = sortBy(dateWithinOptions, option => option.order).map(option => ({
    value: option.id,
    label: option.name
  }))
  const spotlightAndGroup = options.view === 'timeline' ? { canSpotlight: true, canGroup: true } : {}
  const permissions = usePermissions('runbooks')
  const canAddFolder = permissions('create_folder')

  const foldersGroup = useFilterGroupFolderData(folders, spotlightAndGroup, { countable: true, canAdd: canAddFolder })
  const centralTeamGroup = useFilterGroupCentralTeamData(centralTeams)
  const quickFiltersGroup = useFilterGroupQuickFiltersData(orderedDateWithinOptions)
  const runTypesGroup = useFilterGroupRunTypesData(runTypes)
  const runbookTypeGroup = useFilterGroupRunbookTypeData(runbookTypes)
  const runStagesGroup = useFilterGroupRunStagesData(runStages)
  const ragStatusesGroup = useFilterGroupRagStatusesData(ragStatuses, spotlightAndGroup)
  const rtoStatusesGroup = useFilterGroupRtoFiltersData(rtoStatuses)
  const rtoConfigurationStatusesGroup = useFilterGroupRtoConfigurationStatusFilterData(rtoConfigurationStatuses)
  const dateGroup = useFilterGroupDateData()
  const templateStatusGroup = useFilterGroupTemplateStatusData(templateStatuses)
  const snippetStatusGroup = useFilterGroupSnippetStatusData(templateStatuses)
  const approvalStatusGroup = useFilterGroupApprovalStatusData(approvalStatuses)
  const templateReviewerGroup = useFilterGroupTemplateReviewerData(users)
  const otherGroup = useFilterGroupOtherData()
  const otherSnippetGroup = useFilterGroupOtherSnippetData()
  const otherTemplateGroup = useFilterGroupOtherTemplateData()
  const customFieldGroups = useFilterGroupCustomFieldsData(
    { customFields, customFieldIds, customFieldOptionIds, customFieldOptionCounts },
    ['runbook_add_edit', 'runbook_edit'],
    { select: () => spotlightAndGroup }
  )
  const isRtoRtaEnabled = ConfigModel.useIsFeatureEnabled('rto_rta')

  return useMemo(() => {
    // orders groups
    return (headers[options.view] || []).reduce<FilterGroup[]>((acc, header) => {
      switch (header) {
        case 'folder':
          return [...acc, foldersGroup]
        case 'quickFilters':
          return [...acc, quickFiltersGroup]
        case 'runTypes':
          return [...acc, runTypesGroup]
        case 'runStage':
          return [...acc, runStagesGroup]
        case 'status':
          return [...acc, ragStatusesGroup]
        case 'rtoStatus':
          if (isRtoRtaEnabled) {
            return [...acc, rtoStatusesGroup]
          } else {
            return acc
          }
        case 'rtoConfigurationStatus':
          if (isRtoRtaEnabled) {
            return [...acc, rtoConfigurationStatusesGroup]
          } else {
            return acc
          }
        case 'date':
          return [...acc, dateGroup]
        case 'centralTeam':
          return [...acc, centralTeamGroup]
        case 'runbookType':
          return [...acc, runbookTypeGroup]
        case 'templateStatus':
          return [...acc, templateStatusGroup]
        case 'snippetStatus':
          return [...acc, snippetStatusGroup]
        case 'approvalStatus':
          return [...acc, approvalStatusGroup]
        case 'reviewer':
          return [...acc, templateReviewerGroup]
        case 'other':
          return [...acc, otherGroup]
        case 'otherSnippet':
          return [...acc, otherSnippetGroup]
        case 'otherTemplate':
          return [...acc, otherTemplateGroup]
        case 'customFields':
          return [...acc, ...customFieldGroups]
      }
    }, [])
  }, [
    approvalStatuses,
    centralTeamGroup,
    customFieldGroups,
    dateGroup,
    foldersGroup,
    otherGroup,
    quickFiltersGroup,
    runbookTypeGroup,
    runStagesGroup,
    runTypesGroup,
    templateStatusGroup,
    users
  ])
}

// TODO: this is a completely separate filter comp just for the linked template select
export const useFilterGroupFolderData = (
  folders: FolderListFolder[] = [],
  overrides: FilterOverrides = {},
  options: { countable: boolean; canAdd: boolean } = { countable: true, canAdd: true }
): FilterGroupHierarchy => {
  const { account } = useAccount()
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const notify = useNotify()
  const { mutate: createFolder } = useFolderCreate({
    onSuccess: () => {
      notify.success(t('folderCreateSuccess'))
    },
    onError: () => {
      notify.error(t('folderCreateError'))
    }
  })

  return useMemo(() => {
    const groupedFolders = groupBy(folders, f => f.parent_id || 'parents')
    return {
      ...{
        isInitiallyOpen: true,
        slug: 'project',
        title: t('folder'),
        type: 'hierarchy',
        optionDisplayLimit: 50,
        itemCreateInputProps: options.canAdd
          ? {
              onCreateItem: value =>
                account && createFolder({ name: value, account_id: account.id, parent_id: null, roles: [] }),
              addNewLabel: t('addNewFolderButtonLabel'),
              placeholder: t('addNewFilterInputPlaceholder')
            }
          : undefined,
        options: sortBy(groupedFolders.parents || [], 'name').map(folder => ({
          suffix: options.countable ? (
            <Badge
              data-testid={`${folder.name}-count`}
              textColor="white"
              color={folder.color}
              label={folder.count}
              max={4}
            />
          ) : undefined,
          value: folder.id,
          label: parse(folder.name),
          'data-testid': `${parse(folder.name)}-option-checkbox`,
          itemCreateInputProps: options.canAdd
            ? {
                onCreateItem: value =>
                  account && createFolder({ name: value, account_id: account.id, parent_id: folder.id, roles: [] }),
                addNewLabel: t('addNewSubfolderButtonLabel'),
                placeholder: t('addNewFilterInputPlaceholder')
              }
            : undefined,
          children: sortBy(groupedFolders[folder.id] || [], 'name').map(subFolder => ({
            suffix: options.countable ? (
              <Badge
                data-testid={`${subFolder.name}-count`}
                textColor="white"
                color={subFolder.color}
                label={subFolder.count}
                max={4}
              />
            ) : undefined,
            value: subFolder.id,
            label: parse(subFolder.name),
            'data-testid': `${parse(subFolder.name)}-option-checkbox`
          }))
        }))
      },
      ...overrides
    } as FilterGroupHierarchy
  }, [folders, overrides])
}

export const useFilterGroupQuickFiltersData = (
  dateOptions: FilterOptionRadio[],
  overrides: FilterOverrides = {}
): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(
    () =>
      ({
        title: t('quickFilters'),
        isInitiallyOpen: true,
        type: 'checkbox',
        options: [
          {
            label: t('inProgressFilter'),
            labelReversed: t('inProgressFilterReversed'),
            value: true,
            valueReversed: false,
            slug: 'alr'
          },
          {
            label: t('startsWithinFilter'),
            slug: 'dd',
            options: dateOptions,
            // Default option value
            value: dateOptions[2]?.value
          },
          {
            label: t('endedWithinFilter'),
            slug: 'de',
            options: dateOptions,
            // Default option value
            value: dateOptions[2]?.value
          },
          {
            label: t('lateFilter'),
            labelReversed: t('lateFilterReversed'),
            value: true,
            valueReversed: false,
            slug: 'late'
          }
        ],
        ...overrides
      } as FilterGroupCheckbox),
    []
  )
}

export const useFilterGroupRtoConfigurationStatusFilterData = (
  rtoConfigurationStatuses: RtoConfigurationStatus[] = [],
  overrides: FilterOverrides = {}
) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return {
    ...{
      slug: 'rto_configuration_status',
      type: 'checkbox',
      title: t('rtoConfigurationStatusFilters'),
      options: rtoConfigurationStatuses.map(presence => ({
        value: presence.slug,
        label: presence.label
      }))
    },
    ...overrides
  } as FilterGroupCheckbox
}

export const useFilterGroupRtoFiltersData = (rtoStatuses: RtoStatus[] = [], overrides: FilterOverrides = {}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })

  return {
    ...{
      slug: 'rto_status',
      type: 'checkbox',
      title: t('rtoFilters'),
      options: rtoStatuses.map(status => ({
        value: status.slug,
        label: status.label
      }))
    },
    ...overrides
  } as FilterGroupCheckbox
}

export const useFilterGroupRunTypesData = (
  runTypes: RunTypes = {},
  overrides: FilterOverrides = {}
): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const runTypeKeys = Object.keys(runTypes)
    return {
      ...{
        isInitiallyOpen: false,
        slug: 'run_type',
        title: t('runType'),
        type: 'checkbox',
        options: runTypeKeys.map(key => ({
          value: key,
          // @ts-ignore we know runTypes is not empty at this point
          label: runTypes[key].name
        }))
      },
      ...overrides
    } as FilterGroupCheckbox
  }, [runTypes])
}

export const useFilterGroupRagStatusesData = (statuses: RagStatus[] = [], overrides: FilterOverrides = {}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return {
    ...{
      slug: 'status',
      type: 'checkbox',
      title: t('status'),
      options: statuses.map(status => ({
        value: status.slug,
        label: status.label
      }))
    },
    ...overrides
  } as FilterGroupCheckbox
}

export const useFilterGroupRunStagesData = (runStages: RunStages = {}, overrides: FilterOverrides = {}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const runStageKeys = Object.keys(runStages)
    return {
      ...{
        slug: 'stage',
        type: 'checkbox',
        title: t('runStage'),
        options: runStageKeys.map(key => ({
          value: key,
          // @ts-ignore we know runStages is not empty at this point
          label: runStages[key].name
        }))
      },
      ...overrides
    } as FilterGroupCheckbox
  }, [runStages])
}

export const useFilterGroupDateData = (overrides: FilterOverrides = {}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    return {
      ...{
        type: 'date',
        title: t('date'),
        options: [
          {
            slug: 'df',
            label: t('dateFrom')
          },
          {
            slug: 'dt',
            label: t('dateTo')
          }
        ]
      },
      ...overrides
    } as FilterGroupDate
  }, [])
}

export const useFilterGroupCentralTeamData = (
  centralTeams: RunbookCentralTeam[] = [],
  overrides: FilterOverrides = {},
  options: { countable: boolean } = { countable: true }
): FilterGroupSelect => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const teams = sortBy(centralTeams, 'name').filter(team => team.count > 0)
    return {
      ...{
        isInitiallyOpen: false,
        slug: 'team',
        title: t('centralTeam'),
        type: 'select',
        optionDisplayLimit: 10,
        expandable: teams.length < 11,
        options: teams.map(team => ({
          color: options.countable ? team.color : undefined,
          count: options.countable ? team.count : undefined,
          value: team.id,
          label: team.name
        }))
      },
      ...overrides
    } as FilterGroupSelect
  }, [centralTeams])
}

export const useFilterGroupTemplateReviewerData = (users: User[] = [], overrides: FilterOverrides = {}) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    return {
      ...{
        isInitiallyOpen: false,
        title: t('reviewer'),
        slug: 'approver',
        type: 'select',
        optionDisplayLimit: 10,
        expandable: users.length < 11,
        options: users.map(user => ({
          value: user.id,
          label: `${user.first_name} ${user.last_name}`
        }))
      },
      ...overrides
    } as FilterGroupSelect
  }, [users])
}

export const useFilterGroupRunbookTypeData = (
  runbookTypes: RunbookTypeType[] = [],
  overrides: FilterOverrides = ({} = {})
): FilterGroupSelect => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const filteredTypes = runbookTypes.filter(rt => rt.key !== 'snippet' && !rt.archived)
    return {
      ...{
        isInitiallyOpen: false,
        slug: 'type',
        title: t('runbookType'),
        type: 'select',
        optionDisplayLimit: 10,
        expandable: filteredTypes.length < 11,
        options: filteredTypes.map(runbookType => ({
          value: runbookType.id,
          label: `${runbookType.name}${runbookType.disabled ? ` ${t('disabledIndicator')}` : ''}`
        }))
      },
      ...overrides
    } as FilterGroupSelect
  }, [runbookTypes])
}

export const useFilterGroupTemplateStatusData = (
  templateStatuses: TemplateStatus = {},
  overrides: FilterOverrides = {}
): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const statusKeys = Object.keys(templateStatuses)
    return {
      ...{
        isInitiallyOpen: false,
        slug: 'ts',
        title: t('templateStatus'),
        type: 'checkbox',
        options: statusKeys.map(ts => ({
          value: camelToSnakeCase(ts),
          // @ts-ignore we know templateStatuses is not empty at this point
          label: templateStatuses[ts].name
        }))
      },
      ...overrides
    } as FilterGroupCheckbox
  }, [templateStatuses])
}

export const useFilterGroupSnippetStatusData = (
  snippetStatuses: TemplateStatus = {},
  overrides: FilterOverrides = {}
): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const statusKeys = Object.keys(snippetStatuses)
    return {
      ...{
        isInitiallyOpen: false,
        slug: 'ts',
        title: t('snippetStatus'),
        type: 'checkbox',
        options: statusKeys.map(ts => ({
          value: camelToSnakeCase(ts),
          // @ts-ignore we know snippetStatuses is not empty at this point
          label: snippetStatuses[ts].name
        }))
      },
      ...overrides
    } as FilterGroupCheckbox
  }, [snippetStatuses])
}

export const useFilterGroupApprovalStatusData = (
  approvalStatuses: ApprovalStatuses = {},
  overrides: FilterOverrides = {}
): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(() => {
    const statusKeys = Object.keys(approvalStatuses)
    return {
      ...{
        isInitiallyOpen: false,
        title: t('approvalStatus'),
        slug: 'approval_status',
        type: 'checkbox',
        options: [
          ...statusKeys.map(ts => ({
            value: camelToSnakeCase(ts),
            // @ts-ignore we know approvalStatuses is not empty at this point
            label: approvalStatuses[ts].name
          })),
          {
            value: true,
            valueReversed: false,
            slug: 'awaiting_my_review',
            label: 'Awaiting my review',
            labelReversed: 'Not awaiting my review'
          }
        ]
      },
      ...overrides
    } as FilterGroupCheckbox
  }, [approvalStatuses])
}

export const useFilterGroupOtherData = (overrides: FilterOverrides = {}): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(
    () =>
      ({
        title: t('other'),
        type: 'checkbox',
        options: [
          {
            label: t('runbooksCanEdit'),
            labelReversed: t('runbooksCanEditReversed'),
            value: true,
            valueReversed: false,
            slug: 'a'
          },
          {
            label: t('runbooksInvolvedIn'),
            labelReversed: t('runbooksInvolvedInReversed'),
            value: true,
            valueReversed: false,
            slug: 'm'
          },
          {
            label: t('incidents'),
            labelReversed: t('incidentsReversed'),
            value: true,
            valueReversed: false,
            slug: 'is_type_incident'
          }
        ],
        ...overrides
      } as FilterGroupCheckbox),
    []
  )
}

export const useFilterGroupOtherSnippetData = (overrides: FilterOverrides = {}): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(
    () =>
      ({
        title: t('other'),
        type: 'checkbox',
        options: [
          {
            label: t('snippetsCanEdit'),
            labelReversed: t('snippetsCanEditReversed'),
            value: true,
            valueReversed: false,
            slug: 'a'
          },
          {
            label: t('snippetsInvolvedIn'),
            labelReversed: t('snippetsInvolvedInReversed'),
            value: true,
            valueReversed: false,
            slug: 'm'
          }
        ],
        ...overrides
      } as FilterGroupCheckbox),
    []
  )
}

export const useFilterGroupOtherTemplateData = (overrides: FilterOverrides = {}): FilterGroupCheckbox => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  return useMemo(
    () =>
      ({
        title: t('other'),
        type: 'checkbox',
        options: [
          {
            label: t('templatesCanEdit'),
            labelReversed: t('templatesCanEditReversed'),
            value: true,
            valueReversed: false,
            slug: 'a'
          },
          {
            label: t('templatesInvolvedIn'),
            labelReversed: t('templatesInvolvedInReversed'),
            value: true,
            valueReversed: false,
            slug: 'm'
          }
        ],
        ...overrides
      } as FilterGroupCheckbox),
    []
  )
}

export const useFilterGroupCustomFieldsData = (
  customFieldData: Partial<{
    customFields: CustomField[]
    customFieldIds: CustomField['id'][]
    customFieldOptionIds: CustomFieldOptionId[]
    customFieldOptionCounts: CustomFieldOptionCounts
  }>,
  slugFilters: CustomFieldApplyToSlug[] = [],
  overrides: CustomFieldOverrides = {}
): FilterGroup[] => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const {
    customFields = [],
    customFieldIds = [],
    customFieldOptionIds = [],
    customFieldOptionCounts = []
  } = customFieldData

  const defaultValues = {
    isInitiallyOpen: false,
    slug: 'f'
  }

  return useMemo(() => {
    return sortBy(
      customFields.filter(
        cf =>
          cf.display_search && slugFilters.includes(cf.apply_to.slug) && !cf.archived && customFieldIds.includes(cf.id)
      ),
      cf => cf.name.toLowerCase()
    ).map(cf => {
      // This can be removed once old SCFs of type 'text' or migrated to use 'searchable' or 'multi_searchable' slug
      const fieldType = isSearchableCustomField(cf.type) ? 'select_menu' : cf.field_type.slug

      switch (fieldType) {
        case 'datetime':
          return {
            ...defaultValues,
            ...{
              title: cf.display_name || cf.name,
              customFieldId: cf.id,
              type: 'date',
              withNoValueOption: true,
              withAnyValueOption: true,
              options: [{ label: t('dateFrom') }, { label: t('dateTo') }]
            },
            ...overrides.date?.(cf)
          } as FilterGroupDate
        case 'text':
        case 'textarea':
          return {
            ...defaultValues,
            ...{
              title: cf.display_name || cf.name,
              customFieldId: cf.id,
              type: 'text',
              withNoValueOption: true,
              withAnyValueOption: true,
              options: [{ label: t('searchFor') }]
            },
            ...overrides.text?.(cf)
          } as FilterGroupText
        case 'checkboxes':
        case 'radiobox':
        case 'select_menu':
        case 'endpoint':
        case 'searchable':
        case 'multi_searchable':
        default:
          return {
            ...defaultValues,
            ...{
              title: cf.display_name || cf.name,
              customFieldId: cf.id,
              type: 'select',
              optionDisplayLimit: 10,
              expandable: true,
              withNoValueOption: true,
              options: [
                ...cf.field_options
                  .filter(option => customFieldOptionIds.includes(option.id))
                  .map(option => ({
                    label: option.name,
                    value: option.id,
                    count: customFieldOptionCounts[option.id],
                    color: option.color
                  }))
              ]
            },
            ...overrides.select?.(cf)
          } as FilterGroupSelect
      }
    })
  }, [customFields, customFieldIds, customFieldOptionIds, overrides])
}

// TODO: remove this when we get rid of 'convertCase: true' on the global config
// request or make the template statuses come back as an array rather than
// a keyed object
export const camelToSnakeCase = (string: string): string => {
  var result = string.replace(/([A-Z])/g, ' $1')
  return result.split(' ').join('_').toLowerCase()
}
