import { useState } from 'react'
import { keyBy } from 'lodash'

import { IntegrationsPanel } from 'main/components/runbook/runbook-edit/integrations/integrations-panel'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'
import { RunbookShowRunbook, StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'

type RunbookIntegrationsState = {
  runbook?: RunbookShowRunbook
  runbookVersionId?: number
  taskLookup?: Record<number, TaskListTask>
  taskTypeLookup?: Record<number, TaskType>
  streamLookup?: Record<number, StreamListStream>
}

export const RunbookIntegrationsPanelConnector = () => {
  const [state, setState] = useState<RunbookIntegrationsState>({})

  const handleClosePanel = () => {
    setState({})
  }

  return (
    <ReactAngularConnector
      mountEvent="open-runbook-integrations-panel"
      unmountEvent="close-runbook-integrations-panel"
      onMount={({ tasks, streams, ...eventData }) => {
        setState({
          ...eventData,
          taskLookup: keyBy(tasks, 'id'),
          streamLookup: keyBy(streams, 'id')
        })
      }}
      onUnmount={handleClosePanel}
    >
      {state.runbook && state.runbookVersionId && state.taskLookup && state.taskTypeLookup && state.streamLookup && (
        <IntegrationsPanel
          runbook={state.runbook}
          runbookVersionId={state.runbookVersionId}
          taskLookup={state.taskLookup}
          taskTypeLookup={state.taskTypeLookup}
          streamLookup={state.streamLookup}
        />
      )}
    </ReactAngularConnector>
  )
}
