import { useEffect, useMemo, useState } from 'react'
import { Modifier, usePopper } from 'react-popper'

type UseSelectPopperOptions = {
  hasIcon?: boolean
  width?: number
}

export function useSelectPopper({
  disclosureRef,
  popoverRef,
  options = {},
  isOpen = false,
  computePositionOnRender = false,
  value
}: {
  disclosureRef: any
  popoverRef: HTMLElement | null
  options?: UseSelectPopperOptions
  computePositionOnRender?: boolean
  isOpen?: boolean
  value?: any
}) {
  const [hasOpened, setHasOpened] = useState(false)
  const [updateState, setUpdateState] = useState(0)
  const hasIcon = !!options.hasIcon
  const fieldWidth = options.width ?? 0
  const modifiers: Modifier<string, Record<string, unknown>>[] = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite' as const,
        requires: ['computeStyles'],
        fn({ state }) {
          // TODO: Add in logic computing width based on whether there is a start icon or not and the width the the end components
          state.styles.popper.minWidth = `${fieldWidth || state.rects.reference.width}px`
          state.styles.popper.maxWidth = `${fieldWidth || state.rects.reference.width}px`
        }
      },
      {
        name: 'offset',
        options: {
          // TODO: adjust the offset based on whether there is an icon
          offset: [hasIcon ? -28 : 0, 4]
        }
      }
    ],
    [hasIcon, fieldWidth]
  )

  const { update: updatePopper, ...usePopperReturn } = usePopper(
    isOpen || (!hasOpened && computePositionOnRender) ? disclosureRef : null,
    popoverRef,
    {
      placement: 'bottom-start',
      modifiers
    }
  )

  useEffect(() => {
    if (isOpen) {
      // because we don't render the options until the select is open, we need to manually trigger a re-render after the
      // it opens to properly position the popper
      setUpdateState(prev => prev + 1)
      setHasOpened(true)
    }
  }, [isOpen])

  useEffect(() => {
    updatePopper?.()
  }, [updateState])

  useEffect(() => {
    if (isOpen) updatePopper?.()
  }, [value])

  return {
    ...usePopperReturn,
    hasOpened
  }
}
