import { colors } from '../../utils'

// NOTE: Nivo does not type the props for layers; we could do it manually at some point.

// Layer functions below are typed with any for props because Nivo doesn't define the type for them at this time.
/**
 * background gray/white horizontal bars in place of grid lines
 */
export const ChartBackgroundRowsLayer = ({
  axisLeft,
  yScale,
  width,
  height: totalHeight,
  margin: { bottom: marginBottom, top: marginTop }
}: any) => {
  const ticks: number[] = Array.isArray(axisLeft?.tickValues) ? axisLeft.tickValues : yScale.ticks(5)
  const [t1, t2] = ticks
  const barHeight = yScale(t1) - yScale(t2)

  return (
    <>
      {ticks.map((_tick, i) => {
        let height = barHeight
        let translate = totalHeight - barHeight * (i + 1) - marginBottom - marginTop
        if (translate < 0) {
          height = height + translate
          translate = 0
        }
        if (i % 2 === 0) {
          return null
        } else {
          return (
            <rect
              key={`tick-bar-${i}`}
              fill={colors.secondaryHover}
              width={width}
              height={height}
              transform={`translate(0, ${translate})`}
            />
          )
        }
      })}
    </>
  )
}
