import { memo, useMemo } from 'react'
import { useLocation, useMatch, useSearchParams } from 'react-router-dom'

import { Filter } from 'main/components/shared/filter'
import { useRunbooksFilterData } from 'main/components/shared/filter/filter-hooks'
import {
  serverQueryObjectToBrowserQueryString,
  useBrowserQueryStringToServerQueryObject
} from 'main/components/shared/filter/filter-params'
import { useClearFilterParams } from 'main/components/shared/filter/filter-provider'
import { SelectedFilters } from 'main/components/shared/filter/filter-types'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useAccountCustomFields, useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceMeta } from 'main/services/api/data-providers/workspace'
import { ConfigModel } from 'main/data-access'
import { FilterPanel } from 'main/components/layout/filter-panel'

export const WorkspaceFilter = memo(() => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { runbooksMetaFull } = useWorkspaceMeta()
  const { customFields } = useAccountCustomFields()
  const {
    templateStatuses,
    runTypes,
    runStages,
    ragStatuses,
    rtoStatuses,
    rtoConfigurationStatuses,
    approvalStatuses
  } = ConfigModel.useGet()
  const { runbookTypes } = useAccountRunbookTypes()
  const { openRightPanel } = useSetActiveRightPanelState()
  const clearFilterParams = useClearFilterParams()
  const timelineMatch = useMatch({ path: '/app/:accountSlug/runbooks/timeline' })
  const templateType = searchParams.get('template_type')
  const isArchived = searchParams.get('archived') === '1'

  const tabLocation = useMemo(() => {
    if (timelineMatch) return 'timeline'
    switch (templateType) {
      case 'snippet':
        return 'snippet'
      case 'default':
        return 'template'
      default:
        return 'runbook'
    }
  }, [templateType, timelineMatch])

  const filterData = useRunbooksFilterData(
    {
      approvalStatuses,
      centralTeams: runbooksMetaFull?.teams,
      folders: runbooksMetaFull?.projects,
      users: runbooksMetaFull?.users,
      ragStatuses,
      rtoStatuses,
      rtoConfigurationStatuses,
      runbookTypes,
      runStages,
      runTypes,
      templateStatuses,
      customFields,
      customFieldIds: runbooksMetaFull?.custom_field_ids,
      customFieldOptionIds: runbooksMetaFull?.field_option_ids,
      customFieldOptionCounts: runbooksMetaFull?.field_option_count_map
    },
    { view: tabLocation }
  )

  const handleFilterChange = (selected: SelectedFilters) => {
    if (Object.keys(selected).length === 0)
      return clearFilterParams({ newFilters: { template_type: templateType || [], archived: isArchived } })

    setSearchParams(serverQueryObjectToBrowserQueryString({ queryObject: selected }))
  }

  const selected = useBrowserQueryStringToServerQueryObject({ query: location.search })

  return (
    <FilterPanel>
      <Filter
        isLayoutFilter
        key={tabLocation} // Rerender filters whenever the user changes tabs
        onHierarchyOptionEditClick={(_, option) =>
          openRightPanel({ type: 'folder-edit', folderId: option.value as number, folders: runbooksMetaFull?.projects })
        }
        selected={selected as SelectedFilters}
        filterData={filterData}
        onChange={handleFilterChange}
      />
    </FilterPanel>
  )
})
