import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ActivityFeed } from '../components/activity-feed/activity-feed'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const ActivitiesPageConnector = () => {
  const { runbookId } = useParams()
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [timezone, setTimezone] = useState('')
  const [disableCommentField, setDisableCommentField] = useState<boolean>(false)

  return (
    <ReactAngularConnector
      mountEvent="show-runbook-activities"
      unmountEvent="hide-runbook-activities"
      onMount={({ currentUserId, timezone, disableCommentField }) => {
        setCurrentUserId(currentUserId)
        setTimezone(timezone)
        setDisableCommentField(disableCommentField)
      }}
    >
      {currentUserId && runbookId && (
        <ActivityFeed currentUserId={currentUserId} timezone={timezone} disableCommentField={disableCommentField} />
      )}
    </ReactAngularConnector>
  )
}
