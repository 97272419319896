import { useRecoilCallback, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { sharedViewState_INTERNAL } from 'main/recoil/shared/models/view/view'

/* -------------------------------------------------------------------------- */
/*                         Refresh custom field loading                       */
/* -------------------------------------------------------------------------- */

export const useCustomFieldRefreshValue = () => {
  return useRecoilValue(sharedViewState_INTERNAL).customFieldRefresh
}

export const useCustomFieldRefreshValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return (await snapshot.getPromise(sharedViewState_INTERNAL)).customFieldRefresh
      },
    []
  )

export const useCustomFieldRefreshUpdate = () => {
  return useRecoilCallback(
    ({ set }) =>
      ({
        customFieldId,
        refreshState
      }: {
        customFieldId: number | null
        refreshState: { refresh: boolean; errors?: { [fieldValueId: number]: string[] | undefined } }
      }) =>
        set(sharedViewState_INTERNAL, prev =>
          produce(prev, draft => {
            if (customFieldId) {
              draft.customFieldRefresh = draft.customFieldRefresh || {}

              draft.customFieldRefresh[customFieldId] = draft.customFieldRefresh[customFieldId] || {
                refresh: false,
                errors: {}
              }

              draft.customFieldRefresh[customFieldId].refresh = refreshState.refresh
              draft.customFieldRefresh[customFieldId].errors = {
                ...prev.customFieldRefresh[customFieldId]?.errors,
                ...refreshState.errors
              }
            }
          })
        ),
    []
  )
}
