import { useQuery } from 'react-query'

import { apiClient, apiClientCompat, ApiError } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'
import { WorkspaceListResponseType } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

export function useAccountsQuery() {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const client = isReactRunbookEnabled ? (apiClientCompat as typeof apiClient) : apiClient
  return useQuery<WorkspaceListResponseType, ApiError>([QueryKeys.Accounts], async () => {
    const { data } = await client.get<WorkspaceListResponseType>({ url: 'accounts' })

    return data
  })
}
