// @ts-nocheck
import 'reflect-metadata'
import { inject, injectable } from 'inversify'
import { unescape } from 'lodash'
import { observable, action } from 'mobx'
import { getRolesForService, getRolesFromServiceRoleTypes } from 'Helpers/.'
import { dateOrNull, dateOrNullIso, getUnix } from 'Shared/Helpers/DateService'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { UsersRepository } from 'app/Repositories/User/UsersRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'

@injectable()
export class UserRepository extends PermissableRepository {
  @inject(UsersRepository)
  private usersRepository: UsersRepository

  @inject(AccountsRepository)
  private accountsRepository: AccountsRepository

  @observable
  public userForEdit: any = null

  @observable
  public archiving: boolean = false

  @observable
  public downloading = false

  @observable
  public selectedId: number = null

  constructor() {
    super('user')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    if (this.userForEdit && this.userForEdit.id === args.userId) return
    this.selectedId = args.userId
    const url = 'users/' + args.userId
    return url
  }

  public postLoad = async (rawData: IBaseResponse) => {
    if (rawData.success) {
      await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'user' })
      this.setUserPm(rawData.body.user, this.accountsRepository.permittedAccounts)
    }
  }

  @action
  public clearSelectedId = () => {
    this.selectedId = null
  }

  @action
  public resetUserToEdit = () => {
    this.userForEdit = null
  }

  @action
  public saveUser = async user => {
    let userDto = {
      user: {
        id: this.userForEdit.id,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        handle: user.nickName,
        avatar: this.userForEdit.avatar,
        description: user.description,
        job_title: user.jobTitle,
        mobile_number: user.mobileNumber,
        sign_in_count: this.userForEdit.loginCount,
        current_sign_in_at: getUnix(this.userForEdit.currentSignInAt),
        last_sign_in_at: getUnix(this.userForEdit.lastSignInAt),
        last_sign_in_ip: this.userForEdit.lastSignInIp,
        created_at: getUnix(this.userForEdit.createdAt),
        online: this.userForEdit.online,
        invitation_accepted_at: getUnix(this.userForEdit.invitationAcceptedAt),
        color: this.userForEdit.color,
        name: this.userForEdit.name,
        pref_sms: user.acceptSMS,
        unique_id: user.uniqueId || this.userForEdit.uniqueId,
        provider: user.provider || this.userForEdit.provider,
        previous_provider: this.userForEdit.previousProvider,
        pref_email: user.prefEmail,
        html_email_preference: user.htmlEmailPrefs,
        country_code: user.countryCode,
        invitation_sent_at: getUnix(this.userForEdit.invitationSentAt),
        locked_at: getUnix(this.userForEdit.lockedAt),
        permissions: this.userForEdit.permissions,
        roles: getRolesForService(user.roles, this.userForEdit.id, this.userForEdit.roles)
      }
    }

    if (user.secretKey) {
      // @ts-ignore
      userDto.user.secret_key = user.secretKey
    }

    if (this.userForEdit.id === this.authenticationRepository.user.id) {
      delete userDto.user.roles
    }

    const responseDto = await this.serviceGateway.put('users/' + this.userForEdit.id, userDto)

    if (responseDto.success) {
      await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'user' })

      const updateUser = {
        ...this.userForEdit,
        archived: responseDto.body.user.archived,
        firstName: responseDto.body.user.first_name,
        lastName: responseDto.body.user.last_name,
        nickName: responseDto.body.user.handle,
        email: responseDto.body.user.email,
        mobileNumberNormalized: responseDto.body.user.mobile_number_normalized,
        uniqueId: unescape(responseDto.body.user.unique_id)
      }

      this.setUserPm(responseDto.body.user, this.accountsRepository.permittedAccounts)
      this.usersRepository.updateUsersWithEditedUser(updateUser)
    }

    return responseDto
  }

  @action
  private setUserPm = (user, accounts) => {
    if (user.archived) {
      this.resetUserToEdit()
    } else {
      const userPm: any = {}
      userPm.id = user.id || null
      userPm.firstName = user.first_name
      userPm.lastName = user.last_name
      userPm.email = user.email
      userPm.nickName = user.handle
      userPm.avatar = user.avatar
      userPm.description = user.description
      userPm.jobTitle = user.job_title
      userPm.mobileNumber = user.mobile_number
      userPm.loginCount = user.sign_in_count || null
      userPm.currentSignInAt = dateOrNull(user.current_sign_in_at)
      userPm.lastSignInAt = dateOrNull(user.last_sign_in_at)
      userPm.currentSignInIp = user.current_sign_in_ip || null
      userPm.lastSignInIp = user.last_sign_in_ip || null
      userPm.createdAt = dateOrNull(user.created_at)
      userPm.online = user.online || null
      userPm.invitationAcceptedAt = dateOrNull(user.invitation_accepted_at)
      userPm.color = user.color || null
      userPm.name = user.name || null
      userPm.acceptSMS = user.pref_sms || null
      userPm.uniqueId = user.unique_id ? unescape(user.unique_id) : null
      userPm.provider = user.provider || null
      userPm.previousProvider = user.previous_provider || null
      userPm.prefEmail = user.pref_email || null
      userPm.htmlEmailPrefs = user.html_email_preference || null
      userPm.countryCode = user.country_code || null
      userPm.secretKey = user.secret_key ? unescape(user.secret_key) : null
      userPm.invitationSentAt = dateOrNull(user.invitation_sent_at)
      userPm.lockedAt = dateOrNull(user.locked_at)
      userPm.history = user.history ? this.getUserHistory(user.history) : null
      userPm.permissions = user.permissions || null
      userPm.roles = getRolesFromServiceRoleTypes(user.role_types) || []
      userPm.oauthClientSessions = user.oauth_client_sessions
      userPm.permittedAccounts = accounts.map(pa => {
        return { id: pa.id, name: pa.name }
      })
      this.userForEdit = userPm
    }
  }

  @action
  public unlockUser = async () => {
    this.loading = true
    const responseDto = await this.serviceGateway.patch('users/' + this.userForEdit.id + '/unlock')

    if (responseDto.success) {
      this.userForEdit.lockedAt = null
      this.usersRepository.updateUsersWithEditedUser(this.userForEdit)
    }
    this.loading = false
    return responseDto
  }

  @action
  public archive = async () => {
    this.archiving = true
    const responseDto = await this.serviceGateway.patch('users/' + this.userForEdit.id + '/archive')
    this.archiving = false
    return responseDto
  }

  public getUserHistory = (rawHistory: any) => {
    let historyRecords = []
    if (rawHistory.records.length > 0) {
      historyRecords = rawHistory.records.map(record => {
        return {
          description: record.description,
          date: dateOrNullIso(record.date)
        }
      })
    }

    return historyRecords
  }

  @action
  public downloadHistoryCSV = async () => {
    if (this.downloading || !this.userForEdit || !this.userForEdit.id) return

    this.downloading = true
    const response = await this.serviceGateway.get(
      'users/' + this.userForEdit.id + '/download_history_csv',
      {},
      {},
      { responseType: 'arraybuffer' }
    )
    this.downloading = false
    return response.body
  }

  @action
  public clearUserForEdit = () => {
    this.userForEdit = null
  }
}
