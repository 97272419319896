import { ReactNode, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'

import { useWebsockets } from 'main/services/hooks'
import { LayoutLoading } from 'main/components/layout/layout-loading'
import { useRunbookChannelQueueProcessing } from 'main/recoil/data-access/updaters__TEMPORARY/use-runbook-channel-response-processor'
import { useRunbookUrlParamState } from 'main/recoil/data-access'
import { CurrentUserModel } from 'main/data-access'
import { WebsocketKeys } from 'main/services/api/websocket-providers'

export const RunbookChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const currentUserId = CurrentUserModel.useId()
  const { subscribe, findExistingSubscription } = useWebsockets()
  const { enqueueOrProcess } = useRunbookChannelQueueProcessing()
  const runbookId = useRunbookUrlParamState()
  const uniqueChannelName = runbookId ? WebsocketKeys.runbookSubscriber(runbookId) : ''

  const websocketRef = useRef<ActionCable.Channel | undefined>()
  const [connected, setConnected] = useState(!!findExistingSubscription('RunbookChannel', runbookId, uniqueChannelName))

  useEffect(() => {
    if (!runbookId || !uniqueChannelName) return

    const existingRunbookChannel = findExistingSubscription('RunbookChannel', runbookId, uniqueChannelName)
    if (existingRunbookChannel) return setConnected(true)

    websocketRef.current?.unsubscribe?.()

    websocketRef.current = subscribe('RunbookChannel', runbookId, uniqueChannelName, {
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return
        enqueueOrProcess(response)
      }
    })
  }, [runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  // IMPORTANT: only render children when certain the ws channel has connected
  return connected ? <>{children}</> : <LayoutLoading filterPanel subHeader rightNav />
}
