import { ReactNode, useEffect } from 'react'

import {
  useRunbookChannelQueueProcessing,
  useRunbookChannelQueueValue
} from 'main/recoil/data-access/updaters__TEMPORARY/use-runbook-channel-response-processor'

export const RunbookChannelQueueProcessor = ({
  isLoading,
  isError,
  children
}: {
  isLoading: boolean
  isError: boolean
  children: ReactNode
}) => {
  const { processQueue, markQueueAs } = useRunbookChannelQueueProcessing()
  const queue = useRunbookChannelQueueValue()

  useEffect(() => {
    if (isLoading || isError) return markQueueAs('open')

    markQueueAs('closed')
    if (queue.length) {
      processQueue()
    }
  }, [queue, isError, isLoading])

  return <>{children}</>
}
