import { atom, selector } from 'recoil'
import { isEmpty } from 'lodash'

import { globalConfig } from 'main/recoil/shared/global-config'
import { runbookRunbookTypeState, runbookState } from '../..'
import { accountTaskTypesGroupedIntegrations } from '../account/task-types'
import { TaskShowTask } from 'main/services/queries/types'
import { TaskShowPermissions } from 'main/recoil/data-access'

// TODO: fix name, says it's an id but its a task
export const taskEditUpdatedState = atom<
  { task: TaskShowTask; last_user_id?: number; permissions?: TaskShowPermissions } | undefined
>({
  key: 'task-edit:updated-task',
  default: undefined
})

export const taskEditTaskTypesState = selector({
  key: 'task-edit:task-types',
  get: ({ get }) => {
    const { taskTypes: taskTypesNormal, taskTypeIntegrations: initialTaskTypeIntegrations } = get(
      accountTaskTypesGroupedIntegrations
    )
    const { dynamic } = get(runbookRunbookTypeState)
    const { linked_runbook_details } = get(runbookState)
    const { integrations } = get(globalConfig)

    const integrationTaskTypeKlasses = integrations.flatMap(integration =>
      integration.actions.map(action => action.klass)
    )
    const taskTypeIntegrations = initialTaskTypeIntegrations.filter(tti => {
      if (tti.archived) return false

      return integrationTaskTypeKlasses.includes(tti.integration_action_items?.[0]?.integration_action)
    })

    return {
      taskTypes: taskTypesNormal.filter(tt => {
        if (tt.archived) return false
        if (!dynamic && tt.key === 'snippet') return false
        if (!isEmpty(linked_runbook_details) && tt.key !== 'snippet' && tt.linkable) return false

        return true
      }),
      taskTypeIntegrations
    }
  }
})
