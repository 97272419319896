import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { SearchableCustomFieldOption } from 'main/services/api/data-providers/user/user-channel-response-types'
import { ExtendedCustomField } from 'main/recoil/runbook'

export type UseSearchableCustomFieldProps = {
  value?: SearchableCustomFieldOption[]
  isMultiSelect?: boolean
  namePrefix?: string
  customField: ExtendedCustomField
  onChange?: (value?: SearchableCustomFieldOption[]) => void
}

export const useSearchableCustomFieldState = ({
  value,
  isMultiSelect,
  namePrefix,
  customField,
  onChange: onChangeProp
}: UseSearchableCustomFieldProps) => {
  const { getValues, setValue } = useFormContext()
  const [selectValue, setSelectValue] = useState<SearchableCustomFieldOption[]>(value || [])

  useEffect(() => {
    setSelectValue(value?.filter(item => !item.destroy) ?? [])
  }, [JSON.stringify(value || {})])

  const onRemove = useCallback(
    (newSelection?: SearchableCustomFieldOption[]) => {
      const nextValue = isMultiSelect
        ? newSelection
        : !newSelection?.length
        ? []
        : [newSelection[newSelection.length - 1]]

      const newSelectionPrimaryKeys = new Set(nextValue?.map(item => item.primaryKey) ?? [])
      let items = [...(nextValue ?? [])]

      value?.forEach(item => {
        if (item.id && !newSelectionPrimaryKeys.has(item.primaryKey)) {
          // mark item for deletion
          items = [...items, { ...item, destroy: true }]
        } else if (!item.id && !isMultiSelect) {
          // if the removed item hasn't been saved in db (i.e. no id), and it's not multiselect then simply remove from the list.
          // we don't need to add destroy flag because we can assume the user is switching between options without saving in between
          items = items.filter(i => i.primaryKey !== item.primaryKey)
        } else {
          delete item.destroy

          if (isMultiSelect) {
            items = items.filter(i => i.primaryKey !== item.primaryKey)
            items.push({ ...item })
          } else {
            items = [item]
          }
        }
      })

      return items
    },
    [value, isMultiSelect]
  )

  const updateChildValues = () => {
    const childValue = getValues(`${namePrefix}field_values.${customField.options?.search_child_id}.value.0`)
    if (childValue) {
      setValue(
        `${namePrefix}field_values.${customField.options?.search_child_id}.value`,
        childValue.updatedAt ? [{ ...childValue, destroy: true }] : []
      )
    }
  }

  const onChange = (val?: SearchableCustomFieldOption[]) => {
    const items = onRemove(val)
    updateChildValues()
    onChangeProp?.(items)
  }

  return [selectValue, onChange] as [SearchableCustomFieldOption[], (val?: SearchableCustomFieldOption[]) => void]
}
