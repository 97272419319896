import parse from 'html-react-parser'
import { eventManager } from 'event-manager'

import { Box, IconName, Text, TextLink } from '@cutover/react-ui'
import { useAppsTimezoneValue } from 'main/recoil/data-access'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'
import { formatActivityDetailsValue, serializeIdsForSearch, useActivityProperties } from './activity-helper'
import { ActivityInsights } from './activity'
import { ConfigModel } from 'main/data-access'

type ActivityHeaderProps = {
  activity: ActivityVM
  activityConfig: ActivityConfig[]
  previousActivity: ActivityVM
  index: number
  hasSelectedTaskLink: boolean
  setSelectedTaskLink: (index: number | null) => void
  time: string
  filterTaskList?: (internalIdsSearchParam: string | undefined) => void
}

type SubjectProps = {
  text: string
  internalIds?: number[]
}

export const ActivityHeader = ({
  activity,
  activityConfig,
  time,
  index,
  hasSelectedTaskLink,
  setSelectedTaskLink,
  filterTaskList
}: ActivityHeaderProps) => {
  const { activityIcon, subject, action, activistName } = useActivityProperties(activity, activityConfig)
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const filterTasks =
    isReactRunbookEnabled && filterTaskList
      ? filterTaskList
      : (serializedIdsForSearch: string | undefined) =>
          eventManager.emit('filter-task-list-by-internal-ids', { serializedIdsForSearch })
  const commentIsDeleted =
    (activity.key === 'runbook.commented' || activity.key === 'task.commented') && activity.display?.reason
  const timezone = useAppsTimezoneValue()

  const toggleTaskFilterState = (internalIds: number[]) => {
    const serializedIdsForSearch = hasSelectedTaskLink ? undefined : serializeIdsForSearch(internalIds)
    setSelectedTaskLink(hasSelectedTaskLink ? null : index)
    filterTasks(serializedIdsForSearch)
  }

  return (
    <Box
      direction="row"
      gap="xsmall"
      pad={{ vertical: '4px' }}
      css={commentIsDeleted && !activity.parent ? 'opacity: 0.5' : ''}
      align="center"
      data-testid="activity-info"
    >
      <ActivityInsights time={time} icon={activityIcon as IconName} featured={activity.featured} />
      <Text
        truncate="tip"
        as="div"
        css="flex: 1; white-space: nowrap; text-overflow: ellipsis;
  overflow: hidden;"
      >
        <Text weight="bold">{activistName} </Text>
        {activity.display?.inline_message ? (
          <Text>{parse(formatActivityDetailsValue(activity.display?.inline_message, timezone, true))}</Text>
        ) : (
          renderSubject(subject, action, toggleTaskFilterState, hasSelectedTaskLink)
        )}
      </Text>
    </Box>
  )
}

const renderSubject = (
  subject: SubjectProps,
  action: string | undefined,
  handleSetFilters: Function,
  active: boolean
) => {
  return (
    <>
      <Text>{action} </Text>
      {subject.internalIds ? (
        <TextLink label={subject.text} active={!!active} onClick={() => handleSetFilters(subject.internalIds)} />
      ) : (
        <Text weight="bold">{subject.text}</Text>
      )}
    </>
  )
}
